/* globals Class, $H, Template */
var jQuery = require('jquery');
var _ = require('lodash');

(function() {
  var jui = window.jui;
  jui.FormSelectInput = Class.create(jui.Component, jui.ControlModel, {
    options: function($super, options) {
      /* jshint unused:vars */
      return $super($H({
        onChange: function() {},
        attributes: {className: 'jui-form-select'},
        onOpen: function() {},
        showSearchInput: true,
        width: 'off',
        template: function(r){return r.text;},
        dropdownCss: {fontSize: '11px'},
        hideSearch: true,
        tabindex: -1,
        selectOptions: [
          //{
          //name: 'Required String',
          //value: 'Required String',
          //className: 'optional Strings',
          //style: 'optional'
          //}
        ]
      }));
    },

    initialize: function($super, options) {
      $super(options);

      if(this.options.onblur) {
        this.addListener('blur', this.options.onblur);
      }
    },

    installUI: function($super) {
      $super();
      var selectOptions = this.createOptionsForSelect(this.options.selectOptions);


      this.input = this.insert(new Element('select', {
        className: 'form-select-input ' + this.options.attributes.className,
        tabindex: this.options.tabindex,
      })).update(selectOptions);

      this.select2 = jQuery(this.input).select2({
        showSearchInput: this.options.showSearchInput,
        width: this.options.width,
        formatResult: this.options.template,
        formatSelection: this.options.template,
        dropdownCssClass: this.options.attributes.className + ' ' + this.options.dropDownClassName,
        dropdownCss: this.options.dropdownCss,
        hideSearch: this.options.hideSearch
      });


      jQuery(this.input).on('open', this.handleOpen.bindAsEventListener(this));
      jQuery(this.input).on('open', this.options.onOpen.bind(this));
      jQuery(document.body).on('mouseup', this.handleEvents.bindAsEventListener(this));
    },

    handleOpen: function(e){
      /* jshint unused:vars */
      window.editor.keyController.requestFocus(this, true);
      jQuery(this.input).on('change', this.options.onChange.bind(this));
    },

    handleEvents: function(e) {
      var id = _.isFunction((e.target).up) ? (e.target).up('#'+ this.options.attributes.id) : null;
      if(_.isEmpty(id)) {
        jQuery(this.input).select2('close');
        jQuery(this.input).off('change');
      }
    },

    createOptionsForSelect: function(selectOptions) {
      var html = '';
      selectOptions.each(function(o) {
        var option =
          new Template('<option value="#{value}">#{name}</option>');
        var result = option.evaluate(o);
        html += result;
      });
      return html;
    },

    getValue: function() {
      return this.input.getValue();
    },

    setValue: function(value) {
      this.input.setValue(value);
      try {
        jQuery(this.input).select2('val', value);
      } catch(e) {
        //Jasmine tests don't allow select2.js to attach to the element
        //this try catch is to allow tests to run through despite select2
        //is not attaching.  The reason this still works is because we are
        //getting and setting the values on the actual select input which
        //is hidden.
      }
    },

    enable: function() {
      jQuery(this.input).select2('enable');
    },

    disable: function() {
      jQuery(this.input).select2('disable');
    },

    setSelectOptions: function(selectOptions) {
      jQuery(this.input).empty().append(this.createOptionsForSelect(selectOptions));
    }

  });

  jui.FormSelectInputWithHint = Class.create(jui.FormSelectInput, {
    installUI: function($super) {
      $super();
      this.hint = this._hint();
      if (this.options.hintText === '' || this.options.hintText === null) {
        this.hintText.hide();
      }
    },

    _hint: function() {
      var element = new Element('div', { className: 'input-hint' });
      element.update(this.options.hintText);
      return this.insert(element);
    }
  });

})();
