require('ub/config/raven-base-config.js'); // adds to window.xxx

var Raven = require('raven-js');
var _ = require('lodash');

var ErrorNotifier = function() {
  this._errorContextStack = [];
  this._userContext = {};
  return this;
};

ErrorNotifier.prototype = (function(){
  return {
    _initRavenGlobalContext: function() {
      this._baseContext = _.merge(
        {},
        (window.RavenBaseContext || {}),
        {extra:{},
         tags: {
          lp_context: window.lp_raven_context,
          editor_version       : window.builderVersion || 'dev',
          undo_manager_execute : false
        }});
      this._resetRavensBaseContext();
    },

    addTagsToBaseContext: function(tags) {
      // public api for adding extra tags after the error notifier
      // is instantiated. The editor object does this.
      this._baseContext.tags = _.merge(this._baseContext.tags, tags);
      this._resetRavensBaseContext();
    },

    _resetRavensBaseContext: function() {
      // The Raven context passed into captureException/Message
      // affects the normal context as set by Raven.setTagsContext
      // so we must reset it after each call
      Raven.setTagsContext(JSON.parse(JSON.stringify(this._baseContext.tags)));
    },

    pushErrorContext: function(context) {
      this._errorContextStack.push(context);
      return this;
    },

    popErrorContext: function() {
      return this._errorContextStack.pop();
    },

    setUserContext: function(context) {
      //TODO: check if Raven is merging this data in or if it
      //overwrites existing context. Suspect it's overwriting.
      this._userContext = JSON.parse(JSON.stringify(context));
      Raven.setUserContext(context);
      return this;
    },

    _getErrorStackContext: function(options){
      return _.reduce(_.flattenDeep([this._baseContext,
                          this._errorContextStack,
                          options]),
               _.merge,
               {});
    },

    captureException: function(error, options) {
      if (window.gon && window.gon.env !== 'test') {
        Raven.captureException(error, this._getErrorStackContext(options));
        this._resetRavensBaseContext(); // do not remove, see comment in resetBaseContext
      }

      return this;
    },

    captureMessage: function(message, options) {
      if (window.gon && window.gon.env !== 'test') {
        Raven.captureMessage(message, this._getErrorStackContext(options));
        this._resetRavensBaseContext(); // do not remove, see comment in resetBaseContext
      }

      return this;
    },

    install: function() {
      this._initRavenGlobalContext();

      if (window.gon && window.gon.user) {
        this.setUserContext(window.gon.user);
      }

      return this;
    }
  };
})();

module.exports = ErrorNotifier;
