/* globals lp,Class */
var jQuery = require('jquery');

module.exports = Class.create(lp.pom.VisibleElementModel, {
  initialize: function($super, element, jso) {
    $super(element, jso);
    if (!this.exists('geometry.maintainAR')) {
      this.set('geometry.maintainAR', true);
    }

    this.addBreakpointModifyableAttributes({
      content: {
        html: false
      }
    });
    // ensure that the width / height of the content are normalized to percentages
    this.setVideoContent(this.get('content.html'));

    //Make sure that the video content is *not* unique between breakpoints. If
    //the breakpoint has it's own content.html we should delete it.
    var breakpoints = this.safeGet('breakpoints');
    if (breakpoints) {
      Object.keys(breakpoints).each((breakpoint) => this.forceDeleteAttrOnBreakpoint(breakpoint, 'content.html'));
    }
  },

  getVideoContent: function() {
    return this.get('content.html');
  },

  setVideoContent: function(videoString, um) {
    if(this.element.page.isEditMode()){
      var videoContent = this._normalizeVideoContent(videoString);

      this.set('content.html', videoContent, um);
    }
  },

  _normalizeVideoContent: function(videoContentString) {
    var videoObj = this._makeVideoSizeRelativeToContainer(videoContentString);
    return this._setWmode(videoObj);
  },

  _makeVideoSizeRelativeToContainer: function(videoContent) {
    // For the elements that have width and height attributes, we extract
    // values upon initial insertion or update of the content string,
    // set that on the model and then make the width/height of the
    // tag 100%

    var $container = jQuery('<div/>').html(videoContent);
    var $videoObj = $container.find('iframe, object, video');

    if ($videoObj.length !== 0) {
      var origWidth = $videoObj.attr('width');
      var origHeight = $videoObj.attr('height');
      var fixedSize = {width: '100%', height: '100%'};

      this._normalizeAndSetSize('width', origWidth);
      this._normalizeAndSetSize('height', origHeight);

      $videoObj.attr(fixedSize);

      if (($videoObj).prop('tagName').toLowerCase() === 'object') {
        $videoObj.children('embed').attr(fixedSize);
      }
    }

    return $container[0];
  },

  _normalizeAndSetSize: function(attr, value /* str */) {
    if(value.indexOf('%') === -1) {
      // value is a pixel value 'px'
      this.set('geometry.size.' + attr, parseInt(value.replace(/[^0-9]/g, ''), 10));
    } else { // 'xxx%'
      if(parseInt(value, 10) !== 100) {
        //TODO: Log warning so we can see if our assumption is
        // incorrect on production poms
        // don't save on the model
      } // else no action required
    }
  },

    //Handles the add wmode to both object and iframe elements.  setting wmode
    //to opaque will make these two elements and flash behave properly
    //regarding z-index.
    _setWmode: function(videoObj) {
      var iframeVideo = videoObj.querySelector('iframe');
      var objectVideo = videoObj.querySelector('object');

      if (iframeVideo) {
        this._setWmodeIframe(iframeVideo);
      } else if (objectVideo) {
        this._setWmodeObject(objectVideo);
      }

      return videoObj.innerHTML;
    },

    //Adds wmode to the iframe element so that way the z-index of videos
    //works as expected.
    _setWmodeIframe: function( videoObj ) {
      if(!this._hasWmode( videoObj ) ) {
        var paramPattern = new RegExp( /\?/g );
        var l = paramPattern.test( videoObj.src ) ? '&' : '?';
        var newSrc = decodeURIComponent(videoObj.src) + l + 'wmode=opaque';
        videoObj.src = newSrc;
      }
      return videoObj;
    },

    //Adds wmode to the object element so that way the z-index of videos
    //works as expected.
    _setWmodeObject: function( videoObj ) {
      if( !this._hasWmode( videoObj ) ) {

        var embedObj = videoObj.select( 'embed' ).first() || videoObj;

        var paramTag = new Element(
          'param', { name: 'wmode', value: 'opaque' }
        );

        embedObj.writeAttribute( 'wmode', 'opaque' );
        videoObj.insert( paramTag, 'top' );
      }
      return videoObj;
    },

    _hasWmode: function(videoObject) {
      if (videoObject.nodeName.toLowerCase() === 'object') {
        return this._hasWmodeObj(videoObject);
      } else if (videoObject.nodeName.toLowerCase() === 'iframe') {
        return this._hasWmodeIframe(videoObject);
      }
    },

    //Determines if the wmode has been injected into the object element yet
    //or not.
    _hasWmodeObj: function( videoObj ) {
      var eles = videoObj.childElements();
      var found = false;
      eles.each( function(ele) {
        if( ele.nodeName.toUpperCase() === 'PARAM' &&
         ele.readAttribute( 'name' ).toLowerCase() === 'wmode' ) {
          found = true;
          return;
        }
      } );
      return found;
    },

    _hasWmodeIframe: function( videoObj ) {
      var wmodePattern = new RegExp( /wmode/gi );
      return wmodePattern.test( videoObj.src );
    }

});
