/* globals Class, $H */
var _ = require('lodash');
var stripScripts = require('ub/utils').stripScripts;

var PanelWarning = require('ub/ui/panel-warning');

window.jui.FormLinkInput2 = Class.create(window.jui.Component, window.jui.ControlModel, {
  options: function($super, options){
    return $super($H({
      attributes:{className:'form-elm link-input2'},
      label:'Link'
    }).merge(options));
  },

  initialize: function($super, options){
    $super(options);

    this.panelState = {
      urlWarningShown: undefined
    };

    if (this.options.onkeydown){ this.addListener('keydown', this.options.onkeydown); }
    if (this.options.onkeyup){ this.addListener('keyup', this.options.onkeyup); }
    if (this.options.onfocus){ this.addListener('focus', this.options.onfocus); }
    if (this.options.onblur){ this.addListener('blur', this.options.onblur); }
  },

  _updateUI: function() {
    this.urlWarning = this.urlWarningComponent.update(this.urlWarning, this.panelState.urlWarningShown);
  },

  installUI: function($super) {
    /* jshint maxcomplexity:15 */
    $super();
    var self = this;

    this.label = this.insert(new Element('label', {className: 'form-elm-label'}).update(this.options.label));
    this.link = this.insert(new Element('input', {
      type:'text',
      className:'text form-elm-input-text'
    }));

    if (this.options.note) {
      this.insert(new Element('span', {className: 'note'}).update(this.options.note));
    }

    this.urlWarningComponent = PanelWarning('#invalid-url.svg-ico-warning4', 'Invalid URL');
    this.urlWarning = this.urlWarningComponent.install();

    this.insert(this.urlWarning);

    if(this.options.passparams) {
      this.appendParameters = this.insert(new window.jui.FormCheckboxInput({
        label: 'Pass through URL parameters',
        inputId: 'append-params-checkbox',
        attributes: {
          className: 'form-elm checkbox-input append-params'
        },
        onclick: function(e) {
          if (self.options.onPassParams) {
            self.options.onPassParams(e);
          }
        }
      }));
    }

    this.target = this.insert(
      new window.jui.FormSelect({
        label             : 'Target',
        id                : 'jui-form-target-select',
        dropDownClassName : 'editor-info-panel-select jui-drop-down',
        selectOptions     : this._buildTargetOptionList(),
        action            : this.onBlur.bind(this)
      })
    );

    if (this.options.hideTarget) {
      this.target.hide();
    }

    if (this.options.inputPosition) {
      this.input.addClassName(this.options.inputPosition);
    }

    this.link.observe('focus', this.onFocus.bind(this));
    this.link.observe('blur', this.onBlur.bind(this));
    if (this.options.onkeyup){
      this.link.observe('keyup', this.onkeyup.bind(this));
    }
    if (this.options.onkeydown){
      this.link.observe('keydown', this.onkeydown.bind(this));
    }
  },

  _validateUrl: function(url) {
    this.panelState.urlWarningShown = ! (window.Flog.isValidUri(url) || _.isEmpty(url));
  },

  setValue: function(value0) {
    var value = _.clone(value0); // so we don't trash the passed-in value
    this.link.value = this.addProtocol(value.url);

    this._validateUrl(value.url);

    this.setAppendParamsValue(value.passparams);

    if (this.target) {
      this.target.setSelectedValue(value.target);
      this.target.setEnabled(value.url !== '');
    }

    this._updateUI();
  },

  setAppendParamsValue: function(value) {
    if(this.options.passparams) {
      this.appendParameters.setValue(value);
    }
  },

  addProtocol: function(url) {
    /* jshint scripturl:true */
    if (url.startsWith('mailto:') ||
        url.startsWith('tel:') ||
        url.startsWith('sms:') ||
        url.startsWith('javascript:') ||
        url.startsWith('#') ||
        url.include('://') ||
        url === '' ||
        url === null ||
        Object.isUndefined(url)) {
      return url || '';
    }
    return 'https://' + url;
  },

  getPassParamsValue: function() {
    if(this.options.passparams) {
      return this.appendParameters.getValue();
    }
  },

  getValue: function() {
    var linkValue = this.link.getValue() || '';
    var sanitizedUrl = stripScripts(linkValue.trim());

    var value = {
      url        : this.addProtocol(sanitizedUrl),
      target     : this.target ? this.target.getValue() : '',
      passparams : this.getPassParamsValue()
    };

    return value;
  },

  onFocus: function(e) {
    if (this.isEnabled()) {
      if (_.isFunction(e.stop)) {
        e.stop();
      }
      this.fireEvent('focus', this);
    }
  },

  onkeydown: function(e) {
    if (e.keyCode === Event.KEY_RETURN) {
      this.blur();
      e.stop();
    }
    this.fireEvent('keydown', this);
  },

  onkeyup: function() {
    if (this.target) {
      this.target.setEnabled(this.link.value !== '');
    }
    this.fireEvent('keyup', this);
  },

  onBlur: function() {
    this.fireEvent('blur', this);
  },

  blur: function() {
    this.link.blur();
  },

  _buildTargetOptionList: function() {
    // Builds a new option list
    var selectOptions = [
        {name: window.editor.mainPage.getConfigValue('selfTargetLabel'), value: '_self' }
      ];

    if ( ! this.options.hideTarget) {
      var additionalOptions = [
        {name: 'New tab or window', value: '_blank'},
        {name: 'Topmost frame', value: '_top'},
        {name: 'Parent frame', value: '_parent'}
      ];

      if (this.options.elmType === 'lp-pom-form') {
        additionalOptions = [
          {name: 'Parent frame', value: '_parent'}
        ];
      }

      selectOptions = selectOptions.concat(additionalOptions);
    }

    return selectOptions;
  }
});
