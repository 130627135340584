/* globals lp, $H, Class, jui */
var _ = require('lodash');
var jQuery = require('jquery');
var labelStyles = require('ub/elements/label-styles').default;
var getDropShadowCss = require('ub/control/shadow-effect').default;
var getButtonElementPublishUrl =
  require('ub/control/image-publish-urls').getButtonElementPublishUrl;

var ButtonElement = Class.create(lp.pom.VisibleElement, lp.ModuleComponent, {
  type: 'lp-pom-button',

  options: function ($super, options) {
    return $super($H({ elementType: 'a' }).merge(options));
  },

  initialize: function ($super, page, jso) {
    this.states = ['up', 'hover', 'active'];
    this.gradientSpecifiers = [
      '-webkit-linear-gradient',
      '-moz-linear-gradient',
      '-ms-linear-gradient',
      '-o-linear-gradient',
      'linear-gradient',
    ];
    this.activeState = this.states[0];

    $super(page, jso, {
      elementType: jso.containerId && jso.containerId.startsWith('lp-pom-form-') ? 'button' : 'a',
    });

    this.page.addListener('beforePageSave', this);
    this.page.addListener('elementRemoved', this);
  },

  getModelClass: function () {
    return lp.module.button.ButtonModel;
  },

  isFormButton: function () {
    var parent = this.getParentElement();
    return parent && parent.getType() === 'lp-pom-form';
  },

  _getTarget: function () {
    var target = this.model.safeGet('action.target');
    var actionType = this.model.get('action.type');

    if (actionType === 'phone' || actionType === 'email') {
      // 'tel:' links do not work in iOS when the page is in an Iframe (e.g. lightbox), unless the
      // link has a target of '_top' or '_blank'. On some platforms '_blank' causes a new empty
      // tab to be opened, but '_top' seems to work well across all platforms with no side
      // effects. (See CN-713)
      return '_top';
    }

    if (this.page.isFormConfirmation() && !target) {
      return '_parent';
    }

    return target;
  },

  _setHrefAndTarget: function () {
    var href = this.model.getCtaifiedLink();
    var target = this._getTarget();

    if (href) {
      this.getViewDOMElement().href = href;
      this.getViewDOMElement().target = target;
    }
  },

  _setDownloadValue: function () {
    var asset = this.model.get('action.asset');
    if (asset.protected_download) {
      this.getViewDOMElement().setAttribute('data-asset-uuid', asset.uuid);
      this.getViewDOMElement().href = '#';
    } else {
      var asset_url;
      if (this.page.isPublishMode() && this.model.exists('action.asset.unique_url')) {
        asset_url = asset.unique_url;
      } else {
        // isPreviewMode
        asset_url = asset.content_url;
      }
      this.getViewDOMElement().href = asset_url.split('/').last().split('?')[0];
      this.getViewDOMElement().rel = 'download';
    }
  },

  _setCloseValue: function () {
    this.getViewDOMElement().href = '#';
    this.getViewDOMElement().setAttribute('data-close', 'true');
  },

  initView: function () {
    //TODO: SPLIT this
    /* jshint maxcomplexity:18 */
    var domElement = this.getViewDOMElement();

    if (this.page.isPublishOrPreviewMode()) {
      switch (this.model.get('action.type')) {
        case 'url':
          this._setHrefAndTarget();
          break;

        case 'email':
          this._setHrefAndTarget();
          break;

        case 'phone':
          this._setHrefAndTarget();
          break;

        case 'externalLightbox':
          if (this.model.safeGet('action.url')) {
            domElement.href = this.model.getCtaifiedLink();
            domElement.target = '_blank';
          }
          break;

        case 'internalLightbox':
          if (this.model.safeGet('action.lightboxId')) {
            domElement.href = this.model.getInternalLightboxPublishedOrPreviewUrl();
            domElement.target = '_blank';
          }
          break;

        case 'download':
          this._setDownloadValue();
          break;

        case 'form':
          this.getViewDOMElement().type = 'submit';
          break;

        case 'close':
          this._setCloseValue();
          break;

        default: // none
      }

      if (this.page.getConfigValue('addGoalAttributeToLinks') && this.isActiveGoal()) {
        domElement.setAttribute('data-goal', '');
      }

      if (this.isAppendParamsEnabled()) {
        domElement.setAttribute('data-params', 'true');
      }
    } else {
      // isEditMode
      domElement.href = '#';
    }

    this.label = this.view.insert(new Element('span', { className: 'label' }));

    if (this.model.exists('content.label')) {
      this.label.update(this.model.get('content.label'));
    }

    if (this.page.getConfigValue('allowExternalLightboxLinks')) {
      if (!this.model.exists('lightboxSize')) {
        this.model.set('lightboxSize', ButtonElement.lightboxSizeDefault);
      }
    }

    // JS: TODO: this is a fix for some possible model corruption due to lp-592
    // it should probably be added to an updater
    if (this.hasBorder() && !this.model.exists('style.up.border.width')) {
      this.model.set('style.up.border', { style: 'none' });
    }
  },

  installModelChangeHandlers: function ($super) {
    $super();
    this.addModelChangeHandler(function (accessor) {
      if (accessor === 'style') {
        this.applyStyleAttributes();
      }
    });
  },

  elementRemoved: function (e) {
    if (e.data.element.type === 'lp-pom-form') {
      var m = this.model;
      if (m.exists('action.type') && m.get('action.type') === 'form') {
        m.set('action.type', 'url');
      }
    }
  },

  hasBorder: function () {
    return (
      this.model.exists('style.up.border.style') &&
      this.model.get('style.up.border.style') !== 'none'
    );
  },

  getBorderWidth: function () {
    return this.model.exists('style.up.border.width') ? this.model.get('style.up.border.width') : 0;
  },

  adjustLabelSize: function () {
    // See the TODO-TR note in updateCSSRules!
    if (this.page.isEditMode()) {
      var h = this.label.getHeight();
      this.model.set('computations.labelHeight', h);
      this.label.style.marginTop = -Math.round(h / 2) + 'px';
    } else if (!this.model.exists('computations.labelHeight')) {
      // isPublishOrPreviewMode
      this.model.set('computations.labelHeight', this.label.getHeight());
    }
  },

  applyStyleAttributes: function ($super) {
    if (!this.view.visible()) {
      return;
    }

    $super();
    this.updateCSSRules();
    // this must come after the other css rules as it writes inline styles on EDIT context
    this.adjustLabelSize();
  },

  getFonts: function () {
    return this.model.getValuesFromAllBreakpoints('style.fontFamily');
  },

  applyBackground: function () {
    // It appears this might have been left here so
    // it doesn't call applyBackground on the parent class (visible_element.js)
  },

  updateCSSRules: function (options) {
    options = options || {};
    options.state = options.state || 'up';
    var removeAttributes = [];
    var rules = [];

    if (this.page.isEditMode()) {
      var state =
        window.editor.activeElement === this
          ? this.getModule().getPropertiesPanel().activeState
          : this.states[0];
      var addRemove = this.getCSSRulesForState(state, false);
      rules = rules.concat(addRemove.addRules);
      removeAttributes = removeAttributes.concat(addRemove.removeAttributes);
    } else {
      // isPublishOrPreviewMode
      this.states.each(function (state) {
        rules = rules.concat(this.getCSSRulesForState(state, true).addRules);
      }, this);
      if (this.model.exists('computations.labelHeight')) {
        // TODO-TR: once visible_element:applyStylesToDom supports
        // setting styles on sub elements, apply this to both contexts
        // and remove the logic in this.adjustLabelSize
        var labelHeight = -Math.round(this.model.get('computations.labelHeight') / 2);

        rules.push({
          selector: '#' + this.id + ' .label',
          attribute: 'margin-top',
          value: this.page.getUnit(labelHeight),
        });
      }
    }

    if (this.model.exists('style.fontSize')) {
      rules.push({
        selector: '#' + this.id,
        attribute: 'font-size',
        value: this.page.getUnit(this.model.get('style.fontSize')),
      });
      rules.push({
        selector: '#' + this.id,
        attribute: 'line-height',
        value: this.page.getUnit(Math.round(this.model.get('style.fontSize') * 1.2)),
      });
    }

    if (this.model.exists('style.fontWeight')) {
      rules.push({
        selector: '#' + this.id,
        attribute: 'font-weight',
        value: this.model.get('style.fontWeight'),
      });
    }

    if (this.model.exists('style.fontFamily')) {
      rules.push({
        selector: '#' + this.id,
        attribute: 'font-family',
        value: this.model.get('style.fontFamily'),
      });
    }

    rules.push({
      selector: '#' + this.id,
      attribute: 'font-style',
      value: this.model.safeGet('style.fontStyle') || 'normal',
    });

    rules.push({
      selector: '#' + this.id,
      attribute: 'text-align',
      value: 'center',
    });

    rules.push({
      selector: '#' + this.id,
      attribute: 'background-repeat',
      value: 'no-repeat',
    });

    if (this.page.isEditMode()) {
      options.removeAttrs = removeAttributes;
      this.applyStylesToDom(rules, options);
    } else {
      // isPublishOrPreviewMode
      this.applyPageStyles(rules, options);
    }

    this._updateLabel();
  },

  _updateLabel: function () {
    var textStyles = this.model.safeGet('style.textStyles');
    var labelContent = this.model.safeGet('content.label');

    this.label.innerHTML = labelStyles.getLabelHtml(textStyles, labelContent);
  },

  generateGradientByState: function (state) {
    var methodName = 'generate' + state.capitalize() + 'State';
    return this.model[methodName](this.model.get('style.' + state + '.auto'));
  },

  _getAutoGenerate: function (state) {
    return this.model.exists('style.' + state + '.auto')
      ? this.model.get('style.' + state + '.auto')
      : false;
  },

  _getAutoGradient: function () {
    return this.model.exists('style.autoGradient') ? this.model.get('style.autoGradient') : false;
  },

  _getStateStyle: function (state, autoGenerate) {
    return autoGenerate ? this.generateGradientByState(state) : this.model.get('style.' + state);
  },

  activate: function ($super) {
    if (this.model.linksToInternalLightbox()) {
      jQuery(this.view.e).addClass('lp-pom-lightbox-button-elm');
    }

    $super();
  },

  deactivate: function ($super) {
    jQuery(this.view.e).removeClass('lp-pom-lightbox-button-elm');

    $super();
  },

  getCSSRulesForState: function (state, createPseudoClass) {
    var addRules = [];
    var removeAttributes = [];

    var selector =
      '#' + this.id + (state !== this.states[0] && createPseudoClass ? ':' + state : '');

    var fillType = this.getCurrentFillType();
    var autoGenerate = this._getAutoGenerate(state);
    var autoGradient = this._getAutoGradient();
    var stateStyle = this._getStateStyle(state, autoGenerate);

    var rules;

    switch (fillType) {
      case 'solid':
        rules = this.createCSSRulesForSolidFill(stateStyle, selector, state);
        break;
      case 'gradient':
        if (autoGradient) {
          // TODO move this condition into a 'fixer/upgrader' file to better keep track of these
          // temp fixes.
          // This is here to fix the bug found in PB-101 where setting
          // 'Create appearance based on "Up" state' is deselected
          // and the state ends up with no gradient of it's own. If the source of the issue is found
          // then this code can be removed
          stateStyle =
            !autoGenerate && !stateStyle.gradient
              ? this.generateGradientByState(state)
              : stateStyle;

          rules = this.createCSSRulesForAutoGradientFill(stateStyle, selector, state);
        } else {
          rules = this.createCSSRulesForCustomGradientFill(stateStyle, selector, state);
        }
        break;
      case 'image':
        rules = this.createCSSRulesForImageFill(stateStyle, selector);
    }

    addRules = addRules.concat(rules.addRules);
    removeAttributes = addRules.concat(rules.removeAttributes);

    if (stateStyle.color) {
      addRules.push({ selector: selector, attribute: 'color', value: '#' + stateStyle.color });
    }
    if (stateStyle.border) {
      if (stateStyle.border.style) {
        // autoscale border width
        addRules.push({
          selector: selector,
          attribute: 'border-style',
          value: stateStyle.border.style,
        });
        addRules.push({
          selector: selector,
          attribute: 'border-width',
          value: this.page.getUnit(stateStyle.border.width),
        });
        addRules.push({
          selector: selector,
          attribute: 'border-color',
          value: '#' + stateStyle.border.color,
        });
      }
    }

    return { addRules: addRules, removeAttributes: removeAttributes };
  },

  getBoxShadowEffect: function (element) {
    const hasBoxShadowEffect =
      element.model.exists('style.effect') &&
      !element.type.includes('text') &&
      Array.isArray(element.model.safeGet('style.effect.enabledTypes')) &&
      element.model.safeGet('style.effect.enabledTypes').includes('dropShadow');
    const dropShadowEffect = hasBoxShadowEffect
      ? getDropShadowCss(element.model.safeGet('style.effect'))
      : 'none';
    return dropShadowEffect;
  },

  createCSSRulesForSolidFill: function (style, selector, state) {
    var addRules = [];
    var removeAttributes = [];

    //TODO: lines 338-347 modify the model directly via "style"

    style.backgroundColor =
      typeof style.backgroundColor === 'undefined' ? 'transparent' : style.backgroundColor;
    style.opacity = typeof style.opacity === 'undefined' ? 100 : style.opacity;

    if (style.backgroundColor === 'transparent') {
      style.opacity = 0;
      if (this.model.exists('style.up.savedBackgroundColor')) {
        style.backgroundColor = this.model.get('style.up.savedBackgroundColor');
      }
    }

    var color = jui.ColorMath.hexToRgb(jui.ColorMath.normalizeHexValue(style.backgroundColor));
    var opacity = style.opacity / 100;
    color.push(opacity);

    addRules.push({
      selector: selector,
      attribute: 'background',
      value: 'rgba(' + color.join() + ')',
    });

    const dropShadowEffect = this.getBoxShadowEffect(this);

    if (this.model.hasHighlight() && style.backgroundColor !== 'transparent') {
      if (state === 'active') {
        addRules.push({
          selector: selector,
          attribute: 'box-shadow',
          value: `${this.model.calculateShadow(style.backgroundColor)}, ${dropShadowEffect}`,
        });
      } else {
        addRules.push({
          selector: selector,
          attribute: 'box-shadow',
          /* This was previously using from/to which is incorrect in the "solid" context.
           * calculateHighlight expects 2 args */
          value: `${this.model.calculateHighlight(style.backgroundColor, null)}, ${dropShadowEffect}`,
        });
      }
    } else {
      addRules.push({
        selector: selector,
        attribute: 'box-shadow',
        value: `${dropShadowEffect}`,
      });
    }

    if (this.model.hasTextShadow() && style.backgroundColor !== 'transparent') {
      addRules.push({
        attribute: 'text-shadow',
        value: this.model.calculateTextShadow(style.backgroundColor),
      });
    } else {
      addRules.push({
        attribute: 'text-shadow',
        value: 'none',
      });
    }

    return { addRules: addRules, removeAttributes: removeAttributes };
  },

  createCSSRulesForAutoGradientFill: function (style, selector, state) {
    var addRules = [];
    var removeAttributes = [];

    var g = {
      to: style.reverseGradient ? style.gradient.from : style.gradient.to,
      from: style.reverseGradient ? style.gradient.to : style.gradient.from,
    };

    var baseColor = style.backgroundColor;

    if (baseColor === 'transparent') {
      baseColor = style.savedBackgroundColor;
    }

    addRules.push({
      selector: selector,
      attribute: 'background-color',
      value: '#' + style.backgroundColor,
    });

    this.gradientSpecifiers.each(function (s) {
      addRules.push({
        selector: selector,
        attribute: 'background',
        value: s + '(#' + g.from + ', #' + g.to + ')',
      });
    });

    const dropShadowEffect = this.getBoxShadowEffect(this);

    if (this.model.hasHighlight() && style.backgroundColor !== 'transparent') {
      if (state === 'active') {
        addRules.push({
          selector: selector,
          attribute: 'box-shadow',
          value: `${this.model.calculateShadow(style.backgroundColor)}, ${dropShadowEffect}`,
        });
      } else {
        addRules.push({
          selector: selector,
          attribute: 'box-shadow',
          value: `${this.model.calculateHighlight(g.from, g.to)}, ${dropShadowEffect}`,
        });
      }
    } else {
      addRules.push({
        selector: selector,
        attribute: 'box-shadow',
        value: `${dropShadowEffect}`,
      });
    }

    if (this.model.hasTextShadow() && style.backgroundColor !== 'transparent') {
      addRules.push({
        attribute: 'text-shadow',
        value: this.model.calculateTextShadow(style.backgroundColor),
      });
    } else {
      addRules.push({
        attribute: 'text-shadow',
        value: 'none',
      });
    }

    return { addRules: addRules, removeAttributes: removeAttributes };
  },

  createCSSRulesForCustomGradientFill: function (style, selector, state) {
    var addRules = [];
    var removeAttributes = [];
    var g = style.gradient;
    var from = style.reverseGradient ? g.to : g.from;
    var to = style.reverseGradient ? g.from : g.to;

    addRules.push({
      selector: selector,
      attribute: 'background-color',
      value: '#' + style.backgroundColor,
    });

    this.gradientSpecifiers.each(function (s) {
      addRules.push({
        selector: selector,
        attribute: 'background',
        value: s + '(#' + from + ', #' + to + ')',
      });
    });

    const dropShadowEffect = this.getBoxShadowEffect(this);

    if (this.model.hasHighlight()) {
      if (state === 'active') {
        addRules.push({
          attribute: 'box-shadow',
          selector: selector,
          value: `${this.model.calculateShadow(from, to)}, ${dropShadowEffect}`,
        });
      } else {
        addRules.push({
          attribute: 'box-shadow',
          selector: selector,
          value: `${this.model.calculateHighlight(from, to)}, ${dropShadowEffect}`,
        });
      }
    } else {
      addRules.push({
        attribute: 'box-shadow',
        selector: selector,
        value: `${dropShadowEffect}`,
      });
    }

    if (this.model.hasTextShadow() && style.backgroundColor !== 'transparent') {
      addRules.push({
        attribute: 'text-shadow',
        value: this.model.calculateTextShadow(g.from),
      });
    } else {
      addRules.push({
        attribute: 'text-shadow',
        value: 'none',
      });
    }

    return { addRules: addRules, removeAttributes: removeAttributes };
  },

  createCSSRulesForImageFill: function (style, selector) {
    var addRules = [];
    var removeAttributes = ['text-shadow', 'box-shadow'];

    var bg = style.backgroundColor;
    var bgColor = !bg || bg === 'transparent' ? 'transparent' : bg;
    var opacity = typeof style.opacity === 'undefined' ? 100 : style.opacity;

    if (bgColor === 'transparent') {
      opacity = 0;
      if (this.model.exists('style.up.savedBackgroundColor')) {
        bgColor = this.model.get('style.up.savedBackgroundColor').replace('#', '');
      }
    }

    var color = jui.ColorMath.hexToRgb(jui.ColorMath.normalizeHexValue(bgColor));
    opacity = opacity / 100;
    color.push(opacity);

    const dropShadowEffect = this.getBoxShadowEffect(this);

    addRules.push({
      selector: selector,
      attribute: 'text-shadow',
      value: 'none',
    });

    addRules.push({
      selector: selector,
      attribute: 'box-shadow',
      value: `${dropShadowEffect}`,
    });

    addRules.push({
      selector: selector,
      attribute: 'background-color',
      value: 'rgba(' + color.join() + ')',
    });

    if (style.image) {
      var publishUrl = this.page.isPublishOrPreviewMode()
        ? getButtonElementPublishUrl(this, style.image)
        : this.page.decorateImageSrc(style.image.unique_url || style.image.content_url);

      addRules.push({
        selector: selector,
        attribute: 'background-image',
        value: 'url(' + publishUrl + ')',
      });

      addRules.push({
        attribute: 'background-size',
        value: '100%',
      });
    }

    return { addRules: addRules, removeAttributes: removeAttributes };
  },

  updateLabel: function (newValue) {
    this.label.update(newValue || '');

    this.adjustLabelSize();
  },

  makeAdjustments: function (details) {
    var accessor = details.accessor;

    var labelChangedAccessors = [
      'geometry.size',
      'style.fontSize',
      'style.fontWeight',
      'style.fontFamily',
      'style.textStyles.strong',
      'style.textStyles.em',
    ];

    var goalChangedAccessors = [
      'action.type',
      'action.asset',
      'action.url',
      'action.phoneNumber',
      'action.lightboxId',
    ];

    if (_.includes(labelChangedAccessors, accessor)) {
      this.adjustLabelSize();
    } else if (_.includes(goalChangedAccessors, accessor)) {
      this.fireEvent('goalChanged', { accessor: accessor, value: details.value });
    } else if (accessor === 'content.label') {
      this.updateLabel(details.value);
    }
  },

  modelChanged: function ($super, e) {
    var details = $super(e);

    if (details.accessor.startsWith('style')) {
      this.updateCSSRules();
    }

    if (
      this.states
        .map(function (s) {
          return 'style.' + s + '.border';
        })
        .indexOf(details.accessor) > -1
    ) {
      this.updateDimensions();
      this.updateOffset();
      this.adjustLabelSize();
    }

    this.makeAdjustments(details);
  },

  getCurrentFillType: function () {
    return this.model.getCurrentFillType();
  },

  isLinkEditable: function () {
    if (this.model.exists('constraints.linkEditable')) {
      return this.model.get('constraints.linkEditable');
    }
    return true;
  },

  isAppendParamsEnabled: function () {
    return (
      (this.model.safeGet('action.passparams') || this.model.linksToInternalLightbox()) &&
      this.model.safeGet('action.type') !== 'phone'
    );
  },

  canHaveGoals: function () {
    return true;
  },

  _getPhoneGoal: function () {
    if (this.model.exists('action.phoneNumber')) {
      return [
        {
          type: 'phone',
          url: this.model.getPhoneUrl(),
        },
      ];
    }

    return [];
  },

  _getRegularUrlGoal: function () {
    var url = this.model.safeGet('action.url');

    if (url && !/^(#|mailto\:)/.test(url)) {
      return [
        {
          type: 'link',
          url: url,
        },
      ];
    }

    return [];
  },

  _getInternalLightboxGoal: function () {
    var lightboxRefId = this.model.safeGet('action.lightboxId');

    // Make sure we have a defined lightbox before we allow goals for it.
    if (!lightboxRefId) {
      return [];
    }

    return [
      {
        type: 'lightbox',
        url: this.model.getInternalLightboxUrl(),
        lightboxRefId: lightboxRefId,
      },
    ];
  },

  _getDownloadGoal: function () {
    var asset = this.model.safeGet('action.asset');

    if (asset && !asset.protected_download) {
      var asset_url = this.page.isPublishMode() ? asset.unique_url : asset.content_url;
      return [
        {
          type: 'file',
          url: asset_url.split('/').last().split('?')[0],
        },
      ];
    }

    return [];
  },

  getGoals: function () {
    var type = this.model.safeGet('action.type');

    if (_.includes(['url', 'externalLightbox', ''], type)) {
      return this._getRegularUrlGoal();
    } else if (type === 'internalLightbox') {
      return this._getInternalLightboxGoal();
    } else if (type === 'download') {
      return this._getDownloadGoal();
    } else if (type === 'phone') {
      return this._getPhoneGoal();
    }

    return []; // Fallback
  },

  destroy: function () {
    this.page.style.removeCSSRules('#' + this.id);
    this.page.removeListener('elementRemoved', this);
    this.page.removeListener('beforePageSave', this);
    this.page.style.updatePageStyles();
  },

  dblclick: function (e) {
    Event.stop(e);
    this.getModule().getPropertiesPanel().controls.buttonLabel.focusAndSelect();
    this.fireEvent('dblclick', e);
  },

  beforePageSave: function () {
    // Update the button model with the size of its corresponding internal lightbox.
    // This would ideally not be stored in the model and instead be generated at publish
    // time when the insertions are built. However this isn't possible, because the
    // insertions are part of the main page instance, which can't access other page
    // instances (lightboxes) to query their dimensions.
    if (this.model.linksToInternalLightbox()) {
      var model = this.model;
      var pageRefId = model.safeGet('action.lightboxId');
      var lightboxPage = window.editor.findPageByRefId(pageRefId);

      if (lightboxPage) {
        _.each(lightboxPage.getBreakPoints(), function (breakpoint) {
          var size = lightboxPage.getDimensions(breakpoint.name);
          model.setOnWritableModelByBreakpoint('lightboxSize', size, breakpoint);
        });
      }
    }
  },
});

ButtonElement.elementDefaults = {
  name: 'Button',
  content: {
    label: 'Button',
  },
  style: {
    fontSize: '16',
    fontWeight: 'bold',
    fontFamily: 'Arial, sans-serif',
    textShadow: false,
    highlight: false,
    fillType: 'solid',
    autoGradient: true,
    effect: {
      enabledTypes: ['none'],
      opacity: 25,
      color: '000000',
      offset: {
        left: 0,
        top: 4,
      },
      blurRadius: 4,
    },
    up: {
      backgroundColor: '0098DB',
      opacity: 100,
      gradient: {
        type: 'custom-gradient',
        from: '0098DB',
        to: '0054a6',
      },
      color: 'fff',
      border: {
        style: 'none',
      },
    },
    hover: {
      auto: true,
    },
    active: {
      auto: true,
    },
  },
  geometry: {
    position: 'absolute',
    offset: { top: 0, left: 0 },
    size: { width: 150, height: 42 },
    borderLocation: 'inside',
    cornerRadius: 5,
  },
  action: {
    type: 'url',
    url: '',
  },
};

ButtonElement.lightboxSizeDefault = {
  width: 840,
  height: 480,
};

module.exports = ButtonElement;
