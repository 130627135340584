"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FEATURE_DEFINITIONS = void 0;
exports.FEATURE_DEFINITIONS = {
    'features/autoscale': {
        type: 'boolean',
        default: false,
        errorFallback: false,
        description: 'Enable autoscale',
        intendedExpiry: 'never',
    },
    'features/fontUpload': {
        type: 'boolean',
        default: false,
        errorFallback: false,
        description: 'Enable font upload functionality',
        intendedExpiry: 'never',
    },
};
