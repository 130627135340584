/* globals $H, Class*/
window.jui.ModalSheet = Class.create(window.jui.Component, {
  options: function($super, options) {
    return $super($H({
      position: {
        x: 'center',
        y: 0
      },
      clickOutsideWillClose:false,
      attributes: {
        className: 'modal-sheet'
      }
    }).merge(options));
  },

  initialize:function($super, options){
    $super(options);
    this.animator = null;
  },

  installUI: function($super) {
    $super();
    this.screen = new Element('div', {className:'modal-sheet-screen'});
    // this.screen.setOpacity(0.3);
    this.content = this.insert(new window.jui.Component({attributes:{className:'content clearfix'}}));
  },

  insertContent: function(content) {
    return this.content.insert(content);
  },

  isOpen: function() {
    return this.parent !== null;
  },

  open: function(parent, options) {
    /* jshint unused:vars */
    parent.insert(this.screen);
    parent.insert(this);

    this.fireEvent('modalSheetOpened');
  },

  close: function() {
    this.screen.remove();
    this.remove();
    this.fireEvent('modalSheetClosed');
  }
});
