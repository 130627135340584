var _ = require('lodash');
var stripScripts = require('ub/utils').stripScripts;

var getEmailUrl = function(model) {
  var emailAddress = model.safeGet('action.emailAddress');

  if (emailAddress) {
    var subject = model.safeGet('action.emailSubject');
    var subjectValue = subject ? '?subject=' + subject  : '';

    return 'mailto:' + emailAddress + subjectValue;
  } else {
    return '';
  }
};

var getPhoneUrl = function(model) {
  var phoneNumber = model.safeGet('action.phoneNumber');
  return phoneNumber ? 'tel:' + encodeURIComponent(phoneNumber) : '';
};

var getRegularUrl = function(model) {
  var destinationURL = model.safeGet('action.url');

  // We are not using safeGet here because of a bug with safeGet. This will be addressed in PB-2034
  if (model.modelData.type === 'lp-pom-image') {
    destinationURL = model.safeGet('content.link');
  }

  return destinationURL ? stripScripts(destinationURL) : '';
};

var getInternalLightboxUrl = function(model) {
  var lightboxId = model.safeGet('action.lightboxId');

  if (lightboxId) {
    var variantId = model.element.page.getMainPageVariantId();
    return 'rel/' + variantId+ '-' + lightboxId + '-lightbox.html';
  } else {
    return '';
  }
};

var actionValueDispatch = {
  url: getRegularUrl,
  externalLightbox: getRegularUrl,
  phone: getPhoneUrl,
  email: getEmailUrl,
  internalLightbox: getInternalLightboxUrl,
};

var getCurrentActionValue =  function(model) {
  // We are not using safeGet here because of a bug with safeGet. This will be addressed in PB-2034
  var type = model.safeGet('action.type');
  var actionFunction = actionValueDispatch[type];

  return _.isFunction(actionFunction) ? actionFunction(model) : '';
};

module.exports = {
  getCurrentActionValue: getCurrentActionValue,
  getRegularUrl: getRegularUrl,
  getEmailUrl: getEmailUrl,
  getPhoneUrl: getPhoneUrl,
  getInternalLightboxUrl: getInternalLightboxUrl
};
