/* globals lp, Class */
var CustomHTMLElement = Class.create(
  lp.pom.VisibleElement,
  lp.ModuleComponent,
  {
    type:'lp-code',

      initView: function() {
        if (this.page.isPublishOrPreviewMode()) {
          this.insertContent();
        } else {
          var hasError    = this.model.exists('content.valid') && !this.model.get('content.valid');
          var errorClass  = hasError ? 'contains-errors' : '';
          var displayName = this.page.getConfigValue('displayNameLowerCase');

          this.view.insert(new Element('div', {className:'place-holder ' + errorClass}).update([
            '<p>This is a placeholder. The actual content is visible in the ',
            'preview or published ', displayName,
            '.</p><p class="dbl-click">Double-click to edit.</p><br />',
            '<span class="error-msg">This HTML block is invalid, which may result in a ',
            'broken published ', displayName, '.</span>'
          ].join('')));

          if (hasError) {
            this.model.element.view.e.down('.error-msg').show();
          } else {
            this.model.element.view.e.down('.error-msg').hide();
          }
        }
      },

      getModelClass: function() {
        return lp.module.code.CustomHTMLModel;
      },

      doubleClick: function(){
        var self = this;
        this.getModule().openBuilder(this, {
          callback: function(data){
            self.model.set('content.html', data.html, self.page.undoManager);
            self.model.set('content.valid', data.valid, self.page.undoManager);
            self.displayWarningIfInvalid(data.valid);
          }
        });
      },

      displayWarningIfInvalid: function(isValid) {
        var element = this.model.element.view.e.down('.place-holder');
        var errorMsg = element.down('.error-msg');
        if(isValid) {
          element.removeClassName('contains-errors');
          if(errorMsg){errorMsg.hide();}
        } else {
          element.addClassName('contains-errors');
          if(errorMsg){errorMsg.show();}
        }

      },

      insertContent: function() {
        if (this.contentInserted) {
          return;
        }
        var innerHTML = this.model.get('content.html');
        this.getViewDOMElement().innerHTML = '';
        this.page.addInsertion(innerHTML, 'div#' + this.model.get('id'));
        this.contentInserted = true;
      }
  }
);

CustomHTMLElement.elementDefaults = {
  name: 'Custom HTML',
  geometry:{
    position:"absolute",
    offset:{top:0,left:0},
    size: {width:315, height:170}
  },
  content:{
    type:null,
    html: '',
    valid: true,
  }
};

module.exports = CustomHTMLElement;
