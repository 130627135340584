/* globals Hash, rxSwitchboard */
var _ = require('lodash');

module.exports = {
  updates: new Hash(),

  add: function(update) {
    this.updates.set(update.version, update);
  },

  run: function(jso) {
    var newData = _.cloneDeep(jso);
    var trace = rxSwitchboard.getPublisher('updater');
    var keys  = this.updates.keys().sort();
    var pagesList = [newData.mainPage].concat(newData.subPages);

    _.forEach(keys, function(key) {
      var currentUpdater = this.updates.get(key);

       // Prior to version 2.8, we would only pass a single page to the updater
       // We now pass the updater the entire data set which includes all subpages
      if (key > 2.7) {
        trace({type: 'updater', id: key, jso: newData});
        newData = currentUpdater.run(newData);
      } else {
        _.forEach(pagesList, function(page) {
          trace({type: 'updater', id: key, jso: page});
          currentUpdater.run(page);
        });
      }
    }.bind(this));

    return newData;
  }
};
