var url = require('url');

function isLinkUnmodifiable(link) {
  /* jshint scripturl:true */
  return link.startsWith('mailto:') || link.startsWith('javascript:') || link.startsWith('#');
}

function handleAnchorLink(urlObj, currentUrl) {
  if (urlObj.href.startsWith(currentUrl + '#')) {
    return urlObj.hash;
  }

  return null;
}

function formatLink(urlObj) {
  var formattedLink = '';

  if (urlObj.protocol) {
    formattedLink += urlObj.protocol.replace(':', '/');
  }
  if (urlObj.host) {
    formattedLink += urlObj.host;
  }
  if (urlObj.path) {
    var telWithLeadingSlash = urlObj.protocol === "tel:" && urlObj.path.startsWith('/');
    formattedLink = telWithLeadingSlash ? formattedLink + urlObj.path.replace('/', '') : formattedLink + urlObj.path;
  }
  if (urlObj.hash) {
    formattedLink += urlObj.hash;
  }
  return formattedLink;
}

function setGoalType(unformattedUrl, formattedUrl, goals) {
  var type = goals.urlIsActiveGoal(unformattedUrl) ? 'clkg' : 'clkn';
  return type + '/' + formattedUrl;
}

module.exports = (opts) => {
  if (!opts) { throw new Error('you must pass an options object as param'); }
  if (!opts.currentUrl) { throw new Error('opts.currentUrl is a required parameter.'); }
  if (!opts.link) { throw new Error('opts.link is a required parameter.'); }

  var urlObj = url.parse(opts.link);

  var anchorLink = handleAnchorLink(urlObj, opts.currentUrl);
  if (anchorLink !== null) { return anchorLink; }

  if ( ! opts.isPublishMode || isLinkUnmodifiable(urlObj.href) || opts.enabled === false) {
    return urlObj.href;
  }

  var formattedUrl = formatLink(urlObj);
  formattedUrl = setGoalType(urlObj.href, formattedUrl, opts.goals);
  return formattedUrl;
};
