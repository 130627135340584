const getLabelHtml = ({strong, em} = {strong: false, em: false}, labelContent = '') => {
  let newLabel = labelContent;

  if (strong) {
    newLabel = `<strong>${newLabel}</strong>`;
  }

  if (em) {
    newLabel = `<em>${newLabel}</em>`;
  }

  return newLabel;
};

export default {getLabelHtml};