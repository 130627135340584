import AlertDialog from 'ub/ui/alert-dialog';
import Rx from 'ub_wrapped/rx';
import utilsNew from './utils-new';
import webfontloader from 'webfontloader';
import FontServiceManager from './manager';

const fontLoaderSubject = new Rx.Subject();

function getWebFonts() {
  return utilsNew.formatForWebfontLoader(FontServiceManager.addedWebFonts);
}

function getSettingsFonts() {
  return FontServiceManager.addedWebFonts;
}

function getOnlyExternalFonts() {
  const allFonts = getSettingsFonts();
  return allFonts.reduce((externalFonts, fontFamily) => {
    const variants = fontFamily.variants.filter(fontVariant => fontVariant.url);
    if (variants.length) {
      return [...externalFonts, { ...fontFamily, variants }];
    }
    return externalFonts;
  }, []);
}

function notifyAllFontsLoaded(loadedFonts) {
  fontLoaderSubject.onNext(loadedFonts);
}

function insertFontFacesStyleTag(fontFacesFamily) {
  fontFacesFamily.forEach(element => {
    const style = document.createElement('style');
    style.textContent = element.fontFacesStyle;
    style.setAttribute('data-font-family', element.family);
    document.head.appendChild(style);
  });
}

const loadAllFonts = () => {
  try {
    webfontloader.load({
      google: { families: getWebFonts() },
      active: () => {
        notifyAllFontsLoaded(getWebFonts());
      },
    });

    const fontFacesFamily = utilsNew.generateFontFacesContent(getOnlyExternalFonts());
    insertFontFacesStyleTag(fontFacesFamily);
  } catch (errObj) {
    // We are here because we could not load web fonts.  Let the builder load
    // and warn the user that web fonts will not display.  Also, log the problem.

    // this seems odd that you would still say thay you loaded the fonts...
    // but I guess default fonts are better than no fonts?
    notifyAllFontsLoaded(getWebFonts());

    // TODO: dont touch window, dont hook into random events like this. This is a way of getting
    //  around timing issues where if the fonts fail to load, we notify lp.js that we are done
    //  loading the fonts anyways. lp.js waits for this font loader to tell it that the fonts are
    //  loaded before it continues to load the page. We hook into page loaded here because otherwise
    //  window.editor.reportError isnt avail... sigh.
    window.editor.addListener('pageLoaded', () => {
      window.editor.reportError({
        error: new Error('Problem loading web fonts'),
        id: window.lp.pageData.id,
        message: 'There was a problem loading web fonts',
        details: 'Problems loading web fonts!',
        userAgent: window.navigator.userAgent,
      });

      // This should be loaded in via require/import and handled with a proper notification that is
      // more modern.
      new AlertDialog({
        title: 'Web Fonts',
        message:
          'There was a problem loading web fonts. Please contact support if ' +
          'this problem persists.',
        icon: 'error',
      }).safeOpen();
    });
  }
};

const loadExternalFonts = fontFamilies => {
  try {
    const fontFacesFamily = utilsNew.generateFontFacesContent(fontFamilies);
    insertFontFacesStyleTag(fontFacesFamily);
  } catch (errObj) {
    // We are here because we could not load web fonts.  Let the builder load
    // and warn the user that web fonts will not display.  Also, log the problem.

    // TODO: dont touch window, dont hook into random events like this. This is a way of getting
    //  around timing issues where if the fonts fail to load, we notify lp.js that we are done
    //  loading the fonts anyways. lp.js waits for this font loader to tell it that the fonts are
    //  loaded before it continues to load the page. We hook into page loaded here because otherwise
    //  window.editor.reportError isnt avail... sigh.
    window.editor.addListener('pageLoaded', () => {
      window.editor.reportError({
        error: new Error('Problem loading external fonts'),
        id: window.lp.pageData.id,
        message: 'There was a problem loading external fonts',
        details: 'Problems loading external fonts!',
        userAgent: window.navigator.userAgent,
      });

      // This should be loaded in via require/import and handled with a proper notification that is
      // more modern.
      new AlertDialog({
        title: 'External Fonts',
        message:
          'There was a problem loading external fonts. Please contact support if ' +
          'this problem persists.',
        icon: 'error',
      }).safeOpen();
    });
  }
};

export default {
  fontLoaderSubject,
  loadAllFonts,
  loadExternalFonts,
  getOnlyExternalFonts,
  getSettingsFonts,
};
