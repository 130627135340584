/* globals lp, jui,$H,Class */
var VideoBackground = require('ub/video-background');


var SectionElement = Class.create( lp.pom.VisibleElement, lp.ModuleComponent, {
  type:'lp-pom-block',

  initialize: function($super, page, jso, context, options){
    // JS: TODO: this should be handled by a future pom updater
    if (jso.geometry.zIndex) {
      delete jso.geometry.zIndex;
    }

    $super(page, jso, context, options);
    this.tempUpdaterToAddNeedBorderApply(jso);
  },

  getModelClass: function() {
    return lp.module.block.BlockModel;
  },

  tempUpdaterToAddNeedBorderApply: function(jso) {
    var hasBorderApply = this.model.exists('geometry.borderApply');

    if(!hasBorderApply) {
      jso.geometry.borderApply = this.getSectionBorderApply();
    }
  },

  getSectionBorderApply: function() {
    return lp.module.block.SectionElement.elementDefaults.geometry.borderApply;
  },

  createDefaultConstraints: function($super) {
    $super();
    this.defaultConstraints.width_resizeable  = false;
    this.defaultConstraints.container         = true;
    this.defaultConstraints.orderable         = true;
    this.defaultConstraints.z_indexable       = false;
    this.defaultConstraints.offsetable        = false;
    this.defaultConstraints.opacityable       = true;
    this.defaultConstraints.parallaxable      = true;
  },

  initView: function() {
    this.content = this.view.insert(new jui.Component({attributes:{className:'lp-pom-block-content'}}));
    this.addPageUpdatedAndRefreshedHandler();

    this.overlay = new lp[this.page.isEditMode() ? 'editor' : 'publisher']
      .ColorOverlayElement(this);

    this.videoBackground = new VideoBackground[this.page.isEditMode() ? 'edit' : 'publish'](this);
  },

  addPageUpdatedAndRefreshedHandler: function() {
    var self = this;
    var visibilityHandler = function() {
      self.setVisibilityOfChildren(self.model.isVisible());
    };
    self.page.addListener('pageUpdatedAndRefreshed', visibilityHandler);
  },

  modelChanged: function($super, e) {
    var details = $super(e);
    if (details.accessor === 'geometry.fitWidthToPage' && this.model.exists('style.border')) {
      this.applyBorderStyle(this.model.get('style.border'));
    }
  },

  applyBorderStyle: function(value) {
    var s = this.view.e.style;
    var m = this.model;

    if (value === null) {
      s.borderLeftStyle = '';
      s.borderTopStyle = '';
      s.borderRightStyle = '';
      s.borderBottomStyle = '';
      s.borderStyle = '';
      s.borderWidth = '';
      s.borderColor = '';
      return;
    }

    if (m.exists("geometry.borderApply")) {
      $H(m.get("geometry.borderApply")).each(function(a) {
        s['border'+a.key.capitalize()+'Style'] = a.value &&
          !(m.exists('geometry.fitWidthToPage') &&
              m.get('geometry.fitWidthToPage') &&
              (a.key === 'right' || a.key === 'left')) ?

          value.style || 'none' : 'none';

      });
    } else {
      s.borderLeftStyle = '';
      s.borderTopStyle = '';
      s.borderRightStyle = '';
      s.borderBottomStyle = '';
      s.borderStyle = value.style || 'none';
    }

    s.borderWidth = value.width ? value.width+'px' : '0px';
    s.borderColor = value.color ? '#'+value.color.replace('#', '') : '';
  },


  insertChildElement: function($super, elm, options) {
    $super(elm, options);
    var root = this.getRootElement();
    root.addListener('blockResized', elm);
    root.addListener('blockMoved', elm);
    root.addListener('blockInserted', elm);
    root.addListener('blockRemoved', elm);
    root.addListener('blockHidden', elm);
    root.addListener('blockShown', elm);
  },

  insertZIndex: function(minZ, gap) {
    this.parentElement.insertZIndex(minZ, gap);
  },

  getContentElement: function() {
    return this.getRootElement().positionedContent;
  },

  getFirstElementByZIndex: function() {
    return this.parentElement.getFirstElementByZIndex();
  },

  getLastElementByZIndex: function() {
    return this.parentElement.getLastElementByZIndex();
  },

  changeChildZIndex: function(element, direction) {
    this.parentElement.changeChildZIndex(element, direction);
  },

  getRootOffset: function() {
    if (this.getRootElement() === null) {
      return {left:0, top:0};
    }
    var sections = this.getRootElement().getSections();
    var left = 0;
    var top = 0;
    var m = this.model;
    var i, l=sections.length, s, sm;

    // calculate the cumulative height of page sections above this one including any outside borders
    for (i=0; i < l && sections[i] !== this; i++) {
      s = sections[i];
      sm = s.model;

      if (sm.isVisible && !sm.isVisible()) {
        continue;
      }

      if (s.hasBorder() && sm.get('geometry.borderLocation') === 'outside') {
        if (sm.exists('geometry.borderApply')) {
          top += s.getBorderWidth() * (sm.get('geometry.borderApply.top') + sm.get('geometry.borderApply.bottom'));
        } else {
          top += s.getBorderWidth() * 2;
        }
      }
      if (s.model.exists('geometry.margin.bottom')) {
        top += (s.model.get('geometry.margin.bottom') * 1);
      }
      top += s.getContentHeight();
    }


    // add width of any outside top border
    if (this.hasBorder() && m.get('geometry.borderLocation') === 'outside') {
      if (m.exists('geometry.borderApply')) {
        top += this.getBorderWidth() * m.get('geometry.borderApply.top');
      } else {
        top += this.getBorderWidth();
      }
    }

    return {left:left, top:top};
  },

  getPageOffset: function() {
    var root = this.getRootElement();
    if (root === null) {
      return {left:0, top:0};
    }

    var ro = root.view.getOffset();
    ro.top = Math.max(0, ro.top);
    var l = Math.floor((root.getPageWidth() - root.getContentWidth()) / 2);
    var t = root.getTopPadding();
    var o = this.getRootOffset();

    o.left = (o.left + l + ro.left);
    o.top = (o.top + t + ro.top);

    return o;
  },

  getContentTop: function() {
    return this.content.getTop();
  },

  isFitWidthToPage: function(){
    return this.model.exists("geometry.fitWidthToPage") &&
      this.model.get("geometry.fitWidthToPage");
  },

  hasInsideBorder: function(){
    return this.hasBorder() && this.model.get('geometry.borderLocation') === 'inside';
  },

  adjustHeightForBorder: function(dimensions) {
    var adjustment  = this.getBorderWidth() * 2;
    return {width:dimensions.width, height:dimensions.height - adjustment};
  },

  fitWidthToPage: function(dimensions){

    if (this.hasInsideBorder()) {
      dimensions = this.adjustHeightForBorder(dimensions);
    }

    var contentSelector = '#'+this.id+' .lp-pom-block-content';

    var rules  = [];
    
    if (this.pageContext('EDIT')) {
      rules.push({attribute: 'height', value: dimensions.height + 'px'});
      rules.push({selector: contentSelector, attribute: 'height', value: dimensions.height + 'px'});

      this.view.setHeight(dimensions.height);
      this.view.setWidth(100, '%');
      dimensions.width = '100%';
    } else {
      rules.push({attribute: 'height', value: this.page.getUnit(dimensions.height)});
      rules.push({selector: contentSelector, attribute: 'height', value: this.page.getUnit(dimensions.height)});

      rules.push({attribute: 'width',  value: '100%' });
      rules.push({selector: contentSelector, attribute: 'width', value: this.page.getUnit(dimensions.width)});
    }
    this.applyPageStyles(rules);

    return dimensions;
  },

  updateDimensions: function(/* dims */) {
    // page sections always get their width from the page width so
    // we explicitly ignore any arguments passed into this function.
    // All changes to the height of a page section should be via
    // setDimensions.
    this.setDimensions(this.model.getSize());
  },

  setDimensions: function($super, dimensions) {
    var rules = [];
    var contentSelector = '#'+this.id+' .lp-pom-block-content';

    if (Object.isNumber(dimensions)) {
      dimensions = {width:dimensions, height:this.getContentHeight()};
    }
    if(this.page.isEditMode()){
      // Uses 2 methods vs 1 due to differences between setDimensions and these.
      // TODO: change whenjui geometry methods are refactored
      this.content.setWidth(100, '%');
      this.content.setHeight(100, '%');
    } else {
      var contentDimensions = this.adjustDimensions(dimensions);
      rules.push({selector: contentSelector, attribute: 'margin-left', value: 'auto'});
      rules.push({selector: contentSelector, attribute: 'margin-right', value: 'auto'});
      rules.push({
        attribute: 'width',
        value: this.page.getUnit(dimensions.width)
      });
      rules.push({
        selector: contentSelector,
        attribute: 'width',
        value: this.page.getUnit(contentDimensions.width)
      });
      rules.push({
        attribute: 'height',
        value: this.page.getUnit(dimensions.height)
      });
      rules.push({
        selector: contentSelector,
        attribute: 'height',
        value: this.page.getUnit(contentDimensions.height)
      });

      this.applyPageStyles(rules);
    }

    $super(dimensions);
    if (this.isFitWidthToPage()) { this.fitWidthToPage(dimensions); }
  },

  getBottomMargin: function() {
    return this.model.exists('geometry.margin.bottom') ? this.model.get('geometry.margin.bottom') : 0;
  },

  getBottomBorderWidth: function() {
    var m = this.model;
    return this.hasBorder() && m.get('geometry.borderLocation') === 'outside' ?
      (m.exists('geometry.borderApply') && m.get('geometry.borderApply.bottom') ?
       this.getBorderWidth() :
       0) : 0;
  },

  getHeightWithBorderAndMargin: function() {
    var hasOutsideBorder =
      this.model.safeGet('style.border.style') !== 'none' &&
      this.model.safeGet('geometry.borderLocation') === 'outside';

    var borderSize = hasOutsideBorder ? this.model.safeGet('style.border.width') || 0 : 0;
    var borderTotal =
      (this.model.safeGet('geometry.borderApply.top') ? borderSize : 0) +
      (this.model.safeGet('geometry.borderApply.bottom') ? borderSize : 0);

    var marginBottom = this.model.safeGet('geometry.margin.bottom') || 0;

    return this.getContentHeight() + borderTotal + marginBottom;
  },

  getOuterBorderOffsetAdjust: function() {
    return {left:0, top:0};
  },

  getInnerBorderOffsetAdjust: function() {
    return {left:0, top:0};
  },

  contentWidthChanged: function(e) {
    this.model.set('geometry.size.width', e.data);
  },

  setVisible: function($super, visible) {
    $super(visible);
    this.setVisibilityOfChildren(visible);
    this.fireEvent(visible ? 'blockShown' : 'blockHidden');
  },

  setVisibilityOfChildren: function(visible) {
    this.childElements.each(function(elm) {
      elm.setVisible(visible && elm.model.isVisible());
    });
  },

  _attach: function($super) {
    $super();
    this.model.addListener('attributeChanged', this.page.getRootElement().blockListener);
    this.page.getRootElement().addListener('contentWidthChanged', this);
  },

  _detach: function($super) {
    $super();
    this.page.getRootElement().removeListener('contentWidthChanged', this);
    this.model.removeListener('attributeChanged', this.page.getRootElement().blockListener);
  }
});

SectionElement.elementDefaults = {
  name: 'Section',
  style:{
    background: {
      backgroundColor: "fff",
      opacity: 100,
    },
    newBackground: {
      type: 'solidColor',
      solidColor: {},
    },
  },
  geometry:{
    position:"relative",
    margin:{left:'auto', right:'auto', bottom:0},
    offset:{top:0,left:0},
    borderLocation:'outside',
    borderApply:{top:true, right:true, bottom:true, left:true},
    backgroundImageApply: false,
    savedBorderState: {left: true, right: true},
    fitWidthToPage: false
  }
};

module.exports = SectionElement;
