import PropTypes from 'prop-types';
import React from 'react';
import styles from 'ui/brand-color/brand-color.scss';
import brandState from 'ui/brand/state-controller';
import { loadBrandColors } from 'ui/brand/brand-api-helper';
import { formatColor } from './utils';
import logUIEvent from '../../../src/log-events/log-ui-events';

const BRAND_COLOR_LIMIT = 20;

const convertToColorState = colors => {
  if (!colors.length) {
    return {};
  }
  return colors
    .map(color => {
      return { [color.css_value ? color.css_value : color]: false };
    })
    .reduce((acc, curr) => {
      return { ...acc, ...curr };
    });
};

export default class BrandColor extends React.Component {
  constructor(props) {
    super(props);

    this.onColorClick = this.onColorClick.bind(this);
    this.onAddClick = this.onAddClick.bind(this);
    this.state = {
      colors: {},
    };
  }

  componentDidMount() {
    loadBrandColors();
    this.colorSubscription = brandState.brandColorsUpdatedSubject.subscribe(colorAssetPack => {
      this.setState({
        colors: convertToColorState(colorAssetPack.colors),
      });
    });

    this.brandColorFocussedSubscription = brandState.brandColorFocusSubject.subscribe(focussed => {
      if (!focussed) {
        this.props.hideRemoveButton();
      } else {
        this.props.showRemoveButton();
      }
    });

    brandState.brandColorsSubject.onNext({ type: 'renderInitialState' });
  }

  componentWillUnmount() {
    this.colorSubscription.dispose();
    this.brandColorFocussedSubscription.dispose();
  }

  onColorClick(ev) {
    const clickedID = ev.target.id;
    const resetColorState = convertToColorState(Object.keys(this.state.colors));

    this.setState({
      colors: { ...resetColorState, [clickedID]: true },
    });

    this.props.onItemClick(clickedID);
    brandState.brandColorFocusSubject.onNext(true);
  }

  onAddClick() {
    this.props.saveColor();
    logUIEvent({
      eventName: 'CB_ADDED_BRAND_COLOR',
      eventData: {},
      workflow: 'brand_colors',
    });
  }

  render() {
    const paletteItems = Object.keys(this.state.colors);
    const isColorIsSelected = Object.values(this.state.colors).includes(true);
    const selectedColor = formatColor(this.props.currentColor);

    return (
      <div className={styles.savedColorPalette}>
        <span>Brand Colors</span>
        <div className={styles.paletteItemGallery}>
          {paletteItems.map((color, i) => (
            <>
              {i < BRAND_COLOR_LIMIT && (
                <div
                  className={
                    this.state.colors[color] ||
                    (color.includes(selectedColor) && !isColorIsSelected)
                      ? `${styles.colorPaletteItem} ${styles.active}`
                      : styles.colorPaletteItem
                  }
                  style={{ backgroundColor: color }}
                  key={color}
                  id={color}
                  onClick={this.onColorClick}
                />
              )}
            </>
          ))}
          {paletteItems.length < BRAND_COLOR_LIMIT && (
            <div
              className={`${styles.colorPaletteItem} ${styles.addPaletteItem}`}
              onClick={this.onAddClick}
            >
              +
            </div>
          )}
        </div>
      </div>
    );
  }
}

BrandColor.propTypes = {
  currentColor: PropTypes.string,
  onItemClick: PropTypes.func.isRequired,
  saveColor: PropTypes.func.isRequired,
  hideRemoveButton: PropTypes.func.isRequired,
  showRemoveButton: PropTypes.func.isRequired,
};
