require('ub_legacy/jui/jui.js');
require('ub_legacy/jui/color_math.js');
require('ub_legacy/jui/modal_dialog.js');
require('ub_legacy/jui/jui_utils.js');
require('ub_legacy/jui/menu.js');
require('ub_legacy/jui/dynamic_tag_selectable.js');
require('ub_legacy/jui/dynamic_tag_attribute_manager.js');
require('ub_legacy/jui/form_text_input.js');
require('ub_legacy/jui/form_numeric_input.js');
require('ub_legacy/jui/form_asset_chooser.js');
require('ub_legacy/jui/form_checkbox_input.js');
require('ub_legacy/jui/form_radio_input.js');
require('ub_legacy/jui/field_error_message.js');
require('ub_legacy/jui/form_color_input.js');
require('ub_legacy/jui/form_link_input.js');
require('ub_legacy/jui/form_link_input2.js');
require('ub_legacy/jui/form_contenteditable.js');
require('ub_legacy/jui/form_dynamic_contenteditable.js');
require('ub_legacy/jui/form_size_input.js');
require('ub_legacy/jui/form_offset_input.js');
require('ub_legacy/jui/form_border_input.js');
require('ub_legacy/jui/form_font_input.js');
require('ub_legacy/jui/form_slider_input.js');
require('ub_legacy/jui/form_select_input.js');
require('ub_legacy/jui/code_editor.js');
require('ub_legacy/jui/bubble_tip.js');
require('ub_legacy/jui/form_multi_checkbox_input.js');
require('ub_legacy/jui/form_multi_radio_input.js');
require('ub_legacy/jui/tab_view.js');
require('ub_legacy/jui/multi_option_dialog.js');
require('ub_legacy/jui/undo_manager.js');
require('ub_legacy/jui/modal_sheet.js');
require('ub_legacy/jui/tri_pane.js');
require('ub_legacy/jui/dnd/dnd.js');
require('ub_legacy/jui/dnd/source.js');
require('ub_legacy/jui/dnd/target-finder.js');
require('ub_legacy/jui/social_widget_layout_menu.js');
require('ub_legacy/jui/help_button.js');
