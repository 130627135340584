/* globals Class, jui, lp,  LP, gon */
var jQuery = require('jquery');
var _ = require('lodash');

var ubBanzai = require('ub/control/banzai-features');
var ensignManager = require('ub/ensign');

var getLightboxInsertion = require('ub/publish/lightbox-insertions').default;
var getAutoscaleInsertion = require('ub/publish/autoscale-insertions').default;
var contentTypeConfig = require('ub/content-type-config');
var ctaLinkFilter = require('ub/data/filters/cta-link');
const utilsNew = require('../../../fonts/font-service/utils-new').default;
var fontService = require('ub/fonts/font-service/publisher').default;
var formPublishStyles = require('ub/elements/form/publish-styles').default;
var getUniqueGoals = require('ub/lp/editor/goals').getUniqueGoals;
var getUnsplashTrackingPixel = require('ub/elements/image/unsplash').getUnsplashTrackingPixel;
var buildPublishUrl = require('ub/control/image-publish-urls').buildPublishUrl;
var { getAnimationKeyframesInsertions } = require('ub/control/animation-effect');
var { getAnimationEffectInsertions } = require('ub/publish/animationeffect-insertions');

var getDefaultGlobalImageQuality =
  require('ub/ui/properties-panel/images/image-quality-helper.js').getDefaultGlobalImageQuality;

var _checkCurrentVersion = function (jso, version) {
  if (jso.version && jso.version !== version) {
    throw new LP.POMError(
      LP.PAGE_VERSION_NEWER_ERR,
      '(page version is' + jso.version + ' version ' + version + 'expected)'
    );
    // throw "Incorrect page version "+jso.version+" Current version is "+this.version;
  }
};

var defaultSettings = {
  defaultWidth: 760,
  showPageTransformBox: true,
  showSectionBoundaries: true,
  showPageSectionProtrusionWarnings: true,
  // TODO: multipleBreakpointsEnabled seems to be unused - can we remove it from defaults?
  multipleBreakpointsEnabled: false,
  contentType: 'pageVariant',
};

window.lp.pom.Page = Class.create(jui.EventSource, {
  version: '3.6',
  type: 'lp-pom-page',

  initialize: function (jso, context, document, previewURL) {
    /* jshint maxcomplexity:12 */
    _checkCurrentVersion(jso, this.version);
    this.document = document || window.document;
    this.settings = _.merge({}, defaultSettings, jso.settings);

    // We are using getUniqueGoals here to recover from a bug (CN-1668) that caused many duplicate
    // goals to be stored into the POM.
    this.settings.activeGoals = getUniqueGoals(this.settings.activeGoals || []);

    this.settings.builderVersion = window.builderVersion;

    this._setInitialState();

    this.context = context; // EDIT || PREVIEW || PUBLISH
    this.previewURL = previewURL;
    this.page = jso.page;
    this.name = jso.name;
    this.title = jso.title || '';
    this.resourceType = jso.type || 'PageVariant';
    this.shared = jso.shared;
    this.variant_id = jso.variant_id || 'a';
    this.has_form = Boolean(jso.has_form);
    this.model = this;
    this.uuid = jso.page.uuid;
    this.wordpressEnabled = jso.wordpress_enabled;
    this.last_element_id = jso.last_element_id || 0;

    this._setBreakpoints();

    this._addFeatureFlaggedSettings();

    this._setId(jso);

    this.undoManager = this._getUndoManager();

    this.metaData = {
      type: 'lp-pom-page-meta-data',
      name: 'SEO',
      title: jso.title || '',
      description: jso.description || '',
      keywords: jso.keywords || '',
    };

    this.page.path_name = this._getPathName();
    this._setOpenGraphValues(jso);
    this._setFaviconValues(jso);
    this._setAutoscale(jso);

    this.currentBreakpoint = this.breakpoints[0];

    this.styles = [];
    this.elements = jso.elements;

    this.style = new lp.pom.PageStyle(this);
    this.goals = new lp.pom.PageGoals(this);
    if (!_.isEmpty(jso.goal_type)) {
      // jscs:ignore disallowSpacesInsideParentheses
      this.goals.addActiveGoal({ type: jso.goal_type, url: jso.goal_url });
    }

    this.pomReady = false;
    this.buildPOM();

    this.fixHasForm();

    this._switchToStartingBreakpoint();
  },
  _setInitialState: function () {
    this.bodyElement = null;
    this.insertions = [];
    this.maxDefault = 10000;
    this.minDefault = 16;
  },

  _setBreakpoints: function () {
    var includeTablet = this.allowTabletBreakpoint();

    this.breakpoints = [
      { name: 'desktop', default: true },
      (includeTablet ? {
        name: 'tablet',
        width: 920,
        minPageWidth: 768,
        maxPageWidth: 768,
        maxPageWidthConfirmation: 360,
        minPageWidthConfirmation: 360,
        
      } : null),
      {
        name: 'mobile',
        width: 600,
        minPageWidth: 320,
        maxPageWidth: 320,
        maxPageWidthConfirmation: 240,
        minPageWidthConfirmation: 240,
      },
    ].filter(Boolean);
  },

  _setOpenGraphValues: function (jso) {
    var parsedOpenGraph =
      typeof jso.open_graph === 'string' ? JSON.parse(jso.open_graph) : jso.open_graph;

    this.open_graph = {
      ogType: parsedOpenGraph.ogType || '',
      ogTitle: parsedOpenGraph.ogTitle || '',
      ogDescription: parsedOpenGraph.ogDescription || '',
      ogImage: parsedOpenGraph.ogImage || '',
      ogURL: parsedOpenGraph.ogURL || '',
    };
  },

  _setFaviconValues: function (jso) {
    /* jshint maxcomplexity:15 */
    try {
      const favicon = jso.favicon;
      if (favicon === null || _.isEmpty(favicon)) {
        this.favicon = null;
        return;
      }
      var parsedFavicon = typeof favicon === 'object' ? favicon : JSON.parse(favicon);
      this.favicon = {
        id: parsedFavicon.id || 0,
        company_id: parsedFavicon.company_id || 0,
        content_content_type: parsedFavicon.content_content_type || '',
        content_file_name: parsedFavicon.content_file_name || '',
        content_file_size: parsedFavicon.content_file_size || 0,
        name: parsedFavicon.name || '',
        uuid: parsedFavicon.uuid || '',
        content_url: parsedFavicon.content_url || '',
        content_url_small: parsedFavicon.content_url_small || '',
        unique_url: parsedFavicon.unique_url || '',
      };
    } catch (e) {
      this.favicon = null;
    }
  },

  _setAutoscale: function (jso) {
    this.autoscale = jso.autoscale || false;
  },

  _addFeatureFlaggedSettings: function () {
    if (ubBanzai.getFeatureValue('contentTypeOverride')) {
      this.settings.contentType = ubBanzai.getFeatureValue('contentTypeOverride');
    }

    var defaultQuality = getDefaultGlobalImageQuality();
    this.settings.globalImageQuality = this.settings.globalImageQuality || defaultQuality;
  },

  _switchToStartingBreakpoint: function () {
    var startingBreakpoint = this.getConfigValue('startingBreakpoint');

    if (startingBreakpoint !== this.currentBreakpoint.name) {
      this.switchToBreakpoint(startingBreakpoint);
    }
  },

  _getUndoManager: function () {
    if (window.editor) {
      return window.editor.undoManager;
    } else {
      return new jui.UndoManager();
    }
  },

  _setId: function (jso) {
    // Ref IDs are used within the builder to uniquely identify and reference subpages
    // within the current variant. They are separate from the page.id property, which is
    // used solely by the webapp and only populated after the first save.
    if (!this.settings.refId) {
      this.settings.refId = this._generateRefId();
    }

    // If the page doesn't have a webapp ID yet, give it a temporary ID based on the
    // refId. After the next save, this value will be replaced by the real webapp
    // ID.
    this.id = jso.id || 'temp_id_' + this.getRefId();
  },

  getRefId: function () {
    return this.settings.refId;
  },

  getMainPageVariantId: function () {
    if (!this.isMain() && this.settings && this.settings.mainPage) {
      return this.settings.mainPage.variant_id || this.variant_id;
    } else {
      return this.variant_id;
    }
  },

  getMainPageUUID: function () {
    if (!this.isMain() && this.settings && this.settings.mainPage) {
      return this.settings.mainPage.uuid || this.page.uuid;
    } else {
      return this.page.uuid;
    }
  },

  _generateRefId: function () {
    if (window.editor) {
      var lastId =
        _(window.editor.pages)
          .map(function (page) {
            return page.getRefId();
          })
          .sortBy()
          .last() || 0;
      return lastId + 1;
    }
  },

  _getPathName: function () {
    if (this.page.path_name) {
      return this.page.path_name;
    } else if (this.isFormConfirmation()) {
      return this.getMainPageVariantId() + '-form_confirmation.html';
    } else if (this.isLightbox()) {
      return this.getMainPageVariantId() + '-' + this.getRefId() + '-lightbox.html';
    }
  },

  removeAbandonedDetachedElements: function () {
    //Generate a list of visible elements that have been detached.
    var detachedElements = _.filter(this.elements, function (el) {
      return typeof el.existsOnDocument === 'function' && !el.existsOnDocument();
    });

    var listOfElementsToDelete = [];

    detachedElements.forEach(function (el) {
      //Generate a list of elements that should be removed so that we can log
      //them sentry
      listOfElementsToDelete.push(el.id);

      this.removeElement(el);
    }, this);

    //If we have a list of elements to remove then log them.
    if (!_.isEmpty(listOfElementsToDelete)) {
      var idListString = listOfElementsToDelete.join(', ');
      console.warn(`Removing detached elements: ${idListString}`);
      lp.errorNotifier.captureMessage(
        '[ELEMENT DELETE] Detected detached visible elements on page load',
        {
          extra: {
            details: idListString + ' will be removed.',
          },
        }
      );
    }
  },

  fixHasForm: function () {
    // See PB-762. At least one customer page had a form but had page.has_form set to
    // false. If that's the case, correct the flag and log to Sentry.
    var realHasForm = this.getElementsByType('lp-pom-form').length > 0;
    var hasForm = this.has_form;

    if (hasForm !== realHasForm) {
      this.has_form = realHasForm;
      lp.errorNotifier.captureMessage('Invalid page.has_form flag detected and fixed', {
        extra: {
          details:
            "Should have been '" + realHasForm + "', was previously set to '" + hasForm + "'",
        },
      });
      if (ubBanzai.features.isDebugModeEnabled()) {
        console.warn('Invalid page.has_form flag detected and fixed');
      }
    }
  },

  elementsAsJSO: function () {
    return _.map(this.elements, function (el) {
      return el.toJSO();
    });
  },

  buildPOM: function () {
    var buildPageElements = function (p) {
      p.elements = p.elements
        .collect(function (e) {
          var module = lp.getModule(e.type);
          if (!Object.isUndefined(module)) {
            return module.buildPageElement(p, e);
          }
          return null;
        })
        .compact();
    };

    var buildPageDocTree = function (p) {
      //JS: iterate through all the page elements and insert them into any containers
      p.elements.each(function (e) {
        var container = p.getElementById(e.containerId);
        if (container) {
          container.insertChildElement(e);
        }
      });
    };

    buildPageElements(this);
    buildPageDocTree(this);
    this.pomReady = true;
    this.fireEvent('POMReady');
  },

  allowViewportMetaTag: function () {
    return this.isMobileEnabled() || !!this.settings.mobilePage;
  },

  isBreakpointEnabled: function (breakpointName) {
    switch (breakpointName) {
      case 'mobile':
        // todo: change this to `mobileBreakpointDisabled`
        return Boolean(this.settings.multipleBreakpointsVisibility);
      case 'tablet':
        return !Boolean(this.settings.tabletBreakpointDisabled);
      case 'desktop':
        return !Boolean(this.settings.desktopBreakpointDisabled);
      default:
        return false;
    }
  },

  isMobileEnabled: function () {
    return this.isBreakpointEnabled('mobile');
  },

  isTabletEnabled: function () {
    return ubBanzai.features.isTabletBreakpointEnabled() && this.isBreakpointEnabled('tablet');
  },

  isDesktopEnabled: function () {
    return this.isBreakpointEnabled('desktop');
  },

  setBreakpointEnabled: function (breakpointName, enabled) {
    switch (breakpointName) {
      case 'mobile':
        this.settings.multipleBreakpointsVisibility = enabled;
        break;
      case 'tablet':
        this.settings.tabletBreakpointDisabled = !enabled;
        break;
      case 'desktop':
        this.settings.desktopBreakpointDisabled = !enabled;
        break;
    }
  },

  getBreakpointMaxWidth: function (breakpointName) {
    var breakpoint = this.getBreakpointByName(breakpointName);

    // For lightboxes and FCDs: when the content is larger than the viewport, and when the browser
    // has physical scrollbars, we increase the lightbox size to compensate for the scrollbars.
    // Since max-width media queries operate on the window width *including* scrollbars we need to
    // add the width of the widest-possible scrollbar to ensure the media query still matches.
    var maxScrollbarWidth = 20;

    if (this.getConfigValue('useDesktopWidthAsCSSBreakpoint')) {
      // On Convertable content types, we want to show the mobile version if the viewport (iframe)
      // is any narrower than the width of the desktop version, instead of using the fixed 600px
      // breakpoint value used by landing pages. This applies to the main page, form confirmation
      // dialog, and lightboxes.
      return this.getDimensions('desktop').width - 1;
    } else if (this.isFormConfirmation()) {
      return breakpoint.maxPageWidthConfirmation + maxScrollbarWidth;
    } else if (this.isLightbox()) {
      return breakpoint.maxPageWidth + maxScrollbarWidth;
    } else {
      return breakpoint.width;
    }
  },

  insertPage: function (container) {
    var root = this.getRootElement();
    container.insert(root.view.e);
    if (this.isPublishOrPreviewMode()) {
      if (!!this.settings.noRobots) {
        this.insertNoRobots();
      }

      if (this.allowViewportMetaTag()) {
        this.insertViewportMetaTag();
      }
    }

    root._attach(); // if this is an _ method, why is it being called here?
    this.fireEvent('pageInserted');
    this.style.updatePageStyles();
  },

  isEditMode: function () {
    return this.context === lp.pom.context.EDIT;
  },

  isPreviewMode: function () {
    return this.context === lp.pom.context.PREVIEW;
  },

  isPublishMode: function () {
    return this.context === lp.pom.context.PUBLISH;
  },

  isPublishOrPreviewMode: function () {
    return this.isPublishMode() || this.isPreviewMode();
  },

  insertPublishedPage: function () {
    var root = this.getRootElement();
    this.document.body.appendChild(root.view.e);

    this.setBodyClassNames();

    if (!!this.settings.noRobots || this.isLightbox()) {
      this.insertNoRobots();
    }

    if (this.allowViewportMetaTag()) {
      this.insertViewportMetaTag();
    }
    this.insertFaviconTag();
    this.insertUACompatibleMetaTag();
    this.insertVersionMetaTag();

    this.getBreakPoints().each(function (breakpoint) {
      this.switchToBreakpoint(breakpoint.name);
      root._attach();
    }, this);

    this.fireEvent('pageInserted');
    this.style.updatePageStyles();
  },

  getMaxWidthForBreakpoint: function (breakpoint) {
    if (this.isFormConfirmation()) {
      return breakpoint.maxPageWidthConfirmation || this.maxDefault;
    } else {
      return breakpoint.maxPageWidth || this.maxDefault;
    }
  },

  getMinWidthForBreakpoint: function (breakpoint) {
    if (this.isFormConfirmation()) {
      return breakpoint.minPageWidthConfirmation || this.minDefault;
    } else {
      return breakpoint.minPageWidth || this.minDefault;
    }
  },

  getMaxWidthForCurrentBreakpoint: function () {
    return this.getMaxWidthForBreakpoint(this.getCurrentBreakpoint());
  },

  getMinWidthForCurrentBreakpoint: function () {
    return this.getMinWidthForBreakpoint(this.getCurrentBreakpoint());
  },

  getBreakPoints: function () {
    return this.breakpoints;
  },

  getEnabledBreakpoints: function () {
    var self = this;
    return this.breakpoints.filter(function (breakpoint) {
      return self.isBreakpointEnabled(breakpoint.name);
    });
  },

  getUnit: function (value, unit = 'px') {
    if (this.isAutoscale()) {
      return `calc(${value}${unit} * var(--scale, 1))`;
    }
    return `${value}${unit}`;
  },

  getScale: function () {
    if (this.isAutoscale()) {
      return `scale(var(--scale, 1))`;
    }
    return `none`;
  },

  isAutoscale: function () {
    return (
      this.allowAutoscale() && !this.isEditMode() && this.autoscale
    );
  },

  _getNewWebFontInsertions: function () {
    var fontScriptContents = fontService.newGenerateFontLoadScriptContent(this);

    if (!_.isEmpty(fontScriptContents)) {
      return {
        content: fontScriptContents,
        placement: 'head',
      };
    }
  },

  _getOldFontInsertions: function () {
    var fonts = this.getFontsFromElements();
    if (fonts.length > 0) {
      return {
        content: fontService.oldGenerateFontLoadScriptContent(fonts),
        placement: 'head',
      };
    }
  },

  getWebFontsInsertion: function () {
    if (!_.isUndefined(this.settings.webFontsInUse)) {
      return this._getNewWebFontInsertions();
    } else {
      return this._getOldFontInsertions();
    }
  },

  removePage: function () {
    var root = this.getRootElement();
    root.view.e.remove();
    root._detach();
    this.fireEvent('pageRemoved');
    this.style.updatePageStyles();
  },

  isInserted: function () {
    return this.pomReady && this.getRootElement().isInserted();
  },

  setBodyClassNames() {
    const { body } = this.document;

    body.classList.add('lp-pom-body');

    if (!this.isMain()) {
      body.classList.add('lp-sub-page');
    }

    if (this.getConfigValue('isEmbeddable')) {
      body.classList.add('lp-convertable-page');
    }

    if (this.isAutoscale()) {
      body.classList.add('lp-autoscale-enabled');
    }
  },

  insertNoRobots: function () {
    const node = document.createElement('meta');
    node.setAttribute('name', 'robots');
    node.setAttribute('content', 'noindex, nofollow');
    this.document.head.appendChild(node);
  },

  insertViewportMetaTag: function () {
    const node = document.createElement('meta');
    node.setAttribute('name', 'viewport');
    node.setAttribute('content', 'width=device-width, initial-scale=1.0');
    this.document.head.appendChild(node);
  },

  insertFaviconTag: function () {
    if (this.favicon && (this.favicon.unique_url || this.favicon.content_url)) {
      const element = document.createElement('link');
      element.setAttribute('rel', 'icon');
      element.setAttribute('href', buildPublishUrl(this.favicon, {}));
      this.document.head.appendChild(element);
    }
  },

  insertVersionMetaTag() {
    const element = document.createElement('meta');
    element.name = 'lp-version';
    element.content = window.builderVersion || 'unknown';
    this.document.head.appendChild(element);
  },

  insertUACompatibleMetaTag() {
    const element = document.createElement('meta');
    element.httpEquiv = 'X-UA-Compatible';
    element.content = 'IE=edge';
    this.document.head.appendChild(element);
  },

  getExternalFontFacesInsertion() {
    const currentFonts = this.settings.webFontsExternalInUse || {};
    const totalFonts = this.settings.fonts || [];
    if (!totalFonts.length || !Object.keys(currentFonts).length) {
      return;
    }
    const externalFontFamily = totalFonts.reduce((externalFonts, fontFamily) => {
      const family = fontFamily.family.toLowerCase();
      if (currentFonts[family]) {
        const variants = fontFamily.variants.filter(
          fontVariant =>
            fontVariant.url &&
            currentFonts[family].some(weight => Number(weight) === fontVariant.fontWeight)
        );
        if (variants.length) {
          return [...externalFonts, { ...fontFamily, variants }];
        }
      }
      return externalFonts;
    }, []);

    const fontFacesContent = utilsNew.generateFontFacesContent(externalFontFamily);
    const fontFacesScripts = fontService.generateFontFacesScripts(fontFacesContent);

    if (fontFacesScripts.length) {
      return {
        content: fontFacesScripts,
        placement: 'head',
      };
    }
  },

  addBreakpoint: function (breakpoint) {
    this.breakpoints.push(breakpoint);
    this.fireEvent('breakpointAdded', breakpoint);
  },

  hasManyBreakpoints: function () {
    return this.breakpoints.length > 1;
  },

  hasBreakpoint: function (name) {
    return (
      typeof this.breakpoints.find(function (breakpoint) {
        return breakpoint.name === name;
      }) !== 'undefined'
    );
  },

  switchToBreakpoint: function (name) {
    //Keep track of the undo state because if the undo stack has
    //user changes than we don't want to clear the undo below otherwise
    //we do clear it.
    var clearUndo = !this.undoManager.canUndoOrRedo();
    this.fireEvent('beforeBreakpointChanged', name);

    var breakpoint = this.getBreakpointByName(name);
    if (typeof breakpoint === 'undefined') {
      return;
    }

    this.undoManager.registerUndo({
      action: this.switchToBreakpoint,
      receiver: this,
      params: [this.currentBreakpoint.name],
    });

    this.currentBreakpoint = breakpoint;
    this.updateAndRefresh();
    if (clearUndo) {
      this.undoManager.clearUndoQueue();
    }
    this.fireEvent('breakpointChanged', name);
  },

  updateAndRefresh: function () {
    jQuery(this.document.activeElement).trigger('blur');
    this.getRootElement().setContentWidthForBreakpoint(this.getCurrentBreakpoint());
    this.updateElementGeometry();
    this.updateElementStyles();
    this.fireEvent('pageUpdatedAndRefreshed');
  },

  isDefaultBreakpoint: function () {
    return this.currentBreakpoint.default === true;
  },

  //TODO: Grep for all uses of this and replace with isMobileBreakpoint() or
  //isDesktopBreakpoint()
  getCurrentBreakpoint: function () {
    return this.currentBreakpoint;
  },

  isMobileBreakpoint: function () {
    return this.getCurrentBreakpoint().name === 'mobile';
  },

  isTabletBreakpoint: function () {
    return this.getCurrentBreakpoint().name === 'tablet';
  },

  isDesktopBreakpoint: function () {
    return this.getCurrentBreakpoint().name === 'desktop';
  },

  getBreakpointByName: function (name) {
    return this.breakpoints.find(function (breakpoint) {
      return breakpoint.name === name;
    });
  },

  updateElementStyles: function () {
    this.elements.each(function (elm) {
      if (typeof elm.applyStyleAttributes === 'function') {
        elm.applyStyleAttributes();
      }
    });
    this.getRootElement().applyDefaultStyles();
  },

  updateElementGeometry: function () {
    this.elements.each(function (elm) {
      if (typeof elm.updateElementGeometry === 'function') {
        elm.updateElementGeometry();
      }
    });
    this.getRootElement().applyDefaultStyles();
  },

  getRootElement: function () {
    return this.getElementById('lp-pom-root');
  },

  getElementByIndex: function (index) {
    return this.elements[index];
  },

  getNextElementId: function () {
    this.last_element_id += 1;
    return this.last_element_id;
  },

  removeForm: function () {
    var form = this.getForm();
    if (form) {
      var fromParent = form.getParentElement();
      form.destroy();
      this.has_form = false;
      this.fireEvent('elementRemoved', { element: form, parentElement: fromParent });
    }
  },

  insertElement: function (elm, options) {
    this.undoManager.registerUndo({
      action: elm.removeFromPage,
      receiver: elm,
    });

    options = options || {};
    elm.id = elm.id || elm.type + '-' + this.getNextElementId();

    if (typeof options.index !== 'undefined' && options.index < this.elements.length) {
      this.elements.splice(options.index, 0, elm);
    } else if (
      options.container &&
      typeof options.containerIndex !== 'undefined' &&
      options.containerIndex < options.container.childElements.length
    ) {
      var i = this.elements.indexOf(options.container.childElements[options.containerIndex]);

      this.elements.splice(i, 0, elm);
    } else {
      this.elements.push(elm);
    }

    if (options.container) {
      options.container.insertChildElement(elm, options);
    }

    if (elm.type === 'lp-pom-form') {
      this.has_form = true;
      this.style.updatePageStyles();
    }

    if (options.silent) {
      return;
    }

    if (options.dontActivateOnInsert) {
      elm.activateOnInsert = false;
    }

    if (options.dontUpdateElementTreeOnInsert) {
      elm.updateElementTreeOnInsert = false;
    }

    this.fireEvent('elementInserted', elm);
  },

  updateElement: function (elm) {
    this.fireEvent('elementInserted', elm);
  },

  removeElement: function (elm, fromParent) {
    this.elements = this.elements.without(elm);
    if (elm.type === 'lp-pom-form') {
      this.has_form = false;
    }
    this.fireEvent('elementRemoved', { element: elm, parentElement: fromParent });
  },

  swapElementOrder: function (a, b) {
    var aIndex = this.elements.indexOf(a);
    var bIndex = this.elements.indexOf(b);
    this.elements[aIndex] = b;
    this.elements[bIndex] = a;
  },

  hasForm: function () {
    return this.has_form;
  },

  hasContent: function () {
    return this.getRootElement().childElements.length > 0;
  },

  getDefaultWidth: function () {
    return this.settings.defaultWidth * 1;
  },

  hasLightboxLink: function () {
    return this.isLightbox() && window.editor.hasButtonLinkedTo(this.getRefId());
  },

  isUnlinked: function () {
    return this.isLightbox() && !this.hasLightboxLink();
  },

  hasBeenViewed: function () {
    return Boolean(!this.isLightbox() || this.settings.hasBeenViewed);
  },

  markAsViewed: function () {
    this.settings.hasBeenViewed = true;
  },

  CTAifyLink: function (link) {
    return ctaLinkFilter({
      currentUrl: window.location.href,
      enabled: !this.getConfigValue('addGoalAttributeToLinks'),
      link: link,
      goals: this.goals,
      isPublishMode: this.isPublishMode(),
    });
  },

  decorateImageSrc: function (src) {
    return this.isPublishOrPreviewMode() ? '/publish' + src : src;
  },

  getElementCount: function () {
    return this.elements.length;
  },

  getLastElement: function () {
    return this.elements.last();
  },

  getElementTypes: function () {
    return this.elements.pluck('type').uniq();
  },

  getElementById: function (id) {
    var elements = this.elements;
    if (id && id.indexOf('lp-multi-select') > -1) {
      return this._getMultiSelectElement();
    }

    return elements.find(function (e) {
      return e.id === id;
    });
  },

  getElementByZIndex: function (zIndex) {
    return _.find(this.elements, function (elm) {
      return elm.view && _.isFunction(elm.view.getZIndex) && elm.view.getZIndex() === zIndex;
    });
  },

  _getMultiSelectElement: function () {
    var activeElement = window.editor.activeElement;
    if (activeElement && activeElement.type === 'lp-multi-select') {
      return activeElement;
    }
  },

  getElementsByType: function (type) {
    return this.elements.filter(function (element) {
      return element.type === type;
    });
  },

  getElementsByTypeList: function (typeArray) {
    return _.flatten(
      typeArray.map(function (type) {
        return this.getElementsByType(type);
      }, this)
    );
  },

  getParalaxEnabledElements: function () {
    var els = this.getElementsByTypeList(['lp-pom-root', 'lp-pom-block']);
    return _.filter(els, function (el) {
      return el.model.hasParallax();
    });
  },

  isModuleAtLimit: function (module) {
    return this.getElementsByType(module.type).length >= module.getLimit(this);
  },

  getForm: function () {
    return this.getElementsByType('lp-pom-form').first();
  },

  getElementsByTypeAndContainerId: function (type, id) {
    return this.elements.select(function (e) {
      return e.containerId === id && e.type === type;
    });
  },

  indexOfElement: function (elm) {
    return this.elements.indexOf(elm);
  },

  getVisibleElements: function () {
    return this.elements.select(function (e) {
      return e.view;
    });
  },

  getElementsWithFonts: function () {
    //If the element responds to getFonts return it.
    return this.elements.select(function (e) {
      return typeof e.getFonts === 'function';
    });
  },

  getFontsFromElements: function () {
    var fontList = [];

    this.getElementsWithFonts().each(function (e) {
      fontList.push(e.getFonts());
    });

    return fontList.flatten().sort().uniq(true);
  },

  generateDefaultElementName: function (type, base, copy) {
    copy = copy || false;

    var copyIndex = base.indexOf('copy');
    if (copyIndex > -1) {
      base = base.substr(0, copyIndex - 1);
    }

    var withDefaultNames = this.getElementsByType(type)
      .pluck('name')
      .grep(new RegExp('^' + base + (copy ? '\\scopy' : '') + '\\s\\d+'));

    var next;

    if (withDefaultNames.length > 0) {
      next = withDefaultNames.max(function (name) {
        var arr = name.split(' ');
        return arr[arr.length - 1] * 1 + 1;
      });
    } else {
      next = 1;
      base = base.sub(/copy\s\d+/, '');
    }

    return base + ' ' + (copy ? 'copy ' : '') + next;
  },

  hasSections: function () {
    return this.getElementsByType('lp-pom-block').length > 0;
  },

  addInsertion: function (content, placement) {
    this.insertions.push({
      content: content,
      placement: placement,
    });
  },

  _getPageDimensionsForJavascriptApi: function () {
    const breakpoints = this.getEnabledBreakpoints();

    return breakpoints.reduce((acc, breakpoint, index) => {
      acc[breakpoint.name] = this.getDimensions(breakpoint.name);

      // set xMaxWidth if this isn't the largest breakpoint
      if (index > 0) {
        acc[`${breakpoint.name}MaxWidth`] = this.getBreakpointMaxWidth(breakpoint.name);
      }

      // ordered list of enabled breakpoints
      acc.breakpoints = [...acc.breakpoints, breakpoint.name];

      return acc;
    }, {
      breakpoints: []
    });
  },

  getUbPageInsertion: function () {
    return {
      content: [
        '<script type="text/javascript">',
        'window.ub = ',
        JSON.stringify({
          page: {
            id: this.uuid,
            variantId: this.variant_id,
            usedAs: this.usedAs(),
            name: gon.page_name,
            url: this.page.published_url,
            dimensions: this._getPageDimensionsForJavascriptApi(),
            isEmbeddable: this.getConfigValue('isEmbeddable') ? true : undefined,
          },
          hooks: {
            beforeFormSubmit: [],
            afterFormSubmit: [],
          },
        }),
        ';</script>',
      ].join(''),
      placement: 'head',
    };
  },

  getOpenGraphMetaTagInsertions: function () {
    var openGraphData = this.open_graph;
    var pageTitle = openGraphData.ogTitle || this.page.title;

    if (_.isEmpty(openGraphData)) {
      pageTitle = this.page.published_url;
    }

    var content = "<meta property='og:title' content='" + _.escape(pageTitle) + "'/>";

    for (const [key, value] of Object.entries(openGraphData)) {
      if (value && key !== 'ogTitle') {
        content +=
          "<meta property='og:" +
          key.replace('og', '').toLowerCase() +
          "' content='" +
          _.escape(value) +
          "'/>";
      }
    }

    return {
      content: content,
      placement: 'head',
    };
  },

  debugPageInsertions: function () {
    this.getPageInsertions().each(function (i) {
      console.log(i);
    });
  },

  getEmbeddableInsertion: function () {
    if (this.getConfigValue('isEmbeddable')) {
      var displaySettings = this.getRootElement().model.safeGet('displaySettings');
      if (displaySettings) {
        return {
          placement: 'head',
          content:
            '<script>window.ub.page.embeddableDisplaySettings = ' +
            JSON.stringify(displaySettings) +
            ';</script>',
        };
      }
    }
  },

  getMainJsInsertion: function () {
    return {
      content: this.publishedScriptTag('published-js/main.bundle.js', true),
      placement: 'body:after',
    };
  },

  getMainCssInsertion: function () {
    return {
      content: this.publishedStylesheetTag('published-css/main.css'),
      placement: 'head',
    };
  },

  getUserScriptInsertions: function () {
    return this.getElementsByType('lp-script')
      .filter(element => !element.model.safeGet('predefined'))
      .map(element => element.toPageInsertion());
  },

  getPredefinedScriptInsertions: function () {
    // The builder used to have two 'predefined' scripts available to users: jquery 1.4.2 and
    // jquery.fancybox 1.3.4. In CN-3137 these were removed but for existing predefined scripts in
    // the POM we need to maintain this logic to insert them separately because:
    //  1. They need to be added before any user scripts, in case user scripts depend on them
    //  2. If they are present we need to also insert the jquery-shims script for compatibility
    const scripts = this.getElementsByType('lp-script')
      .filter(element => element.model.safeGet('predefined'))
      .map(element => element.toPageInsertion());

    if (scripts.length) {
      scripts.push({
        content: this.publishedScriptTag('published-js/jquery-shims.bundle.js'),
        placement: 'head',
      });
    }

    return scripts;
  },

  getPageInsertions: function () {
    // TODO-TR: split getPageInsertions out into a separate utils module.
    var insertions = _.compact(
      [].concat(
        this.getMainCssInsertion(),
        this.getOpenGraphMetaTagInsertions(),

        // Legacy predefined script elements (jQuery)
        this.getPredefinedScriptInsertions(),

        // window.ub object
        this.getUbPageInsertion(),
        getLightboxInsertion(this),
        this.getWebFontsInsertion(),
        this.getEmbeddableInsertion(),
        this.getExternalFontFacesInsertion(),

        getAnimationKeyframesInsertions(this),
        getAnimationEffectInsertions(this),

        // Autoscale insertion (after window.ub)
        getAutoscaleInsertion(this),

        // Animation effect CSS link tags
        // getAnimationEffectInsertion(this),

        // Insertions added by elements - custom HTML, stylesheets, videos etc
        this.insertions,

        // Custom script elements
        this.getUserScriptInsertions(),

        this.getMainJsInsertion(),
        this.getLCP(),
        getUnsplashTrackingPixel(this)
      )
    );

    // add html comment markers denoting the start and end of each placement:
    var groupedInsertions = { head: [], 'body:before': [], 'body:after': [] };
    var addToGroup = function (insertion) {
      groupedInsertions[insertion.placement] = groupedInsertions[insertion.placement] || [];
      groupedInsertions[insertion.placement].push({
        content: insertion.content,
        placement: insertion.placement,
      });
    };
    // add all our standard insertions to groupedInsertions:
    insertions.forEach(addToGroup);

    // add comment markers around each 'placement' of insertions:
    var flattenInsertions = function (insertions) {
      var flattenedInsertions = [];
      var add = function (elem) {
        flattenedInsertions.push(elem);
      };
      var startMarker, endMarker;
      for (var placement in insertions) {
        startMarker = 'start';
        endMarker = 'end';
        if (placement.indexOf('before') !== -1) {
          // The lp-publisher code reverses these.
          // It *prepends* elements to the top of body:before.
          startMarker = 'end';
          endMarker = 'start';
        }
        flattenedInsertions.push({
          placement: placement,
          content: '<!-- lp:insertions ' + startMarker + ' ' + placement + ' -->',
        });
        insertions[placement].forEach(add);
        flattenedInsertions.push({
          placement: placement,
          content: '<!-- lp:insertions ' + endMarker + ' ' + placement + ' -->',
        });
      }
      return flattenedInsertions;
    };
    //
    return flattenInsertions(groupedInsertions);
  },

  topMost: function (elm1, elm2) {
    //TODO: refactor
    /*jshint maxcomplexity: 15 */
    if (elm1.page !== elm2.page || elm1.parentElement === null || elm2.parentElement === null) {
      return;
    }

    var e1 = elm1;
    var e2 = elm2;
    var d1 = elm1.getRootDistance();
    var d2 = elm2.getRootDistance();

    if (d1 < d2) {
      e2 = elm2.getAncestorAtDistance(d1);
      if (e2 === e1 || d1 < 2) {
        return elm2;
      }
    } else if (d2 < d1) {
      e1 = elm1.getAncestorAtDistance(d2);
      if (e2 === e1 || d2 < 2) {
        return elm1;
      }
    }

    var z1 = e1.model.getZIndex() || 0;
    var z2 = e2.model.getZIndex() || 0;

    if (z1 > z2) {
      return elm1;
    }

    if (z2 > z1) {
      return elm2;
    }

    var i1 = this.elements.indexOf(e1);
    var i2 = this.elements.indexOf(e2);

    return i1 > i2 ? elm1 : elm2;
  },

  getAttachments: function () {
    var attachments = [];
    this.getElementsByType('lp-pom-button').each(function (b) {
      if (b.model.exists('action') && b.model.get('action.type') === 'download') {
        attachments.push(b.model.get('action.asset'));
      }
    });
    return attachments;
  },

  getDimensions: function (breakpointName) {
    var breakpoint = this.getBreakpointByName(breakpointName);
    var rootElement = this.getRootElement();

    var totals = rootElement
      .getSections()
      .filter(function (section) {
        return section.model.isVisible(breakpointName);
      })
      .reduce(
        function (acc, section) {
          var model = section.model;

          var hasOutsideBorder =
            model.safeGet('style.border.style', breakpoint) !== 'none' &&
            model.safeGet('geometry.borderLocation', breakpoint) === 'outside';

          var borderSize = hasOutsideBorder
            ? model.safeGet('style.border.width', breakpoint) || 0
            : 0;

          return {
            height:
              acc.height +
              model.getHeight(breakpoint) +
              (model.safeGet('geometry.margin.bottom', breakpoint) || 0) +
              (model.safeGet('geometry.borderApply.top', breakpoint) ? borderSize : 0) +
              (model.safeGet('geometry.borderApply.bottom', breakpoint) ? borderSize : 0),
            leftBorder: Math.max(
              acc.leftBorder,
              model.safeGet('geometry.borderApply.left', breakpoint) ? borderSize : 0
            ),
            rightBorder: Math.max(
              acc.rightBorder,
              model.safeGet('geometry.borderApply.right', breakpoint) ? borderSize : 0
            ),
          };
        },
        {
          height: rootElement.model.safeGet('geometry.padding.top', breakpoint) || 0,
          leftBorder: 0,
          rightBorder: 0,
        }
      );

    return {
      height: totals.height,
      width:
        rootElement.model.get('geometry.contentWidth', breakpoint) +
        totals.leftBorder +
        totals.rightBorder,
    };
  },

  getContentWidth: function () {
    return this.getRootElement().getContentWidth();
  },

  usedAs: function () {
    return this.page.used_as;
  },

  isMain: function () {
    return this.usedAs() === 'main';
  },

  isLightbox: function () {
    return this.usedAs() === 'lightbox';
  },

  isFormConfirmation: function () {
    return this.usedAs() === 'form_confirmation';
  },

  getUsedAsText: function () {
    if (_.isUndefined(this.page) || this.isMain()) {
      return this.getConfigValue('displayName');
    } else if (this.isFormConfirmation()) {
      return 'Form Confirmation Dialog';
    } else if (this.name) {
      // e.g. lightboxes
      return this.name;
    } else {
      return 'Page';
    }
  },

  allowForm: function () {
    return this.isMain() || this.isLightbox();
  },

  allowTitleAndMetadata: function () {
    return this.isMain() && this.getConfigValue('allowPageMetadata');
  },

  allowRootBackground: function () {
    return this.isMain() && this.getConfigValue('allowRootBackground');
  },

  allowParallax: function () {
    // Parallax shouldn't be allowed on form confirmation dialogs but we're leaving it
    // there for legacy reasons
    return (
      this.isDesktopBreakpoint() &&
      !window.editor.page.isLightbox() &&
      this.getConfigValue('allowParallaxBackgrounds')
    );
  },

  allowAutoLayout: function () {
    return this.isMobileBreakpoint() && this.getConfigValue('allowAutoLayout');
  },

  allowFitWidthToPage: function () {
    return this.isMain() && this.getConfigValue('allowFitWidthToPage');
  },

  allowSectionMargins: function () {
    return this.isMain() && this.getConfigValue('allowSectionMargins');
  },

  allowTopPadding: function () {
    return this.isMain() && this.getConfigValue('allowRootTopPadding');
  },

  allowAutoscale: function () {
    return this.isMain() &&
      this.getConfigValue('allowAutoscale') &&
      (ubBanzai.features.isAutoscaleEnabled() || ensignManager.getFlagByKey('features/autoscale'));
  },

  allowTabletBreakpoint: function () {
    return this.isMain() && this.getConfigValue('allowTabletBreakpoint') && ubBanzai.features.isTabletBreakpointEnabled();
  },

  toFullJSO: function () {
    var jso = {
      description: this.metaData.description,
      elements: _.map(this.elements, function (elm) {
        return elm.toJSO();
      }),
      goal_type: null,
      goal_url: null,
      has_form: this.hasForm(),
      id: this.id,
      keywords: this.metaData.keywords,
      last_element_id: this.last_element_id,
      name: this.name,
      page: this.page,
      settings: this.settings,
      open_graph: this.open_graph,
      favicon: this.favicon,
      autoscale: this.autoscale,
      shared: this.shared,
      styles: this.styles,
      title: this.metaData.title,
      type: 'PageVariant', // Saving as anything other than 'PageVariant' breaks saving to webapp
      variant_id: this.variant_id,
      version: this.version,
    };

    return jso;
  },

  toJSO: function () {
    var cta = this.cta;
    if (cta === '' || cta === null) {
      var urls = this.getURLs('link');
      if (urls.length > 0) {
        cta = urls[0];
      }
    }
    var jso = {
      version: this.version,
      name: this.name,
      shared: this.shared,
      last_element_id: this.last_element_id,
      goal_url: null,
      goal_type: null,
      has_form: this.hasForm(),
      title: this.metaData.title,
      description: this.metaData.description,
      keywords: this.metaData.keywords,
      settings: this.settings,
      open_graph: this.open_graph,
      favicon: this.favicon,
      autoscale: this.autoscale,
      styles: this.styles,
      elements: [],
    };

    this.elements.each(function (e) {
      jso.elements.push(e.toJSO());
    });

    return jso;
  },

  prepareForSave: function (editor) {
    fontService.storeTextWebFontsInUse(this);

    formPublishStyles.saveStylesForPublish(this, this.elements, editor);

    this.fireEvent('beforePageSave', this.page);
  },

  toXML: function () {
    var elements = [];
    var attachments = this.getAttachments();

    this.elements.each(function (e) {
      elements.push(e.toJSO());
    });

    /* jshint multistr:true */
    var xml =
      '<' +
      this.resourceType.underscore() +
      '>' +
      (Object.isUndefined(this.page)
        ? ''
        : '<page> \
          <id>' +
          this.page.id +
          '</id> \
          <name>' +
          this.page.name +
          '</name> \
          <used_as>' +
          this.page.used_as +
          '</used_as>' +
          (Object.isUndefined(this.page.path_name)
            ? ''
            : '<path_name>' + this.page.path_name + '</path_name>') +
          '</page>') +
      '<id>' +
      this.id +
      '</id> \
         <version>' +
      this.version +
      '</version> \
         <name><![CDATA[' +
      this.name +
      ']]></name> \
         <last_element_id>' +
      this.last_element_id +
      '</last_element_id> \
         <has_form>' +
      this.has_form +
      '</has_form> \
         <title><![CDATA[' +
      this.metaData.title +
      ']]></title> \
         <description><![CDATA[' +
      this.metaData.description +
      ']]></description> \
         <keywords><![CDATA[' +
      this.metaData.keywords +
      ']]></keywords> \
         <settings><![CDATA[' +
      Object.toJSON(this.settings) +
      ']]></settings> \
         <open_graph><![CDATA[' +
      Object.toJSON(this.open_graph) +
      ']]></open_graph> \
      <favicon><![CDATA[' +
      Object.toJSON(this.favicon) +
      ']]></favicon> \
      <autoscale>' +
      this.autoscale +
      '</autoscale> \
         <elements><![CDATA[' +
      Object.toJSON(elements).gsub(']]>', ']]]]><![CDATA[>') +
      ']]></elements>';

    if (attachments.length > 0) {
      attachments.each(function (a) {
        xml +=
          '<add_attachment> \
                <asset_id>' +
          a.id +
          '</asset_id> \
                <protected_download>' +
          a.protected_download +
          '</protected_download> \
                </add_attachment>';
      });
    }

    xml += '</' + this.resourceType.underscore() + '>';

    return xml;
  },

  publishedStylesheetTag: function (path, id) {
    var params = id ? 'id="' + id + '"' : '';
    return (
      '<link href="' +
      window.lp.getPublishedAssetsUrl(path) +
      '" rel="stylesheet" media="screen" type="text/css" ' +
      params +
      '/>'
    );
  },

  publishedScriptTag: function (path, async) {
    return (
      '<script ' +
      (async ? 'async ' : '') +
      'src="' +
      window.lp.getPublishedAssetsUrl(path) +
      '" type="text/javascript"></script>'
    );
  },

  getConfigValue: function (property) {
    return contentTypeConfig.getValue(property, this.settings.contentType);
  },

  getLastTextStyle: function () {
    return this.settings.lastTextStyle || null;
  },

  setLastTextStyle: function (textStyleObject) {
    if (_.isEmpty(textStyleObject)) {
      delete this.settings.lastTextStyle;
    } else {
      this.settings.lastTextStyle = textStyleObject;
    }
  },

  addPreloadImageLink: function (url) {
    this.insertions.push({
      content: '<link rel="preload" href="' + url + '" as="image">',
      placement: 'head',
    });
  },

  getLCP: function () {
    var largest = {
      area: 0,
      url: '',
    };
    this.elements.each(function (e) {
      if (e.type === 'lp-pom-image') {
        var area =
          e.model.get('content.asset.size.width') * e.model.get('content.asset.size.height');
        if (area > largest.area) {
          largest.area = area;
          largest.url = e.model.get('content.asset.content_url');
        }
      }
    });
    this.addPreloadImageLink(largest.url);
  },
});
