import snakeCase from 'lodash/snakeCase';
import createElement from 'ub/create-dom-element';

import * as phoneValidation from './phone-validation';
import * as emailValidation from './email-validation';

const _generateFieldLabel = field => {
  const styleSpan = createElement('span', { class: 'label-style' });

  styleSpan.innerHTML = field.validations.required ? `${field.name}&nbsp;*` : field.name;

  const labelElm = createElement('label', {
    class: 'main lp-form-label',
    for: field.id,
    id: `label_${field.id}`,
  });

  labelElm.appendChild(styleSpan);

  return labelElm;
};

const _makeFieldViews = field => {
  const container = createElement('div', {
    class: `lp-pom-form-field ${field.lpType}`,
    id: `container_${field.id}`,
  });

  let label;

  if (!field.hideLabel) {
    label = _generateFieldLabel(field);

    container.appendChild(label);
  }

  const inputWrapper = createElement('div', { class: 'ub-input-item input-wrap' });

  return {
    container,
    inputWrapper,
    label,
  };
};

const _addGroupInputs = (groupType, field, inputWrap) => {
  inputWrap.addClassName('optionsList');
  inputWrap.setAttribute('id', `group_${field.id}`);

  field.options.forEach((option, index) => {
    const optionContainer = createElement('div', {
      class: 'option',
      id: `ub-option-${field.id}-item-${index}`,
    });

    const valueSnakeCase = snakeCase(option.value);
    const fieldId = `${field.id}_${valueSnakeCase}`;

    const inputElm = createElement('input', {
      type: groupType,
      id: fieldId,
      name: field.id,
      value: option.value,
      class: `${groupType} form-builder-${groupType}-input form_elem_${field.id}`,
      checked: option.checked,
    });

    if (field.validations.required) {
      if (field.type === 'checkbox') {
        // The `required` attribute on checkboxes does not work the way we want (it would require
        // that every checkbox in the group was selected), so instead we use a data attribute to
        // record that the dropdown group is required and apply custom validation in
        // src/published/form/validation.ts
        inputElm.setAttribute('data-required', '');
      } else {
        inputElm.setAttribute('required', '');
      }
    }

    const styleSpan = createElement('span', { class: 'label-style' });

    styleSpan.innerHTML = option.label;

    const labelElm = createElement('label', {
      for: fieldId,
      class: `opt-label form-builder-${groupType}-label`,
    });

    labelElm.appendChild(styleSpan);

    optionContainer.appendChild(inputElm);
    optionContainer.appendChild(labelElm);

    inputWrap.appendChild(optionContainer);
  });
};

export const getInputType = field => {
  // Only add in the type if not custom validation as the validation from the types
  //interupts the custom validation
  if (!field.validations.custom) {
    // Use <input type=email> only if the field currently has email validation enabled, since it has
    // built-in validation
    if (field.validations && field.validations.email) {
      return 'email';
    }

    // Use <input type=tel> if the field was originally added as a phone field - regardless of whether
    // the field currently has phone validation enabled - since it does not have any built-in
    // validation
    if (field.show && field.show.phone) {
      return 'tel';
    }
  }

  return 'text';
};

export const getInputPattern = (validationType, validations) => {
  // Set the pattern to custom if validate as phone and validate as email are false
  if (validations.custom && !validations.phone && !validations.email) {
    return validations.custom;
  }
  if (validations.phone) {
    switch (validationType) {
      case 'uk':
        return phoneValidation.uk.source;

      case 'generic':
        return phoneValidation.generic.source;

      case 'australian':
        return phoneValidation.australia.source;

      case 'north-american':
      default:
        return phoneValidation.northAmerica.source;
    }
  }
  if (validations.email) {
    return emailValidation.generic;
  }
};

const getSingleLineTextField = field => {
  const views = _makeFieldViews(field);

  const input = createElement('input', {
    id: field.id,
    name: field.id,
    type: getInputType(field),
    class: `ub-input-item single text form_elem_${field.id}`,
  });

  if (field.placeholder) {
    input.setAttribute('placeholder', field.placeholder);
  }

  if (field.validations.required) {
    input.setAttribute('required', '');
  }

  const pattern = getInputPattern(field.validationType, field.validations);
  if (pattern) {
    input.setAttribute('pattern', pattern);
  }

  views.container.appendChild(input);

  return {
    field,
    root: views.container,
    label: views.label,
  };
};

const getMultiLineTextField = field => {
  const views = _makeFieldViews(field);
  const input = createElement('textarea', {
    id: field.id,
    name: field.id,
    class: `ub-input-item single text form_elem_${field.id}`,
  });

  if (field.validations.required) {
    input.setAttribute('required', '');
  }

  views.container.appendChild(input);

  views.container.addClassName('multi-group');

  return {
    field,
    root: views.container,
    label: views.label,
  };
};

const getHiddenField = field => {
  const input = createElement('input', {
    id: field.id,
    name: field.id,
    type: 'hidden',
    class: 'hidden',
    value: field.value,
  });

  return {
    field,
    root: input,
  };
};

const getDropdownField = field => {
  const views = _makeFieldViews(field);
  const select = createElement('select', {
    id: field.id,
    name: field.id,
    class: `ub-input-item single form_elem_${field.id}`,
  });

  field.selectOptions.each((option, index) => {
    const value = field.invalidOptions && field.invalidOptions.include(index) ? '' : option;

    const optionElm = createElement('option', { value: value });
    optionElm.innerHTML = option;

    select.appendChild(optionElm);
  });

  if (field.validations.required) {
    select.setAttribute('required', '');
  }

  views.container.appendChild(select);

  return {
    field,
    root: views.container,
    label: views.label,
  };
};

const getCheckboxField = field => {
  const views = _makeFieldViews(field);
  const inputWrap = views.inputWrapper;

  views.container.addClassName('multi-group');

  views.container.appendChild(views.inputWrapper);
  _addGroupInputs('checkbox', field, inputWrap);

  return {
    field,
    root: views.container,
    label: views.label,
  };
};

const getRadioField = field => {
  const views = _makeFieldViews(field);
  const inputWrap = views.inputWrapper;

  views.container.addClassName('multi-group');

  views.container.appendChild(views.inputWrapper);
  _addGroupInputs('radio', field, inputWrap);

  return {
    field,
    root: views.container,
    label: views.label,
  };
};

export default {
  'single-line-text': getSingleLineTextField,
  email: getSingleLineTextField,
  'multi-line-text': getMultiLineTextField,
  hidden: getHiddenField,
  'drop-down': getDropdownField,
  'checkbox-group': getCheckboxField,
  'radio-group': getRadioField,
};
