window.lp.ModuleElementBuilderMethods = {
  hasElementBuilder: true,

  getElementBuilderClass: function() {
    return this.namespace[this.getElementBuilderClassName()];
  },

  getElementBuilderClassName: function() {
    return (this.elementBuilderClassName ?
           this.elementBuilderClassName :
           this.name.replace(/ /g,'') + 'ElementBuilder');
  },

  openElementBuilder: function(elm, options) {
    window.editor.dialogManager.openDialog(
      this.getElementBuilder(),
      {elm: elm, options: options}
    );
  },

  getElementBuilder: function() {
    this.elementBuilder  = this.elementBuilder || new (this.getElementBuilderClass())();
    return this.elementBuilder;
  },

  // Deprecated methods:
  openBuilderDialog : function(elm, options) {
    this.openElementBuilder(elm, options);
  },

  openBuilder: function(elm, options) {
    this.openElementBuilder(elm, options);
  },

  getBuilder: function() {
    return this.getElementBuilder();
  }
};
