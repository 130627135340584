// For overrides in 'test' environment see  src/tests/legacy/support/test_banzai_features.js

import ubBanzai from 'ub/control/banzai-features';
import includes from 'lodash/includes';

const getPlanFeatures = () => window.gon.featurePlans || {};

const canOverridePlan = () => (
  window.gon.olark_plan_name === 'Unbouncer' ||
  includes(['development', 'integration'], window.gon.env)
);

const getPlanFeature = (featureName) => {
  const planValue = getPlanFeatures()[featureName];

  const enabledOnPlan = typeof planValue === 'undefined' ? true : planValue;

  const overrideEnabled = ubBanzai.features.isOverridePlanEnabled();

  // This allows the developer to override the plan values easily
  // using the query parameter: ?overridePlan=1
  return overrideEnabled && canOverridePlan() ? true : enabledOnPlan;
};

const getBanzaiValue = (featureName) => {
  const isDefinedInBanzai = ubBanzai.isFeatureDefined(featureName);

  // If the feature is not defined in banzai, we set this to 'true' so that we can
  // default to whatever value we get from plan flags
  return isDefinedInBanzai ? ubBanzai.isFeatureEnabled(featureName) : true;
};

const isFeatureEnabled = (featureName) => (
  getBanzaiValue(featureName) && getPlanFeature(featureName)
);

export default { isFeatureEnabled };