/* globals Class, $H, CodeMirror */
var jQuery = require('jquery');

window.jui.CodeEditor = Class.create(window.jui.Component, window.jui.ControlModel, {
  options: function($super, options) {
    return $super($H({
      onMouseup: function(){},
      onKeyup: function(){},
      onChange: function(){},
      toggleCheckboxYPosition: '1px',
      attributes: {
        className: 'jui-code-editor'
      }
    }).merge(options));

  },

  initialize: function($super, options) {
    $super( options );
  },

  installUI: function($super) {
    $super();
    var $labelContainer = jQuery('<div class="label-container">');
    this.insert($labelContainer[0]);

    this.label = jQuery('<div class="code-label">' + this.options.label + '</div>')[0];
    $labelContainer.append(this.label);

    this.input = this.insert(new Element('textarea'));

    var self = this;

    this.codeMirror = CodeMirror( function(elt) {
      elt.observe( 'mouseup', self.options.onMouseup );
      elt.observe( 'keyup', self.options.onKeyup );
      self.e.replaceChild( elt, self.input );
    }, {
      mode: self.options.mode || 'text/html',
      tabMode: self.options.tabMode || 'indent',
      lineNumbers: self.options.lineNumbers ? self.options.lineNumbers : true,
      lineWrapping: self.options.lineWrapping ? self.options.lineWrapping : true,
      gutter: self.options.gutter ? self.options.gutter : true,
      theme: 'yeti',
      onChange: self.options.onChange
    } );

    this.toggleWrapping = new window.jui.FormCheckboxInput({
      label: "Soft Wrap",
      checked: self.options.lineWrapping ? self.options.lineWrapping : true,
      inputId: 'toggle-wrapping-checkbox',
      onclick: function( e ) {
        var wrap = e.source.getValue();
        self.codeMirror.setOption( 'lineWrapping', wrap );
      }
    });

    $labelContainer.append(this.toggleWrapping.e);
    $labelContainer.append(this.label.e);
  },

  focus: function() {
    this.codeMirror.focus();
  },

  setValue: function(value) {
    this.codeMirror.setValue(value);
    this.codeMirror.refresh();
  },

  getValue: function() {
    return this.codeMirror.getValue();
  },
});
