var jQuery = require('jquery');

var loadAsync = function loadAsync(u){
  setTimeout(function(){
    var d = document, f = d.getElementsByTagName('script')[0],
    s = d.createElement('script');
    s.type = 'text/javascript'; s.async = true; s.src = u;
    f.parentNode.insertBefore(s, f);
  }, 1);
};

jQuery(function() {

  //---------------------------------------------------------------------------
  // Dropdown menus (cogs, header: context-switching, account/user dropdown menu).
  //
  jQuery('.dropdown-toggle').dropdown();

});


//TODO update once _totango.html.erb doesn't depend on this being in global scope.
if (typeof module !== 'undefined') {
  module.exports = loadAsync;
}
window.loadAsync = loadAsync;
