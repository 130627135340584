/* globals jui, $H, Class */

var rxUi = window.rxUi;

module.exports = Class.create(jui.Component, {
  options: function($super,options){
    return $super($H({attributes:{className:'form-label-width-indicator'}}).merge(options));
  },

  initialize: function($super) {
    this.elm             = null;
    this.tracking        = false;
    //This is the origin label width which is used when dragging the label width.
    this._origLabelWidth = 0;
    this.undoRegistered  = false;

    $super();
  },

  installUI: function($super) {
    $super();
    this.arrow = this.insert(new Element('div', {className:'arrow'}));

    var self = this;
    this._uiBinding = new rxUi.Binding('LabelWidthControlTransformBox');
    this._uiBinding
      .bindDrag(this.e)
      .ub_subscribe(function(ev){
      if(ev.type === 'startDrag') {
        self.tracking = true;
        self._origLabelWidth = ev.startOffset.left - self.elm.getCalculatedLabelWidth();

        if (!self.undoRegistered) {
          self.undoRegistered = true;
          if (self.elm.isAutoCalculateLabelWidth()) {
            self.elm.page.undoManager.registerUndo({
              action:self.elm.setAutoCalculateLabelWidth,
              receiver:self.elm,
              params:[self.elm.getUndoManager()]
            });
          } else {
            self.elm.getUndoManager().registerUndo({
              action:self.elm.setLabelWidthExplicitly,
              receiver:self.elm,
              params:[self.elm.getCalculatedLabelWidth(), self.elm.getUndoManager()]
            });
          }
        }
      } else if(ev.type === 'drag') {
        self.elm.setLabelWidthExplicitly(ev.pageX - self._origLabelWidth);
      } else if(ev.type === 'endDrag') {
        self.tracking = false;
        self.undoRegistered = false;
      }
    });
  },

  updateUI: function() {
    if (this.elm.model.get('geometry.label.alignment') === 'left') {
      this.setOffset({left: this.elm.getCalculatedLabelWidth() - 4, top: -5});
      this.setHeight(this.elm.model.getHeight() + 2);
      this.show();
    } else {
      this.hide();
    }
  },

  setElement: function(elm){
    this.elm = elm;
    this.updateUI();
  }
});
