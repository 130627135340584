/* globals lp,Class */
var inheritTextStyle = require('ub/control/inherit-text-style').inheritTextStyle;

var TextModule = Class.create(
  lp.ModuleBase,
  lp.ModuleElementMethods,
  lp.ModuleElementPropertiesPanelMethods,
  {
    afterInsert: function(element) {
      // When a form is added, a text element is added to the form confirmation dialog and
      // afterInsert is called. But calling inheritTextStyle causes an error to be thrown from
      // Froala, presumably because the Froala element is detached and not in the DOM at this point.
      // This condition ensures we only call inheritTextStyle when the page is the currently active
      // editor page, and the text element will therefore be in the DOM.
      if (element.page === window.editor.page) {
        inheritTextStyle(element);
      }
    },
  }
);

lp.addModule(new TextModule({
  type: 'lp-pom-text',
  version: '1.0',
  name: 'Text',
  usageType: 'page-widget',
  namespace: lp.module.text,
}));

module.exports = TextModule;
