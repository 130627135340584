import _ from 'lodash';

import config from 'ub/config/content-types.js';

export function getValue(property, contentType) {
  if (!property || !contentType) {
    throw new Error('contentTypeConfig.getValue called without a property and/or contentType');
  }

  const configForContentType = _.merge({}, config.defaults, config.overrides[contentType]);

  if (typeof configForContentType[property] === 'undefined') {
    const error = new Error(`Property '${ property }' not found in content-types.json`);
    window.lp.errorNotifier.captureException(error, {
      extra: {
        property: property,
        contentType: contentType,
      }
    });

    throw error;
  } else {
    return configForContentType[property];
  }
}

export function getValueUsingUrl(property, url) {
  // This function can be used to get a content type value in the case where we don't have
  // the POM data - i.e. we don't know what the page's content type is. It shouldn't be
  // used unless absolutely necessary. Things to be aware of:
  // 1. It requires that the values of `webappVariantsResource` inside
  //    content-types.json accurately reflect the URLs used to serve up the builder.
  // 2. It cannot distinguish between two content types that have the same
  //    `webappVariantsResource` - i.e. overlays and stickyBars.
  const contentType = _.findKey(
    _.merge({default: config.defaults}, config.overrides),
    ({webappVariantsResource}) => {
      return new RegExp(`/${webappVariantsResource}/`).test(url);
    }
  );

  if (!contentType) {
    throw new Error(`Could not determine content type from URL: ${url}`);
  }

  return getValue(property, contentType);
}
