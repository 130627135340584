/* globals Class, $H, Template */
var fontServiceUtils = require('ub/fonts/font-service/utils').default;

window.jui.FormFontInput = Class.create( window.jui.Component, window.jui.ControlModel, {
  options: function($super,options){
    return $super($H({
      attributes:{className:'form-elm font-input'},
      label:'Border',
      familySelectOptions:[
        {name:"Arial",value:"Arial, sans-serif"},
        {name:"Comic Sans MS",value:"'Comic Sans MS', cursive, sans-serif"},
        {name:"Courier",value:"'Courier New', Courier, monospace"},
        {name:"Georgia",value:"Georgia, serif"},
        {name:"Lucida Sans Unicode",value:"'Lucida Sans Unicode', 'Lucida Grande', sans-serif"},
        {name:"Tahoma",value:"Tahoma, Geneva, sans-serif"},
        {name:"Times New Roman",value:"'Times New Roman', Times, serif"},
        {name:"Trebuchet MS",value:"'Trebuchet MS', sans-serif"},
        {name:"Verdana",value:"Verdana, Geneva, sans-serif"}
      ],
      weightSelectOptions:[
        {name:'Regular',value:'normal'},
        {name:'Bold',value:'bold'}
      ],
      // Used to enforce a minimum and maximum font size
      sizeBounds: {
        min: 10,
        max: 200
      },
      showSize:true
    }).merge(options));
  },



  initialize:function( $super, options ){
    $super( options );

    if ( this.options.onfocus ) {
      this.addListener( 'focus', this.options.onfocus );
    }

    if ( this.options.onblur ) {
      this.addListener( 'blur', this.options.onblur );
    }

  },

  installUI: function( $super ) {
    $super();
    this.addGoogleWebFonts();
    this.insert( new Element('label', {className:'form-elm-label'}).update(this.options.label));

    this.family = this.insert(new window.jui.FormSelectInput({
      attributes: {className: 'family font-select', id: 'jui-font-selector'},
      dropDownClassName: 'editor-info-panel-select jui-drop-down',
      hideSearch: false,
      dropdownCss: {
        fontSize: '15px',
        borderRadius: '0px 3px 3px 3px'
      },
      selectOptions: this.options.familySelectOptions,
      onChange: this.onSelect.bind(this),
      template: this.fontFamilySelectFormat
    }));

    var weightOpts = {
      attributes: {className:'weight font-select', id: 'jui-font-weight-selector'},
      dropDownClassName : 'editor-info-panel-select jui-drop-down',
      width: '65px',
      selectOptions: this.options.weightSelectOptions,
      onChange: this.onSelect.bind(this)
    };

    weightOpts.width = '80px';

    this.weight = this.insert(new window.jui.FormSelectInput(weightOpts));

    this.size = this.insert(new Element('input', {type:'text', className:'text size form-elm-input-text form-elm-mini-input'}));
    if (!this.options.showSize) {
      this.size.hide();
    }

    this.size.observe('focus', this.onFocus.bind(this));
    this.size.observe('blur', this.onBlur.bind(this));
    this.size.observe('keypress', window.jui.NumericInputFilter.filter);
  },

  fontFamilySelectFormat: function(fontFamily) {
    var name = {name: fontFamily.text, style: fontFamily.id};
    var template = new Template('<span style="font-family: #{style}; width: 200px;">#{name}</span>');
    return template.evaluate(name);
  },

  addGoogleWebFonts: function() {
    fontServiceUtils.webFonts.forEach(function(font) {
      this.options.familySelectOptions.push({name: font, value: font});
    }, this);
  },

  setValue: function(value) {
    value = value || {};
    this.size.value = value.size || '14';
    this.family.setValue(value.family || 'arial');
    this.weight.setValue(value.weight || 'normal');
  },

  getValue: function() {
    var value = {};
    value.family = this.family.getValue();
    value.weight = this.weight.getValue();
    if (this.options.showSize) {
      value.size = this.size.getValue();
    }
    return value;
  },

  onSelect: function() {
    this.fireEvent('blur', this);
  },

  onFocus: function( e ) {
    this.memorizedSize = this.size.value;

    if( this.isEnabled() ) {
      e.stop();
      this.fireEvent('focus', this);
    }
  },

  onkeydown: function(e) {
    if (e.keyCode === Event.KEY_RETURN){
      this.blur();
      e.stop();
    }
  },

  onBlur: function(e) {
    if ( this.isEnabled() ) {
      this._checkSize(e.target.value);
      this.fireEvent('blur', this);
    }
  },

  blur: function() {
    this.size.blur();
  },

  _checkSize: function(value) {
    if ( value === null || value === '' || isNaN(value) ) {
      this.size.value = this.memorizedSize || '14';
    } else if ( parseInt(value) > this.options.sizeBounds.max ) {
      this.size.value = this.options.sizeBounds.max;
    } else if ( parseInt(value) < this.options.sizeBounds.min ) {
      this.size.value = this.options.sizeBounds.min;
    }
  },

});
