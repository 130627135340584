import _ from 'lodash';
import Rx from 'ub_wrapped/rx';
import BrandColorActions from 'ui/brand/brand-color-actions';

const brandColorsSubject = new Rx.Subject();
const brandColorsUpdatedSubject = new Rx.Subject();
const brandColorFocusSubject = new Rx.Subject();

let colorsState = {
  colors: [],
  updated_at: '',
};

brandColorsUpdatedSubject.subscribe((newState) => {
  colorsState = newState;
});

brandColorsSubject
  .filter((ev) => {
    return !_.isEmpty(ev.type);
  })
  .subscribe((ev) => {
    const newState = BrandColorActions[ev.type](colorsState, ev.value);

    brandColorsUpdatedSubject.onNext(newState);
  });

export default {
  brandColorsSubject,
  brandColorsUpdatedSubject,
  brandColorFocusSubject,
};
