/* globals rxSwitchboard, jui */
// TODO: remove the dep on jui and rxSwitchboard, factor initialization code out to new module

var Rx = require('ub_wrapped/rx');
var _ = require('lodash');
var jQuery = require('jquery');
var ubBanzai = require('ub/control/banzai-features');
var createRingBuffer = require('ub/data/ring_buffer').createRingBuffer;

var isShowRxUIEventsEnabled = ubBanzai.features.isShowRxUIEventsEnabled();
var isShowJuiEventsEnabled = ubBanzai.features.isShowJuiEventsEnabled();
//////////////////////////////////////////////////////////////////////
// Misc helper fns
var _getTime;
if (window.performance && window.performance.now) {
  _getTime = function() {
    return window.performance.now();
  };
} else {
  _getTime = function(){
    return new Date();
  };
}

var _normalizeElement = function(element0) {
  if (_.isArray(element0)) {
    // RxJS expects an element or a NodeList
    return jQuery(_.map(element0, _normalizeElement));
  } else {
    return jui.isComponent(element0) ? element0.e : element0;
  }
};

var _normalizeRoutingKey = function(key0) {
  if (_.isString(key0)) {
    return key0;
  } else if (_.isArray(key0)){
    var key = _(key0);
    if (isShowRxUIEventsEnabled && (key.includes(undefined) || key.includes(null))) {
      console.error('Routing key array contains null or undefined: %o', key);
    }
    return key.toString();
  } else {
    if (isShowRxUIEventsEnabled) {
      console.error('Invalid routingKey: must be a string or a tuple');
    }
    return key0;
  }
};

var _normalizeTimestamp = function(ts) {
  return _.isString(ts) ? new Date(ts) : ts;
};

//////////////////////////////////////////////////////////////////////
// constructors for Rx.Observables

var fromEvent = function(element, eventName, selector) {
  // A short-hand wrapper around Rx.Observable.fromEvent that knows about jui elements
  return Rx.Observable.fromEvent(_normalizeElement(element), eventName, selector);
};

var fromLiveEvent = function(elm, eventType) {
  var elms         = _.isArray(elm) ? elm : [elm];
  var elmSelectors = _normalizeElement(elms);

  return Rx.Observable.create(function(observer) {
    var handler = function(eventObject) {
      observer.onNext(eventObject);
    };

    _.each(elmSelectors, function(elmSelector) {
      jQuery(document).on(eventType, elmSelector, handler);
    });

    return function() {
      _.each(elmSelectors, function(elmSelector) {
        jQuery(document).off(eventType, elmSelector, handler);
      });
    };
  });
};

// TODO: refactor function to remove self-execution. It fires browser-events
// during the browser or node interpretation.
// We shouldn't try to fire browser events until the document exists.
// In mocha unit tests we stub/mock document and window.
var observeDrag = (function() {
  var mouseups   = Rx.Observable.fromEvent(document, 'mouseup');
  var mousemoves = Rx.Observable.fromEvent(document, 'mousemove');

  jQuery(function() {
    mouseups.do(Event.stop);
    mousemoves.do(Event.stop);
  });

  return function observeDrag(startElement, startObservable, evParams) {
    // `startObservable` and `evParams` are optional
    var mousedowns = startObservable || fromEvent(startElement, 'mousedown').do(Event.stop);
    var source = mousedowns
      .flatMap(
        function(e) {
          // http://stackoverflow.com/questions/6073505/what-is-the-difference-between-screenx-y-clientx-y-and-pagex-y
          var dragStartOffset = {left: Event.pointerX(e), top: Event.pointerY(e)};
          var mousePosition;

          var toDragEv = function(move) {
            var pageX = Event.pointerX(move)
            , pageY = Event.pointerY(move);

            mousePosition = {pageX: pageX, pageY: pageY};

            return {
              type        : 'drag',
              startOffset : dragStartOffset,
              left        : pageX - dragStartOffset.left,
              top         : pageY - dragStartOffset.top,
              pageX       : pageX,
              pageY       : pageY,
              metaKey     : e.metaKey,
              ctrlKey     : e.ctrlKey
            };
          };

          var mouseDrags = mousemoves
            .takeUntil(mouseups)
            .map(toDragEv);

          var dragStart = Rx.Observable.return({
            type        : 'startDrag',
            startOffset : dragStartOffset,
            pageX     : dragStartOffset.left,
            pageY     : dragStartOffset.top
          });

          var dragEnd = Rx.Observable.return({
            type:'endDrag',
            startOffset: dragStartOffset
          }).map(function(ev0) {
            return _.merge({}, ev0, mousePosition);
          });

          return Rx.Observable
            .concat(dragStart, mouseDrags, dragEnd)
            .map(function(ev) {
              return _.merge(ev, evParams);
            });
        });
    return source;
    //
  };
})();

//////////////////////////////////////////////////////////////////////
// singleton event router

var EventBus = function() {
  var eventSources, eventSinks;
  this._uiBindings = {}; // routingKey:uiBinding
  this._eventSources = eventSources = {}; // routingKey:observable
  this.eventSinks = eventSinks = {}; // routingKey:subject
  // where routingKey is either a string identifying the component for singletons
  // or [componentName, instanceId]

  this.replayEndedSubject = new Rx.Subject();

  // events for registration / deregistration of routingKeys
  this._lifecycleEvents = new Rx.Subject();
  var _registrations = this._lifecycleEvents
    .filter(function(ev) { return ev.type === 'register';});
  var _unregistrations = this._lifecycleEvents
    .filter(function(ev) { return ev.type === 'deregister';});

  _.bindAll(this._lifecycleEvents, ['onNext']);

  if (isShowRxUIEventsEnabled) {
    this._lifecycleEvents.subscribe(function(ev) {
      console.debug('%crxUi %s: %s', "background: #eaeaea", ev.type, ev.routingKey, ev);
    });
  }

  _registrations.subscribe(function(registration) {
    var routingKey = registration.routingKey;
    if (isShowRxUIEventsEnabled && eventSinks[routingKey]) {
      console.groupCollapsed(
        '%cRe-registering already registered routingKey: ' + routingKey, "color:red");
      console.trace();
      console.groupEnd();
    }
    eventSinks[routingKey] = registration.eventSink;
    eventSources[routingKey] = registration.eventSource;
  });

  _unregistrations.subscribe(function(ev) {
    delete eventSources[ev.routingKey];
    delete eventSinks[ev.routingKey];
  }) ;

  this._centralEventHub = new Rx.Subject();
  this.eventLog = [];

  // Stop running code that can't run during execution
  jQuery(function() {
    if (ubBanzai.features.isRecordRxUIEventsEnabled()) {
      this._centralEventHub.subscribe(this.eventLog.push.bind(this.eventLog));
    }
  }.bind(this));

  this._linkToErrorNotifier();

  _.bindAll(this, _.functionsIn(this));
};

EventBus.prototype._linkToErrorNotifier = function() {
  var maxRxUIEventsToBuffer = ubBanzai.getFeatureValue('maxRxUIEventsToBuffer');
  if (maxRxUIEventsToBuffer > 0) {
    this.ringBuffer = createRingBuffer(maxRxUIEventsToBuffer);
    this._centralEventHub.subscribe(
      this.ringBuffer.push.bind(this.ringBuffer));
  }
};

EventBus.prototype._deregister = function(routingKey) {
  this._lifecycleEvents.onNext({type: "deregister", routingKey: routingKey});
};

EventBus.prototype._register = function(routingKey0, eventSource0, eventSink) {
  var routingKey = _normalizeRoutingKey(routingKey0);
  var eventSource = eventSource0.map(function(ev) {
    ev.routingKey = routingKey;
    return ev;
  });

  // dereg onError or onCompleted termination
  var dereg = _.partial(this._deregister, routingKey);
  // eventSource.dispose is called to unregister

  eventSource.subscribe(function(){}, dereg, dereg);

  this._lifecycleEvents.onNext({type: "register",
                                routingKey: routingKey,
                                eventSource: eventSource,
                                eventSink: eventSink});
};

EventBus.prototype._registerBinding = function(uiBinding) {
  var self = this;
  var routingKey = uiBinding.routingKey;
  var subscription = uiBinding.source.map(function(ev) {
    ev.routingKey = routingKey;
    ev.timestamp = new Date();
    if(ubBanzai.features.isRecordRxUIEventsEnabled()) {
      self.canvasEl = self.canvasEl || window.document.getElementById('canvas-body');

      ev.pageScrollPosY = self.canvasEl ? self.canvasEl.scrollTop : 0;
      ev.pageScrollPosX = self.canvasEl ? self.canvasEl.scrollLeft : 0;
    }
    return ev;
  }).ub_subscribe(this._centralEventHub.onNext.bind(this._centralEventHub));
  // previous line MUST NOT pass onComplete/onError from the source into the centralEventHub

  //TODO: review whether we should track/dispose this subscription:
  this._centralEventHub
    .ub_filterOn('routingKey', routingKey)
    .ub_subscribe(uiBinding._dispatchEvent.bind(uiBinding));

  // if the client code in the component calls eventSink.onCompleted,
  // we need to unlink it from the eventSource
  uiBinding.sink.subscribeOnCompleted(subscription.dispose.bind(subscription));
  this._uiBindings[uiBinding.routingKey] = uiBinding;
  this._register(routingKey, uiBinding.source, uiBinding.sink);
};

EventBus.prototype.recordingPrefix = 'rxui/';

EventBus.prototype.record = function(name, description) {
  var metadata = {
    builderVersion: window.builderVersion,
    banzaiFeatures: ubBanzai.getFeatureValues(),
    location: window.location,
    window: {
      innerWidth: window.innerWidth,
      innerHeight: window.innerHeight
    },
    screen: _.merge(
      {orientation: window.screen.orientation.type},
      _.pick(
        window.screen,
        'availHeight availLeft availTop availWidth colorDepth height width'.split(' ')))
  };
  window.localStorage.setItem(
    this.recordingPrefix+name,
    JSON.stringify(
      {events: this.eventLog,
       description: description,
       metadata: metadata
      }));
};

EventBus.prototype._getRecording = function(name) {
  var key = this.recordingPrefix + name;
  if (localStorage.hasOwnProperty(key)) {
    return JSON.parse(window.localStorage.getItem(key));
  } else {
    return null;
  }

};

EventBus.prototype.replay = function(name) {
  var recording = this._getRecording(name);
  var events = _.isArray(recording) ? recording : recording.events;
  if (events) {
    this._replay(events);
  } else {
    throw new Error('rxUi recording does not exist or is blank: ' + name);
  }
};

EventBus.prototype.queueRecording = function(recordingName) {
  var self = this;
  var replay = function(){
    console.log('rxUi replaying recording: ', recordingName);
    window.onbeforeunload = function () { };
    self.replay(recordingName);
  };
  if (window.editor && window.editor.mainPage) {
    // assume we're already loaded
    setTimeout(replay, 25);
  } else {
    rxSwitchboard.subjects.page_loaded.ub_subscribe(replay);
  }
};

EventBus.prototype.replayInPopup = function(recordingName, speed) {
  var recording = this._getRecording(recordingName);
  if ( ! recording ) {
    throw new Error('rxUi recording does not exist: ' + recordingName);
  }

  speed = speed || ubBanzai.getFeatureValue('rxRecordingReplaySpeed');
  var url = (
    window.location.protocol + '//' +
      window.location.host + window.location.pathname +
      '?showRxUIEvents=1&rxRecordingReplaySpeed='+speed);
  var height = recording.metadata.window.innerHeight;
  var width = recording.metadata.window.innerWidth;

  if (window._rxUIReplayWindow) {
    try {
      window._rxUIReplayWindow.close();
    } catch (e) {
      console.trace('error closing last rxUIReplayWindow: ' + e);
    }
  }
  var win = window.open(
    url, 'rx_replay', ('height='+height+', width='+width));
  window._rxUIReplayWindow = win;
  win.eval('window.onload = function () { rxUi.eventBus.queueRecording("'+recordingName+'")}; ');
  return win;
};

EventBus.prototype.uploadRecording = function(recordingName, url /* optional */) {
  var recording = this._getRecording(recordingName);
  var events = _.isArray(recording) ? recording : recording.events;
  var hostname = window.location.hostname;
  if (hostname.indexOf('-dev') === -1) {
    hostname = 'localhost';
  }
  var port = window.location.protocol === 'https:' ? 4001 : 4000;
  return jQuery.ajax({
    url: url || '//' + hostname + ':' + port + '/recordings/' + recordingName,
    method: 'POST',
    contentType: 'application/json',
    dataType: 'json',
    data: JSON.stringify({
      "creator"     : "Unknown", // we haven't
      "description" : recording.description || recordingName,
      "stream"      : events,
      "metadata"    : recording.metadata
 })});
};

var calculateEventReplayDelay = function(ev, previousTimestamp, timeScaling) {
  var ts = _normalizeTimestamp(ev.timestamp);
  var constantTimeDelay = (ts - previousTimestamp);
  var scaledDelay = constantTimeDelay / timeScaling;
  if (ev.routingKey.indexOf('Dialog,ImageScaling') > -1) {
    return constantTimeDelay * 2;
  } else if (timeScaling > 1.5 && constantTimeDelay > 2) {
    return Math.min(scaledDelay, 1);
  } else {
    return scaledDelay;
  }
};

EventBus.prototype._replay = function(events, targetSink) {
  var cursor = jQuery(
    '<img id="fake-cursor" src="//s3.amazonaws.com/builder.unbounce.com/builder_assets/cursor.png" />')
    .css({position: 'fixed',
          height: '25px',
          width: '25px',
          paddingLeft: "-10px",
          paddingTop: "-10px",
          zIndex: 20123})
    .appendTo(document.body);

  var startTime = _normalizeTimestamp(events[0].timestamp);
  var previousTimestamp = startTime;
  var timeScaling = ubBanzai.getFeatureValue('rxRecordingReplaySpeed');
  targetSink = targetSink || this._centralEventHub;

  var self = this;
  var handlePageScrollPostion = function(ev) {
    if(self.canvasEl && _.isNumber(ev.pageScrollPosY) && _.isNumber(ev.pageScrollPosX)) {

      if(self.canvasEl.scrollTop !== ev.pageScrollPosY){
        self.canvasEl.scrollTop  = ev.pageScrollPosY;
      }

      if(self.canvasEl.scrollLeft !== ev.pageScrollPosX) {
        self.canvasEl.scrollLeft = ev.pageScrollPosX;
      }
    }
  };

  Rx.Observable.fromArray(events)
    .map(function(ev) {
      var delay = calculateEventReplayDelay(ev, previousTimestamp, timeScaling);
      previousTimestamp = _normalizeTimestamp(ev.timestamp);
      return Rx.Observable.empty().delay(delay)
        .concat(Rx.Observable.return(ev));
    })
    .concatAll()
    .subscribe(function(ev)  {
      if (ev.pageX) {
        cursor.show();
        cursor.offset({top: ev.pageY, left: ev.pageX});
      }

      if (ubBanzai.features.isRecordRxUIEventsEnabled()) {
        handlePageScrollPostion(ev);
      }

      console.log(ev);
      targetSink.onNext(ev);
    }, _.noop, this._replayEnded.bind(this));
};

EventBus.prototype._replayEnded = function() {
  this.replayEndedSubject.onNext(true);
};

//////////////////////////////////////////////////////////////////////
// our singleton instance of EventBus
var EVENT_BUS = new EventBus();

//////////////////////////////////////////////////////////////////////
var Binding = function(routingKey, eventBus /* optional */) {
  this._eventBus = eventBus || EVENT_BUS;
  this.routingKey = _normalizeRoutingKey(routingKey);
  this.source = new Rx.Subject();
  // this.sink is indirectly subscribed to this.source
  this.sink = new Rx.Subject();
  this._eventBus._registerBinding(this);

  _.bindAll(this.source, ['onNext']);
  _.bindAll(this, ['wrapCallback']);
};

Binding.prototype = {
  onNext: function(ev) {
    this.source.onNext(ev);
  },

  _dispatchEvent: function(ev) {
    if (isShowRxUIEventsEnabled) {
      this._dispatchEventInstrumented(ev);
    } else {
      this.sink.onNext(ev);
    }
  },

  _dispatchEventInstrumented: function(ev) {
    var label = this._eventLoggingLabel(ev);
    if (isShowJuiEventsEnabled) {
      console.groupCollapsed("%crxUi: %s", "background: lightyellow", label, ev);
    }
    if (this._shouldProfile(ev)) {
      console.profile(this._profilingLabel(ev));
    }

    var start = _getTime();
    this.sink.onNext(ev);

    var duration = Math.round(_getTime() - start);
    var durationStyle = 'background: #eaeaea;';
    if (duration > 300) {
      durationStyle += 'color: red; font-size: 14px;';
    } else if (duration > 100 ) {
      durationStyle += 'color: orange; font-size: 14px;';
    }
    if (this._shouldProfile(ev)) {
      console.profileEnd(this._profilingLabel(ev));
    }
    if (isShowJuiEventsEnabled) {
      console.groupEnd();
      console.debug('%c %s ms', durationStyle, duration);
    } else {
      console.log("%crxUi: %s %c %s ", "background: lightyellow", label, durationStyle, duration + ' ms', ev);
    }
  },

  _shouldProfile: function(/* ev */) {
    // This is a stub which we can alter when profiling particular things in dev.
    return false;
    //return this.routingKey === 'Editor' || this.routingKey === 'PageTabBar';
  },

  _profilingLabel:  function(ev) {
    // The key used for console.profile/profileEnd
    return this._eventLoggingLabel(ev);
  },

  _eventLoggingLabel:  function(ev) {
    return this.routingKey + ': ' + ev.type;
  },

  subscribe: function(subscriber){
    this.sink.ub_subscribe(subscriber);
  },

  ub_subscribe: function(subscriber){
    this.subscribe(subscriber);
  },

  dispose: function() {
    // This must be called
    // this will unregister the routing key
    this.source.onCompleted();
    this.sink.onCompleted();
  },

  register: function(sourceObservable) {
    sourceObservable.ub_subscribe(this.source.onNext);
    return this.sink;
  },

  filterByEventType: function(domainEventType) {
    //TODO: replace this with a single multi-method double dispatch
    //style wrapper that ensures we only have one subscriber on this.sink
    return this.sink.filter(function(ev) {
      return ev.type === domainEventType;
    });
  },

  mkNamespacedVersion: function(routingKeySuffix) {
    // Creates a new binding from the current one, with a more specific routing key
    var routingKey = this.routingKey + ',' + routingKeySuffix;
    return new Binding(routingKey);
  },

  subscribeDomainEvents: function(dispatchTable, context) {
    // Subscribes the binding to a dispatch table that maps domain event types to handlers

    var isDebugModeEnabled = ubBanzai.features.isDebugModeEnabled();

    var _mkDomainEventDelegator = function(dispatchTable) {
      return function domainEventDelegator(ev) {
        var eventHandler = dispatchTable[ev.type];

        if (_.isFunction(eventHandler)) {
          eventHandler.call(context, ev);  // Ensure the handler has the correct `this` context
        } else if (isDebugModeEnabled) {
          console.warn('Domain event not found in dispatch table:', ev.type);
        }
      };
    };

    if (dispatchTable && _.isObject(dispatchTable)) {
      this.sink.ub_subscribe(_mkDomainEventDelegator(dispatchTable));
    } else if (isDebugModeEnabled) {
      console.warn('Domain event dispatch table invalid or not found for routing key:',
        this.routingKey);
    }

    return this;
  },

  wrapCallback: function(domainEventType, callback) {
    this.sink.filter(function(ev) {
      return ev.type === domainEventType;
    }).ub_subscribe(callback);

    var self = this;
    return function() {
      self.source.onNext({type: domainEventType});
    };
  },

  wrapCallbackWithData: function(domainEventType, callback) {
    this.sink.filter(function(ev) {
      return ev.type === domainEventType;
    }).ub_subscribe(callback);

    var self = this;

    var _deepCopy = function(node) {
      if(_.isObject(node)) {
        return _.reduce(node, function(acc, val, key) {
          if(val.constructor.name !== 'klass') {
            acc[key] = _deepCopy(val);
          }
          return acc;
        }, {});
      } else {
        return node;
      }
    };

    return function(ev0) {
      var ev = _deepCopy(ev0 || {});
      self.source.onNext(_.merge(ev, {type: domainEventType}));
    };
  },

  registerOnEventType: function(domainEventType, sourceObservable) {
    return this.register(
      sourceObservable
        .map(function(ev) {
          return _.merge(ev, {type: domainEventType});
        })
    ).filter(function(ev) {
      return ev.type === domainEventType;
    });
  },

  bindDOM: function(element, domEvent, domainEventType) {
    var sourceObservable = fromEvent(element, domEvent)
      .map(function(ev) {
        if (ev.target) {
          return {_domTarget: ev.target.id};
        } else {
          return {};
        }
      });
    return this.registerOnEventType(domainEventType, sourceObservable);
  },

  bindDOMLive: function($elms, domEvent, domainEventType) {
    var sourceObservable = fromLiveEvent($elms, domEvent)
      .map(function(ev) {
        var syntheticEv = {origEvent: ev};
        if (ev.currentTarget && ev.currentTarget.id) {
          syntheticEv._currentTarget = ev.currentTarget.id;
        }

        if (ev.target && ev.target.id) {
          syntheticEv._domTarget = ev.target.id;
        }

        return syntheticEv;
      });
    return this.registerOnEventType(domainEventType, sourceObservable);
  },

  bindDrag: function(startElement, startObservable, evParams) {
    // `startObservable` and `evParams` are optional
    return this.register(observeDrag(startElement, startObservable, evParams))
      .filter(function(ev) {
        return _.includes(['startDrag', 'drag', 'endDrag'], ev.type);
      });
  },

  // wrapped jui component constructors
  mkFormFontInput: function(options, inputName) {
    var changeEventType = 'change' + (inputName || 'Font'),
        self = this;

    options = _.merge(options, {
      onfocus: function(e) {
        window.editor.keyController.requestFocus(e.data, this);
      },

      onblur: function(e) { // triggered by jui.FormFontInput
        var font = e.data.getValue();
        self.onNext({
          type  : changeEventType,
          value : font
        });
      }
    });

    // Ensure the control is in sync, e.g. after change event is replayed from recording
    this.sink.filter(function(ev) {
      return ev.type === changeEventType;
    }).ub_subscribe(function(ev) {
      if ( ! _.isEqual(self.control.getValue(), ev.value)) {
        self.control.setValue(ev.value);
      }
    });

    this.control = new jui.FormFontInput(options);
    return this.control;
  }
};

//////////////////////////////////////////////////////////////////////
// public exports:

var rxUi = {
  fromEvent: fromEvent,
  observeDrag: observeDrag,

  eventBus: EVENT_BUS,
  Binding: Binding
};

window.rxUi = rxUi;
module.exports = rxUi;

jQuery(function() {
  try {
    if (ubBanzai.features.isRecordRxUIEventsEnabled()) {
      var recordingName = ubBanzai.getFeatureValue('replayRxRecording');
      if (recordingName) {
        EVENT_BUS.queueRecording(recordingName);
      }
    }
  } catch (e) {
    console.log(e);
  }
});
