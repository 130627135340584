/* globals Class, $H */
window.jui.FormSliderInput = Class.create( window.jui.Component, window.jui.ControlModel, {
  options: function($super,options){
    return $super($H({
      attributes:{className:'form-elm slider-input'},
      slider:{}
    }).merge(options));

  },

  initialize:function( $super, options ){
    this.suppressEvents = false;
    $super( options );

    if ( this.options.onchange ) { this.addListener( 'change', this.options.onchange );}
    if ( this.options.onfocus ) {this.addListener( 'focus', this.options.onfocus );}
    if ( this.options.startTracking ) {
      this.slider.addListener( 'startTracking', this.options.startTracking );
    }
    if ( this.options.stopTracking ) {
      this.slider.addListener( 'stopTracking', this.options.stopTracking );
    }
  },

  installUI: function( $super ) {
    $super();
    this.label = this.insert( new Element('label', {className: 'form-elm-label'}).update(this.options.label));
    this.slider = this.insert(new window.jui.Slider(this.options.slider));
    this.slider.addListener('valueChanged', this);
    this.size = this.insert(new Element('input', {type:'text', className:'text slider form-elm-input-text slider-input-text form-elm-mini-input'}));

    this.size.observe('focus', this.onFocus.bind(this));
    this.size.observe('blur', this.onBlur.bind(this));
    this.size.observe('keypress', window.jui.NumericInputFilter.filter);
  },

  setValue: function(v) {
    // TODO: JS: validation
    this.size.value = v || 0;
    this.suppressEvents = true;
    this.slider.setRealValue(v);
    this.suppressEvents = false;
  },

  getValue: function() {
    return this.slider.getRealValue();
  },

  valueChanged: function() {
    var v = this.slider.getRealValue();
    this.size.value = v;
    if (!this.suppressEvents) { this.fireEvent('change', v);}
  },

  show: function($super) {
    var r =  $super();
    this.updateKnob();
    return r;
  },

  updateKnob: function() {
    this.slider.updateKnob();
  },

  onFocus: function( e ) {
    this.memorizedValue = this.size.value;

    e.stop();
    this.fireEvent('focus', this);
  },

  onkeydown: function(e) {
    if(e.keyCode === Event.KEY_RETURN) {
      this.blur();
      e.stop();
    }
  },

  onBlur: function(e) {
    if ( this.isEnabled() ) {
      this._checkValue(e.target.value);
    }
  },

  blur: function() {
    this.size.blur();
  },

  _checkValue: function(value) {
    if ( value === null || value === '' || isNaN(value) ) {
      this.setValue(this.memorizedValue);
    } else {
      this.slider.setRealValue(this.size.getValue());
    }
  }

});
