import _ from 'lodash';
import formViews from './views';

const updateFields = (formElm, options = {accessor: 'content.fields'}) => {
  const fields = formElm.getFieldsFromModel();

  formElm.fieldViewMap = [];
  formElm.fieldsContainer.clear();

  fields.forEach((field) => {
    const createFieldViewFn = formViews[field.lpType];
    const fieldViews = createFieldViewFn(field);

    formElm.fieldsContainer.insert(fieldViews.root);
    formElm.fieldViewMap.push(fieldViews);
  });

  formElm.updateCSSRules(options);

  if (!_.isEmpty(formElm.parentElement)) {
    formElm.regenerateGeometry();
  }

  formElm.updateHeight();
  if (formElm.hasAutoButtonPlacement()) {
    formElm.positionSubmitButton();
  }
};

const getUnwrappedLabelWidth = (fieldView) => {
  const label = fieldView.label;
  let width = 0;

  if (!fieldView.hideLabel && fieldView.lpType !== 'hidden' && label) {
    const labelPosition = label.style.position;
    const labelWidth = label.style.width;

    // We need to temp apply these styles for us to get the
    // calculated bounding rect
    label.style.position = 'fixed';
    label.style.width = 'auto';

    width = Math.ceil(label.getBoundingClientRect().width);

    label.style.position = labelPosition;
    label.style.width = labelWidth;
  }

  return width;
};

const getLongestLabelWidth = (formElm) => {
  return _(formElm.fieldViewMap)
    .map(getUnwrappedLabelWidth)
    .max();
};

export default {
  updateFields,
  getUnwrappedLabelWidth,
  getLongestLabelWidth
};
