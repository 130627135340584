/* globals lp,Class */
var BoxModule = Class.create(
  lp.ModuleBase,
  lp.ModuleElementMethods,
  lp.ModuleElementPropertiesPanelMethods
);

lp.addModule(new BoxModule({
  type:'lp-pom-box',
  version:'1.0',
  name: 'Box',
  usageType:'page-widget',
  namespace: lp.module.box
}));
