import { addColor, removeColor } from 'ui/brand/brand-api-helper';

const renderInitialState = currentState => {
  return currentState;
};

const setBrandColors = (currentState, value) => {
  return { ...currentState, ...value };
};

/**
 * Helper function to format colors
 * The value must be formatted to avoid duplicate colors with SB.
 */
const formatColor = value => {
  const colorWithAlpha =
    value.rgb.a === 1
      ? `${value.newRgbString.slice(0, -1)}, 1)`.replace('rgb', 'rgba')
      : value.newRgbString;
  return colorWithAlpha.replace(/\s/g, '');
};

const addBrandColor = (currentState, value) => {
  const formatedColor = formatColor(value);
  const brandColor = {
    css_value: formatedColor,
    usage_data: { source: 'user', usages: [] },
  };

  let newColorPack = {
    ...currentState,
    ...{ colors: [...currentState.colors] },
  };

  // Only adds a new color if it does not exists in the color pack
  if (!currentState.colors.some(e => e.css_value === formatedColor)) {
    newColorPack = {
      ...currentState,
      ...{ colors: [...currentState.colors, brandColor] },
    };
    addColor(newColorPack, brandColor);
  }

  return newColorPack;
};

/**
 * Helper function to compare colors
 * The Brand Color can be rgba or hex, with spaces or without.
 * The Brand Colors are populated by the scraper service or being saved from the CB or SB
 */
const hasColorMatch = (brandColor, ...colors) => {
  const brandCssValue = brandColor.css_value.replaceAll(' ', '');
  let matched = false;

  colors.forEach(color => {
    if (color === brandCssValue) matched = true;
  });

  return matched;
};

const removeBrandColor = (currentState, value) => {
  const { newRgbString, hex } = value;
  const formatedColor = formatColor(value);
  const cleanRgbString = newRgbString.replaceAll(' ', '');

  const updatedBrandColor = currentState.colors.filter(brandColor => {
    return !hasColorMatch(brandColor, formatedColor, cleanRgbString, hex);
  });

  const newColorPack = { ...currentState, ...{ colors: updatedBrandColor } };
  removeColor(newColorPack, formatedColor);

  return newColorPack;
};

export default {
  renderInitialState,
  setBrandColors,
  addBrandColor,
  removeBrandColor,
};
