var pomUpdates = require('ub_legacy/lp/pom/updates/updates');


// See LP-8819 and PB-677

pomUpdates.add(Object.extend({
  minVersion : '2.5',
  version    : '2.6',

  run: function(jso) {
    if (this.checkVersion(jso)) {

      jso.elements
        .filter(function(el) {
          return el.type === 'lp-pom-form';
        })
        .forEach(function(el) {
          if (el.confirmationPageElements) {
            delete el.confirmationPageElements;
          }
        });

      jso.version = this.version;
    }
  }

}, window.lp.pom.updater));
