"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAllFlags = exports.getFlagByKey = exports.initializeFeatureFlags = void 0;
var ensign_manager_1 = require("./ensign-manager");
var initializeFeatureFlags = ensign_manager_1.EnsignManager.getInstance().initializeFeatureFlags;
exports.initializeFeatureFlags = initializeFeatureFlags;
var getFlagByKey = ensign_manager_1.EnsignManager.getInstance().getFlagByKey;
exports.getFlagByKey = getFlagByKey;
var getAllFlags = ensign_manager_1.EnsignManager.getInstance().getAllFlags;
exports.getAllFlags = getAllFlags;
