import { cloneDeep } from 'lodash';

import { safeGet, safeRemove, safeSet } from 'ub/utils';
import pomUpdates from 'ub_legacy/lp/pom/updates/updates';
import checkVersion from './checkVersion';

pomUpdates.add({
  minVersion: '3.4',
  version: '3.5',

  run: function (pomData0) {
    if (!checkVersion(this.minVersion, this.version, pomData0.mainPage.version)) {
      return pomData0;
    }

    const pomData = cloneDeep(pomData0);

    [pomData.mainPage, ...pomData.subPages].forEach(page => {
      page.elements.forEach(updateEffectToArray);
      page.version = this.version;
    });

    return pomData;
  },
});

function updateEffectToArray(element) {
  if (['lp-pom-button', 'lp-pom-text', 'lp-pom-box', 'lp-pom-image'].includes(element.type)) {
    const effectType = safeGet(element, 'style.effect.type') || 'none';
    safeSet(element, 'style.effect.enabledTypes', [effectType]);
    safeRemove(element, 'style.effect.type');
  }
}
