/* globals lp,Class */
var StylesheetElement = Class.create(
  lp.pom.Element,
  lp.ModuleComponent,
  {
    type:'lp-stylesheet',

    initialize: function($super, page, jso, context, options) {
      $super(page, jso, context, options);
      if(this.page.isPublishOrPreviewMode()) {
        this.page.addInsertion(this.model.safeGet('content.html'), 'head');
      }
    },

    createDefaultConstraints: function($super) {
      $super();
      this.defaultConstraints.displayable = false;
      this.defaultConstraints.copyable = false;
    }
  }
);

StylesheetElement.elementDefaults = {
  name: 'Stylesheet',
  containerId: null,
  content: {
    type: null,
    valid: true,
  }
};

module.exports = StylesheetElement;
