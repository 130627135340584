/* globals Class, $H */
window.jui.FormCheckboxInput = Class.create( window.jui.Component, window.jui.ControlModel, {
  options: function($super,options){
    return $super($H({
      attributes:{className:'form-elm checkbox-input'},
      label:'Checkbox',
      labelClass:''
    }).merge(options));

  },

  initialize:function( $super, options ){
    $super( options );

    if ( this.options.onclick ) {
      this.addListener( 'click', this.options.onclick );
    }
  },

  installUI: function( $super ) {
    $super();
    var checkboxAttrs = {type:'checkbox', className:'checkbox form-elm-checkbox'};
    var labelClass = "";

    if (this.options.labelClass){
      labelClass = " " + this.options.labelClass;
    }

    var labelAttrs = {className: 'right form-elm-checkbox-label' + labelClass};

    if (this.options.checked) {
      checkboxAttrs.checked = true;
    }

    if (this.options.name) {
      checkboxAttrs.name = true;
    }

    if (this.options.value) {
      checkboxAttrs.value = true;
    }

    if(this.options.inputId) {
      checkboxAttrs.id = this.options.inputId;
      labelAttrs.htmlFor = this.options.inputId;
    }

    this.checkbox = this.insert(new Element('input', checkboxAttrs));
    this.label = this.insert( new Element('label', labelAttrs).update(this.options.label));

    this.checkbox.observe('change', this.onClick.bind(this));
  },

  setValue: function(bool) {
    this.checkbox.checked = bool;
  },

  getValue: function() {
    return this.checkbox.checked;
  },

  disable: function($super) {
    /* jshint unused:vars */
    this.e.addClassName('dsbl');
    this.checkbox.disabled = true;
  },

  enable: function($super) {
    /* jshint unused:vars */
    this.e.removeClassName('dsbl');
    this.checkbox.disabled = false;
  },

  onClick: function() {
    if( this.isEnabled() ) {
      this.fireEvent('click', this.getValue());
    }
  }
});
