/* globals lp,Class */
var formGeometry = require('ub/elements/form/geometry').default;

var FormModule = Class.create(
    lp.ModuleBase,
    lp.ModuleElementMethods,
    lp.ModuleElementBuilderMethods,
    lp.ModuleElementPropertiesPanelMethods,
    {
      getLimit: function() {
        return 1;
      },
      beforeCreate: function(page, defaults, options, fields) {
        defaults.content.fields = fields;

        defaults.content.confirmTarget = page.getConfigValue('formTargetDefault');

        if (page.getConfigValue('allowFormExternalLightboxAction')) {
          defaults.lightboxSize = lp.module.form.FormElement.lightboxSizeDefault;
        }

        page.undoManager.startGroup();
      },

      afterInsert: function(element, options) {
        if (element.page.isEditMode()) {
          element.createDependantElements();
        }

        if (element.page.getConfigValue('setFormColumnsAutomatically')) {
          formGeometry.setFieldWidthByFieldCount(element);
        }

        if (options.center) {
          var size = element.model.getSize();
          element.model.setOffset({
            left: options.offset.left,
            top: options.offset.top - Math.round(size.height / 2)
          });
        }

        element.page.undoManager.endGroup();
      },

      getTransformBoxUIExtensions: function(elm) {
        /* jshint unused:vars */

        this.labelWidthIndicator = this.labelWidthIndicator ||
          new lp.module.form.LabelWidthControlTransformBoxExtension();
        return [
        {
          placement: this.labelWidthIndicator.updateUI.bind(this.labelWidthIndicator),
          component: this.labelWidthIndicator
        }
        ];
      }
    }
);

lp.addModule(new FormModule( {
  type:'lp-pom-form',
  version:'1.7.2',
  name: 'Form',
  usageType:'page-widget',
  namespace: lp.module.form
}));

module.exports = FormModule;
