/* globals Class, jui, eventTracker */

var jQuery = require('jquery');
var _ = require('lodash');
var Entities = require('html-entities').AllHtmlEntities;
var entities = new Entities();
var urlParser = require('url');

var editorGoals = require('ub/lp/editor/goals');

var $conversionNotification = jQuery('<div class="new-elem-notification conversion-goal-msg" />');
$conversionNotification.html('<p>Set up your conversion goal.</p> <a class="close-btn">' +
    '<span class="icon-close svg-ico-close-dialog"></span></a>');

function urlsMatch(url1, url2) {
  return (
    editorGoals.stripTrailingSlash(url1.toLowerCase()) ===
    editorGoals.stripTrailingSlash(url2.toLowerCase())
  );
}

window.lp.pom.PageGoals = Class.create(jui.EventSource, {
  _showConversionNotification: function() {
    var self = this;
    var isFirstGoalAdded = function() {
      return _.isEmpty(self.getActiveGoals()) && self.getGoals().length === 1;
    };

    if (isFirstGoalAdded()) {
      $conversionNotification.css('visibility', 'visible');
    } else if (_.isEmpty(self.getGoals())) {
      $conversionNotification.css('visibility', 'hidden');
    }
  },

  initialize: function(page){
    this.page = page;
    this.type = 'lp-pom-page-goals';
    this.name = 'Conversion Goals';

    page.addListener('POMReady', this);
    page.addListener('elementInserted', this);
    page.addListener('elementRemoved', this);
  },

  POMReady: function() {
    this.page.elements.each(function(e) {
      if (e.canHaveGoals()) {
        e.addListener('goalChanged', this);
      }
    }, this);
  },

  getFormGoal: function(elm) {
    return editorGoals.getElmGoal(elm);
  },

  elementInserted: function(e) {
    var elm = e.data;

    if (elm.type === 'lp-pom-form') {
      editorGoals.addActiveGoalForAllPages(window.editor, this.getFormGoal(elm), true);
    }

    if (elm.canHaveGoals()) {
      elm.addListener('goalChanged', this);
    }
  },

  elementRemoved: function(e) {
    var elm = e.data.element;
    if (elm.type === 'lp-pom-form') {
      this.removeActiveGoal(elm.getGoals()[0]);
    }

    if (elm.canHaveGoals()) {
      elm.removeListener('goalChanged', this);
    }
  },

  goalChanged: function(event) {

    editorGoals.updateAndSyncGoals(window.editor);
    this.fireEvent('availableGoalsChanged');

    var isLightboxOrGoalDestinationAdded = event.data.accessor !== 'action.type' ||
      (event.data.accessor === 'action.type' && event.data.value === 'internalLightbox');

    if (isLightboxOrGoalDestinationAdded) {
      this._showConversionNotification();
    }
  },

  getGoals: function() {
    // NOTE: Remove if allowing goals on form confirmation dialogs
    // in the future
    if (this.page.isFormConfirmation()) {
      return [];
    }

    var goals = _.flatten(
      this.page.elements
        .filter(function(el) {
          return el.canHaveGoals();
        })
        .map(function(el) {
          return el.getGoals();
        })
      );

      var formGoals = goals.filter(function(goal) {
        return goal.type === 'form';
      });

      var urlGoals = goals
        .filter(function(goal) {
          return window.Flog.isValidUri(goal.url);
        })
        .map(function(goal) {
          var uri = new window.Flog.UriParser(goal.url);
          if (uri.pathname === null && uri.protocol !== null) {
            return _.merge({}, goal, {url: goal.url});
          } else {
            return goal;
          }
        });

    return editorGoals.addSortOrder(formGoals.concat(urlGoals));
  },

  getActiveGoals: function() {
    return editorGoals.addSortOrder(this.page.settings.activeGoals);
  },

  urlIsActiveGoal: function(url) {
    var urlObj = urlParser.parse(url);

    return this.getActiveGoals().some(function(ag) {
      var goalUrlObj = urlParser.parse(ag.url);
      return urlsMatch(goalUrlObj.href, urlObj.href);
    }.bind(this));
  },

  isGoalActive: function(goal) {
    return this.getActiveGoals().some(function(activeGoal) {
      return goal && activeGoal.type === goal.type && urlsMatch(activeGoal.url, goal.url);
    }.bind(this));
  },

  addActiveGoal: function(g) {
    if (!this.isGoalActive(g)) {
      this.page.settings.activeGoals.push(g);
      this.fireEvent('activeGoalsChanged');
      if(typeof eventTracker !== 'undefined' && typeof g.type !== 'undefined') {
        eventTracker.track("Set a Conversion Goal", "Editor");
      }
    }
  },

  removeActiveGoal: function(g) {
    var found = this.page.settings.activeGoals.find(function(ag) {return ag.type === g.type && ag.url === g.url;});

    if (!Object.isUndefined(found)) {
      this.page.settings.activeGoals = this.page.settings.activeGoals.without(found);
      this.fireEvent('activeGoalsChanged');
    }

    if (typeof eventTracker !== 'undefined' && 0 === this.page.settings.activeGoals.length) {
      eventTracker.track("Disable Conversion Goals", "Editor");
    }
  },

  getPropertiesPanel: function(infoPanel) {
    return window.lp.pom.PageGoals.getPropertiesPanel(this, infoPanel);
  }
});


window.lp.pom.PageGoals.getPropertiesPanel = function(elm, infoPanel){
  window.lp.pom.PageGoals.elementInfoPanel = window.lp.pom.PageGoals.elementInfoPanel ||
  new (Class.create(
    window.lp.editor.panel.ElementPropertiesPanel, {type:this.type}, {
    routingKey: 'PageGoalsPanel',
    initialize: function($super, infoPanel) {
      this.infoPanel = infoPanel;
      this.updating = false;
      $super();
      this.advancedPanel.hide();

      var self = this;
      this._uiBinding.sink.filter(function(ev) {
        return ev.type === 'toggleGoal';
      }).ub_subscribe(function(ev) {
        self.goalClicked(ev.goal, ev.active);
      });

    },

    _addConversionNotification: function() {
      var $goalsTab = jQuery(this.infoPanel.tabs.get('goals').e);

      $goalsTab.append($conversionNotification);
      $conversionNotification.css('visibility', 'hidden');

      $conversionNotification.on('click', function() {
        $conversionNotification.css('visibility', 'hidden');
      });
    },

    installUI: function($super){
      $super();

      this.conversionGoals = this.insert(new jui.CollapsiblePanel({
        title: 'Conversion Goals',
        attributes: {
          className: 'collapsible-panel panel-goals-goals'
        }
      }));
      this.note = this.conversionGoals.insert(document.createElement('div'));
      this.goalsList = this.conversionGoals.insert(new jui.Component({attributes:{className:'goals-list clearfix'}}));

      if (window.editor.mainPage.getConfigValue('showExternalConversionGoals')) {
        this.externalTracking = this.insert(new jui.CollapsiblePanel({
          title: 'External Conversion Tracking',
          attributes: {
            className: 'collapsible-panel panel-goals-tracking'
          }
        }));
        this.note2 = this.externalTracking.insert(new Element('div').update(
          "<p>Are you using our click-through templates to push your visitors further into the sales funnel? If so, here's some of the things you need to know:</p>" +
            "<ul class='bullets'>" +
            "<li><span>Use our external tracking script to measure the real conversion at the final destination point (eg. after registration or sign up).</span></li>" +
            "<li><span>Uncheck all other goals above to avoid duplicate conversions.</span></li>" +
            "</ul>"+
            "<a href=\"http://documentation.unbounce.com/hc/en-us/articles/203879180\" target=\"_blank\"  class='button full-width-button'>Further info on conversion tracking</a>"
        ));
      }

      this._addConversionNotification();
    },

    setElement: function($super, goals) {
      this.element = goals;
      this.title.update(goals.name);
      this.title.className = goals.type + " options-panel-title svg-ico-page";

      if (!goals.hasListeners('activeGoalsChanged')) {
        goals.addListener('activeGoalsChanged', this);
      }
      if (!goals.hasListeners('availableGoalsChanged')) {
        goals.addListener('availableGoalsChanged', this);
      }

      this.updateUI();
    },

    activeGoalsChanged: function() {
      if (!this.updating) {
        this.updateUI();
      }
    },

    availableGoalsChanged: function() {
      if (!this.updating) {
        this.updateUI();
      }
    },

    goalClicked: function(g, active) {
      this.updating = true;

      editorGoals.addActiveGoalForAllPages(window.editor, g, active);

      this.updatePanelState();

      this.updating = false;
    },

    updatePanelState: function() {
      var possibleGoals = editorGoals.getAllGoals(window.editor);
      var activeGoals = editorGoals.getAllActiveGoals(window.editor);
      var $goalsTab = jQuery(this.infoPanel.tabs.get('goals').e);
      var warningClass = 'svg-ico-warning4';
      var $conversionNotification = jQuery('.conversion-goal-msg');

      if (_.isEmpty(activeGoals)){
        $goalsTab.addClass(warningClass);
      } else {
        $goalsTab.removeClass(warningClass);
        $conversionNotification.css('visibility', 'hidden');
      }

      if (possibleGoals.length === 0) {
        $conversionNotification.css('visibility', 'hidden');
        this.note.innerHTML = [
          '<p>There are currently no items that can be selected as conversion goals.',
          'To create a conversion goal, add a form, button, image, or text box with a link',
          'to the page, then check off which ones you would like to count as a',
          'conversion goal.</p>'
        ].join(' ');
      } else {
        this.note.innerHTML = '<p>Check the item(s) below to track as conversion goals.</p>';
      }
    },

    updateUI: function() {
      this.goalsList.clear();

      this.updatePanelState();

      // TODO: this next paragraph can be cleaned up once the rxUi.Binding api has been
      // fleshed out a bit
      var self = this;
      var goalClicked = function(g, e) {
        self._uiBinding.onNext({type: 'toggleGoal', goal: g,
                                active: e.source.getValue()});
      };

      var possibleGoalsSorted = _.sortBy(editorGoals.getAllGoals(window.editor), 'sortOrder');

      possibleGoalsSorted.each(function(g, i) {
        var typeLabel = g.type.capitalize() + ':';
        var valueLabel = g.url;

        switch (g.type) {
          case 'phone':
            valueLabel = g.url.replace('tel:', '');
            break;
          case 'form':
            typeLabel = 'Form Submission';
            valueLabel = '';
            break;
          case 'lightbox':
            var lbPage = window.editor.findPageByRefId(g.lightboxRefId);
            valueLabel = lbPage ? lbPage.name : '(Error: Lightbox Page Missing)';
            break;
        }

        var label = '<span class="type">' + typeLabel + '</span><p title="'+ valueLabel +'">' + self.formattedLabel(valueLabel) + '</p>';

        //var onclick = self._uiBinding.wrapCallback();
        this.goalsList.insert(new jui.FormCheckboxInput({
          label: label,
          inputId: 'goal-' + i + '-checkbox',
          onclick: _.partial(goalClicked, g),
          checked: editorGoals.isGoalActive(window.editor, g)
        }));

      }, this);
    },

    formattedLabel: function(label) {
      // Adds an ellipsis the label to restrict the word wrapping to about 3-4 lines
      var labelEscaped = entities.encodeNonUTF(label);
      return (labelEscaped.length > 90) ? labelEscaped.substring(0,90) + '...' : labelEscaped;
    },

    activate: function($super) {
      $super();
      this.updateUI();
    }
  }))(infoPanel);

  this.elementInfoPanel.setElement(elm);
  return this.elementInfoPanel;
};
