/* globals Class, $H */

window.jui.FormBorderInput = Class.create(window.jui.Component, window.jui.ControlModel, {
  options: function($super, options){
    return $super($H({
      attributes:{className:'form-elm border-input'},
      label:'Border',
      styleSelectOptions:[
        {name:'None',value:'none'},
        {name:'Solid',value:'solid'},
        {name:'Dotted',value:'dotted'},
        {name:'Dashed',value:'dashed'},
        {name:'Double',value:'double'},
        {name:'Groove',value:'groove'},
        {name:'Ridge',value:'ridge'},
        {name:'Inset',value:'inset'},
        {name:'Outset',value:'outset'}],
      widthSelectOptions:[
        {name:'1',value:1},
        {name:'2',value:2},
        {name:'3',value:3},
        {name:'4',value:4},
        {name:'5',value:5},
        {name:'6',value:6},
        {name:'7',value:7},
        {name:'8',value:8},
        {name:'9',value:9},
        {name:'10',value:10}]
    }).merge(options));

  },

  initialize:function($super, options){
    $super(options);

    if (this.options.onfocus) {
      this.addListener('focus', this.options.onfocus);
    }

    if (this.options.onblur) {
      this.addListener('blur', this.options.onblur);
    }
  },

  installUI: function($super) {
    $super();

    this.insert( new Element('label',{className:'form-elm-label'}).update(this.options.label));

    this.style = this.insert(new window.jui.FormSelectInput({
      attributes: {className: 'style form-elm-flex', id: 'jui-form-border-style-select'},
      dropDownClassName : 'editor-info-panel-select jui-drop-down',
      width: '80px',
      selectOptions: this.options.styleSelectOptions,
      onChange: this.onSelect.bind(this)
    }));

    this.width = this.insert(new window.jui.FormSelectInput({
      attributes: {className: 'width form-elm-flex', id: 'jui-form-border-width-select'},
      dropDownClassName : 'editor-info-panel-select jui-drop-down',
      width: '45px',
      selectOptions: this.options.widthSelectOptions,
      onChange: this.onSelect.bind(this)
    }));

    this.colorInput = new window.jui.FormColorInput({
      attributes: {className: 'form-border color-input form-elm-flex'},
      onblur: this.onBlur.bind(this),
      onfocus: this.onFocus.bind(this),
      borderSelector: true
    });

    this.insert(this.colorInput);
  },

  setValue: function(value) {
    value = value || {};
    this.setSelectedValue(this.style, value.style || 'none');
    this.setSelectedValue(this.width, value.width || 1);

    this.colorInput.setValue(value.color || '000');
    this.setControlStates();
  },

  setSelectedValue: function(select, value) {
    select.setValue(value);
  },

  getValue: function() {
    var style = this.style.getValue();
    var value = {style:style};
    if (style !== 'none') {
      value.width = this.width.getValue() * 1;
      value.color = this.colorInput.getValue();
    }
    return value;
  },

  buttonColorChanged: function(e) {
    var value = e.data;
    this.colorInput.setValue(value);

    this.fireEvent('blur', this);
  },

  setControlStates: function() {
    var style = this.style.getValue();
    if (style === 'none') {
      this.width.disabled = true;
      this.colorInput.disable();
    } else {
      this.width.disabled = this.options.disableWidth || false;
      this.colorInput.enable();
    }

    this.style.disabled = this.options.disableStyle || false;
  },

  onSelect: function() {
    this.setControlStates();
    this.fireEvent('blur', this);
  },

  onFocus: function() {
    if(this.isEnabled()) {
      this.fireEvent('focus', this);
    }
  },

  onkeyup: function(e) {
    if(e.target.value !== '') {
      this.colorInput.setButtonColor(e.target.value);
    }
  },

  onkeydown: function(e) {
    if (e.keyCode === Event.KEY_RETURN) {
      this.fireEvent('blur', this);
      e.stop();
    }
  },

  onBlur: function() {
    this.fireEvent('blur', this);
  }
});
