/* globals lp */
var jQuery = require('jquery');
var Raven = require('raven-js');
var LZString = require('ub_wrapped/lz-string');
// TODO: can this use the non-wrapped version?
var Rx = require('ub_wrapped/rx');

var ubBanzai = require('ub/control/banzai-features');
var createRingBuffer = require('ub/data/ring_buffer').createRingBuffer;

var switchboard = {subjects:{}};

module.exports = switchboard;

//TODO: add a per editor session UUID (not a session cookie) + an
//autoincrement int ID for each save. Use the combo to identify
//saved versions of this variant's POM. This would be saved on the
//POM itself and be used to identify the sns save events.
// Persist the following columns along with the pom content:
// variantid, timestamp_of_save, editor_session_id, id_of_save
var subjects = switchboard.subjects;
var inDebugMode = ubBanzai.features.isDebugModeEnabled();

switchboard.getOrCreateSubject = function(name) {
  var subj = switchboard.subjects[name];
  if (typeof subj === 'undefined') {
    subj = switchboard.subjects[name] = new Rx.Subject();
  }
  return subj;
};

switchboard.getPublisher = function(name) {
  // this is the main entry point from client code at the moment
  var subj = switchboard.getOrCreateSubject(name);
  return subj.safePublish.bind(subj);
};

//////////////////////////////////////////////////
// the sockets of our debug switchboard.

// Future iterations of this will define and lock down the message
// format for each subject.
subjects.jui_events = new Rx.Subject();
subjects.pom_updates = new Rx.Subject();
subjects.element_set = new Rx.Subject();
subjects.element_delete_attr = new Rx.Subject();
subjects.property_panels = new Rx.Subject();
subjects.undo_manager_execute = new Rx.Subject();
subjects.window_error = new Rx.Subject();
subjects.page_saves = new Rx.Subject();
subjects.editor_errors = new Rx.Subject();
subjects.keyboard_events = new Rx.Subject();

subjects.page_loaded = subjects.jui_events
    .filter(function(ev) {
      return ev.type === 'pageLoaded';
    });

subjects.first_page_load = subjects.page_loaded.take(1);

subjects.page_updated_and_refreshed = subjects.jui_events
    .filter(function(ev) {
      return ev.type === 'pageUpdatedAndRefreshed';
    });

// element_visibility_events is used to stitch the
// `element_visibility_manager` together with
// UI controls that toggle visibility, such as
// `page_element_tree`.
// Note: This style of UI binding pre-dates rxUI
// and should not be used elsewhere.
subjects.element_visibility_events = new Rx.Subject();

// provide a better way of doing this
subjects.all = Rx.Observable.merge(
  subjects.editor_errors
  , subjects.jui_events
  , subjects.pom_updates
  , subjects.element_set
  , subjects.property_panels
  , subjects.undo_manager_execute
  , subjects.window_error
  , subjects.page_saves
  , subjects.keyboard_events
  , subjects.element_visibility_events
);

//////////////////////////////////////////////////
// log errors in dev/debug mode
if (inDebugMode) {
  subjects.editor_errors.subscribe(function(err) {
    console.group("%cEditor Error", "color:yellow; background:red; font-size: 13pt");
    console.log(err.message);
    console.trace(err.error || err.message);
    console.log(err.details);
    console.groupEnd();
  });

  subjects.window_error.subscribe(function(err) {
    console.group("%cWindow Error", "color:yellow; background:red; font-size: 13pt");
    console.trace(err);
    console.groupEnd();
  });

}

var _getRavenContextForEditorError = function(ev) {
  try {
    var activeElement = window.editor.activeElement;
    var page = window.editor.page;
    return {
      message: ev.message,
      tags: {
        message: ev.message,
        breakpoint: page ? page.getCurrentBreakpoint().name : 'unknown',
        active_element_type: activeElement && activeElement.getType(),
        page_used_as: page ? page.page.used_as : 'unknown'},
      extra: {
        active_element_id: activeElement && activeElement.id,
        time_taken: ev.timeTaken
      }};
  } catch (e) {
    Raven.captureException(e, {tags: {fn:'_addRavenContext', message: ev.message}});
    return {tags: {message: ev.message}, message: ev.message};
  }
};

subjects.editor_errors
  .subscribe(function(edErr) {
    if (edErr.error) {
      lp.errorNotifier.captureException(edErr.error, _getRavenContextForEditorError(edErr));
    } else {
      // editor.reportError is sometimes called with error=null
      lp.errorNotifier.captureException(edErr.message, _getRavenContextForEditorError(edErr));
    }

  });

//////////////////////////////////////////////////
// publish page saves to sns along with a trace of all uses of undo_manager
// TODO extract this into a separate module

var extractUndoDetails = function(undoEvent) {
  var actionFn = undoEvent.action.action;
  var trace = {
    actionFnString: actionFn.toString().substring(0, 70)
    //, action: undoEvent.action
  };
  if (actionFn.name) {
    trace.actionFnName = actionFn.name;
  }
  if (undoEvent.receiver.id) {
    trace.receiverId = undoEvent.receiver.id;
  }

  var params = undoEvent.params;
  if (Array.isArray(params) && params.length > 0 && params[0].id) {
    trace.firstParamId = params[0].id;
  }
  if (typeof undoEvent.receiver.element !== "undefined") {
    trace.receiverElementId = undoEvent.receiver.element.id;
  }
  return trace;
};

var addEditorEventContext = function(ev0) {
  var ev = ev0;
  ev.ts = new Date().toISOString();
  if (window.editor && window.editor.page) {
    ev.breakpoint = window.editor.page.getCurrentBreakpoint().name;
    ev.page = window.editor.page.page.used_as;
  } else {
    // this happens if an error is fired during or before page load
    ev.breakpoint = 'unknown';
    ev.page = 'not-loaded';
  }
  return ev;
};

// track editor errors
var errorsBuffer = createRingBuffer(50);
subjects.editor_errors
  .map(addEditorEventContext)
  .subscribe(errorsBuffer.push);

// track undo/redo usage
var undoManagerTraceBuffer = createRingBuffer(1000);
subjects.undo_manager_execute
  .map(extractUndoDetails)
  .map(addEditorEventContext)
  .subscribe(undoManagerTraceBuffer.push);

var pomSavesGatewayURL = ubBanzai.getFeatureValue('pomSavesGatewayURL');


var publishSaveAndTraceData = function(saveEv) {
  try {
    var payload = {
      savePayload: saveEv.data
      , undoTraceEvents: undoManagerTraceBuffer.buffer
      // TODO: also include any Raven captured errors that were not reported via editor.reportError
      , editorErrors: errorsBuffer.buffer
    };

    jQuery.ajax({
      method: "POST",
      url: pomSavesGatewayURL,
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      data: JSON.stringify(addEditorEventContext({
        id: saveEv.id,
        url: saveEv.url,
        msgSchemaVersion: '0.2',
        sessionId: lp.getSessionId(),
        sessionIncrement: lp.sessionIncrement,
        userAgent: window.navigator.userAgent,
        builderVersion: window.builderVersion,
        data: LZString.compressToBase64(JSON.stringify(payload)),
      })),
      success: null // fire and forget for now
    });
  } catch (err) {
    if (console.trace) {
      console.trace(err);
    }
  }
};
subjects.page_saves.subscribe(publishSaveAndTraceData);

//////////////////////////////////////////////////
// console debug tracing that is off by default:

var getEventId = function(ev) {
  if (ev.id) {
    return ev.id;
  } else if (typeof ev.source === 'object'){
    if (ev.source.id) {
      return ev.source.id;
    } else if (typeof ev.source.element === 'object') {
      return ev.source.element.id;
    }
  }
  return '?';
};

switchboard.debugTrace = false;
switchboard.debugFilter = function() {return true;};
switchboard.debugOff = function() {
  if (switchboard._debugSubscription) {
    switchboard._debugSubscription.dispose();
    switchboard._debugSubscription = null;
  }
};
switchboard.debugOn = function() {
  switchboard.debugOff();
  window.onerror = function(errorMsg, url, lineNumber) {
    subjects.window_error.onNext(
        'Error: ' + errorMsg + ' Script: ' + url + ' Line: ' + lineNumber);
  };
  switchboard._debugSubscription = subjects.all
      .filter(function(ev0) { return switchboard.debugFilter(ev0);})
      .subscribe(function(ev0) {
        var id = getEventId(ev0),
            ev = {type: ev0.type
                  , id: id
                  , ev: ev0};
        if (switchboard.debugTrace) {
          if (console.groupCollapsed) {
            console.groupCollapsed("%c guts: %s", "background: #ddd", ev.type, ev);
            console.log(ev0);
            console.count(ev.type);
            console.trace();
            console.groupEnd();
          } else {
            console.trace(ev);
          }
        } else {
          console.debug("%c guts: %s", "background: #ddd", ev.type, ev);
        }
      });
};

if (ubBanzai.features.isShowJuiEventsEnabled()) {
  switchboard.debugOn();
  var qstring = document.location.search;
  if (qstring.indexOf("trace") > -1) {
    switchboard.debugTrace = true;
  }
}
