/* globals Class, $H */

// WARNING: this straight-up doesn't work. Use a React component instead.
window.jui.FormMultiRadioInput = Class.create(window.jui.Component, window.jui.ControlModel, {
  options: function($super, options) {
    return $super($H({
      attributes: { className: 'form-elm multi-radio-input' },
      name: '',
      label: 'Choose'
    }).merge(options) );
  },

  initialize: function($super, options) {
    this.radiobuttons = {};
    $super(options);

    if(this.options.change) {
      this.addListener('change', this.options.change);
    }
  },

  installUI: function($super) {
    $super();
    this.insert(new Element('label', {className: 'form-elm-label'}).update(this.options.label));

    this.options.radiobuttons.each(function(r) {
      var wrapper = new Element( 'div', {className: 'form-elm-radio-wrapper'});
      var label = new Element('label', {className: 'mini form-elm-radio-label', htmlFor: r.id}).update(r.label);
      this.radiobuttons[r.id] =
        wrapper.insert(new Element('input', {
          type: 'radio',
          checked: r.checked,
          className:'form-elm-radio',
          name: this.options.name,
          id: r.id
        }));
      this.radiobuttons[r.id].observe('click', this.change.bind(this));
      wrapper.insert(label);
      this.insert(wrapper);
      label.observe('click', this.change.bind(this));
    }, this );
  },

  select: function(key) {
    if(key.length === 0){return;}
    this.radiobuttons[key].checked = true;
  },

  getValue: function() {
    return $H(this.radiobuttons).keys().find(function(k) {
      return this.radiobuttons[k].checked;
    }, this);
  },

  change: function() {
    this.fireEvent('change', this.getValue());
  }
} );

window.jui.FormMultiRadioInput2 = Class.create(window.jui.Component, window.jui.ControlModel, {
  options: function($super) {
    return $super($H({
      attributes: {className: 'form-elm multi-radio-input-vertical'},
      className: 'radio-group',
      name: '',
      label: 'Choose'
    }));
  },

  initialize: function($super, options) {
    this.radiobuttons = {};
    $super(options);

    if(this.options.change) {
      this.addListener('change', this.options.change);
    }
    if(this.options.selected) {
      this.addListener('selected', this.options.selected);
    }
  },

  installUI: function($super) {
    $super();
    this.insert(new Element('label', {className: 'radiobutton-group-title form-elm-label'})).update(this.options.label);

    this.options.radiobuttons.each(function(r) {
      var div = new window.jui.Component({attributes: {className: 'radiobutton-group form-elm'}});
      var label = new Element('label', {className: 'mini form-elm-radio-label', htmlFor: r.id}).update(r.label);

      this.radiobuttons[r.id] =
        div.insert(new Element('input', {type: 'radio', checked: r.checked, name: this.options.name, className: 'form-elm-radio', id: r.id}));
      this.radiobuttons[r.id].observe('click', this.change.bind(this));
      if( Object.isString(r.label) ) {
        div.insert(label);
        //div.observe('click', this.change.bind(this));
      } else {
        div.insert(r.label);
      }

      this.insert(div);
    }, this);
  },

  select: function(key) {
    if(key.length === 0){return;}
    this.radiobuttons[key].checked = true;
  },

  getValue: function() {
    return $H(this.radiobuttons).keys().find(function(k) {
      return this.radiobuttons[k].checked;
    }, this);
  },

  change: function(e) {
    e.target.focus();
    this.fireEvent('change', this.getValue());
  }
});
