var pomUpdates = require('ub_legacy/lp/pom/updates/updates');

pomUpdates.add( Object.extend( {
  minVersion: '1.7',
  version: '1.9',
  run: function( jso ) {
    if( this.checkVersion( jso ) ) {
      jso.elements.each( function( el ) {
        if( el.type === 'lp-pom-form' ) {
          el.content.fields.each( function( field ) {
            field.id = field.id.gsub( ' ', '_' ).gsub( /\W+/, '' );
          } );
        }
      } );
      jso.version = this.version;
    }
  }
}, window.lp.pom.updater ) );
