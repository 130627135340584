/* globals lp, jui, Class, $H */
const jQuery = require('jquery');
const _ = require('lodash');
const { clsx } = require('clsx');

const { applyEffect } = require('ub/control/element-effect');

const getBackgroundPublishUrls = require('ub/control/image-publish-urls').getBackgroundPublishUrls;

/*
 * Private methods which previously used to exist as public methods
 */
var _backgroundUpdater = function (elm) {
  //The reason for this method is to handle old transparency.  If an element is meant
  //to be transparent we convert it to use opacity instead.  This is to handle
  //backwards backwards compatibility and only runs once.
  var m = elm.model;

  if (!m.exists('style.background.backgroundColor')) {
    m.set('style.background.backgroundColor', 'transparent');
    // ... and then do the rest of the fix below ...
  }

  var bgColor = m.get('style.background.backgroundColor').replace('#', '');
  var savedColorSel = 'style.background.savedBackgroundColor';
  var defaultColor = m.exists(savedColorSel) ? m.get(savedColorSel) : 'ffffff';

  if (bgColor === 'transparent') {
    m.set('style.background.backgroundColor', defaultColor);
    m.set('style.background.opacity', 0);
  }
};

var _installViewObservers = function (elm) {
  if (elm.page.isEditMode()) {
    ['click', 'mouseover', 'mouseout', 'mousedown', 'dblclick'].each(function (type) {
      elm.view.observe(type, function (e) {
        Event.stop(e);
        if (typeof elm[type] === 'function') {
          elm[type](e);
        }
        elm.fireEvent(type, e);
      });
    });
  }
};

var _rootLayoutChanged = function (elm) {
  if (elm.is('offsetable')) {
    elm.updateOffset();
  }
};

var _getElementsByZIndexOrder = function (elm) {
  return elm.getIndexables().sortBy(function (element) {
    return element.model.get('geometry.zIndex') * 1;
  });
};

var _getPageSection = function (element) {
  var iterator = function (elm) {
    if (!elm) {
      return null;
    } else if (elm.type === 'lp-pom-block') {
      return elm;
    } else {
      return iterator(elm.parentElement);
    }
  };
  return iterator(element);
};

var _getHexColorAsRgb = function (elm) {
  return jui.ColorMath.hexToRgb(elm.normalizedBgColor(elm.model));
};

var _isParentPageSectionVisible = function (elm) {
  return _getPageSection(elm) === elm.getParentElement() && _getPageSection(elm).model.isVisible();
};

var _applyMargin = function (elm, value) {
  var m = elm.model;

  if (!m.exists('geometry.margin') && !value) {
    return;
  }

  value = value || m.get('geometry.margin');
  var rules = [];

  if (typeof value === 'object') {
    $H(value).each(function (entry) {
      // autoscale margin
      rules.push({
        attribute: 'margin-' + entry.key,
        value: typeof entry.value === 'number' ? elm.page.getUnit(entry.value) : entry.value,
      });
    });
  } else {
    rules.push({ attribute: 'margin', value: value });
  }

  if (elm.page.isEditMode()) {
    elm.applyStylesToDom(rules);
  } else {
    // isPublishOrPreviewMode
    elm.applyPageStyles(rules);
  }
};

var _applyPadding = function (elm, value) {
  var m = elm.model;

  if (!m.exists('geometry.padding') && !value) {
    return;
  }

  value = value || m.get('geometry.padding');
  var rules = [];

  if (typeof value === 'object') {
    $H(value).each(function (entry) {
      // autoscale padding
      rules.push({
        attribute: 'padding-' + entry.key,
        value: typeof entry.value === 'number' ? elm.page.getUnit(entry.value) : entry.value,
      });
    });
  } else {
    rules.push({ attribute: 'padding', value: value });
  }

  if (elm.page.isEditMode()) {
    elm.applyStylesToDom(rules);
  } else {
    // isPublishOrPreviewMode
    elm.applyPageStyles(rules);
  }
};

var _applyCornerRadius = function (elm) {
  var m = elm.model;
  var rules = [];
  var removeAttributes = [];
  var attributeMap = {
    tl: 'border-top-left-radius',
    tr: 'border-top-right-radius',
    bl: 'border-bottom-left-radius',
    br: 'border-bottom-right-radius',
  };
  var radius = m.exists('geometry.cornerRadius') ? m.get('geometry.cornerRadius') : 0;

  if (Object.isNumber(radius)) {
    // autoscale: border-radius
    rules.push({ attribute: 'border-radius', value: elm.page.getUnit(radius) });
    removeAttributes = [
      'border-top-left-radius',
      'border-top-right-radius',
      'border-bottom-left-radius',
      'border-bottom-right-radius',
    ];
  } else {
    for (var corner in radius) {
      if (radius[corner] === 0) {
        // Removing the attribute will allow the "desktop" value to bleed
        // through to mobile even if there's an override here.
        // FIXES: [CS-1094](https://unbounce.atlassian.net/browse/CS-1094)
        rules.push({ attribute: attributeMap[corner], value: 0 });
      } else {
        rules.push({ attribute: attributeMap[corner], value: elm.page.getUnit(radius[corner]) });
      }
    }
  }

  var options = {};
  if (elm.applyBorderTo) {
    options.elm = elm.applyBorderTo.elm;
    options.selector = elm.applyBorderTo.selector;
  }

  if (elm.page.isEditMode()) {
    options.removeAttrs = removeAttributes;
    elm.applyStylesToDom(rules, options);
  } else {
    // isPublishOrPreviewMode
    elm.applyPageStyles(rules, options);
  }
};

var _applyZIndex = function (elm, zIndex) {
  zIndex = zIndex || elm.model.getZIndex();
  if (elm.page.isEditMode()) {
    elm.view.setZIndex(zIndex);
  } else {
    // isPublishOrPreviewMode
    elm.applyPageStyles([{ attribute: 'z-index', value: zIndex }]);
  }
};

var _rebaseZIndex = function (elm, oldBase, newBase) {
  var newZ = newBase - oldBase + elm.model.getZIndex();
  elm.model.setZIndex(newZ);
};

var _updateZIndex = function (elm, value) {
  var newZ = value || elm.model.getZIndex();
  _applyZIndex(elm, newZ);
  // NOTE: don't set this on current element's model as this method can be called by a modelChange handler
  // ... an infinite loop would result

  if (elm.childElements.length > 0) {
    var elms = elm.childElements;
    var oldMinZ = elms
      .collect(function (el) {
        return el.model.getZIndex();
      })
      .sort()
      .first();
    var newMinZ = elm.getMinZIndex();

    if (newMinZ !== oldMinZ) {
      elm.childElements = [];

      elms.each(function (el) {
        _rebaseZIndex(el, oldMinZ, newMinZ);
      });

      elm.childElements = elms;
    }
  }
};

var _adjustOffset = function (elm, offset) {
  var o = { left: offset.left, top: offset.top };

  if (elm.parentElement !== null) {
    if (elm.parentElement.type === 'lp-pom-block') {
      jui.Point.add(o, elm.parentElement.getRootOffset());
    } else {
      jui.Point.subtract(o, elm.parentElement.getInnerBorderOffsetAdjust());
    }
  }

  jui.Point.subtract(o, elm.getOuterBorderOffsetAdjust());

  return o;
};

var _updateScale = function (elm) {
  var scale = elm.model.getScale();
  if (elm.page.isEditMode()) {
    elm.getView().setScale(scale);
  } else if (scale !== 1) {
    // isPublishOrPreviewMode
    // autoscale: get scale multiplied by autoscale factor
    elm.applyPageStyles([
      { attribute: 'transform', value: `scale(${elm.page.getUnit(scale, '')})` },
      { attribute: 'transform-origin', value: '0 0' },
      { attribute: '-webkit-transform', value: `scale(${elm.page.getUnit(scale, '')})` },
      { attribute: '-webkit-transform-origin', value: '0 0' },
    ]);
  }
};

var _getParentBorderOffsetAdjust = function (elm) {
  var adjust = { left: 0, top: 0 };
  var pe = elm.parentElement;
  if (pe !== null && pe.type !== 'lp-pom-block' && pe.type !== 'lp-pom-root') {
    var pm = pe.model;
    var peAdjust = _getParentBorderOffsetAdjust(pe);
    adjust.left += peAdjust.left;
    adjust.top += peAdjust.top;
    if (pe.hasBorder()) {
      adjust.top +=
        (pm.exists('geometry.borderApply')
          ? pm.get('geometry.borderApply.top')
            ? pe.getBorderWidth()
            : 0
          : pe.getBorderWidth()) * 1;
      adjust.left +=
        (pm.exists('geometry.borderApply')
          ? pm.get('geometry.borderApply.left')
            ? pe.getBorderWidth()
            : 0
          : pe.getBorderWidth()) * 1;
    }
  }
  return adjust;
};

var _updateOffsetOnBlockChange = function (elm) {
  if (elm.is('offsetable')) {
    elm.updateOffset();
  }
};

// ============================================================================

window.lp.pom.VisibleElement = Class.create(
  lp.pom.Element,
  lp.pom.VisibleElementViewWrapperMethods,
  {
    initialize: function ($super, page, jso, options) {
      $super(page, jso);
      this.view = new lp.pom.ElementView(this, options);

      if (_.isFunction(this.initView)) {
        this.initView();
      }

      this.rootLayoutListener = _.partial(_rootLayoutChanged, this);
      _installViewObservers(this);
      this.installModelChangeHandlers();
    },

    existsOnDocument: function () {
      return this._attached;
    },

    getModelClass: function () {
      return lp.pom.VisibleElementModel;
    },

    // PROTECTED : Overridden in almost all child elements
    createDefaultConstraints: function ($super) {
      $super();
      this.defaultConstraints.displayable = true;
      this.defaultConstraints.selectable = true;
      this.defaultConstraints.width_resizeable = true;
      this.defaultConstraints.height_resizeable = true;
      this.defaultConstraints.resizeable = true;
      this.defaultConstraints.offsetable = true;
      this.defaultConstraints.z_indexable = true;
    },

    // PROTECTED : Overridden in button element
    installModelChangeHandlers: function () {
      /* jshint unused:vars */
      this.addModelChangeHandler(function (accessor, value, previous, base, attr) {
        if (accessor.startsWith('geometry.offset')) {
          if (accessor === 'geometry.offset.left') {
            value = { left: value, top: this.model.getTop() };
          }

          if (accessor === 'geometry.offset.top') {
            value = { left: this.model.getLeft(), top: value };
          }

          this.updateOffset(value);
        }
      });

      this.addModelChangeHandler(function (accessor, value, previous, base, attr) {
        if (accessor.startsWith('geometry.size')) {
          this.updateDimensions(value);
        }
      });

      this.addModelChangeHandler(function (accessor, value, previous, base, attr) {
        if (accessor === 'geometry.zIndex') {
          if (typeof value !== 'undefined' && value !== null) {
            _updateZIndex(this, value);
          }
        }
      });

      this.addModelChangeHandler(function (accessor, value, previous, base, attr) {
        if (accessor.startsWith('geometry.position')) {
          this.getViewDOMElement().style.position = this.model.get('geometry.position');
        }
      });

      this.addModelChangeHandler(function (accessor, value, previous, base, attr) {
        if (accessor === 'geometry.scale') {
          _updateScale(this);
        }
      });

      this.addModelChangeHandler(function (accessor, value, previous, base, attr) {
        if (accessor.startsWith('geometry.visible')) {
          this.setVisible(value);
          this.applyStyleAttributes();
        }
      });

      this.addModelChangeHandler(function (accessor, value, previous, base, attr) {
        if (
          accessor.startsWith('geometry.borderLocation') ||
          accessor.startsWith('geometry.borderApply') ||
          accessor.startsWith('geometry.fitWidthToPage')
        ) {
          this.applyBorder();
          this.updateDimensions();
          this.updateOffset();
          this.childElements.each(function (c) {
            c.updateOffset();
          });
        }
      });

      this.addModelChangeHandler(function (accessor, value, previous, base, attr) {
        if (accessor === 'geometry.cornerRadius') {
          _applyCornerRadius(this);
        }
      });

      this.addModelChangeHandler(function (accessor, value, previous, base, attr) {
        if (accessor.startsWith('style.background')) {
          this.applyBackground();
        }
      });

      this.addModelChangeHandler(function (accessor, value, previous, base, attr) {
        if (
          accessor === 'geometry.padding' ||
          accessor === 'geometry.contentWidth' ||
          accessor === 'geometry'
        ) {
          this.applyGeometry({ accessor: accessor, value: value, attr: attr });
        }
      });

      this.addModelChangeHandler(function (accessor, value, previous, base, attr) {
        if (accessor.startsWith('style.border')) {
          this.applyBorder(value);
          this.updateDimensions();
          this.updateOffset();
        }
      });

      this.addModelChangeHandler(function (accessor, value, previous, base, attr) {
        if (accessor.startsWith('geometry.margin')) {
          _applyMargin(this, value);
        }
      });

      this.addModelChangeHandler(function (accessor, value, previous, base, attr) {
        if (accessor.startsWith('geometry.padding')) {
          _applyPadding(this, value);
        }
      });
      this.addModelChangeHandler(function (accessor, value, previous, base, attr) {
        if (accessor.startsWith('style.effect')) {
          applyEffect(this);
        }
      });
    },

    getView: function () {
      return this.view;
    },

    getViewDOMElement: function () {
      return this.view.e;
    },

    // PROTECTED : Overrides parent class method and over riden in block element
    _attach: function ($super) {
      _backgroundUpdater(this);

      var visible = this.view.visible();
      if (!visible) {
        this.view.show();
      }
      $super();

      this.applyStyleAttributes();

      if (!visible) {
        this.view.hide();
      }

      this.page.getRootElement().addListener('layoutChanged', this.rootLayoutListener);
    },

    // PROTECTED : Overrides parent class method
    _detach: function ($super) {
      $super();
      this.page.getRootElement().removeListener('layoutChanged', this.rootLayoutListener);
    },

    changeParent: function (newParent, zIndex) {
      if (newParent === this) {
        console.error(`Aborted attempt to change element #${this.id}'s parent to itself`);
        return;
      }

      if (this.containsElement(newParent)) {
        console.error(
          `Aborted attempt to change element #${this.id}'s parent to a descendant of itself (#${newParent.id})`
        );
        return;
      }

      var oldParentId = this.containerId;
      var p = jui.Point.subtract(this.getPageOffset(), newParent.getPageOffset());
      this.model.setOffset(p);
      if (this.parentElement) {
        this.parentElement.removeChildElement(this);
      } else {
        // NOTE: this block is a hack to work around a bug in the undo
        // manager that results in this method being called on elements with parentElement=null.
        // The two calls below are roughly what .removeChildElement does to the child.
        // See https://unbounce.hipchat.com/search?q=Cannot+read+property+%27removeChildElement%27+of+null&t=rid-13895&a=Search#09:04:55
        // TODO-TR: revisit this once the undo manager has been gutted
        this._detach();
        this.view.remove();
      }

      if (Object.isNumber(zIndex)) {
        this.model.setZIndex(zIndex);
        newParent.insertZIndex(zIndex, this.getDescendants(true).length + 1);
      } else {
        this.model.deleteAttr('geometry.zIndex');
      }

      newParent.insertChildElement(this);
      var payload = this;
      payload.oldParentId = oldParentId;
      this.fireEvent('parentChanged', payload);
    },

    setParentElement($super, newParent) {
      if (newParent === this) {
        console.error(`Aborted attempt to set element #${this.id}'s parent to itself`);
        return;
      }

      if (this.containsElement(newParent)) {
        console.error(
          `Aborted attempt to set element #${this.id}'s parent to a descendant of itself (#${newParent.id})`
        );
        return;
      }

      $super(newParent);
    },

    insertChildElement: function ($super, elm, options) {
      if (this.hasAncestor(elm)) {
        console.error(
          `Aborted attempt to insert an ancestor element (#${elm.id}) as a child of #${this.id}`
        );
        return;
      }

      options = options || {};

      var contentElm = this.getContentElement();
      var zIndex = elm.model.getZIndex();

      if (elm.is('z_indexable') && typeof zIndex === 'undefined') {
        zIndex = this.getNextZIndex();
        elm.model.setZIndex(zIndex);
        this.insertZIndex(zIndex, elm.getDescendants(true).length + 1);
      }

      if (options.insertZIndex) {
        this.insertZIndex(zIndex, elm.getDescendants(true).length + 1);
      }

      if (
        typeof options.containerIndex !== 'undefined' &&
        options.containerIndex < this.childElements.length
      ) {
        var i = contentElm.children.indexOf(this.childElements[options.containerIndex].getView());

        contentElm.insert(elm.getView(), i);
      } else {
        contentElm.insert(elm.getView());
      }

      $super(elm, options);
    },

    removeChildElement: function ($super, elm) {
      $super(elm);
      elm.view.remove();
      this.getRootElement().compactZIndexes();
    },

    getVisibleChildElements: function () {
      return this.childElements.findAll(function (elm) {
        return elm.model.isVisible();
      });
    },

    // PROTECTED : Overridden in root element
    getIndexables: function () {
      return this.childElements.findAll(function (elm) {
        return elm.is('z_indexable');
      });
    },

    getFirstElementByZIndex: function () {
      return _getElementsByZIndexOrder(this).first();
    },

    getLastElementByZIndex: function () {
      return _getElementsByZIndexOrder(this).last();
    },

    hide: function (options) {
      this.childElements.invoke('hide', options);
      this.view.hide();
      this.fireEvent('hidden', options);
    },

    show: function (options) {
      this.view.show();

      this.childElements
        .filter(function (elm) {
          return elm.model.isVisible();
        })
        .invoke('show', options);

      this.fireEvent('shown', options);
    },

    changeZIndex: function (direction) {
      this.getParentElement().changeChildZIndex(this, direction);
    },

    changeChildZIndex: function (element, direction) {
      this.page.undoManager.registerUndo({
        action: this.changeChildZIndex,
        receiver: this,
        params: [element, direction * -1],
      });

      var currentZ = element.model.getZIndex();

      var swappy = this.getIndexables()
        .sortBy(function (elm) {
          return elm.model.getZIndex() * direction;
        })
        .find(function (elm) {
          return elm.model.getZIndex() * direction > currentZ * direction;
        });

      if (!Object.isUndefined(swappy)) {
        var newZ = swappy.model.getZIndex();

        if (direction === -1) {
          currentZ = newZ + element.getMaxZIndex() - currentZ + 1;
        } else {
          newZ = currentZ + swappy.getMaxZIndex() - newZ + 1;
        }

        element.model.setZIndex(newZ);
        swappy.model.setZIndex(currentZ);
      }
      element.fireEvent('zIndexChanged');
    },

    changeOrder: function (element, direction) {
      this.page.undoManager.registerUndo({
        action: this.changeOrder,
        receiver: this,
        params: [element, direction * -1],
      });

      var elms = this.childElements.select(function (e) {
        return e.is('orderable');
      });
      if (elms.length < 2) {
        return;
      }

      var oldIndex = elms.indexOf(element);
      var swapIndex = oldIndex + direction;

      if (swapIndex < 0 || swapIndex >= elms.length || swapIndex === oldIndex) {
        return;
      }

      var swappy = elms[swapIndex];

      this.page.swapElementOrder(swappy, element);
      this.swapElementOrder(swappy, element);
      element.fireEvent('moved');
      element.fireEvent('orderChanged');
    },

    swapElementOrder: function (a, b) {
      var aIndex = this.childElements.indexOf(a);
      var bIndex = this.childElements.indexOf(b);

      this.childElements[aIndex] = b;
      this.childElements[bIndex] = a;
      // swap the DOM Elements
      if (aIndex < bIndex) {
        a.getViewDOMElement().insert({ before: b.view.e });
      } else {
        a.getViewDOMElement().insert({ after: b.view.e });
      }
    },

    getDescendants: function (indexable, depth) {
      indexable = indexable || false;
      depth = depth || -1;
      var d = [];
      depth--;

      this.childElements.each(function (e) {
        if (!indexable || e.is('z_indexable')) {
          d.push(e);
        }
        if (depth !== 0) {
          d = d.concat(e.getDescendants(indexable, depth));
        }
      });
      return d;
    },

    containsElement: function (elm) {
      return this.getDescendants().indexOf(elm) > -1;
    },

    getMinZIndex: function () {
      if (this.is('z_indexable')) {
        return this.model.getZIndex() + 1;
      } else {
        return this.parentElement.getMinZIndex();
      }
    },

    getMaxZIndex: function () {
      if (this.is('z_indexable')) {
        return this.getMinZIndex() + this.getDescendants(true).length - 1;
      } else {
        return this.parentElement.getMaxZIndex();
      }
    },

    getNextZIndex: function () {
      return this.getMaxZIndex() + 1;
    },

    insertZIndex: function (minZ, gap) {
      gap = gap || 1;
      this.childElements.each(function (e) {
        if (e.is('z_indexable')) {
          var current = e.model.getZIndex();
          if (current >= minZ) {
            e.model.setZIndex(current + gap);
          }
        }
      });

      this.parentElement.insertZIndex(minZ, gap);
    },

    addElementShadow: function () {
      this.view.e.addClassName('elm-selected');
    },

    removeElementShadow: function () {
      this.view.e.removeClassName('elm-selected');
    },

    applyPageStyles: function (rules, options) {
      options = options || {};
      rules.each(function (rule) {
        rule.selector = rule.selector || options.selector || '#' + this.id;
        this.page.style.setCSSRule(rule);
      }, this);

      // NOTE This condition is for subclasses that are calling applyPageStyles
      // without checking if the context is editmode.
      if (this.page.isEditMode() && window.editor) {
        // this only seems to be reached from form elements
        window.editor.pages.each(function (page) {
          if (page.hasForm()) {
            setTimeout(function () {
              page.style.updatePageStyles();
            }, 0);
          }
        });
      }
    },

    applyStylesToDom: function (rules, options) {
      options = options || {};
      var elm = options.elm || this.getViewDOMElement();
      // TODO-TR: BUG: this is different semantics from applyPageStyles which
      // allows us to target elements within this current view element.
      var cssText = elm.style.cssText;
      var currentRules =
        cssText === ''
          ? []
          : cssText
              .split(';')
              .collect(function (r) {
                return r.strip() === '' ? null : r;
              })
              .compact();

      if (options.removeAttrs) {
        options.exact = options.exact || false;
        currentRules = currentRules.select(function (rule) {
          rule = rule.strip();
          for (var i = 0, l = options.removeAttrs.length, attr; i < l; i++) {
            attr = options.removeAttrs[i];
            if (options.exact ? rule === attr : rule.startsWith(attr)) {
              return false;
            }
          }
          return rule !== '';
        });
      }
      rules =
        currentRules
          .concat(
            rules.collect(function (rule) {
              return rule.attribute + ':' + rule.value;
            })
          )
          .join(';') + ';';
      elm.style.cssText = rules;
    },

    normalizedBgColor: function (m0) {
      var m = m0 || this.model;
      var bgColor = m.get('style.background.backgroundColor').replace('#', '');
      var defaultColor = m.exists('style.background.savedBackgroundColor')
        ? m.get('style.background.savedBackgroundColor')
        : 'ffffff';

      return jui.ColorMath.normalizeHexValue(bgColor === 'transparent' ? defaultColor : bgColor);
    },

    getBGColorAsRGBA: function (color) {
      var m = this.model;
      color = color ? color : _getHexColorAsRgb(this);
      var opacity = m.exists('style.background.opacity')
        ? parseInt(m.get('style.background.opacity'), 10) / 100
        : 1;
      color.push(opacity);
      return 'rgba(' + color.join() + ')';
    },

    applyBackground: function () {
      /* jshint maxcomplexity:15 */
      // TODO: refactor this!!!!
      var m = this.model,
        rules = [],
        color;

      if (m.exists('style.background.backgroundColor')) {
        color = _getHexColorAsRgb(this);
        var rgba = this.getBGColorAsRGBA(color);
        rules.push({ attribute: 'background', value: rgba });
      }

      if (m.getCurrentFillType() === 'gradient') {
        var g = m.getGradient(),
          from = g.from.replace('#', ''),
          to = g.to.replace('#', '');

        rules.push({
          attribute: 'background',
          value: '-webkit-linear-gradient(#' + from + ', #' + to + ')',
        });
        rules.push({
          attribute: 'background',
          value: '-moz-linear-gradient(#' + from + ', #' + to + ')',
        });
        rules.push({
          attribute: 'background',
          value: 'linear-gradient(#' + from + ', #' + to + ')',
        });

        if (this.page.isPublishOrPreviewMode()) {
          rules.push({
            attribute: 'background',
            value: '-ms-linear-gradient(#' + from + ', #' + to + ')',
          });
          rules.push({
            attribute: 'background',
            value: '-o-linear-gradient(#' + from + ', #' + to + ')',
          });
        }
      } else if (m.exists('style.background.image') && m.shouldApplyBackgroundImage()) {
        var img = m.get('style.background.image');
        if (!(img === null || !img.content_url || img.content_url === 'none')) {
          var bgRepeatPath = 'style.background.backgroundRepeat',
            bgPositionPath = 'style.background.backgroundPosition';
          if (this.page.isPublishOrPreviewMode()) {
            getBackgroundPublishUrls(this, this.page.getCurrentBreakpoint()).forEach(
              function (url, resolutionIndex) {
                rules.push({
                  attribute: 'background-image',
                  value: 'url(' + url + ')',
                  media:
                    resolutionIndex === 0
                      ? undefined
                      : [
                          // We are adding the non-standard -webkit-device-pixel-ratio because Safari does
                          // not support min-resolution, regardless of the unit.
                          //
                          // 2x image (resolutionIndex: 1) should be shown to pixel ratio > 1 or >=~1.1
                          // 3x image (resolutionIndex: 2) should be shown to pixel ratio > 2 or >=~2.1
                          '-webkit-min-device-pixel-ratio: ' + (resolutionIndex + 0.1),

                          // We are using the dpi unit here instead of the more straightforward dppx unit
                          // because IE 11 does not support the latter.
                          //
                          // CSS reports 1x pixel ratio as 96dpi, 2x as 192dpi etc. So:
                          // 2x image (resolutionIndex: 1) should be shown to pixel ratio > 1 or >=97dpi
                          // 3x image (resolutionIndex: 2) should be shown to pixel ratio > 2 or >=193dpi
                          'min-resolution: ' + (resolutionIndex * 96 + 1) + 'dpi',
                        ],
                });
              }
            );
          } else {
            rules.push({
              attribute: 'background-image',
              value: 'url(' + (img.unique_url || img.content_url) + ')',
            });
          }

          if (this.page.isPublishOrPreviewMode() && m.isBackgroundImageFixed()) {
            rules.push({ attribute: 'background-attachment', value: 'fixed' });
          }

          if (m.exists(bgRepeatPath)) {
            rules.push({ attribute: 'background-repeat', value: m.get(bgRepeatPath) });
          }

          if (m.exists(bgPositionPath)) {
            rules.push({ attribute: 'background-position', value: m.get(bgPositionPath) });
          }

          if (m.isBackgroundImageStretched()) {
            rules.push({ attribute: 'background-size', value: 'cover' });
          }
        }
      }

      if (this.page.isEditMode()) {
        this.applyStylesToDom(rules, { removeAttrs: ['background'] });
      } else {
        // isPublishOrPreviewMode
        this.applyPageStyles(rules);
      }
    },

    applyEffect: applyEffect,

    applyBorder: function (value) {
      /* jshint maxcomplexity:16 */
      var m = this.model;
      var rules = [];

      if (!m.exists('style.border') && !value) {
        // explicitly set to none to be breakpoint safe
        rules.push({ attribute: 'border-style', value: 'none' });
      } else {
        value = value || m.get('style.border');
        var borderApply = m.exists('geometry.borderApply') ? m.get('geometry.borderApply') : false;
        var borderStyle = borderApply
          ? borderApply.top === borderApply.left &&
            borderApply.left === borderApply.bottom &&
            borderApply.bottom === borderApply.right
            ? borderApply.top
              ? value.style
              : 'none'
            : [
                borderApply.top ? value.style : 'none',
                borderApply.right ? value.style : 'none',
                borderApply.bottom ? value.style : 'none',
                borderApply.left ? value.style : 'none',
              ].join(' ')
          : value === null
            ? 'none'
            : value.style;
        rules.push({ attribute: 'border-style', value: borderStyle });
        if (borderStyle !== 'none') {
          // autoscale: border-width
          rules.push({ attribute: 'border-width', value: this.page.getUnit(value.width) });
          if (value.color && value.color.strip() !== '') {
            rules.push({ attribute: 'border-color', value: '#' + value.color.replace('#', '') });
          } else {
            //In the off chance that the color is blank explictly set it to none to be breakpoint safe
            rules.push({ attribute: 'border-color', value: 'none' });
          }
        }
      }

      var options = {};

      if (this.applyBorderTo) {
        options.elm = this.applyBorderTo.elm;
        options.selector = this.applyBorderTo.selector;
      }

      if (this.page.isEditMode()) {
        this.applyStylesToDom(rules, options);
      } else {
        // isPublishOrPreviewMode
        this.applyPageStyles(rules, options);
      }
    },

    applyGeometry: function () {
      var s = this.getViewDOMElement().style;
      var m = this.model;
      var rules = [];

      if (this.page.isEditMode() && m.exists('geometry.position')) {
        s.position = m.get('geometry.position');
      } else {
        // could be in any mode here
        if (m.exists('geometry.position')) {
          rules.push({ attribute: 'position', value: m.get('geometry.position') });
        }
      }

      if (this.is('offsetable')) {
        this.updateOffset();
      }

      if (this.is('z_indexable')) {
        _applyZIndex(this);
      }

      if (m.exists('geometry.size')) {
        this.updateDimensions();
      }

      _updateScale(this);

      if (!!m.containerId && (!m.isVisible() || !_isParentPageSectionVisible(this))) {
        rules.push({ attribute: 'display', value: 'none' });
      }

      if (rules.length > 0) {
        if (this.page.isEditMode()) {
          this.applyStylesToDom(rules);
        } else {
          // isPublishOrPreviewMode
          this.applyPageStyles(rules);
        }
      }
    },

    updateElementGeometry: function () {
      this.setVisible(this.model.isVisible());
    },

    setVisible: function (visible) {
      if (this.page.isEditMode()) {
        if (this.parentElement) {
          // reverted parentElement.isVisibleOnPage() to parentElement.model.isVisible()
          // the change was originally made to fix test failure on issue PB-939
          // however the issue on PB-939 no longer exists as all the tests pass with isVisible
          var isParentVisible = this.parentElement.model.isVisible();
          this.view.setVisible(visible && isParentVisible);
        } else {
          this.view.setVisible(visible);
        }
      } else {
        // isPublishOrPreviewMode
        var options = { elm: this.getViewDOMElement() };
        var show = visible ? 'block' : 'none';
        var rules = [{ attribute: 'display', value: show }];
        this.applyPageStyles(rules, options);
      }
    },

    isVisibleOnPage: function () {
      return jQuery('#' + this.id).is(':visible');
    },

    applyStyleAttributes: function () {
      // These element types should never have background, border, margin or padding rules
      // applied to them - just geometry and visiblity
      var excludedElementTypes = ['lp-code', 'lp-pom-form', 'lp-pom-social-widget', 'lp-pom-video'];

      if (!_.includes(excludedElementTypes, this.type)) {
        this.applyBackground();
        this.applyBorder();
        applyEffect(this);
        _applyMargin(this);
        _applyPadding(this);
        _applyCornerRadius(this);
      }

      this.applyGeometry();
      this.setVisible(this.model.isVisible());
    },

    hasBorder: function () {
      return (
        this.model.exists('style.border.style') && this.model.get('style.border.style') !== 'none'
      );
    },

    getBorderWidth: function () {
      return this.model.get('style.border.width');
    },

    getOuterBorderOffsetAdjust: function () {
      var m = this.model;
      if (this.hasBorder() && m.get('geometry.borderLocation') === 'outside') {
        return {
          top:
            (m.exists('geometry.borderApply')
              ? m.get('geometry.borderApply.top')
                ? this.getBorderWidth()
                : 0
              : this.getBorderWidth()) * 1,
          left:
            (m.exists('geometry.borderApply')
              ? m.get('geometry.borderApply.left')
                ? this.getBorderWidth()
                : 0
              : this.getBorderWidth()) * 1,
        };
      }

      return { left: 0, top: 0 };
    },

    getInnerBorderOffsetAdjust: function () {
      var m = this.model;
      if (this.hasBorder() && m.get('geometry.borderLocation') === 'inside') {
        return {
          top:
            (m.exists('geometry.borderApply')
              ? m.get('geometry.borderApply.top')
                ? this.getBorderWidth()
                : 0
              : this.getBorderWidth()) * 1,
          left:
            (m.exists('geometry.borderApply')
              ? m.get('geometry.borderApply.left')
                ? this.getBorderWidth()
                : 0
              : this.getBorderWidth()) * 1,
        };
      }

      return { left: 0, top: 0 };
    },

    updateOffset: function (value) {
      this.setOffset(value || this.model.getOffset());
    },

    setOffset: function (offset) {
      offset = _adjustOffset(this, offset);

      if (this.page.isEditMode()) {
        this.view.setOffset(offset);
      } else {
        // isPublishOrPreviewMode
        this.applyPageStyles([
          { attribute: 'left', value: this.page.getUnit(offset.left) },
          { attribute: 'top', value: this.page.getUnit(offset.top) },
        ]);
      }
    },

    updateDimensions: function (/* dims */) {
      // TODO-TR: this is sometimes called with a dims arg, sometimes not.
      // the modelChangeHandler always passes dims in. Be careful about breakpoints.
      // lp-block overrides this to explicitly ignore the dims arg
      // ... this.setDimensions(dims || this.model.getSize()); once we are confident
      //
      this.setDimensions(this.model.getSize());
    },

    setDimensions: function (dims) {
      dims = this.adjustDimensions(dims);
      if (this.page.isEditMode()) {
        var view =
          this.applyDimensionsTo && this.applyDimensionsTo.juiComponent
            ? this.applyDimensionsTo.juiComponent
            : this.getView();
        view.setDimensions(dims);
      } else {
        // isPublishOrPreviewMode
        var options = {};
        if (this.applyDimensionsTo && this.applyDimensionsTo.selector) {
          options.selector = this.applyDimensionsTo.selector;
        }

        // autoscale: apply height/width
        this.applyPageStyles(
          [
            { attribute: 'width', value: this.page.getUnit(dims.width) },
            { attribute: 'height', value: this.page.getUnit(dims.height) },
          ],
          options
        );
      }
    },

    adjustDimensions: function (dims) {
      var m = this.model;
      if (this.hasBorder() && this.model.get('geometry.borderLocation') === 'inside') {
        var adjust = { width: 0, height: 0 };
        if (m.exists('geometry.borderApply')) {
          adjust.width += m.get('geometry.borderApply.left') ? this.getBorderWidth() * 1 : 0;
          adjust.width += m.get('geometry.borderApply.right') ? this.getBorderWidth() * 1 : 0;
          adjust.height += m.get('geometry.borderApply.top') ? this.getBorderWidth() * 1 : 0;
          adjust.height += m.get('geometry.borderApply.bottom') ? this.getBorderWidth() * 1 : 0;
        } else {
          adjust.width = this.getBorderWidth() * 2;
          adjust.height = this.getBorderWidth() * 2;
        }
        dims = { width: dims.width - adjust.width, height: dims.height - adjust.height };
      }
      return dims;
    },

    getRootOffset: function (ref) {
      /* jshint unused:vars */
      var m = this.model;
      var mo = m.getOffset();
      var o = { left: mo.left, top: mo.top };
      if (this.parentElement) {
        jui.Point.add(o, this.parentElement.getRootOffset());
      }

      return o;
    },

    getPageOffset: function () {
      var m = this.model;
      var mo = m.getOffset();
      var o = { left: mo.left, top: mo.top };

      if (this.parentElement) {
        jui.Point.add(o, this.parentElement.getPageOffset());
      }

      return o;
    },

    clone: function (container0) {
      // This method is called when an element is duplicated
      var module = lp.getModule(this.type);
      var model = this.model.clone();

      var container = container0 || this.getParentElement();

      var options = {
        container: container,
        dontActivateOnInsert: true,
        dontUpdateElementTreeOnInsert: true,
      };

      if (this.type === 'lp-pom-block') {
        options.containerIndex = this.getIndexWithinVisibleElements();
      }

      model.id = null;
      model.name = this.page.generateDefaultElementName(this.type, this.name, true);
      model.geometry.zIndex = container.getNextZIndex();

      var clone = module.buildPageElement(this.page, model);
      this.page.insertElement(clone, options);

      this.childElements.each(function (c) {
        c.clone(clone);
      }, this);

      return clone;
    },

    blockResized: function () {
      _updateOffsetOnBlockChange(this);
    },

    blockMoved: function () {
      _updateOffsetOnBlockChange(this);
    },

    blockRemoved: function () {
      _updateOffsetOnBlockChange(this);
    },

    blockInserted: function () {
      _updateOffsetOnBlockChange(this);
    },

    blockHidden: function () {
      _updateOffsetOnBlockChange(this);
    },

    blockShown: function () {
      _updateOffsetOnBlockChange(this);
    },

    getContentElement: function () {
      return this.view;
    },

    getContentWidth: function () {
      return Math.round(this.model.getWidth() * this.model.getScale());
    },

    getScaledContentHeight: function () {
      return Math.round(this.model.getHeight() * this.model.getScale());
    },

    getContentHeight: function () {
      return Math.round(this.model.getHeight() * this.model.getScale());
    },

    getClassname() {
      return clsx('lp-element', this.type, ...this.model.getCustomClassnamesArray());
    },
  }
);
