import _ from 'lodash';
import webfontloader from 'webfontloader';
import Rx from 'ub_wrapped/rx';
import googleFonts from './fonts';

const googleFontsApiSubject = new Rx.Subject();

const loadGoogleFonts = (fontFamilies, autoRender = true) => {
  webfontloader.load({
    google: {families: fontFamilies},
    fontactive: (fontFamily) => {
      if (autoRender){
        googleFontsApiSubject.onNext({fontFamily});
      }

      // After the font has loaded, text may wrap differently, causing the height of text element to
      // change. Forcing an `updateHeight` of the active element will ensure its bounding box is
      // sized correctly.
      if (window.editor && window.editor.activeElement && window.editor.activeElement.updateHeight) {
        window.editor.activeElement.updateHeight();
      }
    }
  });
};

const findFontInList = (family) => {
  if (typeof family === 'string') {
    return _.find(googleFonts.fontMap, (googleFont) => {
      return googleFont.family.toLowerCase() === family.toLowerCase();
    });
  }
};

const isGoogleFont = (family) => {
  return !_.isEmpty(findFontInList(family));
};

export default {
  subject: googleFontsApiSubject,
  loadGoogleFonts,
  findFontInList,
  isGoogleFont,
};
