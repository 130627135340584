import brandState from 'ui/brand/state-controller';

// CCS: Store colors in the backend
const addColor = (newColor) => {
  brandState.brandColorsSubject.onNext({
    type: 'addBrandColor',
    value: newColor,
  });
};

// CCS: Remove colors in the backend
const removeColor = (targetColor) => {
  brandState.brandColorsSubject.onNext({
    type: 'removeBrandColor',
    value: targetColor,
  });

  // Remove focus
  brandState.brandColorFocusSubject.onNext(false);
};

const removeFocus = () => {
  brandState.brandColorFocusSubject.onNext(false);
};

export default {
  addColor,
  removeColor,
  removeFocus,
};
