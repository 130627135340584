/* globals Class */
var jQuery = require('jquery');
var _ = require('lodash');

;(function(){
  window.lp.pom.SingleColumnGrid = Class.create({
    initialize: function(page, options) {

      options = options || {};

      var defaultMargins         = {top: 20, left: 20, bottom: 20, right: 20};
      var defaultMarginsForBoxes = {top: 10, left: 10, bottom: 10, right: 10};

      this.page            = page;
      this.margin          = options.margin || defaultMargins;
      this.marginForBoxes  = options.marginForBoxes || defaultMarginsForBoxes;
      this.verticalSpacing = options.verticalSpacing || 24;

      this.centerSmallElements       = true;
      this.sortInVisualOrder         = true;
      this.scaleHeadlines            = true;
      this.stretchLargeImagesToEdges = true;
      this.topAlignFormLabels        = true;
      this.ignoreWidthThreshold      = false;

      this.elementWidthResizeThreshold = 280;
      this.scaleHeadlineThreshold      = 24;

      this.elementIdMap         = [];
      this.elementPropertiesMap = [];
    },

    fitElementsToGrid: function(entirePageOrBlock) {
      var pageSections = this._getPageSectionsToExecuteOn(entirePageOrBlock)
        , um           = this.page.undoManager;

      var setBackgroundProperties = function(model) {
        var newBGPropsHelper = window.lp.pom.NewBGPropsHelper
          , styleType        = newBGPropsHelper.getStyleType(model);

        // Enable 'stretch to page edges' for all sections
        newBGPropsHelper.setProperty(model, styleType, 'fitWidthToPage', true);

        // Enable 'fit background to container' for any section that has the image
        // background style
        if (newBGPropsHelper.getStyleType(model) === 'image') {
          newBGPropsHelper.setProperty(model, styleType, 'fitToContainer', true);
        }
      };

      um.startGroup();
      pageSections.each(function(pageSection) {

        setBackgroundProperties(pageSection.model);

        var top = this._handleElementsByParentElement(pageSection);
        pageSection.model.setHeight(top, um);
      }, this);

      um.endGroup();

      jQuery(window).trigger('autoLayoutComplete', {
        ranOn: entirePageOrBlock
      });
    },

    _getPageSectionsToExecuteOn: function(entirePageOrBlock) {
      var activePageSection = _.filter([window.editor.activeElement], function(e){
        return e.type === 'lp-pom-block';
      });

      if(this._shouldRunOnEntirePage(entirePageOrBlock, activePageSection)) {
        return this.page.getElementsByType('lp-pom-block');
      } else {
        return activePageSection;
      }
    },

    _shouldRunOnEntirePage: function(entirePageOrBlock, activePageSection) {
      return 'page' === entirePageOrBlock || _.isEmpty(activePageSection);
    },

    _positionElementsOnGrid: function(parentElement, elm, top, margin, index) {
      var width       = this._getWidthByPageSection(parentElement)
        , elmMaxWidth = width - this._getHorizontalMargins(parentElement);

      if (this._extendsWidthThreshold(elm)){
        var offset = {top: top, left: margin.left};
        this._fitToGrid(elm, parentElement, elmMaxWidth, offset);
      } else {
        if (index === 0) {top = Math.min(top, elm.model.getTop());}
        this._repositionElementIntoGrid(elm, width, top);
      }
    },

    _getScaledContentHeightAndVerticalSpacing: function(elm) {
      // Scale elements
      var scaledContentHeight = elm.getScaledContentHeight();
      if(_.isFinite(scaledContentHeight) && _.isFinite(this.verticalSpacing)) {
        return scaledContentHeight + this.verticalSpacing;
      }
      return 0;
    },

    _calculateTopForBoxes: function(elm, margin, top) {
      if (elm.childElements.length > 0) {
        var newTop = (this._handleElementsByParentElement(elm, {
          margin: this.marginForBoxes
        }));

        newTop += (top - elm.model.getHeight() - this.verticalSpacing - this.marginForBoxes.top);

        if (newTop > top) {
          top = newTop;
        }
      }
      return top;
    },

    _handleElementsByParentElement: function(parentElement, options) {
      var margin          = (options && options.margin) || this.margin
        , eleVisualOrder  = this._getElementsInVisualOrder(parentElement)
        , visibleChildren = parentElement.getVisibleChildElements()
        , elements        = this.sortInVisualOrder ? eleVisualOrder : visibleChildren
        , placementTop    = margin.top;

      if (elements.length > 0) {
        elements.each(function(elm, index) {
          this._positionElementsOnGrid(parentElement, elm, placementTop, margin, index);
          placementTop += this._getScaledContentHeightAndVerticalSpacing(elm);

          // Conditional changes to 'top' value
          if (elm.type === 'lp-pom-form') {
            placementTop += this._getTopPositionForForm(elm);
          } else if(elm.type === 'lp-pom-box') {
            placementTop = this._calculateTopForBoxes(elm, margin, placementTop);
          }

        }, this);
      } else {
        placementTop += margin.bottom;
      }

      return placementTop;
    },

    _getWidthByPageSection: function(pageSection) {
      return pageSection.getContentWidth();
    },

    _getHorizontalMargins: function(elm) {
      if (elm.isTypeOf('lp-pom-box')) {
        return this.marginForBoxes.left + this.marginForBoxes.right;
      } else {
        return this.margin.left + this.margin.right;
      }
    },

    _fitToGridByScalling: function(elm, elmMaxWidth, offset) {
      var scale = elmMaxWidth / elm.model.getWidth()
        , um    = this.page.undoManager;

      elm.model.setScale(scale, um);
      elm.model.setOffset({left: offset.left, top: offset.top}, um);
    },

    _fitToGridWithMaintainedAspectRatio: function(elm, elmMaxWidth, offset) {
      var scale = elmMaxWidth / elm.model.getWidth()
        , um    = this.page.undoManager;

      elm.model.setSize({
        width: Math.round(elm.model.getWidth() * scale),
        height: Math.round(elm.model.getHeight() * scale)
      }, um);
      elm.model.setOffset({left: offset.left, top: offset.top}, um);
    },

    _fitFormLabelsToGrid: function(elm, elmMaxWidth, offset) {
      var um = this.page.undoManager;
      elm.setLabelAlignment('top', um);
      var width = Math.round(elmMaxWidth);
      elm.model.setSize({width: width, height: elm.model.getHeight()}, um);
      elm.model.setOffset({left: offset.left, top: offset.top}, um);
    },

    _fitElementToGrid: function(elm, elmMaxWidth, offset) {
      var width  = Math.round(elmMaxWidth)
        , height = elm.model.getHeight()
        , um     = this.page.undoManager;

      if (elm.model.isScaleToFit()) {
        elm.model.setScale(1, um);
      }

      elm.model.setSize({width: width, height: height}, um);
      elm.model.setOffset({left: offset.left, top: offset.top}, um);
    },

    _extendsWidthThreshold: function(elm) {
      var elmWidth = elm.model.getWidth();
      return this.ignoreWidthThreshold || elmWidth > this.elementWidthResizeThreshold;
    },

    _getTopPositionForForm: function(elm) {
      var submitButtonEl    = elm.submitButton
        , submitButtonModel = submitButtonEl.model
        , buttonTop         = submitButtonModel.getTop()
        , buttonHeight      = submitButtonEl.getScaledContentHeight();

      return ((buttonTop - elm.getScaledContentHeight()) + buttonHeight);
    },

    _fitToGrid: function(elm, pageSection, elmMaxWidth, offset) {
      if (elm.type === 'lp-pom-image' || elm.type === 'lp-pom-video') {
        this._fitToGridWithMaintainedAspectRatio(elm, elmMaxWidth, offset);
      } else if (this.scaleHeadlines && this._isHeadline(elm)) {
        this._fitToGridByScalling(elm, elmMaxWidth, offset);
      } else if (this.topAlignFormLabels && elm.type === 'lp-pom-form') {
        this._fitFormLabelsToGrid(elm, elmMaxWidth, offset);
      } else {
        this._fitElementToGrid(elm, elmMaxWidth, offset);
      }
    },

    _repositionElementIntoGrid: function(elm, width, top) {
      var um = this.page.undoManager;
      var left = Math.round((width - elm.model.getWidth()) / 2);
      elm.model.setOffset({left: left, top: top}, um);
    },

    _isHeadline: function(elm) {
      if (elm.type === 'lp-pom-text') {
        var node = elm.getViewDOMElement();
        var firstChildFontSize = parseInt(jQuery(node).children().first().css('fontSize'));
        if (typeof firstChildFontSize === 'number'  &&  firstChildFontSize >= this.scaleHeadlineThreshold) {
          return true;
        } else {
          var spans = node.select('span[style]');
          var spanFontSize;

          for (var i=0,l=spans.length; i<l; i++) {
            spanFontSize = parseInt(spans[i].style.fontSize);
            if (typeof spanFontSize === 'number'  &&  spanFontSize >= this.scaleHeadlineThreshold) {
              return true;
            }
          }
        }
      }
      return false;
    },

    _getElementsInVisualOrder: function(parentElement) {
      return this._sortGroupsByTop(
          this._groupElementsByVerticalOverlap(this._getElementsInfo(parentElement))
          .collect(function(vertGroup) {
            return this._sortGroupsByLeft(
                this._groupElementsByHorizontalOverlap(vertGroup)
                .collect(function(horzGroup) {
                  return this._sortElementsByTop(horzGroup);
                }, this));
          }, this))
      .flatten()
      .pluck('element');
    },

    _getElementsInfo: function(parentElement) {
      return parentElement
        .getVisibleChildElements()
        .collect(function(e) {
          var model       = e.model
            , elementInfo = {
              'element' : e,
              'left'    : model.getLeft(),
              'top'     : model.getTop(),
              'width'   : model.getWidth(),
              'height'  : model.getHeight()
            };

          elementInfo.right  = elementInfo.left + elementInfo.width;
          elementInfo.bottom = elementInfo.top + elementInfo.height;

          return elementInfo;
        });
    },

    _sortElementsByTop: function(elements) {
      return elements
        .sortBy(function(elm) {
          return elm.top;
        });
    },

    _sortGroupsByLeft: function(groups) {
      return groups
        .sortBy(function(group) {
          return group[0].left;
        });
    },

    _sortGroupsByTop: function(groups) {
      return groups
        .sortBy( function(group) {
          return group[0][0].top;
        });
    },

    _groupElementsByVerticalOverlap: function(elements) {
      var groupedElements = []
        , group           = []
        , compare;

      while (elements.length > 0) {
        compare = elements.shift();
        group.push(compare);

        this._getVerticalOverlaps(compare, elements, group);

        groupedElements.push(group);
        group = [];
      }

      return groupedElements;
    },

    _groupElementsByHorizontalOverlap: function(elements) {
      var groupedElements = []
        , group           = []
        , compare;

      while (elements.length > 0) {
        compare = elements.shift();
        group.push(compare);

        this._getHorizontalOverlaps(compare, elements, group);

        groupedElements.push(group);
        group = [];
      }

      return groupedElements;
    },

    _getHorizontalOverlaps: function(compare, elements, group) {
      var found, i;

      for (i=elements.length-1; i>=0; i--) {
        if (this._isElementHorizontalOverlap(compare, elements[i])) {
          found = elements.splice(i,1)[0];
          group.push(found);
          this._getHorizontalOverlaps(found, elements, group);
          i=elements.length-1;
        }
      }
    },

    _getVerticalOverlaps: function(compare, elements, group) {
      var found, i;

      for (i=elements.length-1; i>=0; i--) {
        if (this._isElementVerticalOverlap(compare, elements[i])) {
          found = elements.splice(i,1)[0];
          group.push(found);
          this._getVerticalOverlaps(found, elements, group);
          i=elements.length-1;
        }
      }
    },

    _isElementVerticalOverlap: function(elm1, elm2) {
      return this._isRangeOverlap([elm1.top, elm1.bottom], [elm2.top, elm2.bottom]);
    },

    _isElementHorizontalOverlap: function(elm1, elm2) {
      return this._isRangeOverlap([elm1.left, elm1.right], [elm2.left, elm2.right]);
    },

    _isRangeOverlap: function(range1, range2) {
      return Math.max(range1[0],range2[0]) < Math.min(range1[1],range2[1]);
    }
  });
})();
