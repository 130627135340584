var pomUpdates = require('ub_legacy/lp/pom/updates/updates');

pomUpdates.add( Object.extend( {

  minVersion: '1.9',
  version: '2.0',

  run: function( jso ) {
    if( this.checkVersion( jso ) ) {
      this.addFancyboxToPage( jso );
      jso.version = this.version;
    }
  },

  addFancyboxToPage: function( jso ) {
    var jQueryIndex = this.getJQueryIndex( jso );
    var allScriptsWithFancybox = this.getAllReferencesToFancybox( jso );
    //Did we find any references to Fancybox? Yes? Keep going.
    if( allScriptsWithFancybox.length > 0 ) {
      //Is Fancybox already included? If so do nothing.
      if( !this.isFancyboxScriptIncluded( allScriptsWithFancybox  ) ) {
        //Insert Fancybox directly after JQuery
        jso.elements.splice( jQueryIndex + 1, 0, this.getFancyboxObject() );
      }
    }
  },

  getAllReferencesToFancybox: function( jso ) {
    var regex = /fancybox/gi;
    return jso.elements.findAll( function( el ) {
      return el.type === 'lp-script' && regex.test( el.content.html );
    } );
  },

  getJQueryIndex: function( jso ) {
    var index = -1;
    jso.elements.each( function( el, i ) {
      if( el.type === 'lp-script' && el.group === 'jquery' ) {
        index = i;
      }
    } );
    return index;
  },

  isFancyboxScriptIncluded: function( jso ) {
    var regex = /<script(.*)src=("|'){0,1}(.*)fancybox(.*)("|'){0,1}(.*)>(\s*)<\/script>/gim;
    return jso.find( function( script ) {
      return regex.test( script.content.html );
    } );
  },

  getFancyboxObject: function() {
    return {
      name: 'jQuery.Fancybox (1.3.4)',
      containerId: null,
      index: 1,
      type: 'lp-script',
      predefined: true,
      placement: 'head',
      content: {
        type: null,
        html: '<link href="http://assets.unbounce.com/m/lp-webapp/jquery.fancybox-1.3.4/fancybox/jquery.fancybox-1.3.4.css" media="screen" rel="stylesheet" type="text/css">\n' +
        '<script src="http://assets.unbounce.com/m/lp-webapp/jquery.fancybox-1.3.4/fancybox/jquery.fancybox-1.3.4.js"></script>'
      }
    };
  }

}, window.lp.pom.updater ) );
