export default function(contentHTML) {
  const newIframe = document.createElement('iframe');

  newIframe.width = '100%';
  newIframe.height = '100%';
  newIframe.style.border = 0;
  newIframe.src = 'about:blank';
  newIframe.sandbox = 'allow-scripts';

  newIframe.srcdoc =  `<!DOCTYPE html>
  <html style="margin: 0; width: 100%; height: 100%;">
    <head>
    </head>
    <body style="margin: 0; width: 100%; height: 100%;">
      ${contentHTML}
    </body>
  </html>`;

  return newIframe;
}