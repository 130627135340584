var createRingBuffer = function (length) {
  var pointer = 0
  , buffer = [];
    return {
      get  : function get (key) {return buffer[key];},
      push : function push (item) {
        buffer[pointer] = item;
        pointer = (length + pointer +1) % length;
      },
      buffer : buffer};
};

module.exports = {createRingBuffer: createRingBuffer};
