require('ub_legacy/lp/pom/image_size_upgrader.js');
require('ub_legacy/lp/lp.js');
require('ub_legacy/lp/module_base.js');
require('ub_legacy/lp/module_element_methods.js');
require('ub_legacy/lp/module_element_properties_panel_methods.js');
require('ub_legacy/lp/module_element_builder_methods.js');
require('ub_legacy/lp/module_component.js');
require('ub_legacy/lp/lp_errors.js');
require('ub_legacy/lp/geometry/comparison.js');
require('ub_legacy/lp/pom/pom.js');
require('ub_legacy/lp/pom/updates/updater.js');
require('ub_legacy/lp/pom/updates/updates.js');
require('ub_legacy/lp/pom/updates/1.0.js');
require('ub_legacy/lp/pom/updates/1.1.js');
require('ub_legacy/lp/pom/updates/1.2.js');
require('ub_legacy/lp/pom/updates/1.3.js');
require('ub_legacy/lp/pom/updates/1.4.js');
require('ub_legacy/lp/pom/updates/1.4.1.js');
require('ub_legacy/lp/pom/updates/1.5.js');
require('ub_legacy/lp/pom/updates/1.6.js');
require('ub_legacy/lp/pom/updates/1.7.js');
require('ub_legacy/lp/pom/updates/1.8.js');
require('ub_legacy/lp/pom/updates/1.9.js');
require('ub_legacy/lp/pom/updates/2.0.js');
require('ub_legacy/lp/pom/updates/2.1.js');
require('ub_legacy/lp/pom/updates/2.2.js');
require('ub_legacy/lp/pom/updates/2.3.js');
require('ub_legacy/lp/pom/updates/2.4.js');
require('ub_legacy/lp/pom/updates/2.5.js');
require('ub_legacy/lp/pom/updates/2.6.js');
require('ub_legacy/lp/pom/updates/2.7.js');

require('ub/updaters/2.8.js');
require('ub/updaters/2.9.js');
require('ub/updaters/3.0.js');
require('ub/updaters/3.1.js');
require('ub/updaters/3.2.js');
require('ub/updaters/3.3.js');
require('ub/updaters/3.4.js');
require('ub/updaters/3.5.js');
require('ub/updaters/3.6.js');

require('ub_legacy/lp/pom/page.js');
require('ub_legacy/lp/pom/page_style.js');

require('ub_legacy/lp/pom/page_goals.js');

require('ub_legacy/lp/pom/visible_element_view_wrapper_methods.js');
require('ub_legacy/lp/pom/element.js');
require('ub_legacy/lp/pom/visible_element.js');
require('ub_legacy/lp/pom/element_model.js');
require('ub_legacy/lp/pom/new_bg_props_model_helper.js');
require('ub_legacy/lp/pom/new_bg_props_upgrader.js');
require('ub_legacy/lp/pom/visible_element_model.js');
require('ub_legacy/lp/pom/element_view.js');
require('ub_legacy/lp/pom/single_column_grid.js');
require('ub_legacy/lp/pom/auto_layout/auto_layout.js');
