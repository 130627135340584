/* globals Class, $H */
window.jui.FormAssetChooser = Class.create( window.jui.Component, window.jui.ControlModel, {
  options: function($super,options){
    return $super($H({
      attributes:{className:'form-elm asset-chooser'}
    }).merge(options));
  },

  initialize:function($super, options){
    $super(options);
  },

  installUI: function($super) {
    $super();

    this.label = this.insert(new Element('label', {className:'form-elm-label'}).update(this.options.label));
    this.assetName = this.insert(new Element('div', {className:'filename form-elm-input-text'}).update(this.options.noFileText || 'No file specified'));
    this.chooseButton = this.insert(new window.jui.Button({
      label  : this.options.chooseLabel || 'Choose an Image',
      action : this.options.changeAction
    }));
  },

  updateAssetName: function(assetName) {
    this.assetName.update(assetName);
  },

  setAsset: function(asset) {
    this.assetName.update(asset === null ? this.options.noFileText || 'No file specified' : asset.name);
    this.chooseButton.setLabel(asset === null ? (this.options.chooseLabel || 'Choose an Image') : (this.options.changeLabel || 'Change Image'));
  }
});
