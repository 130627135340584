ubBanzai = require 'ub/control/banzai-features'
_        = require 'lodash'
{safeSet, safeGet} = require 'ub/utils'

propertiesToInherit = [
  'style'
  'geometry.cornerRadius'
  'geometry.size.height'
]

getNonImageButtons = (page) ->
  page
    .getElementsByType 'lp-pom-button'
    .reject (element) ->
      _.some element.page.breakpoints, (breakpoint) ->
        element.model.safeGet('style.fillType', breakpoint) is 'image'

getLastButton = ->
  # Get the last button that was added to the current page. If there is no button on this
  # page, check other subpages.

  if window.editor?.page
    _.last(getNonImageButtons(window.editor.page)) or
      _(window.editor.pages)
        .reverse() # So we prioritise the main page over sub pages
        .map getNonImageButtons
        .flatten()
        .last()

inheritLastButtonStyle = (page, defaults) ->
  lastButton = getLastButton()
  return unless lastButton

  breakpoints = lastButton.page.getBreakPoints()

  # Iterate through properties and clone desktop and mobile properties
  for accessor in propertiesToInherit
    for breakpoint in breakpoints
      if breakpoint.default
        defaultValue = safeGet lastButton.model.getBasicBreakpointModel(breakpoint), accessor
        safeSet defaults, accessor, defaultValue
      else
        # We use `getBasicBreakpointModel` to get just the properties that are distinct on
        # breakpoints and only copy those over to the breakpoints breakpoint defaults. This ensures that
        # on the new button, sub-properties that are not distinct on breakpoints maintain their
        # link to desktop.
        if lastButton.model.existsByBreakpoint accessor, breakpoint
          value = safeGet lastButton.model.getBasicBreakpointModel(breakpoint), accessor
          safeSet defaults, 'breakpoints.' + breakpoint.name + '.' + accessor, value


module.exports = inheritLastButtonStyle
