var utils = require('ub/utils');
var _safeGet = utils.safeGet;
var _safeSet = utils.safeSet;
var pomUpdates = require('ub_legacy/lp/pom/updates/updates');


// This upgrader adds an 'action.type' to image elements.
// We now have several action options for images with the default now being 'url'.
// This is to make sure this property is set if the action is set to 'Go to URL'.

pomUpdates.add(Object.extend({
  minVersion : '2.6',
  version    : '2.7',

  run: function(jso) {
    if (this.checkVersion(jso)) {

      jso.elements
        .filter(function(el) {
          return el.type === 'lp-pom-image';
        })
        .forEach(function(el) {
          if ( ! _safeGet(el, 'action.type')) {
            _safeSet(el, 'action.type', 'url');
          }
        });

      jso.version = this.version;
    }
  }

}, window.lp.pom.updater));
