/* globals Class, lp, jui */
var _ = require('lodash');

var banzai = require('ub/control/banzai-features');
var getImageElementPublishUrls =
  require('ub/control/image-publish-urls').getImageElementPublishUrls;
var setDefaultModelQuality =
  require('ub/ui/properties-panel/images/image-quality-helper').setDefaultModelQuality;
var { updateCanvasImageSource, addLoadState } = require('ub/ui/canvas/canvas-image-quality-helper');
var fetchImageDimensions = require('ub/control/fetch-image-dimensions').default;
var AlertDialog = require('ub/ui/alert-dialog');

function scaleImageToPage(imageSize) {
  const scaleFactor = Math.min(window.editor.page.getContentWidth() / imageSize.width, 1);

  return {
    width: Math.round(imageSize.width * scaleFactor),
    height: Math.round(imageSize.height * scaleFactor),
  };
}

var ImageElement = Class.create(lp.pom.VisibleElement, lp.ModuleComponent, {
  type: 'lp-pom-image',

  getCSSSelector: function () {
    return '#' + this.id;
  },

  _getTarget: function () {
    if (this.model.linksToExternalLightbox()) {
      return '_blank';
    }

    if (this.model.get('action.type') === 'phone') {
      // 'tel:' links do not work in iOS when the page is in an Iframe (e.g. lightbox), unless the
      // link has a target of '_top' or '_blank'. On some platforms '_blank' causes a new empty
      // tab to be opened, but '_top' seems to work well across all platforms with no side
      // effects. (See CN-713)
      return '_top';
    }

    var target = this.model.safeGet('content.target');

    if (this.page.isFormConfirmation() && !target) {
      return '_parent';
    }

    return target || '';
  },

  initView: function () {
    /* jshint maxcomplexity:14 */
    var innerContainer = this.view.insert(
      new jui.Component({ attributes: { className: 'lp-pom-image-container' } })
    );
    var imageContainer = innerContainer;

    var applyTo = {
      juiComponent: innerContainer,
      elm: innerContainer.e,
      selector: this.getCSSSelector() + ' .' + innerContainer.e.className,
    };
    
    innerContainer.e.style.overflow = 'hidden';

    this.applyBorderTo = applyTo;
    this.applyDimensionsTo = applyTo;

    // this was added as a temp fix to disable lazyloading because safari 15.4.x doesn't support it properly
    var loading;
    if (banzai.features.isLazyLoadImagesEnabled()) {
      loading = this.page.getConfigValue('isEmbeddable') ? 'eager' : 'lazy';
    } else {
      loading = null;
    }

    if (this.page.isPublishOrPreviewMode()) {
      var enabledBreakpoints = this.page.getEnabledBreakpoints();

      var attributes = enabledBreakpoints.reduce(
        function (accAttributes, breakpoint) {
          if (this.model.isVisible(breakpoint.name)) {
            getImageElementPublishUrls(this, breakpoint).forEach(function (url, resolutionIndex) {
              accAttributes['data-src-' + breakpoint.name + '-' + (resolutionIndex + 1) + 'x'] =
                url;
            });
          }

          return accAttributes;
        }.bind(this),
        {
          // <img> elements are published with this placeholder image as the 'src' attribute, as
          // well as a series of 'data-src-*' attributes - for each breakpoint and available
          // resolution (1x, 2x, 3x). The lp-form/public/main.js script runs on the published page
          // and sets the 'src' and 'srcset' attributes to real image URLs from the relevant
          // 'data-src' URLs. We do this because images can differ between breakpoints because we
          // allow the user to scale and crop them on each breakpoint independently, and we want to
          // make sure that we only load the relevant version of the image in the visitor's browser,
          // e.g. larger desktop images should not start to load on mobile devices.
          src: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
          alt: this.model.safeGet('content.alt') || '',
          loading: loading,
        }
      );

      this.img = new Element('img', attributes);

      if (this.model.exists('content.title')) {
        this.img.setAttribute('title', this.model.get('content.title'));
      }

      var href = this.model.getCurrentActionValue();

      if (href) {
        imageContainer = new Element('a', {
          href: this.page.CTAifyLink(href),
          target: this._getTarget(),
        });

        if (this.page.getConfigValue('addGoalAttributeToLinks') && this.isActiveGoal()) {
          imageContainer.setAttribute('data-goal', '');
        }

        if (this.isAppendParamsEnabled()) {
          imageContainer.setAttribute('data-params', 'true');
        }

        innerContainer.insert(imageContainer.insert(this.img));
      }

      imageContainer.insert(this.img);
    } else {
      // isEditMode
      var hasSizeTransforms = !(
        this.model.get('geometry.transform.size.width') === 0 &&
        this.model.get('geometry.transform.size.height') === 0
      );

      this.img = document.createElement('img');
      imageContainer.insert(this.img);

      updateCanvasImageSource(this);

      if (hasSizeTransforms) {
        this.updateImagePosition();
        this.updateImageSize();
      }

      this.verifyAssetSize();
    }

    if (this.page.getConfigValue('allowExternalLightboxLinks')) {
      if (!this.model.exists('lightboxSize')) {
        this.model.set('lightboxSize', ImageElement.lightboxSizeDefault);
      }
    }
  },

  updateImagePosition: function () {
    var offset = this.model.getTransformOffsetAdjustedForInnerBorder();
    if (this.page.isEditMode()) {
      this.img.style.marginLeft = offset.left + 'px';
      this.img.style.marginTop = offset.top + 'px';
    }
  },

  getModelClass: function () {
    return lp.module.image.ImageModel;
  },

  setDimensions: function ($super, dims) {
    $super(dims);
    if (this.page.isPublishOrPreviewMode()) {
      var options = {};
      if (this.applyDimensionsTo && this.applyDimensionsTo.selector) {
        // BUG options is not defined
        options.selector = this.applyDimensionsTo.selector + ' img';
      }

      // autoscale: apply height/width
      this.applyPageStyles(
        [
          { attribute: 'width', value: this.page.getUnit(dims.width) },
          { attribute: 'height', value: this.page.getUnit(dims.height) },
        ],
        options
      );
    }
  },

  replaceAsset: function (asset) {
    window.editor.wait();
    this.view.hide();

    this.page.undoManager.registerUndo({
      action: this.replaceAsset,
      receiver: this,
      params: [_.cloneDeep(this.model.get('content.asset'))],
    });

    fetchImageDimensions(asset.content_url)
      .then(dimensions => {
        this.model.set('content.asset', {
          company_id: asset.company_id,
          uuid: asset.uuid,
          unique_url: asset.unique_url,
          content_url: asset.content_url,
          content_content_type: asset.content_content_type,
          name: asset.name,
          size: dimensions,
          sizeVerified: true,
        });

        this.updateAssetDimensions(dimensions, false);

        window.editor.stopWaiting();
      })
      .catch(error => {
        console.warn(error);
        this.view.show();
        window.editor.stopWaiting();
        new AlertDialog({
          title: 'Images',
          message: 'The image failed to load. Please contact support if this problem persists.',
          icon: 'error',
        }).safeOpen();
      });
  },

  doubleClick: function () {
    var self = this;
    this.getModule().openElementBuilder(this, {
      callback: function (asset) {
        if (asset && self.isAssetReplaceable(asset.uuid, asset.name)) {
          self.replaceAsset(asset);
        }
      },
      selectedAsset: self.model.get('content.asset'),
    });
  },

  isAssetReplaceable: function (uuid, name) {
    return !this.isUUIDTheSame(uuid) || !this.isNameTheSame(name);
  },

  isUUIDTheSame: function (uuid) {
    return uuid === this.model.get('content.asset.uuid');
  },

  isNameTheSame: function (name) {
    return name === this.model.get('content.asset.name');
  },

  setSizeAttributes: function (elementSize, transformSize, transformOffset) {
    this.model.set('geometry.transform.size', transformSize);
    this.model.set('geometry.transform.offset', transformOffset);
    this.model.set('geometry.size', elementSize);
    this.model.set('geometry.maintainAR', true);

    setDefaultModelQuality(this);

    if (this.page.isMobileBreakpoint() || this.page.isTabletBreakpoint()) {
      // If image is being created in the non-default breakpoint we need to make sure that
      // dimensions are transfered to that breakpoint.
      const desktop = this.page.getBreakpointByName('desktop');
      this.model.setOnWritableModelByBreakpoint('geometry.transform.size', transformSize, desktop);
      this.model.setOnWritableModelByBreakpoint(
        'geometry.transform.offset',
        transformOffset,
        desktop
      );
      this.model.setOnWritableModelByBreakpoint('geometry.size', elementSize, desktop);
    }

    updateCanvasImageSource(this);

    this.fireEvent('imageChanged');
  },

  updateAssetDimensions: function (assetDimensions, autoscale) {
    this.model.set('content.asset.size', assetDimensions);
    this.model.set('content.asset.sizeVerified', true);

    const nullTransformOffset = { left: 0, top: 0 };

    if (autoscale) {
      // Scale the image to fit the page, without prompting the user. This is currently only used
      // when dragging an image file from the OS into the canvas.
      const size = scaleImageToPage(assetDimensions);
      this.setSizeAttributes(size, size, nullTransformOffset);
      this.addToPage();
      return;
    }

    const elementSize = this.model.get('geometry.size');
    const isReplacingExistingAsset = elementSize.width !== 0 && elementSize.height !== 0;

    if (isReplacingExistingAsset) {
      const isDifferentAssetDimensions =
        assetDimensions.width !== elementSize.width ||
        assetDimensions.height !== elementSize.height;

      if (isDifferentAssetDimensions) {
        // Replacing an existing image with a new image that has different dimensions from the
        // element
        const dialog = new jui.MultiOptionDialog({
          classNames: ['image-too-big'],
          clickOutsideWillClose: false,
          elementId: this.id,
          uiBinding: window.editor.dialogManager.getUIBinding('imageScaling'),
          message: new Element('p').update(
            'The image you have chosen is not the same size as the image being replaced. ' +
              'Would you like to scale the new image to fit the size of the replaced image?'
          ),
          options: [
            { label: "Don't Scale", eventType: 'selectDontScaleButtonWhileReplacingImage' },
            { label: 'Scale Image to Fit', eventType: 'selectScaleButtonWhileReplacingImage' },
          ],
        });

        // When the dialog is showing during adding an image, looking up the image by ID
        // won't work – and the event subscriber needs it. So we store a reference to it
        // in the dialog manager.
        window.editor.dialogManager.openDialog(dialog, { imageElm: this });
      } else {
        // Replacing an existing image with a new image that has the same dimensions as the
        // element
        this.setSizeAttributes(elementSize, elementSize, nullTransformOffset);
        this.view.show();
      }

      return;
    }

    const isNewAssetLargerThanPage = assetDimensions.width > this.page.getContentWidth();

    if (isNewAssetLargerThanPage) {
      // Adding a new image that is larger than page
      const dialog = new jui.MultiOptionDialog({
        classNames: ['image-too-big'],
        clickOutsideWillClose: false,
        elementId: this.id,
        uiBinding: window.editor.dialogManager.getUIBinding('imageScaling'),
        message: new Element('p').update(
          'The image you have chosen is wider than the page. ' +
            'Would you like to scale it to fit within the page or keep it full size?'
        ),
        options: [
          { label: 'Use Full Size', eventType: 'selectDontScaleButtonWhileCreating' },
          { label: 'Scale Image to Fit Page', eventType: 'selectScaleButtonWhileCreating' },
        ],
      });

      window.editor.dialogManager.openDialog(dialog, { imageElm: this });

      return;
    }

    // Adding a new image that fits within the page
    this.setSizeAttributes(assetDimensions, assetDimensions, nullTransformOffset);
    this.addToPage();
  },

  updateImageSize: function () {
    var size = this.model.safeGet('geometry.transform.size');
    //Prevent image from being set to anything less than 1px.
    if (size && size.width > 0 && size.height > 0) {
      if (this.page.isEditMode()) {
        this.img.style.width = size.width + 'px';
        this.img.style.height = size.height + 'px';

        // This syncs the view to the size of the image mask
        if (
          this.model.safeGet('geometry.size.width') > 0 &&
          this.model.safeGet('geometry.size.height') > 0
        ) {
          this.view.e.style.width = this.model.safeGet('geometry.size.width') + 'px';
          this.view.e.style.height = this.model.safeGet('geometry.size.height') + 'px';
        } else {
          this.view.e.style.width = size.width + 'px';
          this.view.e.style.height = size.height + 'px';
        }
      }
    }
  },

  updateElementGeometry: function ($super) {
    $super();

    this.updateImagePosition();
    this.updateImageSize();

    if (this.page.isEditMode()) {
      updateCanvasImageSource(this);
    }
  },

  removeTransformations: function () {
    var m = this.model;
    var size = m.get('content.asset.size');
    m.setSize({ width: size.width, height: size.height });
    m.set('geometry.transform.size', { width: size.width, height: size.height });
    m.set('geometry.transform.offset', { left: 0, top: 0 });
  },

  modelChanged: function ($super, e) {
    var details = $super(e);

    if (details.accessor === 'content.link') {
      this.fireEvent('goalChanged', this);
    } else if (details.accessor.startsWith('geometry.transform.offset')) {
      this.updateImagePosition();
    } else if (details.accessor.startsWith('geometry.transform.size')) {
      this.updateImageSize();
    }
  },

  applyBorder: function ($super, value) {
    $super(value);
    if (this.page.isEditMode()) {
      this.updateImagePosition();
    }
  },

  canHaveGoals: function () {
    return true;
  },

  getGoals: function () {
    var type = this.model.safeGet('action.type');

    if (!type) {
      return [];
    }

    var typeMap = {
      url: 'link',
      externalLightbox: 'link',
      phone: 'phone',
    };

    var actionValue = this.model.getCurrentActionValue();

    if (actionValue && !/^(#|mailto\:)/.test(actionValue)) {
      return [
        {
          type: typeMap[type],
          url: actionValue,
        },
      ];
    } else {
      return [];
    }
  },

  isAppendParamsEnabled: function () {
    return (
      this.model.safeGet('content.passparams') && this.model.safeGet('action.type') !== 'phone'
    );
  },

  addToPage() {
    this.page.insertElement(this, { container: this.page.getElementById(this.containerId) });
  },

  verifyAssetSize() {
    // In the past there were bugs that caused `content.asset.size` to be set to an incorrect value
    // (or, going further back, to not be set all). Now that this bug is fixed, this function will
    // fix existing bad data by loading the original image and setting `content.asset.size` to the
    // true image dimensions. The sizeVerified flag ensures that this only happens once for each
    // image element.

    if (this.model.safeGet('content.asset.sizeVerified')) {
      return;
    }

    addLoadState(this);

    this.model
      .fetchAssetDimensions()
      .then(dimensions => {
        this.model.set('content.asset.size', dimensions);
        this.model.set('content.asset.sizeVerified', true);
        updateCanvasImageSource(this);
      })
      .catch(error => {
        if (process.env.NODE_ENV === 'production') {
          console.warn('Failed to verify asset size:', error);
        }
      });
  },
});

ImageElement.scalingDialogUIBindingHandler = function (ev) {
  // Class method.
  // Editor subscribes this method to the image scaling dialog UI binding.

  const element =
    window.editor.page.getElementById(ev.elementId) ||
    window.editor.dialogManager.getCurrentDialog().imageElm;

  const elementSize = element.model.get('geometry.size');
  const assetSize = element.model.get('content.asset.size');
  const nullTransformOffset = { left: 0, top: 0 };

  // Handlers for each of the dialogs' action buttons
  var domainEvents = {
    selectDontScaleButtonWhileReplacingImage: function () {
      element.setSizeAttributes(assetSize, assetSize, nullTransformOffset);
      element.view.show();
    },

    selectScaleButtonWhileReplacingImage: function () {
      const currentAR = elementSize.width / elementSize.height;
      const ar = assetSize.width / assetSize.height;

      let scaleFactor;
      let transformSize;
      let transformOffset;

      if (currentAR > ar) {
        scaleFactor = elementSize.width / assetSize.width;
        transformSize = {
          width: Math.round(assetSize.width * scaleFactor),
          height: Math.round(assetSize.height * scaleFactor),
        };
        transformOffset = {
          top: Math.round((elementSize.height - transformSize.height) / 2),
          left: 0,
        };
      } else {
        scaleFactor = elementSize.height / assetSize.height;
        transformSize = {
          width: Math.round(assetSize.width * scaleFactor),
          height: Math.round(assetSize.height * scaleFactor),
        };
        transformOffset = {
          top: 0,
          left: Math.round((elementSize.width - transformSize.width) / 2),
        };
      }

      element.setSizeAttributes(elementSize, transformSize, transformOffset);
      element.view.show();
    },

    selectDontScaleButtonWhileCreating: function () {
      element.setSizeAttributes(assetSize, assetSize, nullTransformOffset);
      element.addToPage();
    },

    selectScaleButtonWhileCreating: function () {
      const size = scaleImageToPage(assetSize);
      element.setSizeAttributes(size, size, nullTransformOffset);
      element.addToPage();
    },
  };

  // Call the relevant handler
  var handler = domainEvents[ev.type];
  if (_.isFunction(handler)) {
    handler();
  } else if (banzai.features.isDebugModeEnabled()) {
    console.warn('No domain event handler for type', ev.type);
  }

  // Close the dialog
  window.editor.dialogManager.closeCurrentDialog();
};

ImageElement.elementDefaults = {
  name: 'Image',
  geometry: {
    position: 'absolute',
    offset: { top: 0, left: 0 },
    size: { width: 0, height: 0 },
    borderLocation: 'outside',
    maintainAR: true,
    transform: {
      offset: { left: 0, top: 0 },
      size: { width: 0, height: 0 },
    },
  },
  style: {
    effect: {
      enabledTypes: ['none'],
      opacity: 25,
      color: '000000',
      offset: {
        left: 0,
        top: 4,
      },
      blurRadius: 4,
    },
  },
  action: {
    type: 'url',
  },
};

ImageElement.lightboxSizeDefault = {
  width: 840,
  height: 480,
};

module.exports = ImageElement;
