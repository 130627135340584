import _ from 'lodash';

export function isGoalActive(editor, goal) {
  return _.some(editor.pages, function(page) {
    return page.goals.isGoalActive(goal);
  });
}

export function getUniqueGoals(goals) {
  return _.uniqBy(goals, 'url');
}

export function getAllGoals(editor) {
  return getUniqueGoals(_.flatten(editor.pages.map(page => page.goals.getGoals())));
}

export function getAllActiveGoals(editor) {
  return getUniqueGoals(_.flatten(editor.pages.map(page => page.goals.getActiveGoals())));
}

export function stripTrailingSlash(url) {
  if (url.substr(-1) === '/') {
    return url.substr(0, url.length - 1);
  }
  return url;
}

export function syncActiveGoals(allGoals, allActiveGoals) {
  return allActiveGoals
    .filter(function(activeGoal) {
      var matchingPossibleGoal = _.find(allGoals, function(possibleGoal) {
        // Because the goal object can be polluted with other properties, we should only
        // sync and filter the active goals by comparing the two properties that matter: "type" and "url"
        return (activeGoal.type === possibleGoal.type) &&
          (stripTrailingSlash(activeGoal.url) === stripTrailingSlash(possibleGoal.url));
      });

      return Boolean(matchingPossibleGoal);
    });
}

export function updateAndSyncGoals(editor) {
  var allGoals = getAllGoals(editor);
  var allActiveGoals = getAllActiveGoals(editor);
  var newActiveGoals = syncActiveGoals(allGoals, allActiveGoals);

  editor.pages
    .filter(page => !page.isFormConfirmation())
    .forEach(page => {
      page.settings.activeGoals = newActiveGoals;
    });
}

export function addActiveGoalForAllPages(editor, g, activateGoal) {
  editor.pages
    .filter(function(page) {
      // We don't add active goals to form confirmation dialog
      return ! page.isFormConfirmation();
    })
    .forEach(function(page) {
      var goals = page.goals;

      if (activateGoal) {
        goals.addActiveGoal(g);
      } else {
        goals.removeActiveGoal(g);
      }
    });
}

const sortOrderMap = {
  download: 0,
  form: 1,
  link: 2,
  phone: 3,
  lightbox: 4
};

export function addSortOrder(goals) {
  return goals.map(goal => _.assign(goal, {sortOrder: sortOrderMap[goal.type] || 0}));
}

export function getElmGoal(elm) {
  // Goals do not initally have a sort order value. This adds a 'sortOrder' property that we use to sort the goals later
  return addSortOrder(elm.getGoals())[0];
}

export function shouldAutoEnableGoal(editor, elm) {
  var previousGoal = getElmGoal(elm);
  var isPreviousGoalActive = isGoalActive(editor, previousGoal);
  return (!previousGoal || isPreviousGoalActive) && editor.page.getConfigValue('autoEnableGoals');
}
