import _ from 'lodash';

import pomUpdates from 'ub_legacy/lp/pom/updates/updates';
import checkVersion from './checkVersion';

/**
 * See https://unbounce.atlassian.net/browse/BD-9354
 * 
 * Some pages were created that had autoscale set to true. This was not the default behavior
 * and this updater guarantees that autoscale is set to false on existing pages.
 */

pomUpdates.add({
  minVersion: '3.3',
  version: '3.4',

  run: function (pomData0) {
    if (!checkVersion(this.minVersion, this.version, pomData0.mainPage.version)) {
      return pomData0;
    }

    const pomData = _.cloneDeep(pomData0);

    [pomData.mainPage, ...pomData.subPages].forEach(page => {
      // autoscale should default to false
      page.autoscale = false;
      page.version = this.version;
    });

    return pomData;
  },
});
