/* globals $H */

var ModelAttributeExtractor = function(model) {
   this.model = model;
};

ModelAttributeExtractor.TransformationMap = {
   fields: '',
   groups: 'group_',
   labels: 'label_',
   containers: 'container_'
};

ModelAttributeExtractor.prototype = (function() {
   var _toStyler = function(model) {
      return $H(ModelAttributeExtractor.TransformationMap).map(function(item) {
         if (model.exists(item.key)) {
            return {
               elements: model.get(item.key),
               idPrefix: item.value
            };
         }
      }).compact();
   };

   return {
      toStyler: function() {
         return _toStyler(this.model);
      }
   };
})();

module.exports = ModelAttributeExtractor;
