/* globals editor */
var _ = require('lodash');

var ubBanzai = window.ubBanzai;

// This module maps the old style.background properties to
// style.newBackground (added as part of LP-7184). It is called by the
// element property panel's element_background_controls_new.js

;(function() {
  var NewBGPropsHelper = window.lp.pom.NewBGPropsHelper;
  var applicableElementTypes = ['lp-pom-root', 'lp-pom-block', 'lp-pom-box', 'lp-pom-text'];


  var _imageAndPatternMappings = {
    opacity          : 'style.background.opacity',
    isColorOverImage : 'style.background.imageAboveColor',
    image            : 'style.background.image',
    parallax         : 'style.background.imageFixed',
    bgColor          : 'style.background.backgroundColor',
    tiling           : 'style.background.backgroundRepeat',
    position         : 'style.background.backgroundPosition'
  };

  var _simplePropertyMappings = {
    pattern: _imageAndPatternMappings,
    image  : _.merge({fitToContainer: 'style.background.imageStretched'}, _imageAndPatternMappings),
    gradient: {
      baseColor       : 'style.background.backgroundColor',
      fromColor       : 'style.background.gradient.from',
      toColor         : 'style.background.gradient.to',
      autoGradient    : 'style.background.autoGradient',
      reverseGradient : 'style.background.reverseGradient'
    },
    solidColor: {
      bgColor: 'style.background.backgroundColor',
      opacity: 'style.background.opacity'},
    // shared by all types
    commonProperties: {
      fitWidthToPage: 'geometry.fitWidthToPage'
    }
  };

  var _advancedImageMappings = function(model, propsFromSimpleMappings){
    var props = _.clone(propsFromSimpleMappings)
      , type  = model.element.type;

    if ( ! model.shouldApplyBackgroundImage()) {
      props.image = undefined;
    }
    var isCorrectElType = _.includes(applicableElementTypes, type);
    if (( _.isUndefined(props.opacity) || _.isNull(props.opacity)) && isCorrectElType) {
      props.opacity = 100;
    }

    props.fitToContainer   = model.isBackgroundImageStretched();
    props.isColorOverImage = _.isUndefined(props.isColorOverImage) ? false : ! props.isColorOverImage;
    return props;
  };

  var _advancedMappingFunctions = {
    pattern: _advancedImageMappings,
    image: _advancedImageMappings,
      gradient: function (model, props0) {
        var props = _.clone(props0);
        if (! props.autoGradient) {
          props.reverseGradient = false;
          }
        var gradient = model.calculateGradient(model.get('style.background.backgroundColor'));
        var reversed = model.safeGet('style.background.reverseGradient');
        if (model.isAutoGradient() && reversed) {
          // DO NOT use model.getGradient() as that flips the colors if reverseGradient
          // explictly asking for either breakpoint here
          props.reverseGradient = true;
          props.autoGradient = true;
        }
        if (_.isEmpty(props.fromColor) && model.isAutoGradient()) {
          props.baseColor = gradient.from;
          props.fromColor = gradient.from;
          props.toColor = gradient.to;
        }
        if (reversed && ! _.isEmpty(props.fromColor)) {
          // on the new model we explictly flip these on the model:
          var beforeSwap = _.clone(props);
          props.fromColor = beforeSwap.toColor;
          props.toColor = beforeSwap.fromColor;
        }
        return props;
      },
    solidColor: function(model, props){
      return props;
    }
  };

  var guessStyleType = function(model, breakpointName) {
    return NewBGPropsHelper
      .withBreakpoint(model, breakpointName, function() {
        var hasImage = model.exists('style.background.image') && model.shouldApplyBackgroundImage();
        var isTiled = model.safeGet('style.background.backgroundRepeat') !== 'no-repeat';
        if (model.safeGet('style.background.fillType') === 'gradient') {
          // Gradient is mutually exclusive from image/pattern/solidColor. So comes first.
          return 'gradient';
        } else if (hasImage && isTiled) {
          return 'pattern';
        } else if (hasImage) {
          return 'image';
        } else {
          //TODO: do a sanity check on backgroundColor / opacity
          return 'solidColor';
        }
      });
  };

  var _stripUndefinedAndNull = function(props) {
    return _.pickBy(props, function(val) {
      return  !  (_.isUndefined(val) || _.isNull(val));
    });
  };

  var breakpointGetProperty = function(propName, model, breakpoint) {
    if (model.existsByBreakpoint(propName, breakpoint)) {
      var basicModel = model.getBasicBreakpointModel(breakpoint)
        , propKeys   = propName.split('.')
        , attr       = basicModel;
      for (var i=0,l=propKeys.length;i<l;i++) {
        attr = attr[propKeys[i]];
      }
      return attr;
    } else {
      return undefined;
    }
  };

  var _saferSafeGet = function(model, propName, breakpoint) {
    if(typeof breakpoint === 'string') {
      breakpoint = model.element.page.getBreakpointByName(breakpoint);
    }
    if (model.exists(propName)) {
      var basicModel = model.getReadableModel(breakpoint)
      , propKeys   = propName.split('.')
      , attr       = basicModel;
        for (var i=0,l=propKeys.length;i<l;i++) {
          attr = attr[propKeys[i]];
        }
      return attr;
    } else {
      return undefined;
    }
  };

  var _performSimpleMappings = function(model, breakpointName, propertyGetter, lookupKeyMappings) {
    return _.fromPairs(_.map(lookupKeyMappings, function(oldKey, newKey){
      var oldVal = propertyGetter(oldKey, model, breakpointName);
      return [newKey, oldVal];
    }));
  };

  var _mkPropertyMapperFn = function(propertyGetter) {
    return function (model, breakpointName, style) {
      var simpleMappings = _simplePropertyMappings[style];
      var advancedMapper = _advancedMappingFunctions[style];
      var newBgProperties = _performSimpleMappings(model, breakpointName, propertyGetter,
                                                   simpleMappings);
        var commonProperties = _performSimpleMappings(model, breakpointName, propertyGetter,
                                                      _simplePropertyMappings.commonProperties);
      var allProps = _.merge(newBgProperties, commonProperties);
      return _stripUndefinedAndNull(
        advancedMapper(model, allProps));
    };
  };

  var _getExplictlySetValues = _mkPropertyMapperFn(breakpointGetProperty);

  var _getValuesFromMergedReadableModel = _mkPropertyMapperFn(
    function(propName, model, breakpointName) {
        return _saferSafeGet(model, propName, breakpointName);
      });

  var _getNewPropsFromOld = function(model, breakpointName) {
    var styleType = guessStyleType(model, breakpointName);
    return NewBGPropsHelper
      .withBreakpoint(model, breakpointName, function() {
        if (breakpointName === 'desktop') {
          return {type: styleType,
                  props: _getExplictlySetValues(model, breakpointName, styleType)};
        } else /* mobile */ {
          var mobileStyleType = styleType;
            var desktopStyleType = guessStyleType(model, 'desktop');
          if (desktopStyleType !== mobileStyleType) {
            return {type: styleType,
                    props: _getValuesFromMergedReadableModel(model, breakpointName, styleType)};
          } else {
            return {type: styleType,
                    props: _getExplictlySetValues(model, breakpointName, styleType)};
          }
        }
      });
    };

  var _updateOldmodelToNew = function(element) {
    var model = element.model;

    if(model.exists('style.newBackground')) {
      return;
    }
    var props = {
      desktop: _getNewPropsFromOld(model, 'desktop'),
      mobile: _getNewPropsFromOld(model, 'mobile')
    };

    _setDesktopStyles(model, props);
    _setMobilestyles(model, props);
  };


  var _handleSharedProps = function(obj, style) {
    var temp = {};
    temp[style] = obj;

    if (style === 'gradient') {
      temp.solidColor = { bgColor: obj.baseColor };
    }

    if (style === 'solidColor') {
      temp.gradient = { baseColor: obj.bgColor };
    }

    var imagePatternSharedProps = ['image', 'opacity', 'bgColor', 'isColorOverImage'];
    if (_.includes(['image', 'pattern'], style)) {
      temp[style === 'image' ? 'pattern' : 'image'] = _.pick(obj, imagePatternSharedProps);
    }
    return temp;
  };

  var _setDesktopStyles = function(model, props) {
    var desktopStyle         = props.desktop.type
      , desktopNewBackground = _.merge(
          {type: desktopStyle},
          _handleSharedProps(props.desktop.props, desktopStyle))
      , styles               = model.getBasicBreakpointModel('desktop').style;
    styles                   = _.merge(styles, {newBackground: desktopNewBackground});
    model.setOnWritableModelByBreakpoint('style', styles, 'desktop');
  };

  var _setMobilestyles = function(model, props) {
    var mobileStyle = props.mobile.type
      ,  mobileNewBackground = {};
    if( ! _.isEmpty(props.mobile.props)) {
      mobileNewBackground.type = mobileStyle;
      model.setOnWritableModelByBreakpoint('style.newBackground.type', mobileStyle, 'mobile');
    }
    mobileNewBackground = _.merge({}, mobileNewBackground, _handleSharedProps(props.mobile.props, mobileStyle));

    var styles = model.getBasicBreakpointModel('mobile').style;
    styles = _.merge(styles, {newBackground: mobileNewBackground});
    model.setOnWritableModelByBreakpoint('style', styles, 'mobile');
  };

  var updateOldPropsToNewProps = function() {
    var elements = _.flattenDeep(_.map(applicableElementTypes, function(type) {
      return editor.page.getElementsByType(type);
    }));
    _.each(elements, _updateOldmodelToNew);

    if (ubBanzai.features.isDebugModeEnabled()) {
      console.info('NewBGPropsUpgrader:', 'Updating old BG if necessary.');
    }
  };

  window.lp.pom.NewBGPropsUpgrader = {
    updateOldPropsToNewProps: updateOldPropsToNewProps
  };

})();
