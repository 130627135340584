var _ = require('lodash');

var FieldCleaner = function(model, fieldAttributeTypes) {
  this.fieldAttributeTypes = fieldAttributeTypes;
  this.model = model;
};

FieldCleaner.prototype = {
  // TODO: add breakpoint aware version
  cleanField: function(field) {
    this._cleanFields([field.id]);
  },

  cleanInvalidFields: function() {
    this.cleanInvalidFieldsByBreakpoint();
  },

  cleanInvalidFieldsByBreakpoint: function() {
    _.each(this.model.element.page.breakpoints, this._cleanInvalidFieldsByBreakpoint.bind(this));
  },

  // private:
  _getValidBreakpointAttributeTypes: function(breakpoint) {
    return this.fieldAttributeTypes.filter(function(type) {
      return this.model && this.model.existsByBreakpoint(type, breakpoint);
    }, this);
  },

  _getBreakpointFieldIds: function(breakpoint) {
    return this._getValidBreakpointAttributeTypes(breakpoint).map(function(type) {
      return _.keys(this.model.safeGet(type, breakpoint));
    }, this).flatten().uniq();
  },

  _cleanInvalidFieldsByBreakpoint: function(breakpoint) {
    //TODO: clean up
    var model = this.model;

    var breakpointFieldsIds = this._getBreakpointFieldIds(breakpoint);

    var visibleFieldIds = _.map(model.safeGet('content.fields', breakpoint), 'id');

    var invalidFieldIds = _.reject(breakpointFieldsIds, function(fieldId) {
      return _.includes(visibleFieldIds,fieldId);
    }).flatten().uniq();

    var invalidFields = _.map(this.fieldAttributeTypes, function(type) {
      return _.map(invalidFieldIds, function(fieldId) {
        return type + '.' + fieldId;
      });
    }).flatten();

    invalidFields
      .filter(function(fieldId){
        return model.existsByBreakpoint(fieldId, breakpoint);
      })
      .each(function(fieldId) {
        if(breakpoint.default) {
          model.deleteAttr(fieldId);
        } else {
          model.forceDeleteAttrOnBreakpoint(breakpoint.name, fieldId);
        }
      });
  },

  _findAccessorsUsedBy: function(ids) {
    var model               = this.model
      , fieldAttributeTypes = this.fieldAttributeTypes;

    return ids.map(function(id) {
      return fieldAttributeTypes.map(function(node) {
        return node + "." + id;
      });
    })
    .flatten()
    .select(function(accessor) {
      return model && model.exists(accessor);
    });
  },

  _cleanFields: function(fieldIds) {
    var model = this.model;
    this._findAccessorsUsedBy(fieldIds).each(function(accessor){
      model.deleteAttr(accessor);
    });
  },
};

module.exports = FieldCleaner;
