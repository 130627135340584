/* global jQuery, rxSwitchboard */
var _ = require('lodash');
var lp = window.lp;

;(function(){
  lp.publisher = {};
  lp.publisher.ColorOverlayElement = function(containerPomElement){
    var overlayId = [containerPomElement.id, 'color-overlay'].join('-');
    var $over = jQuery('<div id="' + overlayId + '" />');

    var containerModel = containerPomElement.model;

    function _main() {
      var viewElement = containerPomElement.getViewDOMElement();
      jQuery(viewElement).prepend($over);

      elementChangeEvs.ub_subscribe(_handleLoadEvent);
    }

    var elementChangeEvs = rxSwitchboard.subjects.jui_events
      .filter(function(ev){
        return ev.type === 'breakpointChanged';
      });

    var _isOverlayApplicable = function() {
      var styleType = containerModel.getNewBackgroundStyleType();
      return _.includes(['image', 'pattern', 'video'], styleType);
    };

    function _handleLoadEvent() {
      /* jshint maxstatements: 50 */
      /* jshint maxcomplexity: 24 */
      var rules = [];

      rules.push({attribute: 'position', value: 'absolute'});

      // Background
      var background;
      if ( ! _isOverlayApplicable() ) {
        background = 'none';
      } else if (containerModel.getImageAboveColor()) {
        background = 'none';
      } else if (containerModel.getCurrentFillType() === 'gradient') {
        background = 'rgba(0,0,0,0)';
      } else {
        background = containerPomElement.getBGColorAsRGBA();
      }
      rules.push({attribute: 'background', value: background });

      // Border radius
      if (containerModel.exists('geometry.cornerRadius')) {
        var radius = containerModel.get('geometry.cornerRadius');
        if (_.isObject(radius)) {
          var radiusToCssAttrMap = {
            tl: 'border-top-left-radius',
            tr: 'border-top-right-radius',
            bl: 'border-bottom-left-radius',
            br: 'border-bottom-right-radius'
          };
          _.each(radiusToCssAttrMap, function(cssAttr, modelAttr) {
            var radiusVal = radius[modelAttr];
            rules.push({attribute:cssAttr, value: containerPomElement.page.getUnit(radiusVal)});
          });
        } else if (radius !== 0) {
          var borderWidth = 0;

          if (containerModel.exists('style.border.width')) {
            borderWidth = containerModel.get('style.border.width');
          }

          rules.push({attribute: 'border-radius', value: containerPomElement.page.getUnit(radius - borderWidth)});
        }
      }

      // Height & Width
      if (containerPomElement.id === 'lp-pom-root') {
        var height = containerPomElement.calculateHeightWithTopPadding();
        rules.push({attribute: 'top',        value: '0'});
        rules.push({attribute: 'width',      value: '100%'});
        rules.push({attribute: 'height',     value: containerPomElement.page.getUnit(height)});
        rules.push({attribute: 'min-height', value: '100%'});
      } else {
        var size = _.clone(containerPomElement.adjustDimensions(containerModel.getSize()));
        if (containerModel.safeGet('geometry.fitWidthToPage'))  {
          size.width = '100%;';
        } else {
          size.width = containerPomElement.page.getUnit(size.width);
        }
        rules.push({attribute: 'height', value: containerPomElement.page.getUnit(size.height) });
        rules.push({attribute: 'width',  value: size.width});
      }

      _.forEach(rules, function(rule) {
        window.page.style.setCSSRule( _.merge(rule, {selector: '#' + overlayId}) );
      });
    }

    _main();

    return {
      element: $over[0]
    };
  };
})();
