const { jui, Class } = window;
var pageStyleHelper = require('ub/elements/page-style-helper');

var createSelectorKey = pageStyleHelper.createSelectorKey;
var createMediaKey = pageStyleHelper.createMediaKey;

window.lp.pom.PageStyle = Class.create(jui.EventSource, {
  initialize: function(page){
    this.page = page;
    this.id = 'page-style-'+page.id;
    this.type = 'lp-pom-page-style';
    this.name = 'Page Settings';
    this.page.addListener('breakpointAdded', this);

    // Shape of this.rules:
    // {
    //   [BreakpointName]: {
    //     [MediaQueryKey]: {
    //       _media: CSSMediaQuery
    //       [SelectorKey]: {
    //         _selector: CSSSelector,
    //         [CSSProperty]: CSSValue,
    //       }
    //     }
    //   }
    // }
    this.rules = {};

    this.page.breakpoints.forEach(function(breakpoint){
      this.rules[breakpoint.name] = {};
    }.bind(this));
  },

  _buildStylesForPublish: function(breakpoint) {
    var originalBreakpoint = this.page.getCurrentBreakpoint();

    try {
      // do not use switchToBreakpoint!
      this.page.currentBreakpoint = breakpoint;
      return this.getCssRules();
    } finally {
      this.page.currentBreakpoint = originalBreakpoint;
    }
  },

  createAndGetStyleNode: function(breakpointName) {
    const pageStyleType = `${this.page.usedAs()}_${breakpointName}`;

    [...this.page.document.querySelectorAll(`style[title="page-styles"][data-page-type="${pageStyleType}"]`)]
      .forEach(node => node.parentNode.removeChild(node));

    return new Element('style', {
      title: 'page-styles',
      type: 'text/css',
      'data-page-type': pageStyleType
    });
  },

  injectStyles: function(cssText, breakpointName) {
    var cssNode = this.createAndGetStyleNode(breakpointName);

    cssNode.appendChild(document.createTextNode('\n' + cssText + '\n'));
    this.page.document.head.appendChild(cssNode);
  },

  createStyleSheets: function() {
    if (this.page.isEditMode()) {
      this.injectStyles(this.getCssRules(), 'page');
    } else {
      // isPublishOrPreviewMode
      this.page.getEnabledBreakpoints().forEach(function (breakpoint) {
        this.injectStyles(this._buildStylesForPublish(breakpoint), breakpoint.name);
      }.bind(this));
    }
  },

  _getRule: function(rule) {
    var css = '\n';

    Object.keys(rule)
      .filter(function(property) { return property !== '_selector'; })
      .forEach(function(property) {
        if (property === 'background') {
          rule.background.each(function(value){
            css += ' background:' + value + ';\n';
          });
        } else {
          css += ' ' + property + ':' + rule[property] + ';\n';
        }
      });

    return css;
  },

  isDuplicateRule: function(rule) {
    var mediaKey = createMediaKey(rule.media);
    var selectorKey = createSelectorKey(rule.selector);
    var rules = this.getCurrentRules();

    if (
      !rules[mediaKey] ||
      !rules[mediaKey][selectorKey] ||
      !rules[mediaKey][selectorKey][rule.attribute]
    ) {
      return false;
    }

    var isDuplicateRule = rules[mediaKey][selectorKey][rule.attribute] === rule.value;
    var isDuplicateBackgroundRule = rule.attribute === 'background' &&
      rules[mediaKey][selectorKey].background.indexOf(rule.value) > -1;

    return isDuplicateBackgroundRule || isDuplicateRule;
  },

  getCssRules: function() {
    return this.buildCSSRules(this.getCurrentRules());
  },

  _getMediaQuery: function(breakpoint, additionalMediaExpressions) {
    var enabledBreakpoints = this.page.getEnabledBreakpoints();
    var largestBreakpoint = enabledBreakpoints[0];

    if (
      this.page.isPublishOrPreviewMode() && (breakpoint.name !== largestBreakpoint.name)
    ) {
      var maxWidthRule = 'only screen and (max-width: ' + this.page.getBreakpointMaxWidth(breakpoint.name) + 'px)';

      if (additionalMediaExpressions.length > 0) {
        return additionalMediaExpressions
          .map(function(mediaExpression) {
            return maxWidthRule + ' and (' + mediaExpression + ')';
          })
          .join(', ');
      } else {
        return maxWidthRule;
      }
    } else if (additionalMediaExpressions.length > 0) {
      return '(' + additionalMediaExpressions.join('), (') + ')';
    } else {
      return null;
    }
  },

  buildCSSRules: function(rules) {
    var _getEditModeOverride = function(rule) {

      var isFormConfirmation = (this.page.usedAs() === 'form_confirmation')
        , newRule            = rule;

      if (rule._selector === 'a' && isFormConfirmation) {
        newRule._selector = '.modal a';
      } else if (rule._selector === 'body') {
        newRule._selector = (isFormConfirmation ? '.modal ' : '') + '.lp-pom-root';
      }

      return newRule;
    }.bind(this);

    var output = [];
    Object.keys(rules).forEach(function(mediaKey) {
      var mediaQuery =
        this._getMediaQuery(this.page.getCurrentBreakpoint(), rules[mediaKey]._media || []);

      if (mediaQuery) {
        output.push('@media ' + mediaQuery + ' {');
      }

      Object.keys(rules[mediaKey])
        .filter(function(selectorKey) { return selectorKey !== '_media'; })
        .forEach(function(selectorKey) {
          var rule = rules[mediaKey][selectorKey];
          var newRule = this.page.isEditMode() ? _getEditModeOverride(rule) : rule;

          output.push(newRule._selector + ' {' + this._getRule(newRule) + '}');
        }.bind(this));

      if (mediaQuery) {
        output.push('}');
      }
    }.bind(this));

    return output.join('\n');
  },

  updatePageStyles: function() {
    this.createStyleSheets();
  },

  breakpointAdded: function(e) {
    this.rules[e.data] = {};
  },

  getCurrentRules: function() {
    return this.rules[this.page.getCurrentBreakpoint().name];
  },

  setCSSRule: function(rule) {
    if (this.page.isPublishOrPreviewMode() && this.isDuplicateRule(rule)){
      return;
    }

    var selectorKey = createSelectorKey(rule.selector);
    var mediaKey = createMediaKey(rule.media);

    var rules = this.getCurrentRules();

    rules[mediaKey] = rules[mediaKey] || {
      _media: rule.media || [],
    };
    rules[mediaKey][selectorKey] = rules[mediaKey][selectorKey] || {
      _selector: rule.selector,
    };

    if (rule.attribute === 'background') {
      rules[mediaKey][selectorKey].background = rules[mediaKey][selectorKey].background || [];
      rules[mediaKey][selectorKey].background.push(rule.value);
    } else {
      rules[mediaKey][selectorKey][rule.attribute] = rule.value;
    }
  },

  removeCSSRules: function(selector){
    var currentRules = this.getCurrentRules();

    Object.keys(currentRules).forEach(function(mediaKey) {
      Object.keys(currentRules[mediaKey])
        .filter(function(selectorKey) {
          return selectorKey !== '_media' &&
            currentRules[mediaKey][selectorKey]._selector.startsWith(selector);
        })
        .forEach(function(selectorKey) {
          delete currentRules[mediaKey][selectorKey];
        });
    });
  },
});
