import _ from 'lodash';

import { safeGet, safeSet } from 'ub/utils';
import pomUpdates from 'ub_legacy/lp/pom/updates/updates';
import checkVersion from './checkVersion';

pomUpdates.add({
  minVersion: '3.2',
  version: '3.3',

  run: function (pomData0) {
    if (!checkVersion(this.minVersion, this.version, pomData0.mainPage.version)) {
      return pomData0;
    }

    const pomData = _.cloneDeep(pomData0);

    const effect = {
      type: 'none',
      opacity: 25,
      color: '000000',
      offset: {
        left: 0,
        top: 4,
      },
      blurRadius: 4,
    };

    const updateElement = el => {
      if (['lp-pom-button', 'lp-pom-text', 'lp-pom-box', 'lp-pom-image'].includes(el.type)) {
        if (!safeGet(el, 'style.effect')) {
          safeSet(el, 'style.effect', effect);
        }
      }
    };

    [pomData.mainPage, ...pomData.subPages].forEach(page => {
      page.elements.forEach(updateElement);
      page.version = this.version;
    });

    return pomData;
  },
});
