/* globals lp, Class */
var BoxElement = Class.create(lp.pom.VisibleElement, lp.ModuleComponent, {
  type: 'lp-pom-box',

  initialize: function ($super, element, modelData) {
    $super(element, modelData);
  },

  initView: function () {
    this.overlay = new lp[this.page.isEditMode() ? 'editor' : 'publisher'].ColorOverlayElement(
      this
    );
  },

  getModelClass: function () {
    return lp.module.box.BoxModel;
  },

  setVisible: function ($super, visible) {
    $super(visible);
    this.setVisibilityOfChildren(visible);
  },

  setVisibilityOfChildren: function (visible) {
    this.childElements.each(function (elm) {
      elm.setVisible(visible && elm.model.isVisible());
    });
  },

  createDefaultConstraints: function ($super) {
    $super();
    this.defaultConstraints.container = true;
    this.defaultConstraints.opacityable = true;
    this.defaultConstraints.parallaxable = false;
  },
});

BoxElement.elementDefaults = {
  name: 'Box',
  style: {
    background: {
      backgroundColor: 'fff',
      opacity: 100,
    },
    newBackground: {
      type: 'solidColor',
      solidColor: {},
    },
    border: {
      style: 'solid',
      width: 1,
      color: 'ccc',
    },
    effect: {
      enabledTypes: ['none'],
      opacity: 25,
      color: '000000',
      offset: {
        left: 0,
        top: 4,
      },
      blurRadius: 4,
    },
  },
  geometry: {
    position: 'absolute',
    offset: { top: 0, left: 0 },
    size: { width: 100, height: 100 },
    borderLocation: 'inside',
    borderApply: { top: true, right: true, bottom: true, left: true },
    layout: null,
  },
};

module.exports = BoxElement;
