import PropTypes from 'prop-types';
import React from 'react';

import { LabelFlair } from 'comp/flairs';

import clsx from 'clsx';

const InputGroup = props => (
  <div className={clsx('input-group', props.class)}>
    <label className={`input-title ${props.borderSelector ? ' hidden' : 'inline-color-selector'}`}>
      {props.title}
      {props.newTag ? <LabelFlair>NEW!!</LabelFlair> : null}
    </label>
    {props.children}
  </div>
);

InputGroup.propTypes = {
  title: PropTypes.string,
  newTag: PropTypes.bool,
  class: PropTypes.string,
  children: PropTypes.node,
  borderSelector: PropTypes.bool,
};

export default InputGroup;
