function getTriggerDefs(page) {
  const mobileBreakpoint = page.getBreakpointByName('mobile');
  const tabletBreakpoint = page.getBreakpointByName('tablet');
  const desktopBreakpoint = page.getBreakpointByName('desktop');

  // Forms have a published JS file that triggers the lightbox upon submit, so they don't need
  // a script insertion.
  return page
    .getElementsByTypeList(['lp-pom-button', 'lp-pom-image'])
    .filter(element => element.model.linksToLightbox())
    .map(element => ({
      selector: element.type === 'lp-pom-image' ? `#${element.id} a` : `#${element.id}`,
      sizes: {
        desktop: element.page.isDesktopEnabled()
          ? element.model.safeGet('lightboxSize', desktopBreakpoint)
          : undefined,
        tablet: element.page.isTabletEnabled()
          ? element.model.safeGet('lightboxSize', tabletBreakpoint)
          : undefined,
        mobile: element.page.isMobileEnabled()
          ? {
              // Mobile lightboxes have a fixed width of 320px. But if the user hasn't overridden the
              // height value for mobile, it won't be set in the POM.
              width: 320,
              height: (element.model.safeGet('lightboxSize', mobileBreakpoint) || {}).height,
            }
          : undefined,
      },
      isExternal: element.model.linksToExternalLightbox(),
    }));
}

export default function getLightboxInsertion(page) {
  // Builds insertion containing global variable with trigger element definitions.
  const triggerDefs = getTriggerDefs(page);

  if (triggerDefs.length > 0) {
    return {
      content: `<script>window.ub.page.lightboxTriggers = ${JSON.stringify(triggerDefs)};</script>`,
      placement: 'head',
    };
  }
}
