/* globals Class */
window.lp.ModuleBase = Class.create({
  initialize: function(config, options) {
    Object.extend(this, config);
    this.options = options || {};
    this.activeElement = null;
    this.propertiesPanel = null;
    this.builderDialog = null;
  }
});
