jQuery       = require 'jquery'
url          = require 'url'

getYouTubeID = require 'get-youtube-id'


class ConnectionFailedError extends Error
  constructor: (host) ->
    @message = "We were unable to access that #{ host } video. Please try again later."

class NotFoundError extends Error
  constructor: (host) ->
    @message = "We were unable to access that #{ host } video. Please try using another
      video."

class PrivateVideoError extends Error
  constructor: (host) ->
    @message = "That seems to be a private #{ host } video. Please try using a video that
      is publicly viewable."

class UnsupportedHostError extends Error
  constructor: ->
    @message = 'The URL you entered is not valid, or is from a video provider that we
      don\'t support.'

class InvalidUrlError extends Error
  constructor: (host) ->
    @message = "The URL you entered is not a valid #{ host } video."


makeOEmbedRequest = (oEmbedApi, videoUrl, videoHost, callback) ->
  jQuery.getJSON "//#{ oEmbedApi }?url=#{ videoUrl }"
    .done (res) ->
      if res.width and res.height and res.thumbnail_url
        callback
          image: res.thumbnail_url
          ratio: (res.width / res.height)

      else
        if res.error is 'Unauthorized'
          callback new PrivateVideoError(videoHost)
        else
          callback new NotFoundError(videoHost)

    .fail (error) ->
      if error?.status is 404
        callback new NotFoundError(videoHost)
      else
        callback new ConnectionFailedError(videoHost)


getVideoHostFromUrl = (videoUrl) ->
  # If the URL doesn't contain a protocol, add one so that url.parse will recognise it
  unless /^(?:f|ht)tps?\:\/\/|\/\//.test(videoUrl) then videoUrl = "http://#{ videoUrl }"

  domain = url.parse(videoUrl, false, true).hostname
  if /(youtube\.|youtu\.be)/.test domain
    'youtube'
  else if /vimeo\./.test domain
    'vimeo'
  else if /wistia\./.test domain
    'wistia'
  else
    new UnsupportedHostError()


youtube =
  getIframeUrl: (videoId) ->
    # See https://developers.google.com/youtube/player_parameters?hl=en#Parameters
    [
      '//www.youtube.com/embed/', videoId,
      '?mute=1',
      '&rel=0', # disable related videos
      '&loop=0', # video-backgrounds.js on the published page handles looping
      '&modestbranding=1',
      '&showinfo=0',
      '&controls=0',
      '&iv_load_policy=3', # disable annotations
      '&autohide=1',
      '&autoplay=0', # video-backgrounds.js on the published page triggers the autoplay
      '&disablekb=1',
      '&fs=0', # hide fullscreen button
      '&html5=1',
      '&enablejsapi=1'
    ].join ''

  getIdFromUrl: (videoUrl) ->
    getYouTubeID(videoUrl) or new InvalidUrlError 'YouTube'

  getImageAndRatio: (videoId, callback) ->
    makeOEmbedRequest 'youtube.com/oembed', "http://www.youtube.com/watch?v=#{ videoId }",
      'YouTube', callback


vimeo =
  getIframeUrl: (videoId) ->
    # See https://developer.vimeo.com/player/embedding
    [
      '//player.vimeo.com/video/', videoId,
      '?background=1&muted=1&autoplay=1&loop=1&badge=0&byline=0&title=0&portrait=0'
    ].join ''

  getIdFromUrl: (videoUrl) ->
    # From https://github.com/regexps/vimeo-regex/blob/4ee374/index.js#L12
    /vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/
      .exec(videoUrl)?[2] or new InvalidUrlError 'Vimeo'

  getImageAndRatio: (videoId, callback) ->
    makeOEmbedRequest 'vimeo.com/api/oembed.json', "http://vimeo.com/#{ videoId }",
      'Vimeo', callback


wistia =
  getIframeUrl: (videoId) ->
    # See http://wistia.com/doc/embed-options
    [
      '//fast.wistia.net/embed/iframe/', videoId,
      '?videoFoam=true&autoPlay=true&endVideoBehavior=loop&volume=0&',
      'controlsVisibleOnLoad=false&playButton=false&volumeControl=false&playbar=false',
      '&fullscreenButton=false&smallPlayButton=false'
    ].join ''

  getIdFromUrl: (videoUrl) ->
    # Adapted from http://wistia.com/doc/oembed
    /(wistia.com|wistia.net|wi.st)\/(medias|embed\/iframe|embed)\/(.*)/
      .exec(videoUrl)?.pop()?.replace('/', '') or new InvalidUrlError 'Wistia'

  getImageAndRatio: (videoId, callback) ->
    makeOEmbedRequest 'fast.wistia.net/oembed',
      "http://home.wistia.com/medias/#{ videoId }", 'Wistia', callback


module.exports = {
  getVideoHostFromUrl

  youtube
  vimeo
  wistia
}
