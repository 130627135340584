/* globals Class */
window.jui.dnd.Source = Class.create(window.jui.EventSource, window.jui.ControlModel, {
  initialize: function(elm, group, payload, options) {
    this.options = options || {};
    this.elm = window.jui.isComponent(elm) ? elm.e : elm;
    this.manager = options.manager || window.jui.dnd.manager;
    this.group = group;
    this.payload = payload;
    this.ghost = options.ghost || this.elm.cloneNode(true);// new Element('div', {className:'dnd-ghost'});
    this.ghost.setOpacity(0.7);
    this.ghost.addClassName('dnd-ghost');
    this.centerGhost = options.centerGhost || false;
    // this.clickAction = options.click;
  },

  addElmListener: function() {
    var self = this;
    this.elm.observe('mousedown', function(e) {
      if (!self.elm.hasClassName('dsbl')) {
        self.manager.startTracking(self, e);
      }
    });

    if (this.options.mouseout) {
      this.elm.observe('mouseout', function() {
        self.options.mouseout(self);
      });
    }
  },

  getGhost: function() {
    return this.ghost;
  },

  getGroup: function() {
    return this.group;
  },

  accepts: function(target) {
    /* jshint unused:vars */
    return true;
  },

  dragInit: function() {
    this.ghost.addClassName('init');
  },

  dragStart: function() {
    this.ghost.removeClassName('init');
  },

  dragStop: function() {
    this.ghost.removeClassName('ok');
  },

  dragEnter: function(point, target) {
    if (target.isScrollArea) {
      return;
    }
    this.ghost.addClassName('ok');
  },

  dragLeave: function(target) {
    /* jshint unused:vars */
    this.ghost.removeClassName('ok');
    this.ghost.removeClassName('init');
  },

  click: function() {
    if (this.options.click) {
      this.options.click(this);
    }
  }
});
