window.lp = window.lp || {};
window.lp.module = window.lp.module || {};

window.lp.module.block = {};
window.lp.module.block.BlockModule = require('ub_legacy/lp/modules/lp-block/block_module.js');
window.lp.module.block.SectionElement = require('ub_legacy/lp/modules/lp-block/block_element.js');
window.lp.module.block.BlockModel = require('ub_legacy/lp/modules/lp-block/block_model.js');

window.lp.module.root = {};
window.lp.module.root.RootModule = require('ub_legacy/lp/modules/lp-root/root_module.js');
window.lp.module.root.RootElement = require('ub_legacy/lp/modules/lp-root/root_element.js');
window.lp.module.root.RootModel = require('ub_legacy/lp/modules/lp-root/root_model.js');

window.lp.module.box = {};
window.lp.module.box.BoxModule = require('ub_legacy/lp/modules/lp-box/box_module.js');
window.lp.module.box.BoxElement = require('ub_legacy/lp/modules/lp-box/box_element.js');
window.lp.module.box.BoxModel = require('ub_legacy/lp/modules/lp-box/box_model.js');

window.lp.module.text = {};
window.lp.module.text.TextModule = require('ub_legacy/lp/modules/lp-text/text_module.js');
window.lp.module.text.TextElement = require('ub_legacy/lp/modules/lp-text/text_element.js');
window.lp.module.text.TextModel = require('ub_legacy/lp/modules/lp-text/text_model.js');

window.lp.module.image = {};
window.lp.module.image.ImageModule = require('ub_legacy/lp/modules/lp-image/image_module.js');
window.lp.module.image.ImageElement = require('ub_legacy/lp/modules/lp-image/image_element.js');
window.lp.module.image.ImageModel = require('ub_legacy/lp/modules/lp-image/image_model.js');

window.lp.module.button = {};
window.lp.module.button.ButtonModule = require('ub_legacy/lp/modules/lp-button/button_module.js');
window.lp.module.button.ButtonElement = require('ub_legacy/lp/modules/lp-button/button_element.js');
window.lp.module.button.ButtonModel = require('ub_legacy/lp/modules/lp-button/button_model.js');

window.lp.module.form = {};
window.lp.module.form.FormModule = require('ub_legacy/lp/modules/lp-form/form_module.js');
window.lp.module.form.FormElement = require('ub_legacy/lp/modules/lp-form/form_element.js');
window.lp.module.form.FormElement.ModelAttributeExtractor = require('ub_legacy/lp/modules/lp-form/form_element/model_attribute_extractor.js');

window.lp.module.form.FormElement.Styler = {};
window.lp.module.form.FormElement.Styler.Getter = require('ub_legacy/lp/modules/lp-form/form_element/styler/getter.js');
window.lp.module.form.FormElement.Styler.Setter = require('ub_legacy/lp/modules/lp-form/form_element/styler/setter.js');

window.lp.module.form.FormElement.FieldCleaner = require('ub_legacy/lp/modules/lp-form/form_element/field_cleaner.js');
window.lp.module.form.FormModel = require('ub_legacy/lp/modules/lp-form/form_model.js');
window.lp.module.form.LabelWidthControlTransformBoxExtension = require('ub_legacy/lp/modules/lp-form/label_width_control_transform_box_extension.js');

window.lp.module.video = {};
window.lp.module.video.VideoModule = require('ub_legacy/lp/modules/lp-video/video_module.js');
window.lp.module.video.VideoElement = require('ub_legacy/lp/modules/lp-video/video_element.js');
window.lp.module.video.VideoModel = require('ub_legacy/lp/modules/lp-video/video_model.js');

window.lp.module.code = {};
window.lp.module.code.CustomeHTMLModule = require('ub_legacy/lp/modules/lp-code/code_module.js');
window.lp.module.code.CustomHTMLElement = require('ub_legacy/lp/modules/lp-code/code_element.js');
window.lp.module.code.CustomHTMLModel = require('ub_legacy/lp/modules/lp-code/code_model.js');

window.lp.module.script = {};
window.lp.module.script.scriptModule = require('ub_legacy/lp/modules/lp-script/script_module.js');
window.lp.module.script.ScriptElement = require('ub_legacy/lp/modules/lp-script/script_element.js');

window.lp.module.stylesheet = {};
window.lp.module.stylesheet.StylesheetModule = require('ub_legacy/lp/modules/lp-stylesheet/stylesheet_module.js');
window.lp.module.stylesheet.StylesheetElement = require('ub_legacy/lp/modules/lp-stylesheet/stylesheet_element.js');

window.lp.module.multiSelect = {};
window.lp.module.multiSelect.MultiSelectModule = require('ub_legacy/lp/modules/lp-multi-select/multi_select_module.js');
window.lp.module.multiSelect.MultiSelectElement = require('ub_legacy/lp/modules/lp-multi-select/multi_select_element.js');

window.lp.module.socialWidget = window.lp.module.socialWidget || {};
window.jui.social_widget_twitter = require('ub_legacy/lp/modules/lp-social-widget/social_widget_twitter.js');
window.jui.social_widget_google = require('ub_legacy/lp/modules/lp-social-widget/social_widget_google.js');
window.jui.social_widget_facebook = require('ub_legacy/lp/modules/lp-social-widget/social_widget_facebook.js');
window.lp.module.SocialWidget = require('ub_legacy/lp/modules/lp-social-widget/social_widget.js');
window.lp.module.socialWidget.SocialWidgetModule = require('ub_legacy/lp/modules/lp-social-widget/social_widget_module.js');
window.lp.module.socialWidget.SocialWidgetModel = require('ub_legacy/lp/modules/lp-social-widget/social_widget_model.js');
window.lp.module.socialWidget.SocialWidgetElement = require('ub_legacy/lp/modules/lp-social-widget/social_widget_element.js');
