import React from 'react';
import ReactDOM from 'react-dom';
import InputGroup from 'ub/ui/inline-text/input-group.jsx';
import ColorSelection from 'ub/ui/inline-text/color-selection.jsx';
import tinycolor2 from 'tinycolor2';

class inlineColorSelector {
  constructor(props) {
    this.container = props.container;
    this.color = props.color;
    this.name = props.name;
    this.changeHandler = props.changeHandler;
    this.changeProperty = props.changeProperty;
    this.label = props.label;
    this.borderSelector = props.borderSelector;
    this.disabled = props.disabled;
    this.showOpacity = props.showOpacity;
  }

  render() {
    ReactDOM.render(
      <InputGroup title={this.label} class='color-input-group text-color' borderSelector={this.borderSelector}>
        <ColorSelection
          color={tinycolor2(this.color).toRgb()}
          name={this.name}
          changeHandler={this.changeHandler}
          changeProperty={this.changeProperty}
          borderSelector={this.borderSelector}
          disabled={this.disabled}
          showOpacity={this.showOpacity}
        />
      </InputGroup>,
      this.container
    );
  }
}

export default inlineColorSelector;
