import tinycolor from 'tinycolor2';

import googleFontApi from 'ub/fonts/font-service/google/api';
import fontManager from 'ub/fonts/font-service/manager';
import { panelEventsSubject } from 'ub/ui/inline-text/states/inline-text-state-subjects';

export function recordTextStyle(element) {
  const { inlineTextStyleAPI, page } = element;

  const paragraphStyle = inlineTextStyleAPI.getParagraphStyle();
  const styleTags = inlineTextStyleAPI.getTextStyles();

  const textStyle = {
    backgroundColor: inlineTextStyleAPI.getFontBackgroundColor(),
    color: inlineTextStyleAPI.getColor(),
    fontFamily: inlineTextStyleAPI.getFontFamily(),
    fontSize: inlineTextStyleAPI.getFontSize(),
    fontStyle: inlineTextStyleAPI.getFontStyle(),
    fontWeight: inlineTextStyleAPI.getFontWeight(),
    lineHeight: inlineTextStyleAPI.getLineHeight(),
    letterSpacing: inlineTextStyleAPI.getLetterSpacing(),
    paragraphStyle: paragraphStyle === 'p' ? null : paragraphStyle,
    styleTags: Object.keys(styleTags).filter(tag => styleTags[tag] === true),
    textAlign: inlineTextStyleAPI.getAlignment(),
  };

  // Clean up unnecessary properties from the object
  Object.keys(textStyle).forEach(key => textStyle[key] || delete textStyle[key]);
  if (textStyle.styleTags.length === 0) {
    delete textStyle.styleTags;
  }
  page.setLastTextStyle(textStyle);
}

export function inheritTextStyle(element) {
  const { inlineTextStyleAPI, page } = element;

  const lastTextStyle = page.getLastTextStyle();

  if (!lastTextStyle) {
    return;
  }

  if (lastTextStyle.paragraphStyle && lastTextStyle.paragraphStyle !== 'p') {
    inlineTextStyleAPI.setParagraphStyle(lastTextStyle.paragraphStyle);
  }

  if (lastTextStyle.fontWeight) {
    inlineTextStyleAPI.setFontWeight(lastTextStyle.fontWeight);
  }

  if (lastTextStyle.fontStyle) {
    inlineTextStyleAPI.setFontStyle(lastTextStyle.fontStyle);
  }

  if (lastTextStyle.fontFamily) {
    if (googleFontApi.isGoogleFont(lastTextStyle.fontFamily)) {
      // The last text element edited may have been using a Google font that was added to the source
      // page but has not been added to the current page. This will add it if it is not already
      // present.
      const { family, variants } = googleFontApi.findFontInList(lastTextStyle.fontFamily);
      fontManager.addFont({ family, variants }, false);
    }

    inlineTextStyleAPI.setFontFamily(lastTextStyle.fontFamily);
  }

  if (lastTextStyle.fontSize) {
    inlineTextStyleAPI.setFontSize(lastTextStyle.fontSize);
  }

  if (lastTextStyle.color) {
    inlineTextStyleAPI.setColor(tinycolor(lastTextStyle.color).toRgbString());
  }

  if (lastTextStyle.backgroundColor) {
    inlineTextStyleAPI.setFontBackgroundColor(
      tinycolor(lastTextStyle.backgroundColor).toRgbString()
    );
  }

  if (lastTextStyle.lineHeight) {
    inlineTextStyleAPI.setLineHeight(lastTextStyle.lineHeight);
  }

  if (lastTextStyle.textAlign) {
    inlineTextStyleAPI.setAlignment(lastTextStyle.textAlign);
  }
  if (lastTextStyle.letterSpacing) {
    inlineTextStyleAPI.setLetterSpacing(lastTextStyle.letterSpacing);
  }
  // strong, em, u, sup, s etc
  (lastTextStyle.styleTags || []).forEach(tag => {
    inlineTextStyleAPI.toggleTextStyle(tag);
  });

  // Update panel state
  panelEventsSubject.onNext({ type: 'updateCurrentTextStyles' });
}
