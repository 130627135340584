h             = require 'virtual-dom/h'
diff          = require 'virtual-dom/diff'
patch         = require 'virtual-dom/patch'
createElement = require 'virtual-dom/create-element'


module.exports = (elementId, message, defaultVisibility=false) ->
  _vTree = {}
  _elementSelector = "##{elementId}"
  _messageNode     = h 'span', message

  _visClass = (isShown) ->
    if isShown then '.on' else ''

  _render = (isShown=false) ->
    h "#{_elementSelector}.warn.svg-ico-warning4#{_visClass isShown }", _messageNode

  update = (currNode, isShown) ->
    newTree = _render isShown
    patches = diff _vTree, newTree
    _vTree  = newTree
    patch currNode, patches

  install = ->
    _vTree = _render defaultVisibility
    createElement _vTree

  {update, install}
