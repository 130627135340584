import { cloneDeep } from 'lodash';

import pomUpdates from 'ub_legacy/lp/pom/updates/updates';
import checkVersion from './checkVersion';

pomUpdates.add({
  minVersion: '3.5',
  version: '3.6',

  run: function (pomData0) {
    if (!checkVersion(this.minVersion, this.version, pomData0.mainPage.version)) {
      return pomData0;
    }

    const pomData = cloneDeep(pomData0);

    [pomData.mainPage, ...pomData.subPages].forEach(page => {
      page.settings.tabletBreakpointDisabled = true;
      page.version = this.version;
    });

    return pomData;
  },
});