/* globals lp,Class */
var ubBanzai = require('ub/control/banzai-features');
var isSandboxMode = ubBanzai.features.isSandboxEnabled();

var CustomeHTMLModule = Class.create(
  lp.ModuleBase,
  lp.ModuleElementMethods,
  lp.ModuleElementBuilderMethods,
  lp.ModuleElementPropertiesPanelMethods,
  {
    beforeCreate: function (page, defaults, options, data) {
      defaults.content.html = data.html;
      defaults.content.valid = data.valid;
    }
  }
);

lp.addModule(new CustomeHTMLModule(
  {
    type:'lp-code',
    getLimit: function() {
      return isSandboxMode ? 0 : Infinity;
    },
    version:'1.0',
    name: 'Custom HTML',
    usageType:'page-widget',
    namespace: lp.module.code
  }
));

module.exports = CustomeHTMLModule;
