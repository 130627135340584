//
// We previously included an instance of jQuery (namespaced to lp.jQuery) on every published page
// regardless of whether the 'user jQuery' script element was enabled by the user in the builder.
// Customers made use of this in their custom scripts.
//
// This updater was added at the same time as we removed the bundled jQuery. It enables user jQuery
// on all existing pages that did not already have it enabled. This means that custom scripts on
// existing pages depending on bundled jQuery will seamlessly transition to using the user jQuery,
// even if user jQuery was disabled before.
//

import _ from 'lodash';

import pomUpdates from '../legacy/lp/pom/updates/updates';
import checkVersion from './checkVersion';

const VERSION = '3.2';
const MIN_VERSION = '3.1';

// Just exported for tests
export default function updater(pomData0) {
  const pomData = _.cloneDeep(pomData0);

  [pomData.mainPage, ...pomData.subPages].forEach(page => {
    if (!checkVersion(MIN_VERSION, VERSION, page.version)) {
      return;
    }

    const isJQueryEnabled = page.elements.some(
      element =>
        element.type === 'lp-script' && element.predefined && element.name === 'jQuery (1.4.2)'
    );

    if (!isJQueryEnabled) {
      page.last_element_id = (page.last_element_id || 0) + 1;

      // Adding it to the start of the elements array replicates the behaviour of
      // ScriptElementBuilder, which inserts jQuery immediately before the first script element,
      // or at the start of the array if there are no scripts.
      page.elements.unshift({
        name: 'jQuery (1.4.2)',
        id: `lp-script-${page.last_element_id}`,
        containerId: null,
        type: 'lp-script',
        predefined: true,
        index: 0,
        placement: 'head',
        content: {
          type: null,
          html:
            '<script src="//ajax.googleapis.com/ajax/libs/jquery/1.4.2/jquery.min.js" type="text/javascript"></script>',
        },
        breakpoints: {},
      });
    }

    page.version = VERSION;
  });

  return pomData;
}

pomUpdates.add({
  minVersion: MIN_VERSION,
  version: VERSION,
  run: updater,
});
