/* globals lp, Class */
var _ = require('lodash');

var actionModel = require('ub/data/action-model');
var fetchImageDimensions = require('ub/control/fetch-image-dimensions').default;

module.exports = Class.create(lp.pom.VisibleElementModel, {
  initialize: function($super, element, modelData) {
    $super(element, modelData);

    this.addBreakpointModifyableAttributes({
      geometry:{
        offset: {
          left:true,
          top:true
        },
        size:{
          width:true,
          height:true
        },
        transform: {
          offset: {
            left:true,
            top:true
          },
          size: {
            width:true,
            height:true
          },
          // quality: true, //keep quality linked between breakpoints
        },
        visible:true,
        borderLocation: true,
        borderApply: {
          top: true,
          right: true,
          left: true,
          bottom: true
        },
        cornerRadius: true,
        keepCircular: true
      },
      style: {
        border: {
          style: true,
          width: true,
          color: true
        }
      },
      lightboxSize: true
    });

    this.addBreakpointAllowDuplicateAttributes({
      geometry:{
        size: true,
        borderApply: {
          top: true,
          right: true,
          bottom: true,
          left: true
        },
        visible: true
      }
    });
  },

  getImageContentSize: function() {
    return this.get('content.asset.size');
  },

  getImageSize: function() {
    return this.get('geometry.transform.size');
  },

  setSize: function(size, undoManager) {
    const previousSize = this.get('geometry.size');
    const previousTransformSize = this.get('geometry.transform.size');
    const previousTransformOffset = this.get('geometry.transform.offset');

    const xScale = size.width / previousSize.width;
    const yScale = size.height / previousSize.height;

    if (undoManager) {
      undoManager.startGroup();
    }

    // Set element size
    this.set('geometry.size', size, undoManager);

    // Scale asset transform size (aka resize)
    this.set('geometry.transform.size', {
      width: Math.round(previousTransformSize.width * xScale),
      height: Math.round(previousTransformSize.height * yScale),
    }, undoManager);

    // Scale asset transform offset (aka mask/crop)
    this.set('geometry.transform.offset', {
      left: Math.round(previousTransformOffset.left * xScale),
      top: Math.round(previousTransformOffset.top * yScale),
    }, undoManager);

    if (undoManager) {
      undoManager.endGroup();
    }
  },

  setImageSize: function(size0, um) {
    var size = JSON.parse(JSON.stringify(size0));
    if (typeof size.width === 'undefined') {
      size.width = this.get('geometry.transform.size.width');
    }
    if (typeof size.height === 'undefined') {
      size.height = this.get('geometry.transform.size.height');
    }
    this.set('geometry.transform.size', {
      width: Math.round(size.width),
      height: Math.round(size.height)
    }, um);
  },

  getCurrentActionValue: function() {
    return actionModel.getCurrentActionValue(this);
  },

  getImageOffsetRelToMask: function() {
    return this.get('geometry.transform.offset');
  },

  setImageOffsetRelToMask: function(offset0, um) {
    var offset = JSON.parse(JSON.stringify(offset0));
    if (typeof offset.top === 'undefined') {
      offset.top = this.get('geometry.transform.offset.top');
    }
    if (typeof offset.left === 'undefined') {
      offset.left = this.get('geometry.transform.offset.left');
    }
    var currentImageSize = this.getImageSize();
    this.set('geometry.transform.offset',
             {left: Math.max(Math.min(offset.left, 0), -currentImageSize.width),
              top: Math.max(Math.min(offset.top, 0), -currentImageSize.height)},
             um);
  },

  getTransformOffsetAdjustedForInnerBorder: function(breakpoint) {
    var offset = this.safeGet('geometry.transform.offset', breakpoint) || { left: 0, top: 0 };
    var borderLocation = this.safeGet('geometry.borderLocation', breakpoint);
    var borderApply = this.safeGet('geometry.borderApply', breakpoint);
    var borderWidth = this.safeGet('style.border.width', breakpoint) || 0;

    if (borderLocation === 'inside') {
      if (borderApply) {
        return {
          left: offset.left - (borderApply.left ? borderWidth : 0),
          top: offset.top - (borderApply.top ? borderWidth : 0),
        };
      } else {
        return {
          left: offset.left - borderWidth,
          top: offset.top - borderWidth,
        };
      }
    } else {
      return offset;
    }
  },

  getMaskSize: function() {
    return this.get('geometry.size');
  },

  setMaskSize: function(size0, um) {
    var size = JSON.parse(JSON.stringify(size0));
    if (typeof size.width === 'undefined') {
      size.width = this.get('geometry.size.width');
    }
    if (typeof size.height === 'undefined') {
      size.height = this.get('geometry.size.height');
    }
    // with safeguard to prevent the mask being larger than the image itself
      var imageSize = this.getImageSize();
    var offset = this.getImageOffsetRelToMask(); // .top, .left are always <=0
    var width = Math.min(size.width, imageSize.width + offset.left);
    var height = Math.min(size.height, imageSize.height + offset.top);
    this.set('geometry.size',
             {width: width, height: height},
             um);
  },

  getMaskOffset: function() {
    return this.getOffset();
  },

  setMaskOffset: function(offset0, um) {
    var offset = JSON.parse(JSON.stringify(offset0));
    if (typeof offset.top === 'undefined') {
      offset.top = this.getTop();
    }
    if (typeof offset.left === 'undefined') {
      offset.left = this.getLeft();
    }
    // this is the offset of the mask on the canvas relative to its parent
    this.set('geometry.offset',
             {top: offset.top, left: offset.left},
             um);
  },

  linksToExternalLightbox: function() {
    return this.safeGet('action.type') === 'externalLightbox' &&
      ! _.isEmpty(this.safeGet('content.link'));
  },

  isSvg: function() {
    return this.safeGet('content.asset.content_content_type') === 'image/svg+xml';
  },

  fetchAssetDimensions() {
    return fetchImageDimensions(this.get('content.asset.content_url'));
  },
});
