import PropTypes from 'prop-types';
import React from 'react';

const AlertDialogContent = ({
  message: message0,
  secondaryMessage: secondaryMessage0,
  icon: icon0,
  containerClass,
  content
}) => {
  const secondaryMessage = () => {
    if (secondaryMessage0) {
      return (
        <p className='modal-secondary'>
          {secondaryMessage0}
        </p>
      );
    }
  };

  const message = () => {
    if (message0) {
      return (
        <div className='modal-panel-description'>
          {message0}
          {secondaryMessage()}
        </div>
      );
    }
  };

  const icon = () => {
    if (icon0) {
      const classNames = `fa fa-exclamation-triangle publish-icon publish-${icon0}-icon`;
      return <div className={classNames} />;
    }
  };

  return (
    <div className={containerClass || 'modal-content-container'}>
      {icon()}
      {message()}
      {content}
    </div>
  );
};

AlertDialogContent.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  secondaryMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  icon: PropTypes.string,
  containerClass: PropTypes.string,
  content: PropTypes.element
};

export default AlertDialogContent;
