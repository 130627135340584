export const Messages = {
  SAVING_COLOR_ERROR:
    'This color couldn’t be saved. Please try again. If the problem continues, reach out to us at support@unbounce.com',
  REMOVING_COLOR_ERROR:
    'This color couldn’t be removed. Please try again. If the problem continues, reach out to us at support@unbounce.com',
  RETRIEVING_COLORS_ERROR:
    'Brand colors couldn’t be retrieved. Please try again. If the problem continues, reach out to us at support@unbounce.com',
  SAVING_COLOR_CONFLICT_ERROR:
    'Your brand colors have been updated on a another page. Please refresh to get the latest colors',
};
