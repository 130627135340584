jQuery = require 'jquery'
_      = require 'lodash'

Rx               = require 'ub_wrapped/rx'
ubBanzai         = require 'ub/control/banzai-features'
rxSwitchboard    = require 'ub/control/rx-switchboard'
videoHostMethods = require 'ub/video-hosts'

class VideoBackground

  constructor: (@containerElement) ->
    @getPropsFromModel()

    @elementClass = 'lp-pom-video-background'
    @elementId    = "#{ @containerElement.id }-video-background"

    @imageElement =
      jQuery '<div />'
        .addClass "#{ @elementClass }-image"
        .attr
          id: "#{ @elementId }-image"

    @iframeElement =
      jQuery '<iframe />'
        .addClass @videoHost
        .attr
          id              : "#{ @elementId }-iframe"
          src             : @videoHostMethods?.getIframeUrl @videoId
          frameborder     : 0
          allowfullscreen : ''

    @element = jQuery '<div />'
      .addClass @elementClass
      .attr
        id           : @elementId
        'data-ratio' : @videoRatio?.toFixed(3) or '1.7'
      .append @imageElement, @iframeElement


  getPropsFromModel: ->
    @videoId    = @containerElement.model.safeGet 'style.newBackground.video.videoId'
    @videoHost  = @containerElement.model.safeGet 'style.newBackground.video.videoHost'
    @videoRatio = @containerElement.model.safeGet 'style.newBackground.video.videoRatio'
    @videoImage = @containerElement.model.safeGet 'style.newBackground.video.videoImage'
    @videoHostMethods = videoHostMethods[@videoHost]


  getBorderRadiusRules: ->
    radius = @containerElement.model.safeGet 'geometry.cornerRadius'

    if _.isObject radius
      cssToModelPropMap =
        'border-top-left-radius'     : 'tl'
        'border-top-right-radius'    : 'tr'
        'border-bottom-left-radius'  : 'bl'
        'border-bottom-right-radius' : 'br'
      _.mapValues cssToModelPropMap, (modelAttr) -> @containerElement.page.getUnit(radius[modelAttr])

    else if _.isNumber(radius) and radius isnt 0
      borderWidth = 0
      if @containerElement.model.exists 'style.border.width'
        borderWidth = @containerElement.model.get 'style.border.width'
      return 'border-radius':  @containerElement.page.getUnit(radius - borderWidth)

    else
      'border-radius': 0


  isApplicable: ->
    Boolean(@containerElement.model.safeGet('style.newBackground.type') is 'video' and
      @videoId and @videoHostMethods)


  isOnRoot: ->
    @containerElement.type is 'lp-pom-root'



class EditVideoBackground extends VideoBackground

  constructor: ->
    super

    isVideoBackgroundsEnabled = @containerElement.page.getConfigValue('allowVideoBackgrounds')

    return if not isVideoBackgroundsEnabled and not @isApplicable()

    @iframeElement.remove()
    @element.prependTo @containerElement.getViewDOMElement()

    juiEvents = rxSwitchboard.subjects.jui_events
    juiEvents
      .filter @elementChangeEventFilter.bind this
      .merge Rx.Observable.fromEvent window, 'resize'
      .merge juiEvents.filter (ev) =>
        ev.type is 'activePageChanged' and ev.data?.current is @containerElement.page
      .merge juiEvents.filter (ev) ->
        ev.type in ['pageLoaded', 'breakpointChanged']
      .ub_subscribe @update.bind this


  elementChangeEventFilter: (ev) ->
    whitelistedAccessors = [
      'style.newBackground.video.videoId'
      'style.newBackground.video.videoImage'
      'style.newBackground.video.videoHost'
      'style.newBackground.video.videoRatio'
      'style.newBackground.video.fitWidthToPage'
      'style.newBackground.type'
      'geometry.cornerRadius'
    ]

    whitelistedEventTypes = [
      'resized'
    ]

    elementId = ev.data?.element?.id or ev.source?.element?.id or ev.id
    elementId is @containerElement.id and
      (ev.data?.accessor in whitelistedAccessors or ev.type in whitelistedEventTypes)


  getContainerAspectRatio: ->
    $rootElement = jQuery @containerElement.getRootElement().getViewDOMElement()
    $canvas      = jQuery window.editor.canvas.e

    width =
      if @isOnRoot() or @containerElement.isFitWidthToPage()
        $rootElement.width()
      else
        @containerElement.model.getWidth()

    height =
      if @isOnRoot()
        $canvas.height()
      else
        @containerElement.model.getHeight()

    width / height


  update: ->
    # Get fresh model values
    @getPropsFromModel()

    unless @isApplicable()
      @element.hide()
      return

    containerRatio = @getContainerAspectRatio()

    @imageElement
      .css do =>
        if containerRatio and not _.isNaN containerRatio
          if @videoRatio > containerRatio
            # Video is wider than element, cut off left and right
            ratioDifference = (@videoRatio / containerRatio) - 1
            positionRules =
              top    : 0,
              left   : - Math.ceil((ratioDifference / 2) * 100) + '%',
              height : '100%',
              width  : Math.ceil(100 + ratioDifference * 100) + '%'
          else
            # Element is wider than video, cut off top and bottom
            ratioDifference = (containerRatio / @videoRatio) - 1
            positionRules =
              top    : - Math.ceil((ratioDifference / 2) * 100) + '%',
              left   : 0,
              height : Math.ceil(100 + ratioDifference * 100) + '%',
              width  : '100%'

        _.merge {
          position   : 'absolute'
          background : "url(#{ @videoImage }) center center / cover"
        }, positionRules

    @element
      .show()
      .css do =>
        $rootElement = jQuery @containerElement.getRootElement().getViewDOMElement()
        $canvas      = jQuery window.editor.canvas.e

        positionRules =
          if @isOnRoot()
            position  : 'fixed'
            top       : $canvas.offset().top
            left      : $rootElement.offset().left
            height    : $canvas.height()
            width     : Math.min $rootElement.width(), $canvas.width()
            'z-index' : -1
            overflow  : 'hidden'
          else
            position : 'absolute'
            width    : '100%'
            height   : '100%'
            overflow : 'hidden'

        _.merge @getBorderRadiusRules(), positionRules



class PublishVideoBackground extends VideoBackground

  constructor: ->
    super

    return unless @isApplicable()

    @element.prependTo @containerElement.getViewDOMElement()

    rxSwitchboard.subjects
      .jui_events
      .filter (ev) -> ev.type is 'breakpointChanged'
      .ub_subscribe @addCSSRules.bind this


  addCSSRules: (ev) ->
    breakpoint = ev?.data
    @containerElement.applyPageStyles(
      _.union @getContainerRules(), @getIframeRules(breakpoint), @getImageRules()
    )


  getContainerRules: ->
    containerRules =
      if @isOnRoot()
        [
          {attribute: 'position', value: 'fixed'}
          {attribute: 'width',    value: '100%'}
          {attribute: 'height',   value: '100%'}
        ]
      else
        [
          {attribute: 'position',  value: 'absolute'}
          {attribute: 'width',     value: '100%'}
          {attribute: 'height',    value:  @containerElement.page.getUnit(@containerElement.model.getHeight())}
          {attribute: 'overflow',  value: 'hidden'}
          {attribute: 'transform', value: 'translateZ(0)'} # hack to hide border radius overflow
        ]

    borderRadiusRules = _.map(@getBorderRadiusRules(), (value, key) -> {attribute: key, value: value})

    containerRules
      .concat \
        {attribute: 'display', value: if @isApplicable() then 'block' else 'none'}
      .concat borderRadiusRules
      .map (rule) => _.merge rule, selector: "##{ @elementId }"


  getIframeRules: (breakpoint) ->
    [
      {attribute: 'display', value: if breakpoint is 'desktop' then 'block' else 'none'}
      {attribute: 'position', value: 'absolute'}
    ].map (rule) =>
      _.merge rule, selector: "##{ @elementId }-iframe"


  getImageRules: ->
    [
      {attribute: 'position', value: 'absolute'}
      {attribute: 'background', value: "url('#{ @videoImage }')"}
      {attribute: 'background-position', value: "center center"}
      {attribute: 'background-size', value: "cover"}
    ].map (rule) =>
      _.merge rule, selector: "##{ @elementId }-image"


module.exports =
  publish : PublishVideoBackground
  edit    : EditVideoBackground
