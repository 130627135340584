import _ from 'lodash';

const handleCheckedAttribute = (domElm, value) => {
  if (value) {
    // If value is false, do not set any attribute for 'checked'.
    // Any given value will set the checkbox as checked
    domElm.setAttribute('checked', 'checked');
  }
};

// tagName accepts any HTML element tag as a string
// for example: 'p', 'div', 'input', 'span'
//
// attributes accepts an object of HTML attributes
// { class: "my-class", for: "input", id: "my-id" }
const createDomElement = (tagName, attributes) => {
  const domElm = document.createElement(tagName);

  _.forEach(attributes, (value, attr) => {
    if (attr === 'checked') {
      handleCheckedAttribute(domElm, value);
    } else {
      domElm.setAttribute(attr, value);
    }
  });

  return domElm;
};

export default createDomElement;
