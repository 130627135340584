/* globals Class */
var _ = require('lodash');

window.jui.ModalDialog = Class.create(window.jui.ModalPanel, {
  initialize: function($super, options) {
    this.actionButtons = {};
    options = options || {};
    this.layoutRules = options.layoutRules;
    $super(options);
  },

  installUI: function($super) {
    $super();
    var self = this;

    this.titleBar = this.insert(new window.jui.Component({attributes: {className: 'title-bar modal-title-bar'}}));
    this.contentPanel = this.insert(new window.jui.Component({attributes: {className: 'content-panel modal-content-panel'}}));
    this.actionBar = this.insert(new window.jui.Component({attributes: {className: 'action-bar modal-action-panel'}}));
    this.title = this.titleBar.insert(new Element('h1', { className: 'modal-title-bar-title' }));

    this.titleBar.observe('mousedown', this.mouseDownListener);

    this.dialogScreen = this.insert(new window.jui.Component({attributes: {className: 'dialog-screen'}}));
    this.errorDialog = this.insert(new window.jui.Component({attributes: {className: 'error-dialog'}}));

    if(this.options.closeX) {

      var closeXEl = new Element('div').
        observe('click', function(){
          self.close();
         })
        .addClassName('modal-close-button');

      this.closeXButton = this.titleBar.insert(closeXEl);
    }

    this.errorText = this.errorDialog.insert(new Element('p', { className: 'modal-error-dialog' }));

    this.dialogScreen.hide();
    this.errorDialog.hide();

    if (typeof this.options.title !== 'undefined') {
      if (this.options.title === false) {
        this.contentPanel.setTop(3);
        this.titleBar.hide();
      }
      this.setTitle(this.options.title);
    }

    if (this.options.actions) {
      this.options.actions.each( function(action) {
        var type = action.type ? action.type : 'button';
        var elm, attr;

        if (type === 'button') {
          var additionalClasses = action.class || '';
          var className = 'button modal-button ' + additionalClasses;

          attr = _.merge({}, {className: className}, action.attributes);
          elm = new window.jui.Button({
            attributes: attr,
            label: action.label,
            action: action.action
          });
        } else if(type === 'checkbox') {
          attr = _.merge({}, {className: 'form-elm checkbox-input'}, action.attributes);
          elm = new window.jui.FormCheckboxInput({
            attributes: attr,
            label: action.label,
            onclick: action.action
          });
        }

        this.actionButtons[action.id] = elm;

        this.actionBar.insert(elm);
      }, this);
    }
  },

  setTitle: function(title) {
    this.title.update(title);
  },

  setContent: function(content) {
    this.contentPanel.update(content);
  },

  setActionBarContent: function(content) {
    this.actionBar.update(content);
  },

  updateLayout: function($super) {
    if (this.layoutRules) {
      this.layoutRules.method.call(this, this.layoutRules.options || {});
    } else {
      $super();
    }
  },

  showErrorDialog: function(errorText) {
    this.errorText.update(errorText);
    this.dialogScreen.show();
    this.errorDialog.show();
  },

  updateButton: function(whichButton, label, action) {
    this.actionButtons[whichButton].setLabel(label);
    if(action) {
      this.actionButtons[whichButton].setAction(action);
    }
  },

  close: function($super) {
    $super();
    this.dismissErrorDialog();
  },

  dismissErrorDialog: function() {
    this.dialogScreen.hide();
    this.errorDialog.hide();
    this.fireEvent('errorDialogDismissed');
  }
});

window.jui.ModalDialog.fitToViewPort = function(options) {
  var padding = options.padding || 2;
  this.setOffset({left:padding, top:padding});
  this.setDimensions({width:document.viewport.getWidth() - padding*2, height:document.viewport.getHeight() - padding*2});
};

window.jui.ModalDialog.centerInViewPort = function(options) {
  if (options.size) {
    this.setDimensions(options.size);
  }

  var loc = {
   left: (document.viewport.getWidth() - this.getWidth()) / 2,
   top: (document.viewport.getHeight() - this.getHeight()) / 2
  };

  this.setOffset(loc);
};
