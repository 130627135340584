/* globals editor */
var Rx = require('ub_wrapped/rx');
var jQuery = require('jquery');
var _ = require('lodash');

var ubBanzai = window.ubBanzai;

// This module 'upgrades' the old style.background.image field with a
// .size attribute that is required by the newBackground props and the
// parallax code.  This corrects bug LP-7712.

// It also adds a content_url to newBackground.{image,pattern}.image
// if that field is missing. Bug LP-7779 is the result of a bug in
// page-bundler that didn't correctly keep the old and new background
// props in sync when re-writing the image urls.

// It is called from lp.editorLoaded() after the editor object is
// initialized but before yielding control to the user.

(function() {
  var sizeCalculationStream = new Rx.Subject();

  var _hasBGImage = function(model) {
    return model.exists('style.background.image.content_url') &&
       ! _.isUndefined(model.safeGet('style.background.image.content_url'));
  };

  var _bgImageMissingSize = function(model) {
    return !model.exists('style.background.image.size');
  };

  var _hasBGImageWithoutSize = function(model) {
    return _hasBGImage(model) && _bgImageMissingSize(model);
  };

  var _getImageContainer = function(){
    var $imgContainer = jQuery('<div id="temp_img_container"></div>')
      .css({
        position: 'absolute',
        top: '100%',
        left: '100%'
      });
    $imgContainer.appendTo('body');
    return $imgContainer;
  };

  var _createImg = function(imgSrc) {
    var $img = jQuery('<img />')
      .attr('src', imgSrc);

    return $img;
  };

  var _allApplicableElements = function() {
    var applicableElementTypes = ['lp-pom-root', 'lp-pom-block', 'lp-pom-box']
      , page                   = editor.page;

    return _.flattenDeep(_.map(applicableElementTypes, function(type) {
      return page.getElementsByType(type);
    }));
  };

  var _allApplicableElementsWithMissingImgSize = function() {
    return _.filter(_allApplicableElements(), function(elm) {
      return _hasBGImageWithoutSize(elm.model);
    });
  };

  function _upgrade() {
    var newBGPropsHelper = window.lp.pom.NewBGPropsHelper
      , $imgContainer    = _getImageContainer();

    var elmsWithImgSize = _.reduce(_allApplicableElementsWithMissingImgSize(), function(res, elm) {
      return res.concat({
        element: elm,
        image: _createImg(elm.model.safeGet('style.background.image.content_url'))
      });

    }, []);

    // make sure we clean up the temp img holder and images inside
    sizeCalculationStream.subscribe(function(){
      $imgContainer.remove();
    });

    if (_.isEmpty(elmsWithImgSize)) {
      // Return false here because no images went through the upgrader
      sizeCalculationStream.onNext(false);
    } else {
      var observables = Rx.Observable
        .fromArray(elmsWithImgSize)
        .flatMap(function(obj) {

          $imgContainer.append(obj.image);
          var loadObs = Rx.Observable.fromEvent(obj.image, 'load');
          var errorObs = Rx.Observable.fromEvent(obj.image, 'error');

          return Rx.Observable.merge(loadObs, errorObs)
            .map(function() {
              return {
                element: obj.element,
                size: {
                  width: obj.image.width(),
                  height: obj.image.height()
                }
              };
            });
        });

      var loadEvents = new Rx.Subject();

      observables
        .bufferWithCount(elmsWithImgSize.length)
        .subscribe(function(bufferedEvents) {

          _.each(bufferedEvents, function(ev) {
            loadEvents.onNext(ev);
          });
          sizeCalculationStream.onNext(true);
        });

      loadEvents.subscribe(function(ev) {
        try {
          var model = ev.element.model
            , props = newBGPropsHelper.getProperties(model);

          //If we don't have an image here then short circuit.
          if(_.isEmpty(props.image)){return;}

          if (ubBanzai.features.isDebugModeEnabled()) {
            console.info('imageUpgrader:', 'fix size on ', model.element.id );
          }

          var oldImageUrl = model.safeGet('style.background.image.content_url');
          var newImageUrl = props.image.content_url;
          var contentURLProperties;

          if(oldImageUrl && oldImageUrl !== newImageUrl) {
            contentURLProperties = {content_url: oldImageUrl};
          } else {
            contentURLProperties = {};
          }

          var imageProps = _.merge({}, props.image, {size: ev.size}, contentURLProperties);
          newBGPropsHelper.setProperty(model, props.style, 'image', imageProps);
        } catch(error) {
          window.editor.reportError({
            error: error,
            id: window.editor.page.id,
            message: 'Error in image_size_upgrader',
            content: 'Error in image_size_upgrader',
            details: error.toString().truncate(300, ''),
            userAgent: window.navigator.userAgent
          });
        }
      });
    }
  }

  window.lp = window.lp || {};
  window.lp.imageUpgrader = {
    upgrade: _upgrade,
    sizeCalculationStream: sizeCalculationStream
  };
})();
