import { insertAnimationKeyframes } from './animation-effect';
import getDropShadowCss from './shadow-effect';

export function applyEffect(element) {
  const m = element.model;
  const options = {
    removeAttrs: [],
  };
  if (!m.exists('style.effect')) {
    return;
  }
  const effect = m.safeGet('style.effect');
  const viewElement = element.getViewDOMElement();

  let enabledTypes = effect.enabledTypes;

  if (typeof enabledTypes === 'undefined' || !Array.isArray(enabledTypes)) return;
  enabledTypes = enabledTypes.filter(type => type !== 'none');

  if (enabledTypes.length === 0) {
    options.removeAttrs = ['text-shadow', 'box-shadow'];
    applyStylesToPage(element, [], options);
    return;
  }
  enabledTypes.forEach(type => {
    switch (type) {
      case 'dropShadow':
        if (!enabledTypes.includes('animation')) {
          options.removeAttrs = ['animation'];
        }
        applyStylesToPage(
          element,
          [
            {
              attribute: element.type.includes('text') ? 'text-shadow' : 'box-shadow',
              value: getDropShadowCss(effect),
            },
          ],
          options
        );
        break;
      case 'animation':
        if (!effect.animationType || effect.animationType === 'none') {
          options.removeAttrs = ['animation'];
        }
        if (!enabledTypes.includes('dropShadow')) {
          options.removeAttrs = ['text-shadow', 'box-shadow'];
          applyStylesToPage(element, [], options);
        }
        viewElement.classList.add(`ub-ani-${effect.animationType}`);

        // insert keyframes only in edit mode, the keyframes are inserted in the page in preview/publish mode
        if (element.page.isEditMode()) {
          insertAnimationKeyframes(effect.animationType || 'none');
          viewElement.classList.add(`ub-ani-play`);
        }
        break;
      default:
        if (typeof window.lp !== 'undefined' && typeof window.lp.errorNotifier !== 'undefined') {
          window.lp.errorNotifier.captureMessage(`Invalid effect type: ${type}`);
        }
    }
  });
}

const applyStylesToPage = (element, rules, options) => {
  if (element.page.isEditMode()) {
    element.applyStylesToDom(rules, options);
  } else {
    // isPublishOrPreviewMode
    element.applyPageStyles(rules, options);
  }
};
