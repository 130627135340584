import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';

import styles from './LabelFlair.scss';

const LabelFlair = (props) => {
  return (
    <label className={clsx(styles.label, styles[props.type], styles[props.size])}>
      {props.children}
    </label>
  );
};

LabelFlair.propTypes = {
  /** Label Text */
  children: PropTypes.any.isRequired,
  /** Label Type (color) */
  type: PropTypes.oneOf(['new', 'notice', 'warn', 'error']),
  /** Label Size */
  size: PropTypes.oneOf(['small', 'medium', 'large'])
};

LabelFlair.defaultProps = {
  type: 'new',
  size: 'small'
};

export default LabelFlair;
