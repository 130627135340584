import _ from 'lodash';

const regularFontWeight = 400;
const regularFontStyle = "normal";

const weightNames = {
  100: 'Thin',
  200: 'Extra Light',
  300: 'Light',
  400: 'Regular',
  500: 'Medium',
  600: 'Semi-Bold',
  700: 'Bold',
  800: 'Extra Bold',
  900: 'Black'
};

export function cleanQuotes(fontString) {
  return fontString.replace(/'|"/g, '');
}

export function getFontWeight(variant) {
  if (variant === 'regular') {
    return regularFontWeight;
  }

  const weight = variant.replace(/\D/g, '');
  return _.isEmpty(weight) ? regularFontWeight : parseInt(weight, 10);
}

export function getFontStyle(variant) {
  if (variant === 'regular') {
    return regularFontStyle;
  }

  const style = variant.replace(/[0-9]/g, '');
  return _.isEmpty(style) ? regularFontStyle : style;
}

export function getDisplayName(name, weight, style) {

  function _capitalize(string) {
    const firstCharacter = 0;
    const subStringIndex = 1;
    return `${string.charAt(firstCharacter).toUpperCase()}${string.slice(subStringIndex)}`;
  }

  const namedWeight = weight ? weightNames[weight] : weightNames[regularFontWeight];

  let namedStyle = '';
  if (!_.isEmpty(style) && (style !== 'normal')) {
    namedStyle = _capitalize(style);
  }

  const removeMultiSpaces = / +(?= )/g;
  return `${name} ${namedWeight} ${namedStyle}`.replace(removeMultiSpaces, '').trim();
}

export function normalizeFontData(fontData) {
  const variants = _.map(fontData.variants, (variant) => {
    const fontWeight = getFontWeight(variant) || '';
    const fontStyle = getFontStyle(variant) || '';
    const displayName = getDisplayName(fontData.family, fontWeight, fontStyle);

    return {
      name: variant,
      fontWeight,
      fontStyle,
      displayName
    };
  });

  return {
    family: fontData.family,
    variants
  };
}
