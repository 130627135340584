/* globals Class, jui, $H */
module.exports = Class.create({

  initialize: function(widgetObj, removedWidgets) {
    this.widgetObject = widgetObj;
    if (Object.isUndefined(removedWidgets)) {
      removedWidgets = [];
    }
    this.setRemovedButtons(removedWidgets);
  },

  getWidget: function() {
    return this.widgetObject;
  },

  getLayout: function() {
    return {
      orientation: this.getOrientation(),
      countOrientation: this.getCountOrientation()
    };
  },

  setWidget: function(widgetObj) {
    this.widgetObject = widgetObj;
  },

  getWidgetBlockHtml: function() {
    if (this.isValid()) {
      return this.wrapInDivContainer(
        this.buildButtonArray(),
        [
          'widget_container',
          this.getOrientation(),
          this.getCountOrientation(),
          'widget-count-' + this.getButtons().length
        ].join(' ')
    );
    }
  },

  getScripts: function() {
    var scriptsArray = [];
    this.getWidget().buttons.each(function(button) {
      scriptsArray.push(this.getButton(button.name).
        getScript(this.getWidget().isActive));
    }, this);
    return scriptsArray;
  },

  getStyles: function() {
    var stylesArray = [];
    this.getWidget().buttons.each(function(button) {
      stylesArray.push(this.getButton(button.name).getStyle());
    }, this);
    return stylesArray;
  },

  getButton: function(buttonName) {
    return jui['social_widget_' + buttonName];
  },

  //Returns the button objects as an array.
  getButtons: function() {
    return this.getWidget().buttons.collect(function(button) {
      return this.getButton(button.name);
    }, this);
  },

  getButtonIndex: function(buttonName) {
    var index = -1;
    this.getWidget().buttons.each(function(button, i){
      if (button.name === buttonName) {
        index = i;
        return;
      }
    });
    return index;
  },

  getCalculatedSize: function() {
    var width             = 0,
        horizontalPadding = 0,
        height            = 0,
        verticalPadding   = 0,
        buttons           = this.getButtons(),
        widget            = this.getWidget();

    buttons.each(function(button) {
      var layout     = this.getButtonLayoutByName(button.name),
          buttonSize = button.getSizeByLayout(layout);

      if (layout.orientation === 'vertical') {
        // If vertical, set the width to the largest of the buttons and total the height
        width = buttonSize.width > width ? buttonSize.width : width;
        height += buttonSize.height;
      } else {
        // If horizontal, set the height to the largest of the buttons and total the width
        width += buttonSize.width;
        height = buttonSize.height > height ? buttonSize.height : height;
      }
    }, this);

    if (widget.orientation === 'horizontal') {
      horizontalPadding = 4 * (buttons.length - 1);
    }

    if (widget.orientation === 'vertical') {
      verticalPadding = 3 * (buttons.length - 1);
    }

    // Return width and height with a calculated buffer for the padding
    return {
      width  : width  + horizontalPadding,
      height : height + verticalPadding
    };
  },

  getOrientation: function() {
    return this.getWidget().orientation;
  },

  getCountOrientation: function() {
    return this.getWidget().countOrientation;
  },

  getButtonLayoutByName: function(buttonName) {
    var buttonObj = $H(this.widgetObject.buttons.find(function(b) {
      return buttonName === b.name;
    })).merge(this.getLayout()).toObject();
    return buttonObj;
  },

  getValueByWidgetNameAndField: function(widgetName, field) {
    var value = '';
    this.getWidget().buttons.each(function(widget) {
      if (widget.name === widgetName) {
        value = widget[ field ];
        return;
      }
    });
    return value;
  },

  setValueByWidgetNameAndField: function(widgetName, field, value) {
    this.getWidget().buttons.each(function(widget) {
      if (widgetName === widget.name) {
        widget[field] = value.replace(/"/g, '&#34;');
        return;
      }
    });
    return this.getWidget();
  },

  setLayout: function(layout) {
    //Set the layout of the widget block.
    var widget = this.getWidget();
    widget.countOrientation = layout.countOrientation;
    widget.orientation = layout.orientation;
    //Set the layout of each button.
    this.getWidget().buttons.each(function(widget) {
      this.setValueByWidgetNameAndField(
        widget.name, 'orientation', layout.orientation);
      this.setValueByWidgetNameAndField(
        widget.name, 'countOrientation', layout.countOrientation);
    }, this);
    return widget;
  },

  cleanup: function() {
    this.getWidget().buttons.each(function(button) {
      this.getButton(button.name).cleanup();
    }, this);
  },

  getURLByWidgetName: function(widgetName) {
    return this.getValueByWidgetNameAndField(widgetName, 'url').stripScripts();
  },

  setURLByWidgetName: function(widgetName, url) {
    return this.setValueByWidgetNameAndField(widgetName, 'url', url.stripScripts());
  },

  savedRemovedButtons: function(button) {
    this.removedButtons.push(button);
  },

  getRemovedButtons: function() {
    return this.removedButtons;
  },

  setRemovedButtons: function(buttonsArray) {
    this.removedButtons = buttonsArray;
  },

  isValid: function() {
    if ( ! this.validateOrientation()) {
      return false;
    }

    if ( ! this.validateCount(this.getCountOrientation())) {
      return false;
    }

    if ( ! this.validateButtons(this.getWidget().buttons)) {
      return false;
    }

    return true;
  },

  buildButtonArray: function() {
    var buttonArray = [];
    this.getWidget().buttons.each(function(button) {
      button.countOrientation = this.getCountOrientation();
      button.orientation      = this.getOrientation();
      button.isActive         = this.getWidget().isActive;
      var widget = this.getButton(button.name).getWidgetHtml(button);
      buttonArray.push(this.wrapInDivContainer(widget, button.name + '_container widget ' + button.orientation));
    }, this);
    return buttonArray;
  },

  validateOrientation: function() {
    var orientations = ['horizontal', 'vertical'];
    if ( ! orientations.include(this.getWidget().orientation)) {
      return false;
    }
    return true;
  },

  validateCount: function(countOrientation) {
    var countTypes = ['none', 'top', 'right'];
    if ( ! countTypes.include(countOrientation)) {
      return false;
    }

    return true;
  },

  validateButtons: function(buttons) {
    if ( ! Object.isArray(buttons)) {
      return false;
    }
    return true;
  },

  //Takes either a button string or an array of button strings.
  wrapInDivContainer: function(buttonArray, className) {
    if (typeof buttonArray === 'string') { buttonArray = [ buttonArray ]; }
    var temp = new Element('div');
    var widgetDiv = new Element('div', {
      className: className +  ' container'
    });
    temp.update(widgetDiv);
    buttonArray.each(function(buttonString) {
      widgetDiv.insert(buttonString);
    });
    return temp.innerHTML;
  }

});
