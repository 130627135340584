/* globals Class, rxSwitchboard */
window.jui.UndoManager = Class.create(window.jui.EventSource, {
  initialize: function() {
    this._traceExecute = rxSwitchboard.getPublisher("undo_manager_execute");
    // An index of all functions in either the undo_queue or the redo_queue
    this.actions = {};
    this.next_id = 1; // The next id to use for an index

    // FIFO queues for undo/redo
    this.undos = [];
    this.redos = [];

    // The currently used action (if undoing or redoing)
    this.current_action = null;

    // The current group (if one is active)
    this.group = null;

    // A stack of active groups, to allow for embedding
    this.groups = [];
    this.group_id = 0;

    // can be: '', 'undo', 'redo'
    this.performing = '';

    this.undoCount = 0; // The count of the available undos
    this.undo_names = []; // The last few names
    this.redoCount = 0; // The count of the available redos
    this.redo_names = [];

    this.enabled = true;
    },

  canUndo: function() {
    return this.undoCount > 0;
  },

  canRedo: function() {
    return this.redoCount > 0;
  },

  canUndoOrRedo: function() {
    return this.canUndo() || this.canRedo();
  },

  enable: function() {
    this.enabled = true;
  },

  disable: function() {
    this.enabled = false;
  },

  /**
   * Run an array of undoable functions
   */
  groupRunner: function(actions) {
    var id;
    while ((id = actions.pop())) {
      var action = this.actions[id];
      delete this.actions[id];
      this.executeAction(action);
    }
  },

  /**
   * Report the function used to undo the current action
   */
  // registerUndo: function(name, action, id, add_to_queue_overwrite) {
  registerUndo: function(action, add_to_queue_overwrite) {
    var id;
    if (!this.enabled || !action) {
      return;
    }

    if (window.editor) {
      action.page = window.editor.page;
    }

    if (this.group !== null) {
      // There is a group open, so add this to the group
      id = action.id || this.next_id++;
      // this.actions[id] = [name, func, parameters, context];
      this.actions[id] = action;
      this.group.params[0].push(id);
      return this;
    }

    var queue = this.performing === 'undo' ? this.redos : this.undos;

    // Get the index id
    id = action.id || this.next_id++;

    // Index the function
    this.actions[id] = action;

    if (!add_to_queue_overwrite) {
      // Push the function into the queue
      queue.push(id);
      // if (queue.length > this.settings.max_undo) {
      //  queue.shift();
      //  if (this.performing == 'undo') {
      //    this.redoCount--;
      //    this.redo_names.shift();
      //  } else {
      //    this.undoCount--;
      //    this.undo_names.shift();
      //  }
      // }

      if (this.performing === 'undo') {
        this.redoCount++;
        // this.redo_names.push(this.current_action[0]);
        // this.settings.redoChange();
        this.fireEvent('redoPerformed');
      } else {
        this.undoCount++;
        if (this.performing === 'redo') {
          // this.undo_names.push(this.current_action[0]);
        } else {
          // A new action means a new "path" so clear the redo queue
          this.clearRedoQueue();
          // this.undo_names.push(name);
        }
        // this.settings.undoChange();
        this.fireEvent('undoPerformed');
      }
    }

    return this;
  },

  /**
   * Undo the last action
   */
  undo: function() {
    if (this.undos.length > 0) {
      // There are undo available

      // If the next action will happen on a different page hold off on the action.
      // The condition below will switch the page instead undoing. Then the next time they
      // hit undo it will resume the proper action. This gives the user visiblity of the page so the
      // action is much more apparent.
      var actionId = this.undos.last();
      var currentAction = this.actions[actionId];

      if (currentAction.page && currentAction.page !== window.editor.page) {
        window.editor.setActivePage(currentAction.page);
        return;
      }

      // Next undo ID
      var id = this.undos.pop();
      this.undoCount--;
      this.undo_names.pop();

      // Pull from index
      var action = this.actions[id];
      delete this.actions[id];

      this.performing = 'undo';
      this.current_action = action;
      // 0 = name, 1 = function, 2 = parameters, 3 = context

      // Put a group around the undo - this means that the redo should only be one action too
      this.startGroup(action.name);
      this.executeAction(action);

      this.endGroup();
      delete this.current_action;
      this.performing = '';

      this.fireEvent('undoPerformed');
    }

    return this;
  },

  executeAction: function(action) {
    window.editor.errorNotifier.pushErrorContext({undo_manager_execute: true});
    try {
      this._traceExecute({type: 'undo_manager_execute',
                          action: action,
                          id: action.id,
                          name: action.name,
                          receiver: action.receiver,
                          params: action.params});
      action.action.apply(action.receiver, action.params);
    } catch (e) {
      var message = e.message ? e.message + ': ' : '';
      message += e.stack ? e.stack.toString() : '';

      window.editor.reportError({
        error: e,
        id: window.editor.mainPage.id,
        message: message,
        details: "Error during undo!",
        userAgent: window.navigator.userAgent
      });
    }
    window.editor.errorNotifier.popErrorContext();
  },

  clearUndoQueue: function() {
    for(var i = this.undos.length-1; i>=0; i--) {
      delete this.actions[this.undos[i]];
    }
    this.undos = [];
    this.undoCount = 0;
    this.redo_names = [];

    // this.settings.undoChange();
    this.fireEvent('undoPerformed');
    return this;
  },

  clearQueues: function() {
    this.actions = {};
    this.undos = [];
    this.undoCount = 0;
    this.undo_names = [];
    this.redos = [];
    this.redoCount = 0;
    this.redo_names = [];
    // this.settings.undoChange();
    this.fireEvent('undoPerformed');
    // this.settings.redoChange();
    this.fireEvent('redoPerformed');
    return this;
  },

  /**
   * Redo the last action undone
   */
  redo: function() {
    if (this.redos.length > 0) {
      // There are redo available

      // If the next action will happen on a different page hold off on the action.
      // The condition below will switch the page instead redoing. Then the next time they
      // hit redo it will resume the proper action. This gives the user visiblity of the page so the
      // action is much more apparent.
      var actionId = this.redos.last();
      var currentAction = this.actions[actionId];

      if (currentAction.page && currentAction.page !== window.editor.page) {
        window.editor.setActivePage(currentAction.page);
        return;
      }

      // Next redo ID
      var id = this.redos.pop();
      this.redoCount--;
      this.redo_names.pop();

      // Pull from index
      var action = this.actions[id];
      delete this.actions[id];

      this.performing = 'redo';
      this.current_action = action;
      // 0 = name, 1 = function, 2 = parameters, 3 = context

      // Group around the redo
      // this.startGroup(action[0]);
      this.startGroup(action.name);
      // action[1].apply(action[3], action[2]);
      this.executeAction(action);
      this.endGroup();
      delete this.current_action;
      this.performing = '';

      // this.settings.redoChange();
      this.fireEvent('redoPerformed');
    }

    return this;
  },

  /**
   * Clear the redo queue
   */
  clearRedoQueue: function() {
    for(var i = this.redos.length-1; i>=0; i--) {
      delete this.actions[this.redos[i]];
    }
    this.redos = [];
    this.redoCount = 0;
    this.redo_names = [];

    this.fireEvent('redoPerformed');
    return this;
  },

  /*
   * Groups
   */

  /**
   * Start a new group
   * @param name The group name
   * @return The group ID
   */
  startGroup: function(name) {
    if (this.group !== null) {
      this.groups.push(this.group);
    }

    this.group_id = this.next_id++;
    // this.group = [name, this.groupRunner, [[]], this, this.group_id];
    this.group = {action:this.groupRunner, receiver:this, name: name, id: this.group_id, params:[[]]};

    return this.group_id;
  },

  /**
   * End the last opened group
   */
  endGroup: function() {
    var grp = this.group;

    if (this.groups.length > 0) {
      this.group = this.groups.pop();
    } else {
      this.group = null;
    }

    // this.undoable.apply(this, grp);
    this.registerUndo(grp);
    return this;
  },

  /**
   * Exit the currently open queue;
   * @param rollback Should the current undos be ran (rollback changes)? (Default true)
   */
  exitGroup: function(rollback) {
    if (rollback !== false) {
        var grp = this.group;
      this.startGroup('rolling back');
      grp[1].apply(grp[3], grp[2]);
            this.exitGroup(false); // So the redos don't get added to the undo queue
    }
    if (this.groups.length > 0) {
      this.group = this.groups.pop();
    } else {
      this.group = null;
    }
    return this;
  },

  /**
   * Resume a previously ended group as long as it is still in the undo/redo queue
   * @param id The group ID
   */
  resumeGroup: function(id) {
      // TODO: What happens if it isn't in the queue? (invalid id)
    if (this.group !== null) {
      this.groups.push(this.group);
    }

    this.group = this.actions[id];
    this.group[5] = true;
    this.group_id = id;
    return this;
  }
});
