export default function getAutoscaleInsertion(page) {
  if (page.autoscale) {
    const breakpoints = page.getEnabledBreakpoints()
      .map(({ name, width }) => ({
        name,
        width,
        contentWidth: page.getDimensions(name).width,
      })).sort((a, b) => b.contentWidth - a.contentWidth);

    return {
      content: `<script>
        (function() {
          // Check for Responsive Content Script
          if (!window.ub || window.ub.autoscaleInitialized) {
            return;
          }
        
          window.ub.autoscaleInitialized = true;

          var MOBILE_EDGE_BUFFER = 16;
          var MAX_MOBILE_SCALE = 1.25;

          var breakpoints = ${JSON.stringify(breakpoints)};

          function debounce(fn) {
            var resizeTimer;
            
            return function() {
              if (resizeTimer) cancelAnimationFrame(resizeTimer);
              resizeTimer = requestAnimationFrame(fn);
            }
          }

          function resize() {
            var size = 1;

            // Width with scroll bar
            var windowWidth = window.innerWidth;

            // Width without scroll bar
            var documentWidth = document.documentElement.clientWidth;

            for (var i = 0; i < breakpoints.length; i++) {
              var breakpoint = breakpoints[i];
              if (windowWidth <= (breakpoint.width || Infinity)) {
                var maxScale = breakpoint.name === "mobile" ? MAX_MOBILE_SCALE : 1;
                var edgeBuffer = breakpoint.name === "mobile" ? MOBILE_EDGE_BUFFER : 0;

                size = Math.min((documentWidth - edgeBuffer) / breakpoint.contentWidth, maxScale);
              }
            }

            document.documentElement.style.setProperty('--scale', size);
          }
          
          var debouncedResize = debounce(resize);

          // Mutation observer ensures that autoscale works when elements are added or removed from the DOM
          var observer = new MutationObserver((mutationsList, observer) => {
            for (var i = 0; i < mutationsList.length; i++) {
              const mutation = mutationsList[i];
              if (mutation.type === 'childList' && (mutation.addedNodes.length > 0 || mutation.removedNodes.length > 0)) {
                debouncedResize();
                break;
              }
            }
          });

          // Initial resize
          resize();

          // Listen for resize events
          window.addEventListener('resize', debouncedResize);

          // Start observing the document body
          observer.observe(document.documentElement, { childList: true, subtree: true });

        })();
      </script>`,
      placement: 'head',
    };
  }
}
