import _ from 'lodash';
import fontServiceUtils from 'ub/fonts/font-service/utils-new';
import {items as webFonts} from '@unbounce/fonts.json';
import {normalizeFontData} from 'ub/fonts/font-service/font-data-normalizer';
import FontServiceManager from 'ub/fonts/font-service/manager';

const addToFontList = (fontString) => {
  return fontServiceUtils.getBaseFontFamily(fontString);
};

const getFontsFromButton = ({style}) => {
  if (style.fontFamily) {
    return addToFontList(style.fontFamily);
  } else {
    window.lp.errorNotifier.captureMessage('fontFamily missing from button element');
  }
};

const getFontsFromText = ({content}) => {
  return _.map(content.fonts, addToFontList);
};

const getFontsFromForm = ({style: {label, cbxlabel}}) => {
  const fonts = [];
  if (label) {
    fonts.push(addToFontList(label.font.family));
  }
  if (cbxlabel) {
    fonts.push(addToFontList(cbxlabel.font.family));
  }
  return fonts;
};

const elementFontExtractTypeMap = {
  'lp-pom-button': getFontsFromButton,
  'lp-pom-text': getFontsFromText,
  'lp-pom-form': getFontsFromForm
};

const elmsThatUseWebfonts = _.keys(elementFontExtractTypeMap);

const filterElementsWithFonts = ({type}) => {
  return _.includes(elmsThatUseWebfonts, type);
};

const getFontsFromElement = (element) => {
  return elementFontExtractTypeMap[element.type](element);
};

const getFullWebFontData = (fontName) => {
  const fontData = _.filter(webFonts, {'family': fontName});
  return normalizeFontData(_.head(fontData));
};

const getAllFontsFromPom = (elements) => {
  return _(elements)
    .filter(filterElementsWithFonts)
    .map(getFontsFromElement)
    .flattenDeep()
  // only return fonts from the allowed list
    .intersection(fontServiceUtils.webFontsNames)
    .uniq()
    .map(getFullWebFontData)
    .value();
};

const getAllFontsFromPage = (page) => {
  const {elements, settings} = page;
  const existingAddedFonts = settings.fonts || [];

  const fontsFromElement = getAllFontsFromPom(elements);

  return _(fontsFromElement)
    .concat(existingAddedFonts)
    .uniqBy('family')
    .value();
};

const flattenNestedElms = (jso) => {
  const nestedElms = _(jso.children)
    .map((childElm) => {
      return flattenNestedElms(childElm);
    })
    .flattenDeep()
    .value();

  return [jso, ...nestedElms];
};

const migrateFontsFromPasteData = (jso) => {
  const elements = flattenNestedElms(jso);
  const fontList = getAllFontsFromPom(elements);

  fontList.forEach((font) => {
    FontServiceManager.addFontFromData(font);
  });
};

const migrateFonts = (allPages) => {
  const newPagesData = _.cloneDeep(allPages);

  const allFontsInUse = _([allPages.mainPage, ...allPages.subPages])
    .map(getAllFontsFromPage)
    .flattenDeep()
    .compact()
    .uniqBy('family')
    .sortBy('family')
    .value();

  _.forEach([newPagesData.mainPage, ...newPagesData.subPages], (pageData) => {
    pageData.settings.fonts = allFontsInUse;
  });

  return newPagesData;
};

export default {
  getAllFontsFromPom,
  getAllFontsFromPage,
  migrateFonts,
  migrateFontsFromPasteData
};
