/* globals Class, $H */
var inlineColorSelector = require('../../components/inline-color-selector.jsx').default;
var tinycolor2 = require('tinycolor2');

window.jui.FormColorInput = Class.create(window.jui.Component, window.jui.ControlModel, {
  options: function($super, options){
    return $super($H({
      attributes:{className:'form-elm color-input'}
    }).merge(options));

  },

  initialize:function($super, options){
    $super(options);
    if (this.options.onfocus) {
      this.addListener('focus', this.options.onfocus);
    }

    if (this.options.onblur) {
      this.addListener('blur', this.options.onblur);
    }
  },

  installUI: function($super) {
    $super();
    this.defaultColor = 'cccccc';

    this.colorSelectorContainer = this.insert(new Element('div', { className:'color-input-group text-color' }));

    this.colorSelector = new inlineColorSelector({
      container: this.colorSelectorContainer, 
      color: this.defaultColor, 
      name: 'color-selector', 
      changeHandler: this.buttonColorChanged.bind(this), 
      changeProperty: 'colorChange', 
      label: this.options.label,
      borderSelector: this.options.borderSelector || false,
      disabled: this.disabled,
      showOpacity: false
    });

    this.colorSelector.render();
  },

  enable: function() {
    this.colorSelector.disabled = false;
    this.colorSelector.render();
  },

  disable: function() {
    this.colorSelector.disabled = true;
    this.colorSelector.render();
  },

  setValue: function(value) {
    // TODO: JS: validation
    this.colorSelector.color = value;
    this.colorSelector.render();
  },

  setSavedColor: function(value) {
    this.defaultColor = value;
  },

  getSavedColor: function() {
    return this.defaultColor;
  },

  getValue: function() {
    return this.colorSelector.color;
  },

  setButtonColor: function(v) {
    this.button.setColor(v);
  },

  onFocus: function(e) {
    this.memorizeCurrentColor();
    if(this.isEnabled()) {
      e.stop();
      this.fireEvent('focus', this);
    }
  },

  memorizeCurrentColor: function() {
    if(this.getValue().strip() !== '') {
      this.memorizedColor = this.getValue();
    }
  },

  buttonColorChanged: function(e) {
    this.setValue(tinycolor2(e.value).toHex());
    this.fireEvent('blur', this);
  },

  onkeydown: function(e) {
    if(Event.KEY_RETURN === e.keyCode) {
      this.blur();
      e.stop();
    }
  },

  onkeyup: function() {
    this.setButtonColor(this.getValue());
  },
});
