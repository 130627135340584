import * as http from '@unbounce/http-client';
import getCsrfToken from 'ub/control/get-csrf-token';

const env = window.gon.env || 'integration';

const config = {
  apiBaseUri: http.getApiBaseUri(env),
  authBaseUri: http.getAuthBaseUri(),
  csrfToken: getCsrfToken(),
  tokenType: 'jwt',
};

// Singleton
const apiClient = http.createApiClient(config);

export default apiClient;
