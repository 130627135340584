/* global jui, Class */
var _ = require('lodash');

var ubBanzai = window.ubBanzai;

window.lp.pom.VisibleElementModel = Class.create( window.lp.pom.ElementModel, {
  MIN_SCALE: 0.5,
  MAX_SCALE: 1.5,

  initialize: function($super, element, modelData){
    var self = this;
    $super(element, modelData);

    if (typeof self.modelData.geometry.visible === 'undefined') {
      self.modelData.geometry.visible = true;
    }

    if (typeof self.modelData.geometry.scale === 'undefined') {
      self.modelData.geometry.scale = 1;
    }

    var mobileScale = self.safeGet('breakpoints.mobile.geometry.scale');
    if (mobileScale && typeof mobileScale === 'string' && mobileScale !== 'fit') {
      self.modelData.breakpoints.mobile.geometry.scale = parseFloat(mobileScale);
    }

    // JS: if the visible attribute is not defined on the mobile breakpoint it should be set to true so it does not inherit from default
    var breakpoints = self.element.page.getBreakPoints();
    breakpoints.each(function(breakpoint) {
      if (breakpoint.default) {
        return;
      }

      if (!jui.isDefined(self.modelData, `breakpoints.${breakpoint.name}.geometry.visible`)) {
        jui.deepSet(self.modelData, `breakpoints.${breakpoint.name}.geometry.visible`, true);
      }
    });

    // set imageFixed to false on mobile
    if(!jui.isDefined(self.modelData, `breakpoints.mobile.style.background.imageFixed`)) {
      jui.deepSet(self.modelData, `breakpoints.mobile.style.background.imageFixed`, false);
    }

    self.addBreakpointModifyableAttributes({
      content: {
        html: true
      },
      geometry:{
        offset:{
          left:true,
          top:true
        },
        size:{
          width:true,
          height:true
        },
        contentWidth:true,
        scale:true,
        visible:true,
        keepCircular: true,
        padding: {
          left: true,
          right: true,
          top: true
        },
        borderLocation: true,
        borderApply: {
          top: false,
          right: false,
          left: false,
          bottom: false
        },
        cornerRadius: {
          tl: true,
          tr: true,
          bl: true,
          br: true
        }
      },
      style: {
        newBackground: {
          type: true,
          solidColor: {
            bgColor: true,
            opacity: true,
            fitWidthToPage: true
          },
          gradient: {
            baseColor: true,
            fromColor: true,
            toColor: true,
            autoGradient: true,
            reverseGradient: true,
            fitWidthToPage: true
          },
          image: {
            image: true,
            parallax: true,
            position: true,
            bgColor: true,
            isColorOverImage: true,
            opacity: true,
            fitToContainer: true,
            fitWidthToPage: true
          },
          pattern: {
            image: true,
            parallax: true,
            position: true,
            bgColor: true,
            isColorOverImage: true,
            opacity: true,
            tiling: true,
            fitToContainer: true,
            fitWidthToPage: true
          },
          video: {
            bgColor: true,
            opacity: true,
            fitWidthToPage: true
          }
        },
        background: {
          imageFixed: true,
          imageStretched: true,
          backgroundColor: true,
          savedBackgroundColor: true,
          gradient: {
            from: true,
            to: true
          },
          fillType: true,
          autoGradient: true,
          imageAboveColor: true,
          reverseGradient: true,
          opacity: true,
          //image: {
            //uuid: true,
            //unique_url: true,
            //content_url: true,
            //name: true
          //},
          backgroundPosition: true,
          backgroundRepeat: true
        },
        defaults: {
          linkDecoration: true,
          color: true,
          linkColor: true
        },
        border: {
          style: true,
          width: true,
          color: true
        }
      }
    });

    self.addBreakpointAllowDuplicateAttributes({
      style: {
        autoGradient: true,
        background: {
          imageStretched: true,
          imageFixed: true,
          imageAboveColor: true,
          backgroundColor: true,
          savedBackgroundColor: true,
          gradient: {
            from: true,
            to: true
          },
          autoGradient: true,
          reverseGradient: true,
          opacity: true,
          fillType: true,
          backgroundPosition: true,
          backgroundRepeat: true,
          //image: {
            //uuid: true,
            //unique_url: true,
            //content_url: true,
            //name: true
          //},
        },
        newBackground: {
          type: true,
          solidColor: {
            bgColor: true,
            opacity: true,
            fitWidthToPage: true
          },
          gradient: {
            baseColor: true,
            fromColor: true,
            toColor: true,
            autoGradient: true,
            reverseGradient: true,
            fitWidthToPage: true
          },
          image: {
            image: true,
            parallax: true,
            position: true,
            color: true,
            isColorOverImage: true,
            opacity: true,
            fitToContainer: true,
            fitWidthToPage: true
          },
          pattern: {
            image: true,
            parallax: true,
            position: true,
            color: true,
            isColorOverImage: true,
            opacity: true,
            tiling: true,
            fitToContainer: true,
            fitWidthToPage: true
          },
          video: {
            bgColor: true,
            opacity: true,
            fitWidthToPage: true
          }
        },
      },
      geometry:{
        visible:true,
        keepCircular: true,
        borderLocation: true,
        borderApply: {
          top: true,
          right: true,
          bottom: true,
          left: true
        }
      }
    });
  },

  isSettable: function(accessor, value) {
    if(accessor === 'style.border.style') {
      if(typeof value !== 'string') {
        var errMsg ='Attempting to set "' + accessor +
          '" to type "' + typeof value + '": ' + value;
        throw new TypeError(errMsg);
      }
    }
    return true;
  },

  setSize: function(size, undoManager) {
    this.unsetScaleToFit(undoManager);
    this._setSize({
      undoManager: undoManager,
      size: size,
      setSizeFn: elSize => this.setOnWritableModel('geometry.size', elSize),
    });
  },

  setSizeByBreakpoint: function(size, breakpoint, undoManager) {
    var self = this;
    this._setSize({
      undoManager: undoManager,
      size: size,
      setSizeFn: function(elSize) {
        self.setOnWritableModelByBreakpoint('geometry.size', elSize, breakpoint);
      }
    });
  },

  _setSize: function(options) {
    // TODO: refactor
    var readable    = this.getReadableModel()
      , previous    = jui.isDefined(readable, 'geometry.size') ? readable.geometry.size : {}
      , undoManager = options.undoManager
      , size        = options.size;

    if (undoManager) {
      undoManager.registerUndo({
        action:this.setSize,
        receiver:this,
        params:[previous, undoManager]
      });
    }

    if(options.setSizeFn) {
      options.setSizeFn(size);
    }

    if ((!Object.isUndefined(previous.width) && previous.width !== size.width) ||
        (!Object.isUndefined(previous.height) && previous.height !== size.height)) {
      this._fireEvent('attributeChanged', {accessor:'geometry.size', value:size, previous:previous});
    }

    if (this.isKeepCircular()) {
      this.makeCircular(undoManager);
    }
  },

  setWidth: function(width, undoManager) {
    var readable = this.getReadableModel();
    var previous = jui.isDefined(readable, 'geometry.size') ? readable.geometry.size : {};

    if (width === previous.width) {
      return;
    }

    if (undoManager) {
      undoManager.startGroup();
    }

    this.unsetScaleToFit(undoManager);

    this.set('geometry.size.width', width, undoManager);

    if (this.isKeepCircular()) {
      this.makeCircular(undoManager);
    }

    if (undoManager) {
      undoManager.endGroup();
    }
  },

  setHeight: function(height, undoManager) {
    var readable = this.getReadableModel();
    var previous = jui.isDefined(readable, 'geometry.size') ? readable.geometry.size : {};

    if (height === previous.height) {
      return;
    }

    if (undoManager) {
      undoManager.startGroup();
    }

    this.unsetScaleToFit(undoManager);

    this.set('geometry.size.height', height, undoManager);

    if (this.isKeepCircular()) {
      this.makeCircular(undoManager);
    }

    if (undoManager) {
      undoManager.endGroup();
    }
  },

  setOffset: function(offset, undoManager) {
    var self = this;
    this._setOffset({
      offset: offset,
      undoManager: undoManager,
      setOffsetFn: function(elOffset) {
        self.setOnWritableModel('geometry.offset', elOffset);
      }
    });
  },

  setOffsetByBreakpoint: function(offset, breakpoint, undoManager) {
    var self = this;
    this._setOffset({
      offset: offset,
      undoManager: undoManager,
      setOffsetFn: function(elOffset) {
        self.setOnWritableModelByBreakpoint('geometry.offset', elOffset, breakpoint);
      }
    });
  },

  _setOffset: function(options) {
    //TODO: Refactor
    var readable    = this.getReadableModel(),
        previous    = jui.isDefined(readable, 'geometry.offset') ? readable.geometry.offset : {},
        offset      = options.offset,
        undoManager = options.undoManager;

    if (undoManager) {
      undoManager.registerUndo({
        action:this.setOffset,
        receiver:this,
        params: [previous, undoManager]
      });
    }

    if(options.setOffsetFn) {
      options.setOffsetFn(offset);
    }

    if ((!Object.isUndefined(previous.left) && previous.left !== offset.left) ||
        (!Object.isUndefined(previous.top) && previous.top !== offset.top)) {
      this._fireEvent('attributeChanged', {accessor:'geometry.offset', value:offset, previous:previous});
    }
  },

  setLeft: function(left, undoManager) {
    //TODO: Refactor
    var readable = this.getReadableModel();
    var previous = jui.isDefined(readable, 'geometry.offset') ? readable.geometry.offset : {};

    if (undoManager) {
      undoManager.registerUndo({
        action:this.setLeft,
        receiver:this,
        params:[previous.left, undoManager]
      });
    }

    this.setOnWritableModel('geometry.offset.left', left);

    if (previous.left !== left) {
      //TODO: should the event receive previous.left?
      this._fireEvent('attributeChanged', {accessor:'geometry.offset.left', value:left, previous:previous});
    }
  },

  setTop: function(top, undoManager) {
    //TODO: Refactor
    var readable = this.getReadableModel();
    var previous = jui.isDefined(readable, 'geometry.offset') ? readable.geometry.offset : {};

    if (undoManager) {
      undoManager.registerUndo({
        action:this.setTop,
        receiver:this,
        params:[previous.top, undoManager]
      });
    }

    this.setOnWritableModel('geometry.offset.top', top);

    if (previous.top !== top) {
      //TODO: should the event receive previous.top?
      this._fireEvent('attributeChanged', {accessor:'geometry.offset.top', value:top, previous:previous});
    }
  },

  setScale(scale, undoManager) {
    this.set(
      'geometry.scale',
      Math.min(Math.max(scale, this.MIN_SCALE), this.MAX_SCALE),
      undoManager
    );
  },

  isScaleToFit() {
    return this.safeGet('geometry.scale') === 'fit';
  },

  unsetScaleToFit(undoManager) {
    if (this.isScaleToFit()) {
      this.setScale(this.getScale(), undoManager);
    }
  },

  setZIndex: function(zIndex, undoManager) {
    //TODO: Refactor
    var readable = this.getReadableModel();
    //TODO: should zindex be undefined like in the getter?
    var previous = jui.isDefined(readable, 'geometry.zIndex') ? readable.geometry.zIndex : 0;

    if (undoManager) {
      undoManager.registerUndo({
        action:this.setZIndex,
        receiver:this,
        params:[previous, undoManager]
      });
    }

    this.setOnWritableModel('geometry.zIndex', zIndex);

    if (previous !== zIndex) {
      this._fireEvent('attributeChanged', {accessor:'geometry.zIndex', value:zIndex, previous:previous});
    }
  },

  setMaxSize: function(size, undoManager) {
    //TODO: Refactor
    if (Object.isUndefined(this.modelData.geometry)){ return; }

    this.modelData.geometry.maxSize = this.getMaxSize();
    var previous = {};
    previous.width = this.modelData.geometry.maxSize.width;
    previous.height = this.modelData.geometry.maxSize.height;

    if (undoManager) {
      undoManager.registerUndo({
        action:this.setMaxSize,
        receiver:this,
        params:[previous, undoManager]
      });
    }

    this.modelData.geometry.maxSize = size;
    if (previous.width !== size.width || previous.height !== size.height) {
      this._fireEvent('attributeChanged', {accessor:'geometry.maxSize', value:size, previous:previous});
    }
  },

  setMinSize: function(size, undoManager) {
    //TODO: Refactor
    if (Object.isUndefined(this.modelData.geometry)){
      return;
    }
    var temp = this.getMinSize();
    var previous = {};
    previous.width = temp.width;
    previous.height = temp.height;

    if (undoManager) {
      undoManager.registerUndo({
        action:this.setMinSize,
        receiver:this,
        params:[previous, undoManager]
      });
    }

    this.setOnWritableModel('geometry.minSize', size);
    if (previous.width !== size.width || previous.height !== size.height) {
      this._fireEvent('attributeChanged', {accessor:'geometry.minSize', value:size, previous:previous});
    }
  },


  setMargin: function(margin, undoManager) {
    var propertyName = 'geometry.margin';
    var previous     = jui.deepQuery(this.getReadableModel(), propertyName) || {};
    var um           = undoManager || this.element.page.undoManager;

    this.set(propertyName, margin, um);
    this._fireEvent('attributeChanged', {
      accessor : propertyName,
      value    : margin,
      previous : previous
    });
  },

  setCustomClassnames: function(customClassnames, undoManager) {
    this.set('customClassnames', customClassnames, undoManager);
  },

  getCustomClassnames: function() {
    return this.safeGet('customClassnames') || '';
  },

  getCustomClassnamesArray: function() {
    return this.getCustomClassnames()
      .split(' ')
      .map(classname => classname.trim())
      .filter(Boolean);
  },

  // JS: Geometry property getters for better performance
  // Code calling these must be sure they exist first
  getSize: function() {
    return this.getReadableModel().geometry.size;
  },

  getMinSize: function() {
    return this.getReadableModel().geometry.minSize || {width:1, height:1};
  },

  getMaxSize: function() {
    return this.getReadableModel().geometry.maxSize || {width:100000, height:100000};
  },

  getWidth: function() {
    return this.getReadableModel().geometry.size.width;
  },

  getHeight: function(breakpoint) {
    return this.getReadableModel(breakpoint).geometry.size.height;
  },

  getOffset: function() {
    return {
      left : this.getLeft(),
      top  : this.getTop()
    };
  },

  getLeft: function() {
    var left = this.safeGet('geometry.offset.left');
    if (_.isUndefined(left) || _.isNull(left)) {
      return this.fixMissingProperty('geometry.offset.left', 0);
    } else {
      return left;
    }
  },

  getTop: function() {
    var top = this.safeGet('geometry.offset.top');
    if (_.isUndefined(top) || _.isNull(top)) {
      return this.fixMissingProperty('geometry.offset.top', 0);
    } else {
      return top;
    }
  },

  getScale: function() {
    if (this.isScaleToFit()) {
      const { parentElement } = this.element;

      if (!parentElement || parentElement.type !== 'lp-pom-block') {
        return 1;
      }

      const parentWidth = this.element.parentElement.model.getWidth();
      const width = this.getWidth();
      const scale = parseFloat(((parentWidth - 40) / width).toFixed(2));

      return Math.min(Math.max(scale, this.MIN_SCALE), 1);
    }

    return this.exists('geometry.scale') ? this.getReadableModel().geometry.scale : 1;
  },

  getZIndex: function() {
    return this.exists('geometry.zIndex') ? this.getReadableModel().geometry.zIndex * 1 : undefined;
  },

  getMargin: function() {
    return this.getReadableModel().geometry.margin;
  },

  isMaintainAR: function() {
    return this.exists('geometry.maintainAR') && this.get('geometry.maintainAR');
  },

  getImageAboveColor: function() {
    var imgPropertyKey = 'style.background.image';
    var propertyKey = 'style.background.imageAboveColor';
    if (this.exists(imgPropertyKey) && this.exists(propertyKey)) {
      return this.safeGet(propertyKey);
    } else if (this.exists(imgPropertyKey) && !this.exists(propertyKey)){
      return true; // TRUE ON EXISTING ELEMENTS
    } else {
      return false; // FALSE AS DEFAULT
    }
  },

  setImageAboveColor: function(setting) {
    return this.set('style.background.imageAboveColor', setting, this.element.page.undoManager);
  },

  isAutoGradient: function() {
    return this.exists('style.background.autoGradient') ? this.get('style.background.autoGradient'): true;
  },

  setAutoGradient: function(auto) {
    //TODO: Refactor
    var um = this.element.page.undoManager;
    um.startGroup();
    this.set('style.background.autoGradient', auto, um);
    if (auto) {
      if (!(this.exists('style.background.auto') && this.get('style.background.auto'))) {
        var gradient = this.calculateGradient(this.get('style.background.backgroundColor'));
        this.setGradientColor(gradient);
      }
    }
    um.endGroup();
  },

  isReverseGradient: function() {
    return this.exists('style.background.reverseGradient') ? this.get('style.background.reverseGradient'): false;
  },

  setBackgroundColor: function(color) {
    //TODO: Refactor
    var bgColPath = 'style.background.backgroundColor';
    var undoManager = this.element.page.undoManager;

    undoManager.startGroup();
    if(color !== 'transparent') {
      this.set('style.background.savedBackgroundColor', color, undoManager);
    }
    this.set(bgColPath, color, undoManager);
    if (this.isAutoGradient()) {
      var gradient = this.calculateGradient(color === 'transparent' ? this.getSavedBackgroundColor() : color);
      this.setGradientColor(gradient);
    }
    undoManager.endGroup();
  },

  hasBackgroundColor: function() {
    return this.exists('style.background.backgroundColor');
  },

  getBackgroundColor: function() {
    return this.exists('style.background.backgroundColor') ? this.get('style.background.backgroundColor') : 'transparent';
  },

  getOpacity: function(){
    if(this.getBackgroundColor() === 'transparent') {
      return 0;
    } else {
      //TODO extract this "default" value
      return this.exists('style.background.opacity') ? this.get('style.background.opacity') : 100;
    }
  },

  setOpacity: function(value) {
    this.set('style.background.opacity', value, this.element.page.undoManager);
  },

  shouldApplyBackgroundImage: function() {
    //over ride in child class
    return  true;
  },

  isBackgroundImageFixed: function() {
    return this.safeGet('style.background.imageFixed') || false;
  },

  isBackgroundImageStretched: function() {
    return this.safeGet('style.background.imageStretched') || false;
  },

  hasLargeBackgroundImage: function() {
    var key  = 'style.background.image.size'
      , size = ubBanzai.getFeatureValue('backgroundImageMinSize');
    return this.exists(key) &&
      (this.get(key).width > size || this.get(key).height > size);
  },

  setStretchBackgroundImage: function(value) {
    this.set('style.background.imageStretched', value, this.element.page.undoManager);
  },

  setFixedBackgroundImage: function(value) {
    this.set('style.background.imageFixed', value, this.element.page.undoManager);
  },

  getSavedBackgroundColor: function() {
    //TODO: Refactor
    if (this.exists('style.background.savedBackgroundColor')) {
      return this.get('style.background.savedBackgroundColor');
    }

    var defaultsStyle = this.element.getElementDefaults().style;

    if (defaultsStyle && defaultsStyle.background && defaultsStyle.background.backgroundColor) {
      return this.element.getElementDefaults().style.background.backgroundColor;
    }

    return 'fff';
  },

  getNonTransparentBackgroundColor: function() {
    var color = this.getBackgroundColor();
    if (color === 'transparent') {
      color = this.getSavedBackgroundColor();
    }
    return color;
  },

  getGradient: function() {
    //TODO: Refactor
    var g;
    if (this.isAutoGradient() || !this.exists('style.background.gradient')) {
      g = this.calculateGradient(this.getNonTransparentBackgroundColor());
    } else {
      g = this.getCustomGradient();
    }

    if (this.isReverseGradient()) {
      var to = g.to;
      g.to = g.from;
      g.from = to;
    }

    return g;
  },

  getCustomGradient: function() {
    var g = this.get('style.background.gradient');
    return {from:g.from, to:g.to};
  },

  getCurrentFillType: function() {
    var accessor = 'style.background.fillType';
    return this.exists(accessor) ? this.get(accessor) : 'solid';
  },

  isSolidFillType: function() {
    return this.getCurrentFillType() === 'solid';
  },

  isGradientFillType: function() {
    return this.getCurrentFillType() === 'gradient';
  },

  setFillType: function(fillType) {
    //TODO: Refactor
    if (fillType === this.getCurrentFillType()) {
      return;
    }

    var undoManager = this.element.page.undoManager;
    undoManager.startGroup();
    if (fillType === 'gradient') {
      this.set('style.background.backgroundColor', this.getSavedBackgroundColor(), undoManager);
    }
    this.set('style.background.fillType', fillType, undoManager);
    undoManager.endGroup();
  },

  setGradientColor: function(color) {
    if(this.getCurrentFillType() === 'gradient') {
      this.set('style.background.gradient', {
        from: color.from,
        to: color.to
      }, this.element.page.undoManager);
    }
  },

  calculateGradient: function(color) {
    return jui.ColorMath.calculateGradient(color);
  },

  setBorder: function(border, undoManager) {
    //TODO: Refactor
    if (undoManager) {
      undoManager.startGroup();
    }
    this.set('style.border', border.border, undoManager);
    this.set('geometry.borderApply', border.borderApply, undoManager);
    this.set('geometry.borderLocation', border.borderLocation, undoManager);

    if (this.isKeepCircular()) {
      this.makeCircular(undoManager);
    }

    if (undoManager) {
      undoManager.endGroup();
    }
  },

  hasBorder: function() {
    return !this.exists('style.border.style') ||
      this.get('style.border.style') !== 'none';
  },

  setCornerRadius: function(radius, undoManager) {
    //TODO: Refactor
    if (this.isKeepCircular() && undoManager) {
      undoManager.startGroup();
    }

    if(_.isPlainObject(radius)) {
      //If we don't have a cornerRadius we assume that there is none and set it to zero.
      var currentRadius = this.safeGet('geometry.cornerRadius') || 0;
      if(_.isNumber(currentRadius)) {
        currentRadius = this._initializeRadiusObj(currentRadius);
      }

      radius = _.merge({}, currentRadius, radius);
    }

    this.set('geometry.cornerRadius', radius, undoManager);

    if (this.isKeepCircular()) {
      this.keepCircular(false, undoManager);
      if (undoManager) {
        undoManager.endGroup();
      }
    }
  },

  _initializeRadiusObj: function(radius) {
    return {
     tr: radius,
     tl: radius,
     bl: radius,
     br: radius
    };
  },

  isKeepCircular: function() {
    return this.exists('geometry.keepCircular') && this.get('geometry.keepCircular') === true;
  },

  keepCircular: function(keepCircular, um) {
    //TODO: Refactor
    if (keepCircular) {
      if (um) {
        um.startGroup();
      }

      this.set('geometry.keepCircular', true, um);
      this.makeCircular(um);

      if (um) {
        um.endGroup();
      }
    } else {
      this.set('geometry.keepCircular', false, um);
    }
  },

  makeCircular: function(um) {
    //TODO: Refactor
    var size = this.getSize();
    var radius = Math.round(Math.min(size.width, size.height) * 0.5);
    if (this.exists('geometry.borderLocation') && this.get('geometry.borderLocation') === 'outside' &&  this.exists('style.border.width')) {
      radius += this.get('style.border.width');
    }
    this.set('geometry.cornerRadius', radius, um);
  },

  isVisible: function(breakpointName) {
    return this.exists('geometry.visible') ?
      this.get('geometry.visible', this.element.page.getBreakpointByName(breakpointName)) : true;
  },

  setVisible: function(visible, um) {
    this.set('geometry.visible', visible, um);
  },

  ///////////////////////////////////////////////////////////////////

  getNewBackgroundProperties: function() {
    var acceptedElementTypes = ['lp-pom-root', 'lp-pom-block', 'lp-pom-box', 'lp-pom-text'];
    if ( _.includes(acceptedElementTypes, this.element.type) ) {
      return window.lp.pom.NewBGPropsHelper.getProperties(this);
    } else {
      throw new Error('Not a valid element type - should be ' + acceptedElementTypes.join(',') );
    }
  },

  getNewBackgroundStyleType: function() {
    // This will only return a value after the upgrader has been run and if
    // the element type supports new backgrounds
    var accessor = 'style.newBackground.type';
    if (this.exists(accessor)) {
      return this.get(accessor);
    } else {
      return null;
    }
  },

  setNewBackgroundStyleType: function(style) {
    window.lp.pom.NewBGPropsHelper.setStyleType(this, style);
  },

  setNewBackgroundProp: function(style, property, val) {
    window.lp.pom.NewBGPropsHelper.setProperty(this, style, property, val);
  },

  hasParallax: function() {
    var attr = 'style.background.imageFixed'
      , desktopBreakpoint = this.element.page.getBreakpointByName('desktop');

    return this.existsOnDefaultModel(attr) && this.get(attr, desktopBreakpoint);
  }
});
