const getDropShadowCss = ({ color, opacity, offset, blurRadius }) => {
  // offset-x, offset-y, blur-radius, color(must include opacity in color)
  const color1 = '#' + color + opacityHex(opacity);

  return offset.left + 'px ' + offset.top + 'px ' + blurRadius + 'px ' + color1 + ' !important';
};

const opacityHex = opacity => {
  const decimalValue = Math.round((opacity * 255) / 100);

  if (opacity < 7) {
    return '0' + decimalValue.toString(16).toUpperCase();
  }
  return decimalValue.toString(16).toUpperCase();
};

export default getDropShadowCss;
