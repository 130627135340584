/* globals lp */
/* eslint-disable prefer-rest-params, prefer-spread */
var Rx = require('rx');
var _ = require('lodash');

var banzai = require('ub/control/banzai-features');

//////////////////////////////////////////////////
// helper code to put into a utils module:
Rx.Subject.prototype.safePublish = function(x) {
  //TODO: rename with x_ prefix
  // an exception catching wrapper around Rx.Subject.onNext
  try {
    this.onNext(x);
  } catch (err) {
    if (banzai.features.isDebugModeEnabled()) {
      if (this.name) {
        console.trace('error on subscriber of ' + this.name + ': ' + err);
      }
      throw err;
    } else {
      // TODO: once we are confident this error reporting doesn't throw errors itself
      // remove this try catch.
      try {
        var tags = {
          fn: 'Rx.Subject.safePublish'
        };
        if (this.name) {
          tags.rx_subject = this.name;
        }
        lp.errorNotifier.captureException(err, {
          tags: tags
        });
      } catch (_e2) {
        console.log('exception in error reporting from Rx.Subject.safePublish');
        // swallow the exception
      }
    }
  }
};

var _convertToSubscriberFn = function(origFn) {
  var isTestEnv = window.gon && window.gon.env === 'test';

  return function errorEatingSubscriberFn() {
    try {
      origFn.apply(this, arguments);
    } catch (err) {
      if (banzai.features.isDebugModeEnabled()|| isTestEnv) {
        console.group("%cException caught in ub_subscribe", "color:yellow; background:red; font-size: 13pt");
        console.log(err);

        if (err.stack) {
          _.forEach(err.stack.split('\n'), function(ln) {
            console.log(ln);
          });
        }

        console.groupEnd();
        throw err;
      } else {
        console.trace(err, err.stack);
        lp.errorNotifier.captureException(err);
      }
    }
  };
};

Rx.Observable.prototype.ub_log = function(prefix) {
  return this.do(function(ev) {
    if (prefix) {
      console.log(prefix, ev);
    } else {
      console.log(ev);
    }
  });
};

Rx.Observable.prototype.ub_filterOn = function(attr, val) {
  return this.filter(function(ev) {
    if (! _.isUndefined(val)) {
      return _.isEqual(ev[attr], val);
    } else {
      return Boolean(ev[attr]);
    }
  });
};

Rx.Observable.prototype.ub_multiFilterOn = function(attr, vals) {
  return this.filter(function(ev) {
    return _.includes(vals, ev[attr]);
  });
};

Rx.Observable.prototype.ub_subscribe = function(observerOrOnNext) {
  if (typeof observerOrOnNext === 'object' && observerOrOnNext.onNext) {
    observerOrOnNext.onNext = _convertToSubscriberFn(observerOrOnNext.onNext.bind(observerOrOnNext));
    return this.subscribe(observerOrOnNext);
  } else if (typeof observerOrOnNext === 'function') {
    var args = [_convertToSubscriberFn(observerOrOnNext)].concat(Array.prototype.slice(arguments).slice(1));
    return this.subscribe.apply(this, args);
  } else {
    throw new TypeError('not a valid observer or callback');
  }
};

module.exports = Rx;
