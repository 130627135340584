import _ from 'lodash';

import utils from './utils';
import utilsNew from './utils-new';
import googleFontsApi from './google/api';
import googleFontVariants from './google/variants';

const EMPTY_VALUE = 0;

const cloneTextElements = (elements) => {
  return elements
    .filter((elm) => {
      const type = elm.getType();
      return type === 'lp-pom-form' || type === 'lp-pom-text';
    })
    .map((elm) => {
      return elm.view.e.cloneNode(true);
    });
};

const generateTextWrapperElement = (textElements) => {
  const textContainer = document.createElement('div');
  const firstTextEl = _.head(textElements);
  if (!document.body.contains(firstTextEl)) {
    textContainer.style.display = 'none';
    _.forEach(textElements, (element) => {
      textContainer.appendChild(element);
    });
  }
  const parentElement = document.body.querySelector('.lp-pom-root') || document.body;
  parentElement.appendChild(textContainer);
  return textContainer;
};

const getInUseFontsFromElements = (textElements) => {
  const computedFontStyles = textElements.map(utilsNew.getComputedFontStyles);
  const googleFonts = [];
  const externalFonts = [];
  computedFontStyles.forEach((fontData) => {
    if (googleFontsApi.isGoogleFont(fontData.family)){
      googleFonts.push(fontData);
    } else if (!utilsNew.isSystemFont(fontData.family)){
      externalFonts.push(fontData);
    }
   });
  const googleFontList = googleFonts.map(googleFontVariants.findFontVariant);
  const mergedFontList = utilsNew.mergeFontObjs(googleFontList);
  const uniqFontWeightList = utilsNew.removeDuplicateWeights(mergedFontList);

  const externalFontsList = externalFonts.reduce((fontList, externalFont) => {
    const {family, weight} = externalFont;
    if (!fontList[family]) {
      fontList[family] = [weight];
    } else {
      fontList[family].push(weight);
    }
    return fontList;
  }, {});
  const uniqExternalFontsList = utilsNew.removeDuplicateWeights(externalFontsList);

  return { uniqFontWeightList , uniqExternalFontsList };
};

const storeTextWebFontsInUse = (page) => {
  const textElements = cloneTextElements(page.elements);

  // The DOM elements of the subpages aren't mounted when out of view so we can't
  // get the computed styles of them without first mounting them to the document
  const textContainer = generateTextWrapperElement(textElements);

  // We're spreading the elements out here rather than when adding them to the wrapped
  // div so they can keep their inheritance from parent elements in the DOM
  const spreadElements = textElements
    .map((element) => {
      return element.querySelectorAll('*');
    })
    .reduce((accumulator, nextArray) => {
      return [...accumulator, ...nextArray];
    }, []);

  const { uniqFontWeightList , uniqExternalFontsList } = getInUseFontsFromElements(spreadElements);
  // Clean up wrapper from DOM
  textContainer.remove();

  page.settings.webFontsInUse = uniqFontWeightList;
  page.settings.webFontsExternalInUse = uniqExternalFontsList;
};

const isWebFont = (font) => {
  return Boolean(utils.findValidFontName(font));
};

const getWebfontFamilies = (fonts) => {
  return fonts
    .filter(isWebFont)
    .map(font => `'${font}${utils.formattedWeights}'`);
};

const generateFontScriptTag = webFonts => {
  if (webFonts.length > EMPTY_VALUE) {
    return `<script>window.ub.page.webFonts = [${webFonts.join(',')}];</script>`;
  } else {
    return '';
  }
};

const getFormStyles = (element) => {
  const model = element.model;
  const labelStyles = model.safeGet('style.label.font');
  const checkboxRadioStyles = model.safeGet('style.cbxlabel.font');

  return [labelStyles, checkboxRadioStyles];
};

const getButtonStyles = (element) => {
  const buttonStyle = element.model.safeGet('style');

  return {
    family: buttonStyle.fontFamily,
    style: buttonStyle.fontStyle,
    weight: buttonStyle.fontWeight,
    textStyles: buttonStyle.textStyles
  };
};

const getFontDataFromElement = (element) => {
  const elType = element.type;

  if (elType === 'lp-pom-form') {
    return getFormStyles(element);
  } else if (elType === 'lp-pom-button') {
    return getButtonStyles(element);
  }
};

const getButtonAndFormWebFonts = (elements) => {
  const validElements = [
    'lp-pom-form',
    'lp-pom-button'
  ];

  return _(elements)
    .filter((el) => {
      return _.includes(validElements, el.type);
    })
    .map(getFontDataFromElement)
    .flattenDeep()
    .filter((fontData) => {
      return googleFontsApi.isGoogleFont(fontData.family);
    })
    .map(googleFontVariants.findFontVariant)
    .value();
};

const getPageDefaultFont = (defaultFont = {}) => {
  const {family, weight} = defaultFont;

  return googleFontsApi.isGoogleFont(family) ? { [family]: [weight] } : {};
};

const oldGenerateFontLoadScriptContent = (fonts) => {
  const webFonts = getWebfontFamilies(utils.generateFontList(fonts));

  return generateFontScriptTag(webFonts);
};

const newGenerateFontLoadScriptContent = ({elements, settings}) => {
  const textFontList = settings.webFontsInUse;
  const buttonAndFormFonts = getButtonAndFormWebFonts(elements);
  const defaultPageFont = getPageDefaultFont(settings.defaultFont);

  const allWebFonts = [...buttonAndFormFonts, textFontList, defaultPageFont];

  const mergedFontList = utilsNew.mergeFontObjs(allWebFonts);
  const formattedWebFonts = utilsNew.formatWebFonts(mergedFontList);

  return generateFontScriptTag(formattedWebFonts);
};

const getWebFontsLinkTag = (fonts) => {
  const href = utils.buildFontList(fonts);

  if (_.isEmpty(href)) {
    return '';
  } else {
    return `<link rel="stylesheet" type="text/css" href="${href}" />`;
  }
};

const generateFontFacesScripts = (fontFacesContent) => {
  return fontFacesContent.map((fontFaces) => {
    return `<style>\n${fontFaces.fontFacesStyle}\n</style>`;
  }).join('\n');
};

export default {
  oldGenerateFontLoadScriptContent,
  newGenerateFontLoadScriptContent,
  getWebFontsLinkTag,
  storeTextWebFontsInUse,
  generateFontFacesScripts,
};
