var pomUpdates = require('ub_legacy/lp/pom/updates/updates');

(function() {
  var elements;

  function partition(arr, begin, end, pivot) {
   var piv=arr[pivot];
   swap(arr, pivot, end-1);
   var store=begin;
   var ix;
   for(ix=begin; ix<end-1; ++ix) {
     if(topMost(arr[ix], piv) === piv) {
       swap(arr, store, ix);
       ++store;
     }
   }
   swap(arr, end-1, store);

   return store;
  }

  function swap(arr, a, b) {
      var tmp = arr[a];
      arr[a] = arr[b];
      arr[b] = tmp;
   }

  function qsort(arr, begin, end) {
    if(end-1>begin) {
      var pivot=begin+Math.floor(Math.random()*(end-begin));

      pivot=partition(arr, begin, end, pivot);

      qsort(arr, begin, pivot);
      qsort(arr, pivot+1, end);
    }
  }

  function correctZIndexes(jso) {
    elements = jso.elements;
    var elms = [];

    jso.elements.each(function(e) {
      if (typeof e.geometry !== 'undefined' && typeof e.geometry.zIndex !== 'undefined') {
        elms.push(e);
      }
    });

    qsort(elms, 0, elms.length);

    elms.each(function(e, i) {
      var found = jso.elements.find(function(elm) {
        return elm.id === e.id;
      });

      found.geometry.zIndex = (i+1);
    });
  }

  function topMost(elm1, elm2) {
    /* jshint maxcomplexity: 15 */
    if (elm1.page !== elm2.page || elm1.parentElement === null || elm2.parentElement === null) {
      return;
    }

    var e1 = elm1;
    var e2 = elm2;
    var d1 = getRootDistance(elm1);
    var d2 = getRootDistance(elm2);

    if (d1 < d2) {
      e2 = getAncestorAtDistance(elm2, d1);
      if (e2 === e1 || d1 < 2) {
        return elm2;
      }
    } else if (d2 < d1) {
      e1 = getAncestorAtDistance(elm1, d2);
      if (e2 === e1 || d2 < 2) {
        return elm1;
      }
    }

    var z1 = e1.geometry.zIndex || 0;
    var z2 = e2.geometry.zIndex || 0;

    if (z1 > z2) {
      return elm1;
    }

    if (z2 > z1) {
      return elm2;
    }

    var i1 = elements.indexOf(e1);
    var i2 = elements.indexOf(e2);

    return (i1 > i2) ? elm1 : elm2;
  }

  function getRootDistance(elm, distance) {
    distance = distance || 0;
    if (elm.type === 'lp-pom-root') {
      return distance;
    }

    distance++;
    var parentElement = getElementById(elm.containerId);
    if (typeof parentElement !== 'undefined') {
      return getRootDistance(parentElement, distance);
    }
    return null;
  }

  function getAncestorAtDistance(elm, distance) {
    var current = getRootDistance(elm);
    if (distance > getRootDistance(elm) || distance < 0) {
      return null;
    }

    var iterator = function(elm, dist) {
      if (dist === distance) {
        return elm;
      }
      var parentElement = getElementById(elm.containerId);
      return iterator(parentElement, --dist);
    };
    return iterator(elm, current);
  }

  function getElementById(id) {
    return elements.find(function(e){return e.id === id;});
  }

  pomUpdates.add(Object.extend({
    minVersion:'1.6',
    version:'1.7',
    run: function(jso) {
      if (this.checkVersion(jso)) {
        correctZIndexes(jso);
        jso.version = this.version;
      }
    }
  }, window.lp.pom.updater));
})();
