"use strict";
/*
 This function is used to validate HTML added by customers in custom HTML elements, Javascripts, and
 stylesheets.

 Rather than checking the validity of the HTML syntax per se, which would require a backend service
 or a call to the W3C Markup Validator Web Service API, we simply want to check that the HTML will
 not break the customer's published page.

 By "break" we really mean the HTML swallowing all of the markup that comes after it. So, to test
 this, we place a canary element after the custom HTML, use DOMParser to parse the whole thing to a
 DOM, and then query the document to ensure that the canary element has been created. This catches
 things like unclosed script/style/iframe tags (e.g. `<iframe>`) and mismatched attribute quotes
 (e.g. `<a href="foo'></a>`).

 See tests/unit/data/validate-html.spec.js for more cases and CN-3333 and CN-3332 for more context.
*/
Object.defineProperty(exports, "__esModule", { value: true });
function validateHtml(html) {
    var domParser = new window.DOMParser();
    try {
        return (domParser
            .parseFromString("<html><body><div>".concat(html, "</div><div id=\"ub-canary\"></div></body></html>"), 'text/html')
            .body.querySelector('#ub-canary') !== null);
    }
    catch (error) {
        console.warn('validateHtml:', error);
        return false;
    }
}
exports.default = validateHtml;
