/* globals lp,Class */
var createSandboxIframe = require('ub/elements/video/sandbox-iframe.js').default;
var videoValidation = require('ub/elements/video/video-validation.js').default;

var VideoElement = Class.create(
  lp.pom.VisibleElement,
  lp.ModuleComponent,
  {
    type:'lp-pom-video',

    initView: function() {
      this._createEmbedContainer();
      this._createOverlay();
      this._insertEmbedCode();
    },

    _createEmbedContainer: function() {
      this.content = new Element(
        'div', { className: 'lp-video-container' }
      );
      this.getViewDOMElement().insert(this.content);
    },

    _createOverlay: function() {
      if( this.page.isEditMode() ) {
        this.overlay = new Element(
          'div', { className: 'lp-video-overlay' }
        ).setOpacity( 0.75 );
        this.view.insert( this.overlay );
      }
    },

    doubleClick: function() {
      var self = this;
      this.getModule().openBuilder( this, {
        callback: function(data) {
          self.model.setVideoContent(data.html, self.page.undoManager);
          self._insertEmbedCode();
        }
      } );
    },

    getModelClass: function() {
      return lp.module.video.VideoModel;
    },

    modelChanged: function($super, e) {
      var details  = $super(e)
        , accessor = details.accessor;

      if(accessor === 'content.html') {
        this._insertEmbedCode();
      }
    },

    _insertEmbedOnPublishOrPreview: function(contentHTML) {
      this.getViewDOMElement().innerHTML = '';
      this.page.addInsertion(contentHTML, 'div#' + this.model.get('id'));
    },

    _insertEmbedOnEditMode: function(contentHTML) {
      while (this.content.firstChild) {
        this.content.removeChild(this.content.firstChild);
      }

      var codeState = videoValidation.validate(contentHTML);

      if (codeState.sandbox) {
        var sandboxFrame = createSandboxIframe(contentHTML);

        this.content.appendChild(sandboxFrame);
      } else {
        contentHTML = contentHTML
          .stripScripts()
          .gsub(/on[a-zA-Z_ ]+=/, function (match) {
            return '_' + match;
          });

        this.content.update(contentHTML);
      }
    },

    _insertEmbedCode: function () {
      var contentHTML = this.model.getVideoContent();

      if (this.page.isPublishOrPreviewMode()) {
        this._insertEmbedOnPublishOrPreview(contentHTML);
      } else {
        this._insertEmbedOnEditMode(contentHTML);
      }

      this.contentInserted = true;
    },

    getVideo: function() {
      var videoObj = this.content.select( 'iframe' ).first() ||
        this.content.select( 'object' ).first() || this.content.select( 'video' ).first();
      if(!videoObj){
        throw new Error('Not a valid video type');
      }
      return videoObj;
    },

    deactivate: function( $super ) {
      if( this.overlay.getStyle( 'display' ) !== 'block' ) {
        this.resetVideo();
        this.overlay.style.display = 'block';
      }
     $super();
    },

    resetVideo: function() {
      var obj = this.getVideo();
      obj.src = obj.src;
    }
  }
);

VideoElement.elementDefaults = {
  name: 'Embed Video',
  geometry:{
    position:"absolute",
    offset:{top:0,left:0},
    size: {width: 400, height: 300}
  },
  content:{
    html: ''
  }
};

module.exports = VideoElement;
