/* globals Class, jui */
var _ = require('lodash');

window.lp.pom.Element = Class.create(jui.EventSource, {
  initialize: function(page, jso){
    // JS: jso is the raw js model object that containes this elements attributes
    this.page = page;
    // JS: copy some attributes from the model data for easier access
    // **note model attributes should be set with this.model.set(accessor) so that the model can fire attribute change events
    // options.attributes.id =
    this.id = jso.id || (this.type+'-'+page.getNextElementId());
    this.containerId = jso.containerId;
    this.name = jso.name;
    this._attached = false;

    // JS: we call getModelClass so that implmenting classes can overide to provide own model class if they want
    this.model = new (this.getModelClass())(this, jso);
    this.model.addListener('attributeChanged', this.modelChanged.bind(this));
    this.modelChangeHandlers = [];

    this.parentElement = null;
    this.childElements = [];

    this.activateOnInsert = true;
    this.updateElementTreeOnInsert = true;
    this.insertTasks = [];

    // JS: these are default values that can be overridden in the model
    // if they are undefined then they should be considered false.
    // constraints are queryable throught the element.is method
    this.defaultConstraints = {};

    this.installListeners();
    this.createDefaultConstraints();
  },

  LP_POM_ELEMENT: true,

  createDefaultConstraints: function() {
    this.defaultConstraints.removable = true;
    this.defaultConstraints.copyable  = true;
    this.defaultConstraints.renamable = true;
  },

  installListeners: function() {
    this.page.addListener('POMReady', this);
  },

  uninstallListeners: function() {
    this.page.removeListener('POMReady', this);
  },

  getModelClass: function() {
    return window.lp.pom.ElementModel;
  },

  getModule: function() {
    return window.lp.getModule(this.type);
  },

  getType: function() {
    return this.type;
  },

  isTypeOf: function(type) {
    return this.getType().toLowerCase() === type.toLowerCase();
  },

  getElementDefaults: function(){
    return this.getModule().getElementDefaults();
  },

  getUndoManager: function() {
    return this.page.undoManager;
  },

  exists: function(a){return this.model.exists(a);},
  get: function(a){return this.model.get(a);},
  set: function(a,v){return this.model.set(a,v);},
  del: function(a){return this.model.deleteAttr(a);},

  is: function(constraint) {
    return this.model.exists('constraints.'+constraint) ? this.model.get('constraints.'+constraint) : !!this.defaultConstraints[constraint];
  },

  isCopyable: function() {
    if (!this.is('copyable')) {
      return false;
    }

    for (var i=0, len=this.childElements.length; i< len; i++) {
      if (!this.childElements[i].isCopyable()) {
        return false;
      }
    }

    return true;
  },

  hasView: function() {
    return typeof this.getView() !== 'undefined';
  },

  setName: function(name, undoManager) {
    this.model.set('name', name, undoManager);
  },

  getName: function() {
    return this.model.exists('name') ? this.model.get('name') : null;
  },

  addModelChangeHandler: function(handler) {
    this.modelChangeHandlers.push(handler);
  },

  modelChanged: function(e){
    var accessor = e.data.accessor;
    var value = e.data.value;
    var previous = e.data.previous;

    var arr = accessor.split('.');
    var attr = arr.pop();
    var base = arr.join('.');

    for (var i=0, len=this.modelChangeHandlers.length; i< len; i++) {
      this.modelChangeHandlers[i].call(this, accessor, value, previous, base, attr);
    }

    if (accessor.startsWith('name')) {
      this.name = value;
    }

    return {accessor:accessor, value:value, base:base, attr:attr};
  },

  pageContext: function(mode) {
    return this.page.context === window.lp.pom.context[mode];
  },

  _attach: function() {
    this._attached = true;
    for (var i=0,len=this.childElements.length;i< len;i++) {
      this.childElements[i]._attach();
    }
  },

  _detach: function() {
    this._attached = false;
    for (var i=0,len=this.childElements.length;i< len;i++) {
      this.childElements[i]._detach();
    }
  },

  activate: function() {
    this.fireEvent('activated');
  },

  deactivate: function() {
    this.fireEvent('deactivated');
  },

  setParentElement: function(elm) {
    this.parentElement = elm;

    const containerId = elm ? elm.id : null;
    this.containerId = containerId;
    this.model.modelData.containerId = containerId;
  },

  getParentElement: function() {
    return this.parentElement || this.page.getElementById(this.containerId);
  },

  handleRemoval: function(removalFunc) {
    /* jshint unused:vars */
    return false;
  },

  getRootElement: function() {
    if (this.type === 'lp-pom-root') {
      return this;
    }

    if (this.containerId !== null) {
      return this.page.getElementById(this.containerId).getRootElement();
    }
    return null;
  },

  getRootDistance: function(distance) {
    distance = distance || 0;
    if (this.type === 'lp-pom-root') {
      return distance;
    }

    distance++;
    if (this.parentElement !== null) {
      return this.parentElement.getRootDistance(distance);
    }
    return null;
  },

  getAncestorAtDistance: function(distance) {
    var current = this.getRootDistance();
    if (distance > this.getRootDistance() || distance < 0) {
      return null;
    }

    var iterator = function(elm, dist) {
      if (dist === distance) {
        return elm;
      }
      return iterator(elm.parentElement, --dist);
    };

    return iterator(this, current);
  },

  hasAncestor: function(elm) {
    if (this.parentElement === null) {
      return false;
    }

    if (elm === this.parentElement) {
      return true;
    }

    return this.parentElement.hasAncestor(elm);
  },

  changeParent: function(newParent) {
    this.parentElement.removeChildElement(this);
    newParent.insertChildElement(this);
    this.fireEvent('parentChanged', this);
  },

  insertChildElement: function(elm, options){
    options = options || {};
    elm.setParentElement(this);

    if ( ! _.isUndefined(options.containerIndex) &&
        options.containerIndex < this.childElements.length) {

      this.childElements.splice(options.containerIndex, 0, elm);

    } else {
      this.childElements.push(elm);
    }

    if (this._attached) {
      elm._attach();
    }

    this.fireEvent('elementInserted', elm);

    return elm;
  },

  removeChildElement: function(elm) {
    var elmIndex = this.childElements.indexOf(elm);

    if (elmIndex === -1) {
      throw new Error("Element not found");
    }
    this.childElements.splice(elmIndex, 1);
    elm._detach();

    this.fireEvent('elementRemoved', elm);
    return elm;
  },

  getChildElements: function() {
    return this.childElements;
  },

  getIndex: function() {
    return this.page.indexOfElement(this);
  },

  getIndexWithinVisibleElements: function() {
    return this.page.getVisibleElements().indexOf(this);
  },

  getNextIndex: function() {
    return this.getIndex() + 1;
  },

  indexOfChildElement: function(elm) {
    return this.childElements.indexOf(elm);
  },

  removeFromPage: function(um) {
    for (var i=this.childElements.length-1; i>=0; i--) {
      this.childElements[i].removeFromPage();
    }
    var fromParent = this.parentElement;
    var parentIndex = fromParent ? fromParent.indexOfChildElement(this) : null;

    if (this.parentElement !== null) {
      this.parentElement.removeChildElement(this);
      this.setParentElement(null);
    }

    var index = this.getIndex();

    this.page.removeElement(this, fromParent);

    var undoManager = um || this.page.undoManager;
    undoManager.registerUndo({
      action: this.page.insertElement,
      receiver: this.page,
      params: [this, {
        container: fromParent,
        dontActivateOnInsert: true,
        index: index,
        containerIndex: parentIndex,
        insertZIndex: true
      }]
    });

    this.destroy();
  },

  destroy: function() {
    this.fireEvent('destroyed');
  },

  hasGoals: function() {
    return this.getGoals().length > 0;
  },

  canHaveGoals: function() {
    return false;
  },

  getGoals: function() {
    return [];
  },

  isActiveGoal: function() {
    return this.getGoals().some(function(goal) {
      return this.page.goals && this.page.goals.isGoalActive(goal);
    }.bind(this));
  },

  hasScripts: function() {
    return false;
  },

  getScripts: function() {
    return null;
  },

  POMReady: function() {
    // over ride in sub class
  },

  toJSO: function(){
    // var modelData = this.model.getModelData();

    // PB-2337: This is a side-effect hidden inside a getter method. It modifies
    //          the model without going through the model setters And it is, in
    //          fact, a "lie" about what it is. This is not `jso` at all, just
    //          the raw modelData only after it has been modified.
    this.model.modelData.id = this.id;
    this.model.modelData.containerId = this.containerId;
    this.model.modelData.type = this.type;

    return this.model.modelData;
  }
});
