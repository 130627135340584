import padStart from 'lodash/padStart';
import path from 'path-browserify';
import parseUrl from 'url-parse';

import banzai from 'ub/control/banzai-features';
import planFeatures from 'ub/plan-features.js';
import {
  getGlobalImageQuality,
  getMaxImageQuality,
} from 'ub/ui/properties-panel/images/image-quality-helper';

const imageServiceUrl = banzai.getFeatureValue('imageOptimizationServiceUrl');
const TOTAL_PIXEL_LIMIT = 25000000; // 25 megapixels

function isTransformableMimeType(mimeType) {
  return mimeType === 'image/jpeg' || mimeType === 'image/png';
}

export function buildPublishUrl(asset, rawTransforms) {
  const transforms = sanitizeTransforms(asset, rawTransforms);
  const url = `/publish${asset.unique_url || asset.content_url}`;

  if (
    !isTransformableMimeType(asset.content_content_type) ||
    Object.keys(transforms).every(key => transforms[key] === null)
  ) {
    return url;
  }

  // Make relative URL absolute
  const assetUrlObj = parseUrl(url, window.location.href);

  // Append a hash of the transformations to the asset's filename
  const assetPathObj = path.parse(assetUrlObj.pathname);
  const hashedAssetPath = path.format({
    dir: assetPathObj.dir,
    name: `${assetPathObj.name}_${getTransformHash(transforms)}`,
    ext: assetPathObj.ext,
  });
  const hashedAssetUrl = `${assetUrlObj.protocol}//${assetUrlObj.host}${hashedAssetPath}`;

  return `${imageServiceUrl}/${encodeURIComponent(hashedAssetUrl)}?${buildQueryString(transforms)}`;
}

// eslint-disable-next-line complexity
function sanitizeTransforms(asset, transforms) {
  // Any of these values can be null
  const { w, h, cropW, cropH, cropX, cropY, q, png8 } = transforms;

  const assetSize = asset.size || {};

  const isValidResize =
    w && h && (Math.round(w) !== assetSize.width || Math.round(h) !== assetSize.height);

  const isValidCrop =
    cropW &&
    cropH &&
    cropX !== null &&
    cropY !== null &&
    (Math.round(cropW) !== Math.round(w) || Math.round(cropH) !== Math.round(h));

  const sanitizedQuality = Math.max(Math.min(Number(q || 100), 100), 1);
  const isJpeg = asset.content_content_type === 'image/jpeg';
  const isPng = asset.content_content_type === 'image/png';

  // Replace any no-op transform props with `null` so they can be omitted from image service query
  // string
  return {
    w: isValidResize ? Math.round(w) : null,
    h: isValidResize ? Math.round(h) : null,
    cropW: isValidCrop ? Math.round(cropW) : null,
    cropH: isValidCrop ? Math.round(cropH) : null,
    cropX: isValidCrop ? Math.abs(Math.round(cropX)) : null,
    cropY: isValidCrop ? Math.abs(Math.round(cropY)) : null,
    q: isJpeg && sanitizedQuality < 100 ? sanitizedQuality : null,
    png8: isPng && png8 ? true : null,
  };
}

// Exported for tests only
export function getTransformHash(transforms) {
  const { w, h, cropW, cropH, cropX, cropY, q, png8 } = transforms;

  return [
    w,
    h,
    cropW,
    cropH,
    cropX,
    cropY,
    // 'png8' and 'q' are mutually exclusive so they can be collapsed into one part of the hash
    png8 ? 80 : q,
  ].reduce(
    (acc, value) => acc + padStart((value || 0).toString(36), 3, '0'),
    // Images are cached on CloudFront based on this hash with a TTL of 1 year. This first character
    // can be incremented if we need to start generating different hashes, e.g. if we make upstream
    // improvements to image compression
    '1'
  );
}

function buildQueryString(transforms) {
  return Object.keys(transforms)
    .filter(key => transforms[key] !== null)
    .map(key => `${key}=${transforms[key]}`)
    .join('&');
}

function getBackgroundCropPosition(model, resizedSize, cropSize, breakpoint) {
  const [positionX, positionY] = (
    model.safeGet('style.background.backgroundPosition', breakpoint) || 'center center'
  ).split(' ');

  const overflowX = Math.max(0, resizedSize.width - cropSize.width);
  const overflowY = Math.max(0, resizedSize.height - cropSize.height);

  let left;
  if (positionX === 'left') {
    left = 0;
  } else if (positionX === 'right') {
    left = overflowX;
  } else {
    // center
    left = overflowX / 2;
  }

  let top;
  if (positionY === 'top') {
    top = 0;
  } else if (positionY === 'bottom') {
    top = overflowY;
  } else {
    // center
    top = overflowY / 2;
  }

  return {
    left: Math.floor(left),
    top: Math.floor(top),
  };
}

function getElementSize(element, breakpoint) {
  if (element.type === 'lp-pom-root') {
    return {
      width: element.model.get('geometry.contentWidth', breakpoint),
      height: Math.max(element.calculateHeightWithTopPadding(), 1080),
    };
  } else {
    // The VisibleElementModel and subclass getSize methods do not take a breakpoint, so unlike
    // other model `get`s in this module, this relies on the page's getCurrentBreakpoint being set
    // to the correct breakpoint before getBackgroundImageTransforms is called.
    // TODO: Refactor getSize methods to take a breakpoint or re-implement each element type's
    // getSize here.
    return element.model.getSize();
  }
}

// eslint-disable-next-line complexity
export function getBackgroundImageTransforms(assetSize, element, breakpoint, scale) {
  const { type, model } = element;

  const isRootElement = type === 'lp-pom-root';
  const isFullWidth = model.safeGet('geometry.fitWidthToPage', breakpoint);
  const isFixed = model.safeGet('style.background.imageFixed', breakpoint);
  const isCover = model.safeGet('style.background.imageStretched', breakpoint);

  const elementSize = getElementSize(element, breakpoint);
  const elementAspectRatio = elementSize.width / elementSize.height;
  const assetAspectRatio = assetSize.width / assetSize.height;

  let cropSize;
  let resizedSize;

  if (isCover) {
    // isCover/"fit to container" causes the image zoom to vary to cover the width and height of the
    // element (maintaining the aspect ratio). We resize the image such that it will cover the
    // element, and crop away any non-visible areas.

    // When isFullWidth/"stretch to edges" is also enabled, the element has a width of 100% of the
    // viewport. Mobile has a defined width (600px), but desktop does not, so we're picking a
    // viewport width value that should be large enough for the majority of desktop displays. For
    // users who have a larger display than this value, the visual appearance of the background
    // image will still be correct, but it will be scaled up to cover their viewport.

    // When isFixed/"parallax" is also enabled (desktop only), we do the same resizing but do not
    // crop the non-visible horizontal areas because these can become visible as the user scrolls.
    // Parallax is not allowed on mobile so we do not need to set an additional maximum viewport
    // height for mobile.
    const displaySize = {
      width: isFullWidth || isFixed || isRootElement ? breakpoint.width || 1920 : elementSize.width,
      height: isFixed ? 1080 : elementSize.height,
    };
    const displayAspectRatio = displaySize.width / displaySize.height;

    if (displayAspectRatio > assetAspectRatio) {
      const resizedWidth = Math.min(displaySize.width, assetSize.width);
      cropSize = {
        width: resizedWidth,
        height: isFixed
          ? resizedWidth / assetAspectRatio
          : resizedWidth / Math.max(assetAspectRatio, elementAspectRatio),
      };

      resizedSize = {
        width: resizedWidth,
        height: resizedWidth / assetAspectRatio,
      };
    } else {
      const resizedHeight = Math.min(displaySize.height, assetSize.height);
      cropSize = {
        width: isFixed
          ? resizedHeight * assetAspectRatio
          : resizedHeight * Math.min(assetAspectRatio, displayAspectRatio),
        height: resizedHeight,
      };

      resizedSize = {
        width: resizedHeight * assetAspectRatio,
        height: resizedHeight,
      };
    }
  } else {
    // When isCover/"fit to container" is disabled, the image shown at 100% zoom. We cannot resize
    // the image, because doing so would affect visual appearance. But we do crop away non-visible
    // areas.

    // When isFullWidth/"stretch to edges" is also enabled, the element has a width of 100% of the
    // viewport. Mobile has a defined width (600px), but desktop does not, so on desktop we keep the
    // full width of the original image and do not do any horizontal cropping, because doing so
    // would cause the image to 'run out' on displays wider than the cropped width.

    // When isFixed/"parallax" is also enabled (desktop only), we do not do any cropping in either
    // dimension because doing so would cause the image to 'run out' at certain scroll positions on
    // displays larger than the cropped dimensions.
    if (isFixed) {
      cropSize = assetSize;
    } else {
      cropSize = {
        width: Math.min(
          isFullWidth || isRootElement ? breakpoint.width || assetSize.width : elementSize.width,
          assetSize.width
        ),
        height: isRootElement ? assetSize.height : Math.min(elementSize.height, assetSize.height),
      };
    }

    resizedSize = assetSize;
  }

  const cropPosition = getBackgroundCropPosition(model, resizedSize, cropSize, breakpoint);

  const globalQuality = getGlobalImageQuality(element);
  const modelQuality = model.safeGet('style.newBackground.image.quality') || globalQuality;

  return {
    w: resizedSize.width * scale,
    h: resizedSize.height * scale,
    cropW: cropSize.width * scale,
    cropH: cropSize.height * scale,
    cropX: cropPosition.left * scale,
    cropY: cropPosition.top * scale,
    q: modelQuality.globalOverride ? modelQuality.value : globalQuality.value,
    png8: modelQuality.globalOverride ? modelQuality.compressPng : globalQuality.compressPng,
  };
}

export function getMaxResolutionAvailable(assetSize, resizedSize) {
  if (!assetSize || !resizedSize) {
    return 1;
  }

  return Math.min(assetSize.width / resizedSize.width, assetSize.height / resizedSize.height);
}

function getScalesToPublish(assetSize, mimeType, resizedSize) {
  if (
    !assetSize ||
    !resizedSize ||
    !planFeatures.isFeatureEnabled('retinaImages') ||
    !isTransformableMimeType(mimeType)
  ) {
    return [1];
  }

  const ratio = Math.max(
    assetSize.width / resizedSize.width,
    assetSize.height / resizedSize.height
  );

  // This is more forgiving than getMaxResolutionAvailable: for example if the original image is
  // between 1 and 2 times larger than the element, we will still publish the 2x version. Even
  // though it will not be a true 2x, it will still be clearer on hi-res displays than just using
  // the 1x.
  if (ratio > 2) {
    return [1, 2, Math.min(ratio, 3)];
  } else if (ratio > 1) {
    return [1, ratio];
  } else {
    return [ratio];
  }
}

function getScaleProperties(scale, assetSize, resizedSize) {
  // These ratios scale each transform property to the desired resolution while ensuring that the
  // image will not be upscaled/enlarged in either dimension if the user has made the image element
  // larger than the original image asset. This prevents us from generating and serving
  // unnecessarily large images - instead we will let the browser upscale the image to the desired
  // element size on the published page.
  const scaleX =
    assetSize && assetSize.width ? Math.min(scale, assetSize.width / resizedSize.width) : scale;
  const scaleY =
    assetSize && assetSize.height ? Math.min(scale, assetSize.height / resizedSize.height) : scale;

  return { scaleX, scaleY };
}

// Setting crop to false will bypass the image service cropping parameters since the builder applies its own cropping.
// This ensures that canvas images aren't cropped twice.
export function getImageElementPublishUrls(element, breakpoint, crop = true, maxQuality = false) {
  const { model } = element;

  const asset = model.get('content.asset', breakpoint);
  const scales = getScalesToPublish(
    asset.size,
    asset.content_content_type,
    model.get('geometry.transform.size', breakpoint)
  );

  const globalQuality = getGlobalImageQuality(element);
  const modelQuality = maxQuality && isTransformableMimeType(asset.content_content_type)
    ? getMaxImageQuality(element)
    : model.safeGet('geometry.transform.quality') || globalQuality;

  const resizedSize = model.get('geometry.transform.size', breakpoint);
  const cropSize = model.get('geometry.size', breakpoint);
  const cropPosition = model.getTransformOffsetAdjustedForInnerBorder(breakpoint);

  return scales.map(scale => {
    const { scaleX, scaleY } = getScaleProperties(scale, asset.size, resizedSize);

    const transforms = {
      w: resizedSize.width * scaleX,
      h: resizedSize.height * scaleY,
      cropW: crop ? cropSize.width * scaleX : null,
      cropH: crop ? cropSize.height * scaleY : null,
      cropX: crop ? cropPosition.left * scaleX : null,
      cropY: crop ? cropPosition.top * scaleY : null,
      q: modelQuality.globalOverride ? modelQuality.value : globalQuality.value,
      png8: modelQuality.globalOverride ? modelQuality.compressPng : globalQuality.compressPng,
    };

    return buildPublishUrl(asset, transforms);
  });
}

export function getBackgroundPublishUrls(element, breakpoint) {
  const { model } = element;
  const asset = model.get('style.background.image', breakpoint);

  if (
    // Before we had built-in support for retina images, we provided a workaround to customers:
    // using a box element with a background image and 'fit background to container' enabled,
    // instead of using an image element.
    // The following condition ensures that on accounts that have the retinaImages plan feature
    // disabled (legacy 2012 plans only, at time of writing), we do not resize box element
    // background images, because doing so would resize the image down to 1x only and effectively
    // break this workaround.
    (element.type === 'lp-pom-box' && !planFeatures.isFeatureEnabled('retinaImages')) ||
    !asset.size ||
    !asset.size.width ||
    !asset.size.height
  ) {
    return [buildPublishUrl(asset, {})];
  }

  const assetSize = constrainAssetSize(asset.size);

  const transforms = getBackgroundImageTransforms(assetSize, element, breakpoint, 1);
  const scales = getScalesToPublish(assetSize, asset.content_content_type, {
    width: transforms.w,
    height: transforms.h,
  });

  return scales.map(scale =>
    buildPublishUrl(asset, getBackgroundImageTransforms(assetSize, element, breakpoint, scale))
  );
}

function constrainAssetSize(assetSize) {
  const assetPixels = assetSize.width * assetSize.height;

  if (assetPixels > TOTAL_PIXEL_LIMIT) {
    const maxRatio = assetPixels / TOTAL_PIXEL_LIMIT;
    return {
      width: Math.round(assetSize.width / maxRatio),
      height: Math.round(assetSize.height / maxRatio),
    };
  }

  return assetSize;
}

export function getButtonElementPublishUrl(buttonElement, asset) {
  if (!isTransformableMimeType(asset.content_content_type)) {
    return buildPublishUrl(asset, {});
  }

  const globalQuality = getGlobalImageQuality(buttonElement);
  const transforms = {
    q: globalQuality.value,
    png8: globalQuality.compressPng,
  };

  return buildPublishUrl(asset, transforms);
}
