const AlertDialog = require('ub/ui/alert-dialog');

/* globals lp,Class */
var ImageModule = Class.create(
      lp.ModuleBase,
      lp.ModuleElementMethods,
      lp.ModuleElementBuilderMethods,
      lp.ModuleElementPropertiesPanelMethods,
      {
        beforeCreate: function (page, defaults, options, asset) {
          defaults.content = {
            asset: {
              company_id: asset.company_id,
              uuid: asset.uuid,
              unique_url: asset.unique_url,
              content_url: asset.content_url,
              content_content_type: asset.content_content_type,
              name: asset.name,
              content_file_size: asset.content_file_size,
            },
            target: page.getConfigValue('linkTargetDefault')
          };

          if (page.getConfigValue('allowExternalLightboxLinks')) {
            defaults.lightboxSize = lp.module.image.ImageElement.lightboxSizeDefault;
          }
        },

        insertElement: function (element, options) {
          if (!options.hideLoader) {
            window.editor.wait();
          }

          const { model } = element;

          model.fetchAssetDimensions()
            .then(dimensions => {
              element.updateAssetDimensions(dimensions, options.autoscale || false);

              if (options.center) {
                model.setOffset({
                  left: model.get('geometry.offset.left') - Math.round(model.get('geometry.size.width') / 2),
                  top:  model.get('geometry.offset.top') - Math.round(model.get('geometry.size.height') / 2)
                });
              }

              if (!options.hideLoader) {
                window.editor.stopWaiting();
              }
            })
          .catch(error => {
            console.warn(error);
            window.editor.stopWaiting();
            new AlertDialog({
              title: 'Images',
              message: 'The image failed to load. Please contact support if this problem persists.',
              icon: 'error'
            }).safeOpen();
          });
        },

        openElementBuilder: function(elm, options) {
          options.onChoose = options.callback;
          options.onlyImages = true;
          window.editor.openAssetLibrary(options);
        }
      }
  );

lp.addModule(new ImageModule({
    type:'lp-pom-image',
    version:'1.0',
    name: 'Image',
    usageType:'page-widget',
    namespace: lp.module.image
  }));

module.exports = ImageModule;
