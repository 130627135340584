import _ from 'lodash';

const EMPTY_LENGTH = 0;

const getFormDimensions = (formElm) => {
  const fieldStyles = formElm.fieldViewMap
    .map((fieldMap) => {
      const rootNode = fieldMap.root;
      const optionNodes = rootNode.getElementsByClassName('option');
      const inputFields = rootNode.getElementsByClassName('ub-input-item');
      const labels = rootNode.getElementsByClassName('lp-form-label');

      return [rootNode, ...optionNodes, ...inputFields, ...labels];
    })
    .reduce((accumulator, nextArray) => {
      return [...accumulator, ...nextArray];
    }, [])
    .map((node) => {
      let selector = `#${node.id}`;

      if (_.includes(node.className, 'ub-input-item')) {
        // To support some legacy customer scripts, we need to target the
        // node like this to be overly specific that it doesn't get overwritten
        if (_.includes(node.className, 'optionsList')) {
          selector = `.ub-input-item#${node.id}`;
        } else {
          selector = `.lp-pom-form-field .ub-input-item.single.form_elem_${node.id}`;
        }
      }

      return {
        selector,
        top: node.offsetTop,
        left: node.offsetLeft,
        width: node.offsetWidth,
        height: node.offsetHeight
      };
    });

  return fieldStyles;
};

const rearrangeBreakpoints = (breakpoints, currentBreakpoint) => {
  const rearrangedBreakpoints = breakpoints.filter((breakpoint) => {
    return breakpoint.name !== currentBreakpoint.name;
  });

  rearrangedBreakpoints.push(currentBreakpoint);

  return rearrangedBreakpoints;
};

const drawBreakpoint = (page, breakpoint, editor) => {
  const currentActiveElement = editor.activeElement;

  if (currentActiveElement) {
    editor.setActiveElement(page.getRootElement());
  }

  page.currentBreakpoint = breakpoint;
  page.updateAndRefresh();

  if (currentActiveElement) {
    editor.setActiveElement(currentActiveElement);
  }
};

const setGeometryForBreakpoints = (page, formElm, editor) => {
  const form = formElm;
  // todo: should this be enabled breakpoints?
  const breakpoints = page.getBreakPoints();
  const currentBreakpoint = page.getCurrentBreakpoint();

  // We move the current breakpoint to the last in the list so that
  // it renders back to the current breakpoint after cylcing through
  const rearrangedBreakpoints = rearrangeBreakpoints(breakpoints, currentBreakpoint);

  rearrangedBreakpoints.forEach((breakpoint) => {
    drawBreakpoint(formElm.page, breakpoint, editor);

    const visible = breakpoints.some(({ name }) => form.model.isVisible(name));
    if (visible) {
      const breakpointFormDimensions = getFormDimensions(formElm);

      form.model.setOnWritableModelByBreakpoint(
        'publishedStyles',
        breakpointFormDimensions,
        breakpoint
      );
    }
  });
};

const saveStylesForPublish = (page, elements, editor) => {
  const formElms = elements.filter((elm) => {
    return elm.getType() === 'lp-pom-form';
  });

  if (formElms.length === EMPTY_LENGTH) {
    return;
  }

  const currentPage = editor.page;
  const pageIsNotActive = currentPage.id !== page.id;

  // If the page is not active, we'll need to temp draw
  // the page to ensure we get the correct styles
  if (pageIsNotActive) {
    page.insertPage(editor.pageBody);
  }

  formElms.forEach((form) => {
    setGeometryForBreakpoints(page, form, editor);
  });

  if (pageIsNotActive) {
    page.removePage();
  }
};

export default {saveStylesForPublish};
