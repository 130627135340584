/* globals lp,Class */
var RootModule = Class.create(
  lp.ModuleBase,
  lp.ModuleElementMethods,
  lp.ModuleElementPropertiesPanelMethods,
  {
    getLimit: function() {
      return 1;
    },
    beforeCreate: function (page, defaults, options, data) {
      /* jshint unused:vars */
      defaults.geometry.contentWidth = page.getDefaultWidth();
    }
  }
);

lp.addModule(new RootModule({
    type:'lp-pom-root',
    version:'1.0',
    name: 'Root',
    namespace: lp.module.root
  }));

module.exports = RootModule;
