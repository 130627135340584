window.lp_raven_context = 'publisher';

// lp-publisher's headless browser (HTMLUnit/Rhino) contains buggy/incomplete implementations of the
// following ES features, which causes lodash and react to throw errors. Remove them and replace
// only the ones we actually need with standards-compliant polyfills.
// TODO: Remove all this when we are ready to commit to node-publisher for production and dev.
if ((window.navigator.userAgent || '').indexOf('Firefox/38.0') > -1) {
  delete window.Map;
  delete window.WeakMap;
  delete window.Set;
  delete window.Symbol;
  delete window.Promise;
  require('es6-map/implement');
  require('es6-set/implement');
}

require('./base');
require('./jui');
require('./pom');
require('./lp-publisher');
