var _ = require('lodash');
var Banzai = require('./banzai');
var hash = require('string-hash');

var banzaiDefinitions = {
  // for each feature:
  // - name,
  // - description
  // - intendedExpiry: string of date to remove,
  // - type: ['boolean', 'string', 'number']
  //         These must be the same as what `typeof` outputs. They are
  //         used in the DSL interpretation.
  // - "default": an expression in our DSL to use if no override is
  //              provided via dynamodb/bless. default is a js keyword so quote it.
  // - errorFallback: what to fall back to if the DSL value cannot be interpreted.

  // NOTE when deleting feature flags be sure not to remove flags used in testing. See file
  // builder/test/support/test_banzai-features.js

  //////////////////////////////////////////////////
  // dev related features

  debugMode: {
    type: 'boolean',
    errorFallback: false,
    default: { envIs: 'development' },
    description: 'Extra error checking and logging + debug features',
  },

  safeMode: {
    type: 'boolean',
    errorFallback: false,
    default: false,
    description:
      'Enter a debugger immediately after the pageData is loaded in the editor.' +
      ' This is useful for fixing bad poms. Only active if debugMode=1 and safeMode=1',
  },

  showRxUIEvents: {
    type: 'boolean',
    errorFallback: false,
    default: false,
    description: 'Console.log rxUi events.',
  },

  showJuiEvents: {
    type: 'boolean',
    errorFallback: false,
    default: false,
    description: 'Console.log jui/lp events. The guts.',
  },

  maxRxUIEventsToBuffer: {
    type: 'number',
    errorFallback: 30,
    default: 30,
    description: 'Max number of rxUi events to capture into the ring buffer',
  },

  recordRxUIEvents: {
    type: 'boolean',
    errorFallback: false,
    default: { isFeatureEnabled: 'debugMode' },
    description: 'Capture/record rxUi events.',
  },

  replayRxRecording: {
    type: 'string',
    errorFallback: null,
    default: null,
    description: 'rxUi recording to auto-replay after editor load. requires recordRxUIEvents',
  },

  rxRecordingReplaySpeed: {
    type: 'number',
    errorFallback: 2.0,
    default: 2.0,
    description: 'Time scaling factor for replay of recordRxUIEvents',
  },

  checkInvariants: {
    type: 'boolean',
    errorFallback: false,
    default: { isFeatureEnabled: 'debugMode' },
    description: 'Check global invariants throughout editing session',
  },

  contentTypeOverride: {
    type: 'string',
    errorFallback: null,
    default: null,
    description:
      'Can be used to override the content type of the current page. ' +
      'Should only be used in dev, or by admins when creating templates for a new ' +
      'content type.',
    intendedExpiry: null,
  },

  //////////////////////////////////////////////////
  // user facing features:

  // Do not delete. This is being used for recording playback in builder-tools.
  republishing: {
    type: 'boolean',
    errorFallback: true,
    default: true,
    description: 'Republish from the builder',
    intendedExpiry: 'Never',
  },

  // DO not delete. This is being used on some customer pages.
  renameWindowModuleToUbModule: {
    type: 'boolean',
    errorFallback: false,
    default: false,
    description: 'Allows us to use ubModule instead of window.module on forms',
    intendedExpiry: 'Never',
  },

  saveAsTemplate: {
    type: 'boolean',
    errorFallback: false,
    default: { any: [{ envIs: 'integration' }, { envIs: 'development' }] },
    description: 'Show the save as template option on toolbar',
    intendedExpiry: 'Never',
  },

  //////////////////////////////////////////////////
  // configuration

  //Publisher's asset renderer requires these URLs to be protocol agnostic.
  imageOptimizationServiceUrl: {
    type: 'string',
    errorFallback: '//image-service.unbounce.com',
    default: '//image-service.unbounce.com',
    description: 'The base URL of the image optimization',
  },

  pollerHostname: {
    type: 'string',
    // If the value given here is an empty string, we default to using 'window.location.host'
    // Only if we set a override through bless will it take this feature flag value
    errorFallback: '',
    default: '',
    // Override is set to poll.unbounce.com on production
    description: 'Hostname of builder poller host',
  },

  pomSavesGatewayURL: {
    type: 'string',
    errorFallback: 'https://dqm8o1z2e1.execute-api.us-east-1.amazonaws.com/production/pomsave',
    default: 'https://dqm8o1z2e1.execute-api.us-east-1.amazonaws.com/production/pomsave',
    // Override is set to https://dqm8o1z2e1.execute-api.us-east-1.amazonaws.com/production/pomsave-integration on integration.
    // API Gateways CORS settings for the production endpoint only allows the domain app.unbounce.com. So any attempts to POST
    // from production/devleopment environments will be rejected
    description: 'POST URL for pom saves lambda',
  },

  backgroundImageMinSize: {
    type: 'number',
    errorFallback: 200,
    default: 200,
    description: 'The min size of the background image to determine the UI defaults',
  },

  republishTimeoutPeriod: {
    type: 'number',
    errorFallback: 3000,
    default: 3000,
    description: 'The default timeout for making a republish request from the editor',
  },

  republishErrorRetryAttempts: {
    type: 'number',
    errorFallback: 3,
    default: 3,
    description: 'The amount of times we try to republish again after seeing an error',
  },

  publishStatePollerFast: {
    type: 'number',
    errorFallback: 500,
    default: 500,
    description: 'The speed in which we poll the server to find the state of publishing',
  },

  publishStatePollerSlow: {
    type: 'number',
    errorFallback: 15000,
    default: 15000,
    description: 'The speed in which we poll the server to find the state of publishing',
  },

  lightboxLimit: {
    type: 'number',
    errorFallback: 1,
    default: 1,
    description: 'The max number of lightboxes allowed in the builder',
  },

  fontPaginationLimit: {
    type: 'number',
    errorFallback: 21,
    default: 21,
    description: 'The number of font nodes we lazy load at a time in the font picker',
  },

  fontTimeBuffer: {
    type: 'number',
    errorFallback: 125,
    default: 125,
    description: 'The time buffer in milliseconds between batches of new fonts being loaded',
  },

  fontCountBuffer: {
    type: 'number',
    errorFallback: 40,
    default: 40,
    description: 'The maximum number of fonts to load in any request',
  },

  fontSearchThreshold: {
    type: 'number',
    errorFallback: 30,
    default: 30,
    description:
      'The percentage strictness of font fuzzy searching in font picker. Lower is stricter',
  },

  builderLoginEndpoint: {
    type: 'string',
    errorFallback: '/users/sign_in.json',
    default: '/users/sign_in.json',
    description: 'The endpoint to hit for re-authentication in the builder',
  },

  builderWebappGoogleOauth2Callback: {
    type: 'string',
    errorFallback: '/users/auth/google_oauth2/callback.json',
    default: '/users/auth/google_oauth2/callback.json',
    description: 'The endpoint to hit for re-authentication usign Google SSO in the builder',
  },

  googleSsoCredentialKey: {
    type: 'string',
    errorFallback: '214338196915-a5bb7j81re1r6eihphfp83437kecfm9u.apps.googleusercontent.com',
    default: '214338196915-a5bb7j81re1r6eihphfp83437kecfm9u.apps.googleusercontent.com',
    description: 'Google Single Sign On Credential clientId key',
  },

  maximumNumberOfFonts: {
    type: 'number',
    errorFallback: 25,
    default: 25,
    description: 'Maximum number of fonts we allow users to add to their page',
  },

  unsplashAccessKey: {
    type: 'string',
    errorFallback: 'de2e8b566492d6222df5813c5246bd0c85e366cc8e2396e364c4827ca30e8bc4',
    default: 'de2e8b566492d6222df5813c5246bd0c85e366cc8e2396e364c4827ca30e8bc4',
    description: 'Unsplash API access key - https://unsplash.com/oauth/applications/27955',
  },

  sandbox: {
    type: 'boolean',
    errorFallback: false,
    default: false,
    description: 'Run the builder in sandbox mode',
  },

  overridePlan: {
    type: 'boolean',
    errorFallback: false,
    default: false,
    description: 'Allows users to override plan values and fall back to banzai',
  },

  lazyLoadImages: {
    type: 'boolean',
    errorFallback: false,
    default: false,
    description: 'Toggle lazy loading images',
  },

  formRegex: {
    type: 'boolean',
    errorFallback: false,
    default: false,
    description: 'Toggle visibility of form regex feature',
    intendedExpiry: '2024-05-04',
  },

  autoscale: {
    type: 'boolean',
    errorFallback: false,
    default: false,
    description: 'Toggle UI visibility for autoscale feature',
    intendedExpiry: '2024-06-30',
  },

  tabletBreakpoint: {
    type: 'boolean',
    errorFallback: false,
    default: false,
    description: 'Toggle tablet breakpoint',
    intendedExpiry: '2024-08-30',
  },

  elementEffects: {
    type: 'boolean',
    errorFallback: false,
    default: false,
    description: 'Toggle UI visibility for element effects panel',
    intendedExpiry: '2024-06-30',
  },
};

//////////////////////////////////////////////////
// support code

var dslFns = (function () {
  var percentOfAccounts = function (threshold) {
    // This references account id of the page variant rather than
    // the account id of the current user viewing the page
    var accountId = window.gon.page_account_id;
    // when percentOf: 0 => always false
    // when percentOf: 100 => always true
    return threshold > hash(String(accountId)) % 100;
  };
  percentOfAccounts.__argtype__ = 'number';
  percentOfAccounts.__doc__ = 'threshold > hash(String(page_account_id)) % 100';

  var percentOfClients = function (threshold) {
    var clientId = window.gon.client_id; // this is page.clientId
    // when percentOf: 0 => always false
    // when percentOf: 100 => always true
    return threshold > hash(String(clientId)) % 100;
  };
  percentOfClients.__argtype__ = 'number';
  percentOfClients.__doc__ = 'threshold > hash(String(clientId)) % 100';

  var envIs = function (env) {
    return window.gon && window.gon.env === env;
  };
  envIs.__argtype__ = 'string';
  envIs.__doc__ = 'development, integration, or production';

  var clientId = function (cid) {
    return window.gon && window.gon.client_id === cid;
  };
  clientId.__argtype__ = 'number';
  clientId.__doc__ = 'Unbounce clientId === arg';

  var accountId = function (accountId) {
    //This references account id of the page variant rather than the account id of the current user viewing the page
    return window.gon && window.gon.page_account_id === accountId;
  };
  accountId.__argtype__ = 'number';
  accountId.__doc__ = 'Unbounce accountId === arg';

  var inChannel = function (channelName) {
    return (
      window.banzai_lookup.urls &&
      window.banzai_lookup.urls.resolved_via &&
      _.some(window.banzai_lookup.urls.resolved_via, function (entry) {
        return _.isObject(entry) && entry.channel === channelName;
      })
    );
  };
  inChannel.__argtype__ = 'string';
  inChannel.__doc__ = 'Bless channel matches arg';

  return {
    envIs: envIs,
    clientId: clientId,
    accountId: accountId,
    inChannel: inChannel,
    percentOfAccounts: percentOfAccounts,
    percentOfClients: percentOfClients,
  };
})();

var queryStringVars = (function () {
  var queryString;
  if (typeof window === 'object') {
    queryString = window.location.search.substr(1).split('&');
  }

  if (!queryString) {
    return {};
  } else {
    var queryObj = {};

    for (var i = 0; i < queryString.length; i++) {
      var param = queryString[i].split('=', 2);
      if (param.length === 1) {
        queryObj[param[0]] = '';
      } else {
        queryObj[param[0]] = decodeURIComponent(param[1].replace(/\+/g, ' '));
      }
    }
    return queryObj;
  }
})();

var _interpretValFromQueryString = function (v, key) {
  try {
    if (banzaiDefinitions[key].type === 'boolean') {
      return Boolean(JSON.parse(v));
    } else if (banzaiDefinitions[key].type === 'number') {
      return parseInt(v, 10);
    } else {
      // treat it like a string
      return v;
    }
  } catch (e) {
    return undefined;
  }
};

var _getOverridesFromQueryString = function () {
  var overrides = _.pick(queryStringVars, _.keys(banzaiDefinitions));
  var interpretedOverrides = _.mapValues(overrides, _interpretValFromQueryString);
  return _.omitBy(interpretedOverrides, _.isUndefined);
};

var _getOverridesFromBlessSelectors = function () {
  var banzai = typeof window === 'object' ? window.banzai_lookup : undefined;
  if (_.isEmpty(banzai) || _.isEmpty(banzai.urls)) {
    return {};
  }

  try {
    var featuresForSelectors = _.map(banzai.urls.resolved_via, function (step) {
      var entry;
      if (typeof step === 'string') {
        entry = banzai.lookup_table[step];
      } else if (step.channel) {
        entry = banzai.lookup_table.channels[step.channel];
      }
      return entry && entry.features;
    }).reverse();
    return _.reduce(featuresForSelectors, _.merge, {});
  } catch (err) {
    console.trace('Error in _getOverridesFromBlessSelectors', err);
    return {};
  }
};

var _getFloodgateOverrides = function () {
  // returns an {} of {flagName:true} for all boolean flags, if floodgate open
  if (!_interpretValFromQueryString(queryStringVars.floodgate)) {
    return {};
  }
  // else if floodgate open:
  var booleanFlags = _.pickBy(banzaiDefinitions, function (def) {
    return def && def.type === 'boolean';
  });
  var forcedOn = _.map(booleanFlags, function (def, name) {
    var entry = {};
    entry[name] = true; // because you can't {variable:val}!
    return entry;
  });
  return _.reduce(forcedOn, _.merge, {});
};

var loadBanzai = function () {
  var overrides = _.merge(
    {},
    typeof window === 'object' ? window.banzai_features_table : {}, //From dynamo table
    _getOverridesFromBlessSelectors(),
    _getFloodgateOverrides(),
    _getOverridesFromQueryString()
  );
  return new Banzai(banzaiDefinitions, overrides, dslFns);
};

module.exports = loadBanzai();
