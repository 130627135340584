module.exports = socialSvg: (type) ->
  svgHeader = '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 130 130" xml:space="preserve">'
  switch type
    when 'horizontal_count_top'
      svgContent = '<path class="svg-social-button" d="M93.5,75h33.6c1.7,0,3,1.3,3,3v13.8c0,1.7-1.3,3-3,3H93.5c-1.7,0-3-1.3-3-3V78C90.5,76.3,91.8,75,93.5,75z"/>
      <path class="svg-social-popup" d="M110.2,47.7h2.8l-0.5,3h-2.8L110.2,47.7z M130.1,38v21.1c0,1.7-1.3,3-3,3h-9.7l-5.4,5.4c-1.2,1.2-3.1,1.2-4.2,0l-5.4-5.4h-8.8c-1.7,0-3-1.3-3-3V38c0-1.7,1.3-3,3-3h33.6C128.7,35,130.1,36.3,130.1,38z M116.5,46H115l0.4-2.6h-1.8l-0.4,2.6h-2.7l0.4-2.6h-1.8l-0.4,2.6h-2v1.7h1.7l-0.5,2.9h-1.8v1.7h1.5l-0.4,2.7h1.8l0.4-2.7h2.7l-0.4,2.7h1.8l0.4-2.7h2v-1.7h-1.7l0.5-2.9h1.8V46z"/>
      <path class="svg-social-button" d="M3,75h33.6c1.7,0,3,1.3,3,3v13.8c0,1.7-1.3,3-3,3H3c-1.7,0-3-1.3-3-3V78C0,76.3,1.3,75,3,75z"/>
      <path class="svg-social-popup" d="M19.7,47.7h2.8l-0.5,3h-2.8L19.7,47.7z M39.6,38v21.1c0,1.7-1.3,3-3,3h-9.7l-5.4,5.4c-1.2,1.2-3.1,1.2-4.2,0l-5.4-5.4H3c-1.7,0-3-1.3-3-3V38c0-1.7,1.3-3,3-3h33.6C38.2,35,39.6,36.3,39.6,38z M26,46h-1.5l0.4-2.6h-1.8L22.7,46H20l0.4-2.6h-1.8L18.2,46h-2v1.7h1.7l-0.5,2.9h-1.8v1.7h1.5L16.7,55h1.8l0.4-2.7h2.7L21.2,55h1.8l0.4-2.7h2v-1.7h-1.7l0.5-2.9H26V46z"/>
      <path class="svg-social-button" d="M48.2,75h33.6c1.7,0,3,1.3,3,3v13.8c0,1.7-1.3,3-3,3H48.2c-1.7,0-3-1.3-3-3V78C45.2,76.3,46.6,75,48.2,75z"/>
      <path class="svg-social-popup" d="M64.9,47.7h2.8l-0.5,3h-2.8L64.9,47.7z M84.8,38v21.1c0,1.7-1.3,3-3,3h-9.7l-5.4,5.4c-1.2,1.2-3.1,1.2-4.2,0L57,62.1h-8.8c-1.7,0-3-1.3-3-3V38c0-1.7,1.3-3,3-3h33.6C83.4,35,84.8,36.3,84.8,38z M71.2,46h-1.5l0.4-2.6h-1.8L67.9,46h-2.7l0.4-2.6h-1.8L63.4,46h-2v1.7h1.7l-0.5,2.9h-1.8v1.7h1.5L61.9,55h1.8l0.4-2.7h2.7L66.4,55h1.8l0.4-2.7h2v-1.7h-1.7l0.5-2.9h1.8V46z"/>'
    when 'vertical_count_top'
      svgContent = '<path class="svg-social-button" d="M48.2,109.7h33.6c1.7,0,3,1.3,3,3v13.8c0,1.7-1.3,3-3,3H48.2c-1.7,0-3-1.3-3-3v-13.8C45.2,111.1,46.6,109.7,48.2,109.7z"/>
       <path class="svg-social-popup" d="M64.5,81.5h2.6l-0.5,2.8H64L64.5,81.5z M84.8,72.7v21.1c0,1.7-1.3,3-3,3h-9.1l-5.3,5.3c-1.2,1.2-3.1,1.2-4.2,0l-5.3-5.3 h-9.7c-1.7,0-3-1.3-3-3V72.7c0-1.7,1.3-3,3-3h33.6C83.4,69.7,84.8,71,84.8,72.7z M70.4,80H69l0.4-2.4h-1.7L67.3,80h-2.5l0.4-2.4 h-1.7L63.2,80h-1.8v1.6h1.5l-0.5,2.7h-1.7v1.6h1.4l-0.4,2.5h1.7l0.4-2.5h2.5l-0.4,2.5h1.7l0.4-2.5h1.8v-1.6h-1.6l0.5-2.7h1.7 C70.4,81.6,70.4,80,70.4,80z"/>
       <path class="svg-social-button" d="M48.2,40.1h33.6c1.7,0,3,1.3,3,3v13.8c0,1.7-1.3,3-3,3H48.2c-1.7,0-3-1.3-3-3V43.1C45.2,41.4,46.6,40.1,48.2,40.1z"/>
       <path class="svg-social-popup" d="M64.5,11.9h2.6l-0.5,2.8H64L64.5,11.9z M84.8,3v21.1c0,1.7-1.3,3-3,3h-9.1l-5.3,5.3c-1.2,1.2-3.1,1.2-4.2,0l-5.3-5.3h-9.7 c-1.7,0-3-1.3-3-3V3c0-1.7,1.3-3,3-3h33.6C83.4,0,84.8,1.3,84.8,3z M70.4,10.3H69l0.4-2.4h-1.7l-0.4,2.4h-2.5l0.4-2.4h-1.7l-0.4,2.4 h-1.8v1.6h1.5l-0.5,2.7h-1.7v1.6H62l-0.4,2.5h1.7l0.4-2.5h2.5l-0.4,2.5h1.7l0.4-2.5h1.8v-1.6h-1.6l0.5-2.7h1.7L70.4,10.3L70.4,10.3z "/>'
    when 'horizontal_count_right'
      svgContent = '<path class="svg-social-button" d="M71.7,56.3h29.6c1.5,0,2.6,1.1,2.6,2.6V71c0,1.5-1.1,2.6-2.6,2.6H71.7c-1.5,0-2.6-1.1-2.6-2.6V58.9 C69,57.4,70.2,56.3,71.7,56.3z"/>
       <path class="svg-social-popup" d="M119.4,66.2h2.2l0.5-2.4h-2.3L119.4,66.2z M128,56.6h-14.5c-1,0-1.8,0.8-1.8,1.8v3c-0.1,0-0.1,0.1-0.2,0.1l-3.3,3.3 c-0.4,0.4-0.4,0.9,0,1.2l3.3,3.3c0.1,0.1,0.2,0.2,0.3,0.2v2.1c0,1,0.8,1.8,1.8,1.8H128c1,0,1.8-0.8,1.8-1.8V58.4 C129.8,57.4,129,56.6,128,56.6z M125,63.7h-1.5l-0.4,2.4h1.4v1.4h-1.6l-0.4,2.2H121l0.4-2.2h-2.2l-0.4,2.2h-1.5l0.4-2.2h-1.2v-1.4 h1.5l0.4-2.4H117v-1.4h1.6l0.4-2.1h1.5l-0.4,2.1h2.2l0.4-2.1h1.5l-0.4,2.1h1.2L125,63.7L125,63.7z"/>
       <path class="svg-social-button" d="M2.8,56.3h29.6c1.5,0,2.6,1.1,2.6,2.6V71c0,1.5-1.1,2.6-2.6,2.6H2.8c-1.5,0-2.6-1.1-2.6-2.6V58.9 C0.2,57.4,1.3,56.3,2.8,56.3z"/>
       <path class="svg-social-popup" d="M50.6,66.2h2.2l0.4-2.4h-2.3L50.6,66.2z M59.2,56.6H44.8c-1,0-1.8,0.8-1.8,1.8v3c-0.1,0-0.1,0.1-0.2,0.1l-3.3,3.3 c-0.4,0.4-0.4,0.9,0,1.2l3.3,3.3c0.1,0.1,0.2,0.2,0.3,0.2v2.1c0,1,0.8,1.8,1.8,1.8h14.4c1,0,1.8-0.8,1.8-1.8V58.4 C61,57.4,60.2,56.6,59.2,56.6z M56.1,63.7h-1.5l-0.4,2.4h1.4v1.4H54l-0.4,2.2h-1.5l0.4-2.2h-2.2l-0.4,2.2h-1.5l0.4-2.2h-1.2v-1.4 h1.5l0.4-2.4h-1.3v-1.4h1.6l0.4-2.1h1.5l-0.4,2.1h2.2l0.4-2.1h1.5L55,62.3h1.2L56.1,63.7L56.1,63.7z"/>'
    when 'horizontal_no_count'
      svgContent = '<path class="svg-social-button" d="M3,55.1h33.6c1.7,0,3,1.3,3,3v13.8c0,1.7-1.3,3-3,3H3c-1.7,0-3-1.3-3-3V58.1C0,56.4,1.3,55.1,3,55.1z"/>
       <path class="svg-social-button" d="M48.2,55.1h33.6c1.7,0,3,1.3,3,3v13.8c0,1.7-1.3,3-3,3H48.2c-1.7,0-3-1.3-3-3V58.1C45.2,56.4,46.5,55.1,48.2,55.1z"/>
       <path class="svg-social-button" d="M93.4,55.1H127c1.7,0,3,1.3,3,3v13.8c0,1.7-1.3,3-3,3H93.4c-1.7,0-3-1.3-3-3V58.1C90.4,56.4,91.7,55.1,93.4,55.1z"/>'
    when 'vertical_no_count'
      svgContent = '<path class="svg-social-button" d="M48.2,55h33.6c1.7,0,3,1.3,3,3v13.8c0,1.7-1.3,3-3,3H48.2c-1.7,0-3-1.3-3-3V58C45.2,56.3,46.6,55,48.2,55z"/>
       <path class="svg-social-button" d="M48.2,85h33.6c1.7,0,3,1.3,3,3v13.8c0,1.7-1.3,3-3,3H48.2c-1.7,0-3-1.3-3-3V88C45.2,86.3,46.6,85,48.2,85z"/>
       <path class="svg-social-button" d="M48.2,25h33.6c1.7,0,3,1.3,3,3v13.8c0,1.7-1.3,3-3,3H48.2c-1.7,0-3-1.3-3-3V28C45.2,26.3,46.6,25,48.2,25z"/>'
   svgFooter = '</svg>'
   svgHeader + svgContent + svgFooter
