const { Class, jui } = window;

window.lp.pom.ElementView = Class.create(jui.Component, {
  initialize($super, element, options0) {
    const options = {
      ...options0,
      attributes: {
        className: element.getClassname(),
      },
    };

    this.element = element;

    $super(options);

    this.e.id = this.element.id;
  },
});
