/* globals jui, Class */
var _ = require('lodash');
var React = require('react');
var ReactDOM = require('react-dom');
var AlertDialogContent = require('./alert-dialog-content.jsx').default;

var AlertDialog = Class.create(jui.ModalDialog, {
  render: function(options) {
    ReactDOM.render(
      React.createElement(
        AlertDialogContent,
        {
          message: options.message,
          secondaryMessage: options.secondaryMessage,
          icon: options.icon,
          content: options.content,
          containerClass: options.containerClass
        }
      ),
      this.contentPanel.e
    );

    if (options.actionBar) {
      ReactDOM.render(options.actionBar, this.actionBar.e);
    }
  },

  initialize: function($super, options) {
    options = _.merge({
      clickOutsideWillClose: true,
      allowHeader: true,
      closeX: true,
      attributes: {
        className: 'modal-panel alert-modal'
      },
      actions: this._buildActions(options)
    }, options);

    $super(options);
    this.show();
  },

  _buildActions: function(options) {
    var self = this;
    var actions = [];

    var cancelClassNames = ['button', 'modal-button', 'push-left'];

    if (options.showCancelButton) {
      actions.push({
        attributes: {
          id: 'close',
          className: cancelClassNames.join(' ')
        },
        type: 'button',
        label: options.cancelLabel || 'Cancel',
        action: function() {
          if (_.isFunction(options.onClose)) {
            options.onClose();
          } else {
            self.close();
          }
        }
      });
    }

    var saveClassNames = ['button', 'modal-button', 'action'];

    if (! options.showCancelButton) {
      saveClassNames.push('push-left');
    }

    actions.push({
      attributes: {
        id: 'action',
        className: saveClassNames.join(' ')
      },
      type: 'button',
      label: options.confirmLabel || 'Got it',
      action: function() {
        self.close();
        if (_.isFunction(options.onConfirm)) {
          options.onConfirm();
        }
      }
    });

    return actions;
  },

  installUI: function($super) {
    $super();
    this.setTitle(this.options.title);
    this.render(this.options);
  },

  close: function($super) {
    $super();

    if (_.isFunction(this.options.onClose)) {
      this.options.onClose();
    }

    if (window.editor.dialogManager) {
      window.editor.dialogManager.currentDialog = null;
    }

    this.fireEvent('closed');
  },

  safeOpen: function() {
    // Opens the dialog through the dialog manager, which ensures multiple dialogs aren't
    // open at the same time, which isn't supported and leads to errors
    if (window.editor.dialogManager) {
      window.editor.dialogManager.openDialog(this);
    } else {
      this.open();
    }
  }
});

module.exports = AlertDialog;
