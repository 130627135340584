import _ from 'lodash';
import googleFontsAPI from 'ub/fonts/font-service/google/api';
import googleFonts from 'ub/fonts/font-service/google/fonts';
import { cleanQuotes } from './font-data-normalizer.js';

const stripFallbackFonts = fontString => {
  const numCharactersToSplit = 1;

  return fontString.split(',', numCharactersToSplit).shift().trim().toLowerCase();
};

const cleanFontString = fontString => {
  return cleanQuotes(stripFallbackFonts(fontString)).replace(/\s+/g, ' ').trim();
};

const findValidFontName = (allowedFonts, fontName) => {
  return _.find(allowedFonts, allowedFont => {
    return allowedFont.toLowerCase() === fontName.toLowerCase();
  });
};

const mergeFontObjs = webFontSet => {
  const mergeCustomizer = (objValue, srcValue) => {
    if (_.isArray(objValue)) {
      return objValue.concat(srcValue);
    }
  };

  // The second {} is to ensure that if webFontSet is an empty array, we don't merge mergeCustomizer
  // (a function) in.
  return webFontSet.length > 0 ? _.mergeWith({}, ...webFontSet, mergeCustomizer) : {};
};

const removeDuplicateWeights = fontMap => {
  const list = _.map(fontMap, (fontWeights, fontFamily) => {
    const fontObj = {};

    fontObj[fontFamily] = _.uniq(fontWeights);

    return fontObj;
  });

  return mergeFontObjs(list);
};

const getComputedFontStyles = elm => {
  const elmStyles = window.getComputedStyle(elm, null);
  const cleanFontName = cleanFontString(elmStyles.fontFamily);
  const matchedFont = googleFontsAPI.findFontInList(cleanFontName);

  const family = matchedFont ? matchedFont.family : cleanFontName;

  // These are forced to false as this property is only useful for buttons and
  // form labels where we are getting the property from the pom. With text, calling
  // getComputedStyle on the dom nodes should already give us the accurate weight and style
  const textStyles = {
    strong: false,
    em: false,
  };

  return {
    family,
    weight: elmStyles.fontWeight,
    style: elmStyles.fontStyle,
    textStyles,
  };
};

const formatWebFonts = webFontSet => {
  return _.map(webFontSet, (fontWeights, fontFamily) => {
    const fontWeightsString = _.uniq(fontWeights).join(',');
    return `'${fontFamily}:${fontWeightsString}'`;
  });
};

const formatForWebfontLoader = fonts => {
  return _.map(fonts, ({ family, variants }) => {
    return `${family}:${_.map(variants, 'name').join(',')}`;
  });
};

const getFormattedFontFamily = family => {
  // If font family name contains a number we need to wrap it in single quotes
  if (/\d/.test(family)) {
    return `'${family}'`;
  }
  return family;
};

const setRecentFonts = font => {
  let recentFonts = getRecentFonts();
  if (recentFonts.length === 0) {
    window.localStorage.setItem('recentFonts', JSON.stringify([font]));
  } else {
    recentFonts = recentFonts.filter(item => item !== font);
    recentFonts.unshift(font);
    recentFonts = recentFonts.slice(0, 5);
    window.localStorage.setItem('recentFonts', JSON.stringify(recentFonts));
  }
};

const getRecentFonts = () => {
  if (window.localStorage.getItem('recentFonts') === null) return [];
  try {
    return JSON.parse(window.localStorage.getItem('recentFonts'));
  } catch (e) {
    return [];
  }
};

const returnFontFaceString = (family, fontStyle, fontWeight, url) => {
  return `@font-face {
    font-family: '${family}';
    font-style: ${fontStyle};
    font-weight: ${fontWeight};
    src: url('${url}');
  }`;
};

const generateFontFacesContent = fonts => {
  const fontFacesFamily = fonts.map(font => {
    const { family, variants } = font;
    const fontFaces = variants.map(variant => {
      return returnFontFaceString(family, variant.fontStyle, variant.fontWeight, variant.url);
    });

    return { fontFacesStyle: fontFaces.join('\n'), family };
  });
  return fontFacesFamily;
};

// Function to insert the style tag with the external font data, this is necessary to apply the changes at the moment a new font is added
const insertFontFamily = fontData => {
  let style = document.querySelector(`style[data-font-family="${fontData.family}"]`);
  if (style) {
    return;
  }
  style = document.createElement('style');
  style.setAttribute('data-font-family', fontData.family);

  const innerHTML = [];
  fontData.variants.forEach(variant => {
    const fontFaceString = returnFontFaceString(
      fontData.family,
      variant.fontStyle,
      variant.fontWeight,
      variant.url
    );
    innerHTML.push(fontFaceString);
  });
  style.innerHTML = innerHTML.join('\n');
  document.head.appendChild(style);
};

// This function is not currently used and the idea is to use in the future to remove the style tag with the name of the font-family
const removeFontFamily = fontFamily => {
  const style = document.querySelector(`style[data-font-family="${fontFamily}"]`);
  if (style) {
    style.remove();
  }
};

// This function is not currently used and the idea is to use in the future to insert the font-face in the style tag with the name of the font-family
const insertFontFace = (fontFamily, { family, fontStyle, fontWeight, url }) => {
  let style = document.querySelector(`style[data-font-family="${fontFamily}"]`);
  if (!style) {
    insertFontFamily(fontFamily);
    style = document.querySelector(`style[data-font-family="${fontFamily}"]`);
  }
  style.textContent += returnFontFaceString(family, fontStyle, fontWeight, url);
};

// This function is not currently used and the idea is to use in the future to remove the font-face in the style tag with the name of the font-family
const removeFontFace = (fontFamily, { family, fontStyle, fontWeight, url }) => {
  const style = document.querySelector(`style[data-font-family="${fontFamily}"]`);
  if (style) {
    style.textContent = style.textContent.replace(
      returnFontFaceString(family, fontStyle, fontWeight, url),
      ''
    );
  }
};

export default {
  sysFonts: [
    { label: 'Arial', value: 'Arial, sans-serif' },
    { label: 'Comic Sans MS', value: 'Comic Sans MS, cursive, sans-serif' },
    { label: 'Courier New', value: 'Courier New, Courier, monospace' },
    { label: 'Georgia', value: 'Georgia, serif' },
    { label: 'Lucida Sans Unicode', value: 'Lucida Sans Unicode, Lucida Grande, sans-serif' },
    { label: 'Tahoma', value: 'Tahoma, Geneva, sans-serif' },
    { label: 'Times New Roman', value: 'Times New Roman, Times, serif' },
    { label: 'Trebuchet MS', value: 'Trebuchet MS, sans-serif' },
    { label: 'Verdana', value: 'Verdana, Geneva, sans-serif' },
  ],

  get sysFontsNames() {
    return _.map(this.sysFonts, 'label');
  },

  get sysFontsValues() {
    return _.map(this.sysFonts, 'value');
  },

  get webFontsNames() {
    return googleFonts.fontFamilies;
  },

  get webFonts() {
    return _.map(this.webFontsNames, font => {
      return {
        label: font,
        value: font,
      };
    });
  },

  get allFonts() {
    return this.sysFonts.concat(this.webFonts);
  },

  get allowedFonts() {
    return this.sysFontsNames.concat(this.webFontsNames);
  },

  getBaseFontFamily(fontString) {
    return findValidFontName(this.allowedFonts, cleanFontString(fontString));
  },

  isSystemFont(fontFamily) {
    return this.sysFonts.some(font => font.label.toLowerCase() === fontFamily.toLowerCase());
  },

  // TODO: copied over from legacy, DO NOT publish this way
  buildFontList() {
    return '';
  },
  formatWebFonts,
  formatForWebfontLoader,
  getFormattedFontFamily,
  cleanFontString,
  mergeFontObjs,
  getComputedFontStyles,
  removeDuplicateWeights,
  setRecentFonts,
  getRecentFonts,
  generateFontFacesContent,
  insertFontFamily,
  removeFontFamily,
  insertFontFace,
  removeFontFace,
};
