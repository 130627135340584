import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Alpha, Hue, Saturation } from 'react-color/lib/components/common';
import { formatColor } from '../brand-color/utils';
import ColorHelper from 'react-color/lib/helpers/color';
import shallowCompare from 'react-addons-shallow-compare';
import reactCSS from 'reactcss';
import _ from 'lodash';
import BrandColor from 'ub/ui/brand-color/brand-color.jsx';
import brandColorManager from 'ub/ui/brand-color/brand-color-manager';
import logUIEvent from '../../../src/log-events/log-ui-events';

// TODO replace tinycolor2 here with the npm version or with whatever
// react-color switches to. For some reason react-color just inlines this as a
// source file in the project instead of as an external module.
import tinycolor2 from 'tinycolor2';

const ESC_KEY = 27;
const RETURN_KEY = 13;

const normalizeAlpha = (alpha) =>
  Math.floor(alpha * 100);

function validAlphaInput(val) {
  return val >= 0 && val <= 100;
}

class ColorPopdown extends Component {

  constructor(props) {
    super(props);

    const colorState = ColorHelper.toState(props.color, 0);
    colorState.inputValue = colorState.hex.replace('#', ''); // preset the input value field
    colorState.inputAlphaValue = normalizeAlpha(colorState.rgb.a); // preset the input value field

    const colorRgbString = tinycolor2(props.color).toRgbString();
    colorState.currentRgbString = colorRgbString;
    colorState.newRgbString = colorRgbString;

    this.state = colorState;

    this.onKeyUp = this.onKeyUp.bind(this);
    this.onColorInputBlur = this.onColorInputBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onColorChange = this.onColorChange.bind(this);
    this.onAlphaInputBlur = this.onAlphaInputBlur.bind(this);
    this.onAlphaInputChange = this.onAlphaInputChange.bind(this);
    this.onRemoveColor = this.onRemoveColor.bind(this);

    this.onChangeComplete = this.onChangeComplete.bind(this);
    this.saveColor = this.saveColor.bind(this);
    this.removePaletteColor = this.removePaletteColor.bind(this);

    this.onItemClick = this.onItemClick.bind(this);
    this.hideRemoveButton = this.hideRemoveButton.bind(this);
    this.showRemoveButton = this.showRemoveButton.bind(this);

    this.state = { ...this.state, showRemoveButton: false, currentSelectedBrandColor: '' };
  }

  componentDidMount() {
    // TODO: fix hack to prevent mousedown from stealing focus from canvas
    if(this.hexInputField) { 
      this.hexInputField.focus();
    }
  }

  componentWillReceiveProps(nextProps) {
    const colorState = ColorHelper.toState(nextProps.color, this.state.oldHue);
    colorState.inputValue = colorState.hex.replace('#', ''); // preset the input value field
    colorState.inputAlphaValue = normalizeAlpha(colorState.rgb.a);

    const colorRgbString = tinycolor2(nextProps.color).toRgbString();
    colorState.currentRgbString = colorRgbString;
    colorState.newRgbString = colorRgbString;

    this.setState(colorState);
  }

  shouldComponentUpdate(nextprops, nextstate) {
    return shallowCompare(this, nextprops, nextstate);
  }

  onKeyUp({ keyCode }) {
    if (keyCode === ESC_KEY) {
      this.resetToOriginalColor();
      this.props.handleCancel();
    }
  }

  onKeyDown({ keyCode, target }) {
    if (keyCode === RETURN_KEY) {
      target.blur();
    }
    return true;
  }

  onChangeComplete() { 
    this.props.handleChangeComplete(this.state.rgb);

    // Send event if the color was selected from the Brand Color list
    const formatedColor = formatColor(this.state.newRgbString);   
    if(this.state.currentSelectedBrandColor === formatedColor) {
      logUIEvent({
        eventName: 'CB_COLOR_PICKER_APPLIED_BRAND_COLOR',
        eventData: {},
        workflow: 'brand_colors',
      });
    }
  }

  saveColor() {
    brandColorManager.addColor(this.state);
  }

  removePaletteColor() {
    brandColorManager.removeColor(this.state);
  }

  // inputType expected = "alpha, hue or saturation"
  onChange(inputType, data0) {
    const data = _.cloneDeep(data0);
    if (ColorHelper.simpleCheckForValidColor(data)) {
      if (data.a === 0 && inputType !== 'alpha') {
        data.a = 1;
      }
      const colors = ColorHelper.toState(data, data.h || this.state.oldHue);
      colors.inputValue = colors.hex.replace('#', ''); // preset the input value field
      colors.inputAlphaValue = normalizeAlpha(colors.rgb.a);
      colors.newRgbString = tinycolor2(colors.rgb).toRgbString();

      this.setState(colors);
      brandColorManager.removeFocus();
    }
  }

  onItemClick(color) {
    this.onChange('brand', color);
    this.setState({ currentSelectedBrandColor: color });
  }

  hideRemoveButton() {
    this.setState({ showRemoveButton: false });
  }

  showRemoveButton() {
    this.setState({ showRemoveButton: true });
  }

  onColorChange({ target }) {
    this.setState({ inputValue: target.value });
  }

  onAlphaInputChange({ target }) {
    // remove all non-digits from the input
    this.setState({ inputAlphaValue: target.value.replace(/\D/g, '') });
  }

  onAlphaInputBlur({ target }) {
    const newAlpha = parseInt(target.value, 10);

    let newState;
    if (validAlphaInput(newAlpha)) {
      const newColor = tinycolor2(this.state.rgb);
      newColor.setAlpha(newAlpha / 100);

      newState = ColorHelper.toState(newColor);
      const colorRgbString = newColor.toRgbString();
      newState.newRgbString = colorRgbString;
      newState.inputAlphaValue = newAlpha;
    } else {
      // restore previously good value
      newState = {
        inputAlphaValue: normalizeAlpha(this.state.rgb.a)
      };
    }

    this.setState(newState);
  }

  onColorInputBlur({ target }) {
    const newInputValue = target.value;
    if (ColorHelper.isValidHex(newInputValue)) {
      const newColor = tinycolor2(newInputValue);
      newColor.setAlpha(this.state.rgb.a);

      const updatedColorState = ColorHelper.toState(newColor);
      updatedColorState.inputValue = newInputValue;
      const colorRgbString = newColor.toRgbString();
      updatedColorState.inputAlphaValue = normalizeAlpha(updatedColorState.rgb.a);
      updatedColorState.newRgbString = colorRgbString;

      this.setState(updatedColorState);
    } else {
      this.resetToOriginalColor();
    }
  }

  onRemoveColor() {
    const newColor = tinycolor2(this.state.rgb);
    newColor.setAlpha(0);

    const updatedColorState = ColorHelper.toState(newColor);
    updatedColorState.newRgbString = newColor.toRgbString();
    updatedColorState.inputAlphaValue = 0;

    this.setState(updatedColorState);
  }

  onRequestFocus({ target }) {
    if (window.editor) {
      window.editor.keyController.requestFocus(target);
    }
  }

  resetToOriginalColor() {
    this.setState({ inputValue: this.state.hex.replace('#', '') }); // reset inputValue to previous
  }

  render() {
    let opacityControls;

    const alphaSliderStyles = reactCSS({
      'default': {
        checkboard: {
          display: 'none'
        },
        gradient: {
          background: '#1e1e1e',
          borderRadius: '14px'
        },
        container: {
          cursor: 'pointer'
        },
        slider: {
          width: '14px',
          borderRadius: '14px',
          height: '14px',
          background: '#8797a3',
          marginTop: '0',
          transform: 'translateX(-7px) translateY(-2px)',
          cursor: 'ew-resize'
        }
      }
    });

    const alphaInputStyle = {
      marginRight: '5px',
      width: '20px',
      textAlign: 'center'
    };

    if (this.props.showOpacity) {
      opacityControls = (
        <div id="opacity-controls" className='color-picker-alpha-container'>
          <div className='alpha-slider'>
            <Alpha
              {...this.state}
              onChange={_.partial(this.onChange, 'alpha')}
              style={alphaSliderStyles}
            />
          </div>
          <div className='color-picker-alpha-input'>
            <input
              className='form-elm-input-text color-value-input'
              value={this.state.inputAlphaValue}
              onFocus={this.onRequestFocus}
              onBlur={this.onAlphaInputBlur}
              onChange={this.onAlphaInputChange}
              onKeyDown={this.onKeyDown}
              maxLength={3}
              style={alphaInputStyle}
            />
          </div>
          <div className='color-picker-alpha'>
            <div
              id="remove-button"
              className='color-picker-alpha-default-button button svg-ico-transparent-icon'
              onClick={this.onRemoveColor}
            />
          </div>
        </div>
      );
    }

    const HuePointer = () =>
      <div className='color-picker-hue-indicator' />;

    const SaturationCrossHair = () =>
      <div className='color-picker-cross-hairs' />;

    return (
      <div id='color-picker' className={`color-picker-container${this.props.borderSelector ? ' left' : ''}${this.props.positionAbove ? ' above' : ''}`}>
        <div className='color-popdown'>
          <div className='color-inputs'>
            <div className='color-components'>
              <div className='saturation-and-hue'>
                <div className='saturation'>
                  <Saturation
                    {...this.state}
                    pointer={SaturationCrossHair}
                    onChange={_.partial(this.onChange, 'saturation')}
                  />
                </div>
                <div className='hue'>
                  <Hue
                    {...this.state}
                    pointer={HuePointer}
                    onChange={_.partial(this.onChange, 'hue')}
                    direction='vertical'
                  />
                </div>
              </div>
              {opacityControls}
            </div>

            <div className='color-values'>
              <div className='color-previews'>
                <div
                  className='color-new'
                  style={{ backgroundColor: this.state.newRgbString }}
                />
                <div
                  className='color-current'
                  style={{ backgroundColor: this.state.currentRgbString }}
                />
              </div>

              <div className='input-field'>
                <label className='form-elm-mini-label xs'>#</label>
                <input
                  className='form-elm-input-text color-value-input'
                  ref={(r) => (this.hexInputField = r)}
                  value={this.state.inputValue}
                  onFocus={this.onRequestFocus}
                  onBlur={this.onColorInputBlur}
                  onChange={this.onColorChange}
                  onKeyDown={this.onKeyDown}
                  onKeyUp={this.onKeyUp}
                  maxLength={6}
                />
              </div>
            </div>
          </div>
          <BrandColor
            id='brand-color'
            currentColor={this.state.currentRgbString}
            onItemClick={this.onItemClick}
            saveColor={this.saveColor}
            hideRemoveButton={this.hideRemoveButton}
            showRemoveButton={this.showRemoveButton}
          />
          <div className='color-picker-footer'>
            <div style={{ display: 'flex' }}>
              {this.state.showRemoveButton &&
                <a id='remove-palette-color-button' className='delete-color-button' onClick={this.removePaletteColor}>
                  <span className="button delete svg-ico-delete" style={{ backgroundColor: 'inherit', color: '#AEAEAE' }}>
                    Remove Color
                  </span> 
                </a>
              } 
            </div>
            <div style={{ display: 'flex' }}>
              <a className='button modal-button push-left' onClick={this.props.handleCancel}>
                <span className='label' >Cancel</span>
              </a>
              <a className='button modal-button action' onClick={this.onChangeComplete}>
                <span className='label'>Choose</span>
              </a>
            </div>

          </div>

        </div>
      </div>
    );
  }
}

ColorPopdown.propTypes = {
  color: PropTypes.object,
  handleChangeComplete: PropTypes.func,
  handleCancel: PropTypes.func,
  saveColor: PropTypes.func,
  showOpacity: PropTypes.bool,
  borderSelector: PropTypes.bool,
  positionAbove: PropTypes.bool
};

export default ColorPopdown;