/* globals lp, Class */
var jQuery = require('jquery');

var TextElement = Class.create(lp.pom.VisibleElement, lp.ModuleComponent, {
  type: 'lp-pom-text',

  initialize: function ($super, page, jso) {
    if (page.isEditMode()) {
      delete jso.geometry.size.height;
    }

    this.updatingHeight = false;

    $super(page, jso);
  },

  getModelClass: function () {
    return lp.module.text.TextModel;
  },

  createDefaultConstraints: function ($super) {
    $super();
    this.defaultConstraints.opacityable = true;
    this.defaultConstraints.scalable = true;
    this.defaultConstraints.height_resizeable = false;

    this.model.setMinSize({ width: 20, height: 0 });
  },

  getContent: function (content0) {
    var content =
      content0 || (this.model.exists('content.text') ? this.model.get('content.text') : '');
    return content;
  },

  initView: function () {
    var error = null;

    try {
      this.view.update(this.getContent());

      if (this.page.isPublishOrPreviewMode()) {
        this.addGoalToLinks();
      }

      if (this.isBetterLineHeight()) {
        this.getViewDOMElement().addClassName('nlh');
      }

      // If this text element has been modified by the new inline text editor, add a
      // class so that line-height will be overridden to 1 (see comment at top of
      // text_element_inline.js)
      if (this.model.safeGet('content.editedInline')) {
        this.getViewDOMElement().addClassName('inline');
      }
    } catch (e) {
      console.warn('Caught error in TextElement::initView', error);
      error = e;
    }

    if (this.page.isFormConfirmation()) {
      jQuery(this.view.e)
        .find('ub\\:dynamic')
        .each(function () {
          this.outerHTML = this.outerHTML.replace('ub:dynamic', 'ub:clientsidedynamic');
        });
    }

    this.handleErrors(error, false, null);
  },

  isBetterLineHeight: function () {
    return this.model.exists('betterLineHeight') && this.model.get('betterLineHeight') === true;
  },

  addGoalToLinks: function () {
    const addGoalAttributeToLinks = this.page.getConfigValue('addGoalAttributeToLinks');

    [...this.view.e.querySelectorAll('a')]
      .filter(anchor => anchor.href)
      .forEach(anchor => {
        if (addGoalAttributeToLinks) {
          if (this.page.goals.urlIsActiveGoal(anchor.href)) {
            anchor.setAttribute('data-goal', '');
          }
        } else {
          anchor.href = this.page.CTAifyLink(anchor.href);
        }

        if (!anchor.target && this.page.isFormConfirmation()) {
          anchor.target = '_parent';
        }
      });
  },

  setHandleVisibility: function (transformBox) {
    transformBox.rmh.setVisible(true);
    transformBox.lmh.setVisible(true);

    //TODO:
    var canScale = window.editor.page.isMobileBreakpoint();

    transformBox.tlh.setVisible(canScale);
    transformBox.trh.setVisible(canScale);
    transformBox.blh.setVisible(canScale);
    transformBox.brh.setVisible(canScale);

    // the height can only be changed from the corners
    transformBox.tmh.setVisible(false);
    transformBox.bmh.setVisible(false);
  },

  setDimensions: function ($super, dims) {
    if (this.updatingHeight) {
      return;
    }

    if (this.hasBorder() && this.model.get('geometry.borderLocation') === 'inside') {
      var adjust = this.getBorderWidth() * 2;
      dims = { width: dims.width - adjust, height: dims.height - adjust };
    }

    if (this.page.isEditMode()) {
      this.updateWidth(dims.width);
      this.updateHeight();
    } else {
      // autoscale: apply text transform
      dims = this.adjustDimensions(dims);
      this.applyPageStyles([
        { attribute: 'width', value: dims.width + 'px' },
        { attribute: 'height', value: dims.height + 'px' },
        { attribute: 'transform', value: this.page.getScale() },
        { attribute: 'transform-origin', value: `0 0` },
      ]);
    }
  },

  getContentHeight: function () {
    var height;
    if (this.hasBorder()) {
      height = this.view.getHeight() - this.getBorderWidth() * 2;
    } else {
      height = this.view.getHeight();
    }

    return Math.round(height * this.model.getScale());
  },

  isHeightResizeable: function () {
    return false;
  },

  updateHeight: function (undoManager) {
    this.updatingHeight = true;

    this.getViewDOMElement().style.height = 'auto';

    var height = this.view.getHeight();
    if (height > 0) {
      this.model.setHeight(height, undoManager);
    }

    this.updatingHeight = false;
  },

  updateWidth: function (inputWidth) {
    this.view.setWidth(inputWidth);
  },

  getText: function () {
    return this.model.get('content.text');
  },

  getFonts: function () {
    return this.model.getValuesFromAllBreakpoints('content.fonts');
  },

  setText: function (text, undoManager) {
    if (this.model.get('content.text') === text) {
      return;
    }

    var error = null;

    if (undoManager) {
      undoManager.startGroup();
    }

    try {
      this.model.set('content.text', text, undoManager);
      this._updateFontList(text, undoManager);
    } catch (e) {
      console.warn('Caught error in TextElement::setText', e);
      error = e;
    }

    this.handleErrors(error, true, undoManager);

    if (this.page.isEditMode()) {
      this.updateHeight(undoManager);
    }

    if (undoManager) {
      undoManager.endGroup();
    }
  },

  _updateFontList: function (t, um) {
    var matchedList = this._getFontsText(t);
    var fontList = matchedList.map(function (font) {
      return this._getFontFromResult(font);
    }, this);

    this.model.set('content.fonts', fontList.flatten().uniq(), um);
  },

  _getFontsText: function (text) {
    text = text.replace(/&#27;/g, "'").replace(/&quot;/g, '"');
    var regex = /(font-family:[a-zA-Z0-9, \-\'\"]*;)/gi;
    return text.match(regex) || [];
  },

  _getFontFromResult: function (font) {
    return this._cleanFontString(font.split(':')[1]);
  },

  _cleanFontString: function (fontString) {
    return fontString
      .strip()
      .replace(';', '') // drop the semi-colons
      .split(',')
      .collect(this._stripWhitespace);
  },

  _stripWhitespace: function (string) {
    return string.replace(/^\s+|\s+$/g, ''); // trim extra whitespaces
  },

  handleErrors: function (error, hasChanged, undoManager) {
    if (error) {
      this.model.set('content.valid', false, undoManager);
      this.model.set('content.errors', [error ? error.toString() : ''], undoManager);
    } else if (hasChanged) {
      this.model.set('content.errors', [], undoManager);
      this.model.set('content.valid', true, undoManager);
    }
  },

  canHaveGoals: function () {
    return true;
  },

  // This is extremely smelly. Overlap with pagegoals and CTA links??
  getGoals: function () {
    return this.view.e
      .select('a')
      .collect(function (a) {
        if (
          (a.href === '' && a.name !== '') ||
          a.href.startsWith(window.location.href + '#') ||
          a.href.startsWith('mailto:')
        ) {
          return null;
        }
        return {
          type: 'link',
          url: a.href,
        };
      })
      .compact();
  },

  deactivate: function () {
    this.fireEvent('goalChanged', this);
  },
});

TextElement.defaultText = 'Double-click to edit this text';

TextElement.elementDefaults = {
  name: 'Text',
  betterLineHeight: true,
  style: {
    background: {
      backgroundColor: 'fff',
      opacity: 0,
    },
    newBackground: {
      type: 'solidColor',
      solidColor: {},
    },
    effect: {
      enabledTypes: ['none'],
      opacity: 25,
      color: '000000',
      offset: {
        left: 0,
        top: 4,
      },
      blurRadius: 4,
    },
  },
  geometry: {
    position: 'absolute',
    offset: { top: 0, left: 0 },
    size: { height: 0, width: 300 },
    borderLocation: 'outside',
  },
  content: {
    text: '<p>' + TextElement.defaultText + '</p>',
    valid: true,
    errors: [],
    fonts: [],
  },
  breakpoints: {
    mobile: {
      geometry: {
        scale: 'fit',
      },
    },
  },
};

module.exports = TextElement;
