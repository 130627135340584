/* globals Class,lp */

var MultiSelectModule = Class.create(
  lp.ModuleBase,
  lp.ModuleElementMethods,
  lp.ModuleElementPropertiesPanelMethods,
  {
    createElement: function (page, options) {
      options = options || {};
      options.offset = options.offset || { left: 0, top: 0 };

      /**
       * When a user multi-selects elements we temporarily create a multi-select element.
       * Sometimes this element can get saved to the POM and will prevent pages from loading
       * as it doesn't have a value for options.size.width
       */
      if (options.type === 'lp-multi-select') {
        return;
      }

      if (options.center) {
        options.offset.left = options.offset.left - 50;
        options.offset.top = options.offset.top - 50;
      }

      var elm = new (this.getElementClass())(page, {
        name: this.defaultName,
        geometry: {
          position: 'absolute',
          offset: { top: options.offset.top, left: options.offset.left },
          size: { width: options.size.width, height: options.size.height },
        },
      });

      elm.parentElement = options.parentElement;
      this.addElementListeners(elm);
      return elm;
    },
  }
);

lp.addModule(
  new MultiSelectModule({
    type: 'lp-multi-select',
    version: '1.0',
    name: 'Multi Select',
    defaultName: 'Multiple Selection',
    usageType: 'editor-extension',
    namespace: lp.module.multiSelect,
  })
);

module.exports = MultiSelectModule;
