/* globals Class, $H */

var rxUi = window.rxUi;

window.jui.TriPane = Class.create( window.jui.Component, {
  options: function($super,options){
    return $super($H({
      attributes:{className:'tri-pane'}
    }).merge(options));
  },

  initialize:function($super, options){
    this.widths = options.widths || {};

    $super(options);
    this._uiBinding = new rxUi.Binding('TriPane');
    this._uiBinding.register(
      rxUi.fromEvent([this.dividers.left, this.dividers.right], 'click')
        .map(function(ev) {
          var pane = ev.target.classList.contains('left') ? 'left' : 'right';
          return {type:'toggle', pane: pane};
        }))
      .pluck('pane').ub_subscribe(this.toggle.bind(this));
  },

  installUI: function($super) {
    /* jshint maxstatements:44 */
    $super();
    this.panes = {};
    this.dividers = {};

    this.panes.left     = this.insert(new window.jui.Component({attributes:{className:'pane left editor-pane elements-pane'}}));
    this.dividers.left  = this.insert(new window.jui.Component({attributes:{className:'tri-pane-divider editor-divider-left divider left editor-divider'}})).update('<span class="editor-divider-handle editor-divider-handle-left"></span>');
    this.panes.center   = this.insert(new window.jui.Component({attributes:{className:'pane center editor-pane canvas-pane'}}));
    this.dividers.right = this.insert(new window.jui.Component({attributes:{className:'tri-pane-divider editor-divider-right divider right editor-divider'}})).update('<span class="editor-divider-handle editor-divider-handle-right"></span>');
    this.panes.right    = this.insert(new window.jui.Component({attributes:{className:'pane right editor-pane options-pane'}}));

    if (typeof this.options.top !== 'undefined') {
      this.setTop(this.options.top);
    }

    if (typeof this.options.bottom !== 'undefined') {
      this.setBottom(this.options.bottom);
    }

    if (typeof this.options.height !== 'undefined') {
      this.setHeight(this.options.height);
    }
  },

  setView: function(view, which) {
    this.panes[which].clear();
    this.panes[which].insert(view);

    if (which === 'left' || which === 'right') {
      this.widths[which] = view.getWidth();
      if (this.panes[which].visible()) {
        this.showPane(which);
      }
    }

    return view;
  },

  toggle: function(which) {
    this[this.panes[which].visible() ? 'hidePane' : 'showPane'](which);
  },


  showPane: function(which) {
    switch (which) {
      case 'left':
        this.panes.left.show();
        this.dividers.left.e.addClassName('open');
        this.addClassName('left-pane-open');
        break;
      case 'right':
        this.panes.right.show();
        this.dividers.right.e.addClassName('open');
        this.addClassName('right-pane-open');
        break;
    }

    this.fireEvent('layoutChanged');
  },

  hidePane: function(which) {
    switch (which) {
      case 'left':
        this.panes.left.hide();
        this.dividers.left.e.removeClassName('open');
        this.removeClassName('left-pane-open');
        break;
      case 'right':
        this.panes.right.hide();
        this.dividers.right.e.removeClassName('open');
        this.removeClassName('right-pane-open');
        break;
    }

    this.fireEvent('layoutChanged');
  }
});
