/* globals Class, $H */

window.jui.FormOffsetInput = Class.create(window.jui.Component, window.jui.ControlModel, {
  options: function ($super, options, label = 'Position', labelL = 'L', labelT = 'T') {
    return $super(
      $H({
        attributes: { className: 'form-elm offset-input' },
        label: label,
        labelL: labelL,
        labelT: labelT,
      }).merge(options)
    );
  },

  initialize: function ($super, options) {
    this.focusField = null;
    $super(options);

    if (this.options.onfocus) {
      this.addListener('focus', this.options.onfocus);
    }

    if (this.options.onblur) {
      this.addListener('blur', this.options.onblur);
    }
  },

  installUI: function ($super) {
    $super();
    var self = this;

    this.insertOffsetFields();

    [this.left, this.top].each(function (field) {
      field.observe('focus', self.onFocus.bind(self));
      field.observe('blur', self.onBlur.bind(self));
      field.observe('keypress', window.jui.NumericInputFilter.filter);
    });
  },

  insertOffsetFields: function () {
    this.insert(new Element('label', { className: 'form-elm-label' }).update(this.options.label));
    this.insert(
      new Element('label', { className: 'mini form-elm-mini-label' }).update(this.options.labelL)
    );
    this.left = this.insert(
      new Element('input', {
        type: 'text',
        className:
          'text left form-elm-input form-elm-input-text form-elm-with-label form-elm-mini-input',
      })
    );
    this.insert(
      new Element('label', { className: 'mini form-elm-mini-label' }).update(this.options.labelT)
    );
    this.top = this.insert(
      new Element('input', {
        type: 'text',
        className:
          'text top form-elm-input form-elm-input-text form-elm-with-label form-elm-mini-input',
      })
    );
  },

  setValue: function (value) {
    // TODO: JS: validation
    this.left.value = value.left;
    this.top.value = value.top;
  },

  getValue: function () {
    return { left: this.left.value * 1, top: this.top.value * 1 };
  },

  onFocus: function (e) {
    this.memorizedValues = {
      left: this.left.value,
      top: this.top.value,
    };

    if (this.isEnabled()) {
      e.stop();
      this.fireEvent('focus', this);
      this.focusField = e.findElement('input');
    }
  },

  onkeydown: function (e /*, controller */) {
    if (e.keyCode === Event.KEY_RETURN) {
      this.blur();
      e.stop();
    }
  },

  onBlur: function (e) {
    if (this.isEnabled() && this._isValidValue(e.target.value)) {
      e.stop();
      this.fireEvent('blur', this);
      this.focusField = null;
    } else {
      this._resetValues();
    }
  },

  blur: function () {
    if (this.focusField && this.focusField.blur) {
      this.focusField.blur();
    }
  },

  _isValidValue: function (value) {
    return value !== null && value !== '' && !isNaN(value);
  },

  _resetValues: function () {
    this.left.value = this.memorizedValues.left || 0;
    this.top.value = this.memorizedValues.top || 0;
  },
});
