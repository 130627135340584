var pomUpdates = require('ub_legacy/lp/pom/updates/updates');

pomUpdates.add(Object.extend({

  minVersion: '2.3',
  version: '2.4',

  run: function(jso) {
    if(this.checkVersion(jso)) {
      this.updateScriptPlacement(jso);
      jso.version = this.version;
    }
  },

  updateScriptPlacement: function(jso) {
    var userStylesAndScripts = this.getUserScripts(jso);
    userStylesAndScripts.each(function(el) {
      if(el.placement === 'before-body-end-tag') {
        el.placement = 'body:after';
      } else if (el.placement === 'after-body-tag') {
        el.placement = 'body:before';
      }
    });
  },

  getUserScripts: function(jso) {
    return jso.elements.findAll(function(el) {
      return el.type === 'lp-script' || el.type === 'lp-stylesheet';
    });
  }
}, window.lp.pom.updater));
