/* globals Class, $H */
window.jui.FieldErrorMessage = Class.create(window.jui.Component, {
  options: function($super, options) {
    return $super($H({
      attributes: {className: 'field-error-message'},
    }).merge(options));
  },

  initialize: function($super, options) {
    $super(options);
    this.field = options.field;
    this.field.insert(this.messageBox);
  },

  installUI: function($super) {
    $super();
    this.messageBox = new Element('div', {
      className: 'inline-error'
    });
    this.message = new Element('div');
    this.messageBox.update(this.message);
    this.hide();
  },

  setValue: function(msg) {
    this.message.update(msg);
  },

  hide: function() {
    this.setValue('');
    this.messageBox.hide();
  },
  show: function(msg) {
    this.setValue(msg);
    this.messageBox.show();
  }

});
