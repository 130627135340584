/* globals Class, $H */

window.jui.TabView = Class.create( window.jui.Component, {
  options: function($super,options){
    return $super($H({
      attributes:{className:'tab-view'}
    }).merge(options));
  },

  initialize:function($super, options){
    this.tabViewItems = $H({});
    this.tabs = $H({});
    this.tabGroup = null;
    this.selectedTabViewItem = null;
    $super(options);
    if (this.options.change){this.addListener('change', this.options.change);}
  },

  installUI: function($super) {
    $super();
    this.tabBar = this.insert(new window.jui.Component({attributes:{className:'tab-bar'}}));
    this.viewPane = this.insert(new window.jui.Component({attributes:{className:'view-pane options-panel-view-pane'}}));
  },

  addTabViewItem: function(item) {
    this.tabViewItems.set(item.id, item);
    var options = {label:item.label, action:this.selectTab.bind(this, item.id), attributes:{className:'tab tab-bar-tab options-panel-tab-bar-tab'}};
    if (this.tabGroup !== null) {
      options.radioGroup = this.tabGroup;
    }
    var tab = this.tabBar.insert(new window.jui.RadioButton(options));
    this.tabs.set(item.id, tab);
    if (this.tabGroup === null) {
      this.tabGroup = tab.group;
    }
  },

  selectTab: function(id) {
    // this wrapper allows rxUi to intercept the source events and
    // separate then from the action to take - this.tabSelected in this case
    this.tabSelected(id);
  },

  tabSelected: function(id) {
    if (this.selectedTabViewItem !== null && this.selectedTabViewItem.view) {
      document.activeElement.blur();
      this.selectedTabViewItem.view.remove();
      if (this.selectedTabViewItem.deselect) {
        this.selectedTabViewItem.deselect();
      }
    }
    this.selectedTabViewItem = this.tabViewItems.get(id);

    if (this.selectedTabViewItem === null) {
      return;
    }

    if (this.selectedTabViewItem.view !== null) {
      this.viewPane.insert(this.selectedTabViewItem.view);
    }

    if (this.selectedTabViewItem.select) {
      this.selectedTabViewItem.select();
    }

    this.fireEvent('change', this.selectedTabViewItem);
  },

  setTabViewItem: function(id) {
    if (this.selectedTabViewItem !== null && this.selectedTabViewItem.id === id) {
      return;
    }
    this.tabGroup.setActiveButton(this.tabs.get(id));
    this.tabSelected(id);
  },

  getTabViewItem: function(id) {
    return this.tabViewItems.get(id);
  },

  replaceTabViewItemView: function(id, view) {
    if (this.selectedTabViewItem !== null && this.selectedTabViewItem.id === id) {
      this.selectedTabViewItem.view.remove();
      this.selectedTabViewItem.view = view;
      this.viewPane.insert(view);
      if (this.selectedTabViewItem.select) {
        this.selectedTabViewItem.select();
      }
    } else {
      this.tabViewItems.get(id).view = view;
    }
  },

  getView: function(id) {
    return this.tabViewItems.get(id).view;
  },

  getTab: function(id) {
    return this.tabs.get(id);
  },

  getViews: function() {
    return this.tabViewItems.values().pluck('view');
  }
});
