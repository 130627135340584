export const defaultJpegQualityValue = 60;
export const globalOverrideDefault = false;
export const compressPngDefault = true;

export const setDefaultModelQuality = elm => {
  const mimeType = elm.model.safeGet('content.asset.content_content_type');
  const globalQuality = getGlobalImageQuality(elm);

  if (mimeType === 'image/jpeg') {
    elm.set('geometry.transform.quality', getJpegSettingsFromGlobal(globalQuality));
  } else if (mimeType === 'image/png') {
    elm.set('geometry.transform.quality', getPngSettingsFromGlobal(globalQuality));
  };
};

export const getGlobalImageQuality = (elm) => {
  const globalImageQualitySettings = elm.page.settings.globalImageQuality;
  const defaults = getDefaultGlobalImageQuality();

  return {
    ...defaults,
    ...globalImageQualitySettings,
    globalOverride: globalOverrideDefault
  };
};

export const getDefaultGlobalImageQuality = () => {
  return {
    value: defaultJpegQualityValue,
    compressPng: compressPngDefault,
  };
};

export const getGlobalJpegQuality = (elm) => {
  const { value, globalOverride } = getGlobalImageQuality(elm);
  return {
    value,
    globalOverride,
  };
};

export const getGlobalPngQuality = (elm) => {
  const { compressPng, globalOverride } = getGlobalImageQuality(elm);
  return {
    compressPng,
    globalOverride,
  };
};

export const getJpegSettingsFromGlobal = ({ value, globalOverride }) => {
  return {value, globalOverride};
};

export const getPngSettingsFromGlobal = ({ compressPng, globalOverride }) => {
  return {compressPng, globalOverride};
};

export const setGlobalImageQuality = (quality0, elm) => {
  const quality = JSON.parse(JSON.stringify(quality0));
  const globalImageQualitySettings = getGlobalImageQuality(elm);

  if (typeof quality.value === 'undefined') {
    quality.value = globalImageQualitySettings.value;
  }
  if (typeof quality.compressPng === 'undefined') {
    quality.compressPng = globalImageQualitySettings.compressPng;
  }
  elm.page.settings.globalImageQuality = { ...quality };
};

export const getDefaultJpegQuality = () => {
  return {
    value: defaultJpegQualityValue,
    globalOverride: globalOverrideDefault,
  };
};

export const getDefaultPngQuality = () => {
  return {
    compressPng: compressPngDefault,
    globalOverride: globalOverrideDefault,
  };
};

export const setImageElementQuality = (quality0 = {}, um, elm) => {
  const quality = JSON.parse(JSON.stringify(quality0));
  const mimeType = elm.model.safeGet('content.asset.content_content_type');

  if(mimeType === 'image/jpeg') {
    setJpegQuality(quality, um, elm);
  } else if (mimeType === 'image/png') {
    setPngQuality(quality, um, elm);
  }
};

export const setJpegQuality = (quality, um, elm) => {
  const globalImageQuality = getGlobalImageQuality(elm);
  const elmQuality = elm.model.safeGet('geometry.transform.quality');

  if (typeof quality.value === 'undefined') {
    quality.value = elmQuality ? elmQuality.value : globalImageQuality.value;
  }
  if (typeof quality.globalOverride === 'undefined') {
    quality.globalOverride = elmQuality ? elmQuality.globalOverride : globalImageQuality.globalOverride;
  }

  elm.set('geometry.transform.quality', {
    value: quality.value,
    globalOverride: quality.globalOverride,
  }, um);
};

export const setPngQuality = (quality, um, elm) => {
  const globalImageQuality = getGlobalImageQuality(elm);
  const elmQuality = elm.model.safeGet('geometry.transform.quality');

  if (typeof quality.compressPng === 'undefined') {
    quality.compressPng = elmQuality ? elmQuality.compressPng : globalImageQuality.compressPng;
  }
  if (typeof quality.globalOverride === 'undefined') {
    quality.globalOverride = elmQuality ? elmQuality.globalOverride : globalImageQuality.globalOverride;
  }

  elm.set('geometry.transform.quality', {
    compressPng: quality.compressPng,
    globalOverride: quality.globalOverride,
  }, um);
};

export const getUpdatedImageQuality = (element, elementAsset) => {
  const globalImageQuality = getGlobalImageQuality(element);
  let updatedQuality;

  if (elementAsset.content_content_type === 'image/jpeg') {
    updatedQuality = getUpdatedJpegQuality(element, globalImageQuality);
  } else if (elementAsset.content_content_type === 'image/png') {
    updatedQuality = getUpdatedPngQuality(element, globalImageQuality);
  }

  return {...globalImageQuality, ...updatedQuality};
};

export const getUpdatedJpegQuality = (element, globalQuality) => {
  const elementQuality = element.model.safeGet('geometry.transform.quality');

  if (typeof elementQuality === 'undefined') {
    setJpegQuality(globalQuality, null, element);
    return globalQuality;
  } else if (!elementQuality.globalOverride) {
    return globalQuality;
  } else {
    return elementQuality;
  }
};

export const getUpdatedPngQuality = (element, globalQuality) => {
  const elementQuality = element.model.safeGet('geometry.transform.quality');

  if (typeof elementQuality === 'undefined') {
    setPngQuality(globalQuality, null, element);
    return globalQuality;
  } else if (!elementQuality.globalOverride) {
    return globalQuality;
  } else {
    return elementQuality;
  }
};

export const isValidJpegQuality = quality => {
  if (!quality){
    return false;
  }
  const {modelQuality, globalQuality} = quality;
  if (modelQuality && modelQuality.globalOverride){
    return Boolean(modelQuality.value && modelQuality.value <= 100);
  } else if (globalQuality) {
    return Boolean(globalQuality.value && globalQuality.value <= 100);
  }
  return false;
};

export const isValidPngQuality = quality => {
  if (!quality){
    return false;
  }
  const {modelQuality, globalQuality} = quality;
  if (modelQuality && modelQuality.globalOverride) {
    return typeof modelQuality.compressPng === "boolean";
  } else if (globalQuality) {
    return typeof globalQuality.compressPng === "boolean";
  }
  return false;
};

export const getMaxImageQuality = element => {
  const mimeType = element.model.safeGet('content.asset.content_content_type');

  if (mimeType === 'image/jpeg') {
    return { value: 100, globalOverride: true };
  } else if (mimeType === 'image/png') {
    return { compressPng: false, globalOverride: true };
  }
};
