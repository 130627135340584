export const insertAnimationKeyframes = animationType => {
  let style = document.querySelector(`style[data-animation-type="${animationType}"]`);
  if (style) style.remove();

  style = document.createElement('style');
  style.setAttribute('data-animation-type', animationType);
  document.head.appendChild(style);
  style.textContent = getStyleContent(animationType);
};

export const getAnimationKeyframesInsertions = page => {
  return page
    .getElementsByTypeList(['lp-pom-box', 'lp-pom-text', 'lp-pom-button', 'lp-pom-image'])
    .filter(el => {
      const enabledTypes = el.model.safeGet('style.effect.enabledTypes');
      return enabledTypes ? enabledTypes.includes('animation') : false;
    })
    .map(el => {
      const animationType = el.model.safeGet('style.effect.animationType');
      return {
        content: `<style data-animation-type="${animationType}">${getStyleContent(animationType)}</style>`,
        placement: 'head',
      };
    });
};

const getStyleContent = effectName => {
  return effectLookupTable[effectName].styleText || '';
};

// TODO: no, i'm not sure I like this either
const effectLookupTable = {
  bounceIn: {
    styleText: `/* Bounce In */
      .ub-ani-bounceIn.ub-ani-play {
        -webkit-animation: bounceIn 2s cubic-bezier(0.215, 0.61, 0.355, 1);
        -moz-animation: bounceIn 2s cubic-bezier(0.215, 0.61, 0.355, 1);
        -o-animation: bounceIn 2s cubic-bezier(0.215, 0.61, 0.355, 1);
        animation: bounceIn 2s cubic-bezier(0.215, 0.61, 0.355, 1); }
      @-webkit-keyframes bounceIn {
        0% {
          -webkit-transform: scale(0.3);
          opacity: 0;}
        40% {
          -webkit-transform: scale(1.1);
          opacity: 0.9;}
        80% {
          -webkit-transform: scale(0.8);
          opacity: 1;}
        100% {
          -webkit-transform: scale(1);
          opacity: 1;}}
      @-moz-keyframes bounceIn {
        0% {
          -moz-transform: scale(0.3);
          opacity: 0;}
        40% {
          -moz-transform: scale(1.1);
          opacity: 0.9;}
        80% {
          -moz-transform: scale(0.8);
          opacity: 1;}
        100% {
          -moz-transform: scale(1);
          opacity: 1;}}
      @-o-keyframes bounceIn {
        0% {
          -o-transform: scale(0.3);
          opacity: 0;}
        40% {
          -o-transform: scale(1.1);
          opacity: 0.9;}
        80% {
          -o-transform: scale(0.8);
          opacity: 1;}
        100% {
          -o-transform: scale(1);
          opacity: 1;}}
      @keyframes bounceIn {
        0% {
          transform: scale(0.3);
          opacity: 0;}
        40% {
          transform: scale(1.1);
          opacity: 0.9;}
        80% {
          transform: scale(0.8);
          opacity: 1;}
        100% {
          transform: scale(1);
          opacity: 1;}}`,
  },
  bounceOut: {
    styleText: `/* Bounce Out */
      .ub-ani-bounceOut.ub-ani-play {
        -webkit-animation: bounceOut 2s cubic-bezier(0.215, 0.61, 0.355, 1);
        -moz-animation: bounceOut 2s cubic-bezier(0.215, 0.61, 0.355, 1);
        -o-animation: bounceOut 2s cubic-bezier(0.215, 0.61, 0.355, 1);
        animation: bounceOut 2s cubic-bezier(0.215, 0.61, 0.355, 1); }
      @-webkit-keyframes bounceOut {
        20% {-webkit-transform: scale(0.9);}
        50%, 65% {
          -webkit-transform: scale(1.1);
          opacity: 1;}
        100% {
          -webkit-transform: scale(0.3);
          opacity: 0;}
      }
      @-moz-keyframes bounceOut {
        20% {-moz-transform: scale(0.9);}
        50%, 65% {
          -moz-transform: scale(1.1);
          opacity: 1;}
        100% {
          -moz-transform: scale(0.3);
          opacity: 0;}
      }
      @-o-keyframes bounceOut {
        20% {-o-transform: scale(0.9);}
        50%, 65% {
          -o-transform: scale(1.1);
          opacity: 1;}
        100% {
          -o-transform: scale(0.3);
          opacity: 0;}
      }
      @keyframes bounceOut {
        20% {transform: scale(0.9);}
        50%, 65% {
          transform: scale(1.1);
          opacity: 1;}
        100% {
         transform: scale(0.3);
         opacity: 0;}}`,
  },
  fadeIn: {
    styleText: `/* fadeIn */
      .ub-ani-fadeIn.ub-ani-play {
        -webkit-animation: fadeIn 1s ease-in;
        -moz-animation: fadeIn 1s ease-in;
        -o-animation: fadeIn 1s ease-in;
        animation: fadeIn 1s ease-in; }
      @-webkit-keyframes fadeIn {
        0% {opacity: 0; }
        100% {opacity: 1; } }
      @-moz-keyframes fadeIn {
        0% {opacity: 0; }
        100% {opacity: 1; } }
      @-o-keyframes fadeIn {
        0% {opacity: 0; }
        100% {opacity: 1; } }
      @keyframes fadeIn {
        0% {opacity: 0; }
        100% {opacity: 1; } }`,
  },
  fadeOut: {
    styleText: `/* fadeOut */
      .ub-ani-fadeOut.ub-ani-play {
        -webkit-animation: fadeOut 1s ease-out;
        -moz-animation: fadeOut 1s ease-out;
        -o-animation: fadeOut 1s ease-out;
        animation: fadeOut 1s ease-out; }
      @-webkit-keyframes fadeOut {
        0% {opacity: 1;}
        100% {opacity: 0;}}
      @-moz-keyframes fadeIn {
        0% {opacity: 1;}
        100% {opacity: 0;}}
      @-o-keyframes fadeIn {
        0% {opacity: 1;}
        100% {opacity: 0;}}
      @keyframes fadeIn {
        0% {opacity: 1;}
        100% {opacity: 0;}}`,
  },
  slideUp: {
    styleText: `/* slideUp */
      .ub-ani-slideUp.ub-ani-play {
        -webkit-animation: slideUp 1s;
        -moz-animation: slideUp 1s;
        -o-animation: slideUp 1s;
        animation: slideUp 1s; }
      @-webkit-keyframes slideUp {
        0% {-webkit-transform: translate3d(0, 100%, 0); }
        100% {-webkit-transform: none; } }
      @-moz-keyframes slideUp {
        0% {-moz-transform: translate3d(0, 100%, 0); }
        100% {-moz-transform: none; } }
      @-o-keyframes slideUp {
        0% {-o-transform: translate3d(0, 100%, 0); }
        100% {-o-transform: none; } }
      @keyframes slideUp {
        0% {transform: translate3d(0, 100%, 0); }
        100% {transform: none; } }`,
  },
  slideDown: {
    styleText: `/* slideDown */
      .ub-ani-slideDown.ub-ani-play {
        -webkit-animation: slideDown 1s;
        -moz-animation: slideDown 1s;
        -o-animation: slideDown 1s;
        animation: slideDown 1s;}
      @-webkit-keyframes slideDown {
        0% {-webkit-transform: translate3d(0, -100%, 0); }
        100% {-webkit-transform: none; } }
      @-moz-keyframes slideDown {
        0% {-moz-transform: translate3d(0, -100%, 0); }
        100% {-moz-transform: none; } }
      @-o-keyframes slideDown {
        0% {-o-transform: translate3d(0, -100%, 0); }
        100% {-o-transform: none; } }
      @keyframes slideDown {
        0% {transform: translate3d(0, -100%, 0); }
        100% {transform: none; } }`,
  },
  bounce: {
    styleText: `/* Bounce */
      .ub-ani-bounce.ub-ani-play {
        -webkit-animation: bounce 2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
        -moz-animation: bounce 2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
        -o-animation: bounce 2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
        animation: bounce 2s cubic-bezier(0.445, 0.05, 0.55, 0.95); }
      @-webkit-keyframes bounce {
        0%, 20%, 53%, 80%, 100% {-webkit-transform: translate3d(0, 0, 0); }
        40%, 43% {-webkit-transform: translate3d(0, -6vh, 0); }
        70% {-webkit-transform: translate3d(0, -3vh, 0); }
        90% {-webkit-transform: translate3d(0, -1vh, 0); } }
      @-moz-keyframes bounce {
        0%, 20%, 53%, 80%, 100% {-moz-transform: translate3d(0, 0, 0); }
        40%, 43% {-moz-transform: translate3d(0, -6vh, 0); }
        70% {-moz-transform: translate3d(0, -3vh, 0); }
        90% {-moz-transform: translate3d(0, -1vh, 0); } }
      @-o-keyframes bounce {
        0%, 20%, 53%, 80%, 100% {-o-transform: translate3d(0, 0, 0); }
        40%, 43% {-o-transform: translate3d(0, -6vh, 0); }
        70% {-o-transform: translate3d(0, -3vh, 0); }
        90% {-o-transform: translate3d(0, -1vh, 0); } }
      @keyframes bounce {0%, 20%, 53%, 80%, 100% {
          transform: translate3d(0, 0, 0); }
        40%, 43% {transform: translate3d(0, -6vh, 0); }
        70% {transform: translate3d(0, -3vh, 0); }
        90% {transform: translate3d(0, -1vh, 0); } }`,
  },
  flash: {
    styleText: `/* Flash */
      .ub-ani-flash.ub-ani-play {
        -webkit-animation: flash 2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
        -moz-animation: flash 2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
        -o-animation: flash 2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
        animation: flash 2s cubic-bezier(0.445, 0.05, 0.55, 0.95); }
      @-webkit-keyframes flash {
        0%, 50%, 100% {opacity: 1; }
        25%, 75% {opacity: 0; } }
      @-moz-keyframes flash {
        0%, 50%, 100% {opacity: 1; }
        25%, 75% {opacity: 0; } }
      @-o-keyframes flash {
        0%, 50%, 100% {opacity: 1; }
        25%, 75% {opacity: 0; } }
      @keyframes flash {
        0%, 50%, 100% {opacity: 1; }
        25%, 75% {opacity: 0; } }`,
  },
  pulse: {
    styleText: `/* Pulse */
      .ub-ani-pulse.ub-ani-play {
        -webkit-animation: pulse 2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
        -moz-animation: pulse 2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
        -o-animation: pulse 2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
        animation: pulse 2s cubic-bezier(0.445, 0.05, 0.55, 0.95); }
      @-webkit-keyframes pulse {
        0% {-webkit-transform: scale3d(1, 1, 1); }
        50% {-webkit-transform: scale3d(1.1, 1.1, 1.1); }
        100% {-webkit-transform: scale3d(1, 1, 1); } }
      @-moz-keyframes pulse {
        0% {-moz-transform: scale3d(1, 1, 1); }
        50% {-moz-transform: scale3d(1.1, 1.1, 1.1); }
        100% {-moz-transform: scale3d(1, 1, 1); } }
      @-o-keyframes pulse {
        0% {-o-transform: scale3d(1, 1, 1); }
        50% {-o-transform: scale3d(1.1, 1.1, 1.1); }
        100% {-o-transform: scale3d(1, 1, 1); } }
      @keyframes pulse {
        0% {transform: scale3d(1, 1, 1); }
        50% {transform: scale3d(1.1, 1.1, 1.1); }
        100% {transform: scale3d(1, 1, 1); } }`,
  },
  shake: {
    styleText: `/* Shake */
      .ub-ani-shake.ub-ani-play {
        -webkit-animation: shake 2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
        -moz-animation: shake 2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
        -o-animation: shake 2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
        animation: shake 2s cubic-bezier(0.445, 0.05, 0.55, 0.95); }
      @-webkit-keyframes shake {
        0%, 100% {-webkit-transform: translate3d(0, 0, 0); }
        10%, 30%, 50%, 70%, 90% {-webkit-transform: translate3d(-1vw, 0, 0); }
        20%, 40%, 60%, 80% {-webkit-transform: translate3d(1vw, 0, 0); } }
      @-moz-keyframes shake {
        0%, 100% {-moz-transform: translate3d(0, 0, 0); }
        10%, 30%, 50%, 70%, 90% {-moz-transform: translate3d(-1vw, 0, 0); }
        20%, 40%, 60%, 80% {-moz-transform: translate3d(1vw, 0, 0); } }
      @-o-keyframes shake {
        0%, 100% {-o-transform: translate3d(0, 0, 0); }
        10%, 30%, 50%, 70%, 90% {-o-transform: translate3d(-1vw, 0, 0); }
        20%, 40%, 60%, 80% {-o-transform: translate3d(1vw, 0, 0); } }
      @keyframes shake {
        0%, 100% {transform: translate3d(0, 0, 0); }
        10%, 30%, 50%, 70%, 90% {transform: translate3d(-1vw, 0, 0); }
        20%, 40%, 60%, 80% {transform: translate3d(1vw, 0, 0); } }`,
  },
  swing: {
    styleText: `/* Swing */
      .ub-ani-swing.ub-ani-play {
        -webkit-transform-origin: top center;
        -moz-transform-origin: top center;
        -o-transform-origin: top center;
        transform-origin: top center;
        -webkit-animation: swing 2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
        -moz-animation: swing 2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
        -o-animation: swing 2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
        animation: swing 2s cubic-bezier(0.445, 0.05, 0.55, 0.95); }
      @-webkit-keyframes swing {
        20% {-webkit-transform: rotate3d(0, 0, 1, 15deg); }
        40% {-webkit-transform: rotate3d(0, 0, 1, -10deg); }
        60% {-webkit-transform: rotate3d(0, 0, 1, 5deg); }
        80% {-webkit-transform: rotate3d(0, 0, 1, -5deg); }
        100% {-webkit-transform: rotate3d(0, 0, 1, 0deg); } }
      @-moz-keyframes swing {
        20% {-moz-transform: rotate3d(0, 0, 1, 15deg); }
        40% {-moz-transform: rotate3d(0, 0, 1, -10deg); }
        60% {-moz-transform: rotate3d(0, 0, 1, 5deg); }
        80% {-moz-transform: rotate3d(0, 0, 1, -5deg); }
        100% {-moz-transform: rotate3d(0, 0, 1, 0deg); } }
      @-o-keyframes swing {
        20% {-o-transform: rotate3d(0, 0, 1, 15deg); }
        40% {-o-transform: rotate3d(0, 0, 1, -10deg); }
        60% {-o-transform: rotate3d(0, 0, 1, 5deg); }
        80% {-o-transform: rotate3d(0, 0, 1, -5deg); }
        100% {-o-transform: rotate3d(0, 0, 1, 0deg); } }
      @keyframes swing {
        20% {transform: rotate3d(0, 0, 1, 15deg); }
        40% {transform: rotate3d(0, 0, 1, -10deg); }
        60% {transform: rotate3d(0, 0, 1, 5deg); }
        80% {transform: rotate3d(0, 0, 1, -5deg); }
        100% {transform: rotate3d(0, 0, 1, 0deg); } }`,
  },
  none: {
    styleText: '',
  },
};
