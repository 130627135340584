var pomUpdates = require('ub_legacy/lp/pom/updates/updates');

pomUpdates.add({
  minVersion:'0',
  version:'1.0',
  run: function(jso) {
    var current = jso.version || '0';
    if (current >= this.version) {
      return;
    }

    if (current < this.minVersion) {
      throw new Error("Page version "+current+" can't be updated to version "+this.version);
    }

    jso.elements.each(function(e) {
      switch (e.type) {
        case 'lp-pom-block':
          e.geometry.borderLocation = 'outside';
          break;
        case 'lp-pom-box':
          e.geometry.borderLocation = 'inside';
          this.updateDimensionsBox(e);
          this.updateOffsetParent(e, jso);
          break;
        case 'lp-pom-image':
          e.geometry.borderLocation = 'outside';
          this.updateOffsetOutside(e);
          this.updateOffsetParent(e, jso);
          break;
        case 'lp-pom-text':
          e.geometry.borderLocation = 'outside';
          this.updateOffsetOutside(e);
          this.updateOffsetParent(e, jso);
          break;
        case 'lp-pom-button':
          e.geometry.borderLocation = 'inside';
          this.updateDimensionsButton(e);
          this.updateOffsetParent(e, jso);
          break;
        case 'lp-pom-form':
        case 'lp-code':
          this.updateOffsetParent(e, jso);
          break;
      }
    }, this);

    jso.version = this.version;
  },

  updateDimensionsBox: function(e) {
    if (!Object.isUndefined(e.style) && !Object.isUndefined(e.style.border) && !Object.isUndefined(e.style.border.width)) {
      var adjust = e.style.border.width * 2;
      e.geometry.size.width = (e.geometry.size.width * 1) + adjust;
      e.geometry.size.height = (e.geometry.size.height * 1) + adjust;
    }
  },

  updateDimensionsButton: function(e) {
    if (!Object.isUndefined(e.style.up) && !Object.isUndefined(e.style.up.border) && !Object.isUndefined(e.style.up.border.width)) {
      var adjust = e.style.up.border.width * 2;
      e.geometry.size.width = (e.geometry.size.width * 1) + adjust;
      e.geometry.size.height = (e.geometry.size.height * 1) + adjust;
    }
  },

  updateOffsetParent: function(e, jso) {
    var c = jso.elements.detect(function(c) {return c.id === e.containerId;});
    if (c.type === 'lp-pom-block') {
        return;
    }
    if (!Object.isUndefined(c.style) && !Object.isUndefined(c.style.border) && !Object.isUndefined(c.style.border.width)) {
      var adjust = c.style.border.width * 1;
      e.geometry.offset.left = (e.geometry.offset.left * 1) + adjust;
      e.geometry.offset.top = (e.geometry.offset.top * 1) + adjust;
    }
  },

  updateOffsetOutside: function(e, jso) {
    /* jshint unused:vars */
    if (!Object.isUndefined(e.style) && !Object.isUndefined(e.style.border) && !Object.isUndefined(e.style.border.width)) {
      var adjust = e.style.border.width * 1;
      e.geometry.offset.left = (e.geometry.offset.left * 1) + adjust;
      e.geometry.offset.top = (e.geometry.offset.top * 1) + adjust;
    }
  }
});
