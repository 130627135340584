var _ = require('lodash');

var _typeof = function(v) {
  if (v instanceof Array) {
    return 'array';
  } else {
    return typeof v;
  }
};

var _defaultLookupFn = function(featureDefinition, overrideValue) {
  if (overrideValue !== 'undefined') {
    return overrideValue;
  } else {
    return featureDefinition.default;
  }
};

var Banzai = function(definitions, overrides, /* optional */ dslFns) {
  // this._definitions = _.forIn(definitions, function(v, k) {
  // });
  this._definitions = definitions || {};
  this._overrides = overrides || {};
  this._initDSLBindings(dslFns);
  this._initBooleanProperties();
};

Banzai.prototype = {
  _initDSLBindings: function(dslFns) {
    var isFeatureEnabled = this.isFeatureEnabled.bind(this);
    isFeatureEnabled.__argtype__ = 'string';

    var getFeatureValue = this.getFeatureValue.bind(this);
    getFeatureValue.__argtype__ = 'string';

    var interpret = this.interpret.bind(this);

    var _all = function(args) {
      return _.every(args, _.partial(interpret, 'boolean'));
    };
    _all.__argtype__ = ['boolean'];

    var _any = function(args) {
      return _.some(args, _.partial(interpret, 'boolean'));
    };
    _any.__argtype__ = ['boolean'];

    var _not = function(arg) {
      return !arg;
    };
    _not.__argtype__ = 'boolean';

    var fromLocalStorage = function(key) {
      return JSON.parse(window.localStorage.getItem(key));
    };
    fromLocalStorage.__argtype__ = 'string';

    this._fnsAvailableToDSL = _.merge(
      {
        isFeatureEnabled: isFeatureEnabled,
        getFeatureValue: getFeatureValue,
        fromLocalStorage: fromLocalStorage,
        all: _all,
        any: _any,
        not: _not
      },
      dslFns || {}
    );
  },

  _getBooleanFeatures: function() {
    return _.pickBy(this._definitions, function(def) {
      return def && def.type === 'boolean';
    });
  },

  _initBooleanProperties: function() {
    this.features = {};
    var self = this;
    _.forOwn(this._getBooleanFeatures(), function(def, name) {
      var featureName = 'is' + name[0].toUpperCase() + name.slice(1) + 'Enabled';
      //TODO: figure out when it is safe to cache the results.
      // The original cached implementation failed when run in the
      // jasmine test enviroment as it changes the gon values
      // throughout the test runs:
      //TODO: can this be put in the jasmine setups -pk

      //var enabled = self.isFeatureEnabled(name);
      //self.features[featureName] = function () { return enabled;};

      self.features[featureName] = function() {
        return self.isFeatureEnabled(name);
      };
    });
  },

  _isValidFeatureValue: function(featureDefinition, value) {
    return typeof value === featureDefinition.type;
  },

  getFeatureValue: function(featureName, depth) {
    var featureDef = this._definitions[featureName];
    if (_.isUndefined(featureDef)) {
      throw new TypeError('missing feature definition for ' + featureName);
    } else if (!_.isObject(featureDef)) {
      throw new TypeError('invalid feature definition for ' + featureName);
    }

    var lookupFn = featureDef.lookup || _defaultLookupFn;
    var overrideValue = this._overrides[featureName];
    var lookup = lookupFn(featureDef, overrideValue);
    var value;
    if (!_.isUndefined(lookup)) {
      value = this.interpret(featureDef.type, lookup, depth);
    }

    if (this._isValidFeatureValue(featureDef, value)) {
      return value;
    } else {
      // TODO: log validation error
      return this.interpret(featureDef.type, featureDef.default);
    }
  },

  getDefaultValue: function(featureName) {
    var definition = this._definitions[featureName];
    return this.interpret(definition.type, definition.default);
  },

  isFeatureEnabled: function(featureName, depth) {
    try {
      return Boolean(this.getFeatureValue(featureName, depth));
    } catch (e) {
      if (window.gon && window.gon.env === 'test') {
        throw e;
      } else if (window.gon === undefined || window.gon.env !== 'production') {
        console.warn('error in banzai lookup: ' + featureName + ':' + e);
      }
      var errorTags = { tags: { banzaiLookupFailure: featureName } };
      try {
        // attempt to report the error but swallow any subsequent errors
        if (window.editor && window.editor.errorNotifier) {
          window.editor.errorNotifier.captureException(e, errorTags);
        } else if (window.Raven) {
          window.Raven.captureException(e, errorTags);
        }
      } catch (err) {
        console.warn(err);
      }
      return Boolean(this._definitions[featureName] && this._definitions[featureName].errorFallback);
    }
  },

  isFeatureDefined: function(featureName) {
    return Boolean(this._definitions[featureName]);
  },

  _MAX_EXPR_DEPTH: 10,
  interpret: function(expectedType, entry, depth) {
    if (!_.isNumber(depth)) {
      depth = 0;
    } else if (depth > this._MAX_EXPR_DEPTH) {
      throw new Error('too much recursion in banzai.interpret');
    }

    if (_typeof(entry) === expectedType) {
      return entry;
    } else if (_typeof(expectedType) === 'array') {
      return entry.map(function(item) {
        return this.interpret(expectedType[0], item, depth + 1);
      }, this);
    } else if (entry === null) {
      return null;
    } else if (_typeof(entry) === 'object') {
      // single key in {}: function to apply, like lisp sexps
      var fnName = _.keys(entry)[0];
      var fn = this._fnsAvailableToDSL[fnName];
      // only 1 argument allowed, 2nd slot is reserved for threading `depth` in
      var fnArg = this.interpret(fn.__argtype__, entry[fnName], depth + 1);
      var res = fn(fnArg, depth + 1);
      if (_typeof(res) === expectedType) {
        return res;
      } else if (_.isEqual(res, fnArg)) {
        throw new TypeError('loop found in banzai.interpret: ' + entry + ' ' + res);
      } else {
        return this.interpret(expectedType, res, depth + 1);
      }
    } else {
      throw new TypeError(
        'invalid type found in banzai.interpret: val=' + entry + ' expected type=' + expectedType
      );
    }
  }
};

Banzai.prototype.getFeatureValues = function() {
  var self = this;
  var table = {};
  _.forEach(this._definitions, function(entry, key) {
    table[key] = self.getFeatureValue(key);
  });
  return table;
};

Banzai.prototype._dump = function() {
  var self = this;
  var table = {};
  var renderEntryExpr = function(expr) {
    return typeof expr === 'object' ? JSON.stringify(expr) : expr;
  };

  var sortedKeys = Object.keys(this._definitions).sort();

  _.forEach(sortedKeys, function(key) {
    var entry = self._definitions[key];

    table[key] = {
      current: self.getFeatureValue(key),
      default: renderEntryExpr(entry.default),
      override: renderEntryExpr(self._overrides[key]),
      description: entry.description,
      type: entry.type
    };
  });

  console.table(table, ['current', 'default', 'override', 'description', 'type']);
};

if (typeof window === 'object') {
  window.Banzai = Banzai;
}

module.exports = Banzai;
