/* globals Class, $H */

window.jui.FormRadioInput = Class.create( window.jui.Component, window.jui.ControlModel, {
  options: function($super,options){
    return $super($H({
      attributes:{className:'form-elm checkbox-input radio-input-wrapper'},
      label:'Checkbox',
      labelClass: ''
    }).merge(options));

  },

  initialize:function( $super, options ){
    $super( options );

    if ( this.options.onclick ) {
      this.addListener( 'click', this.options.onclick );
    }
  },

  installUI: function( $super ) {
    $super();
    var checkboxAttrs = {type:'radio', className:'checkbox radio-input-item'};
    var labelClass = "";

    if (this.options.labelClass) {
      labelClass = " " + this.options.labelClass;
    }

    var labelAttrs = {className:'right' + labelClass};

    if (this.options.checked) {
      checkboxAttrs.checked = true;
    }

    if (this.options.name) {
      checkboxAttrs.name = true;
    }

    if (this.options.value) {
      checkboxAttrs.value = true;
    }

    if (this.options.inputId) {
      checkboxAttrs.id = this.options.inputId;
      labelAttrs.htmlFor = this.options.inputId;
    }

    this.checkbox = this.insert(new Element('input', checkboxAttrs));

    this.label = this.insert( new Element('label', labelAttrs)
      .update(this.options.label));

    this.checkbox.observe('click', this.onClick.bind(this));
  },

  setValue: function(bool) {
    this.checkbox.checked = bool;
  },

  getValue: function() {
    return this.checkbox.checked;
  },

  onClick: function() {
    if( this.isEnabled() ) {
      this.fireEvent('click', this);
    }
  }
});
