import pomUpdates from 'ub_legacy/lp/pom/updates/updates';
import MigratorUtils from 'ub/fonts/pom-updater/migrator-utils';
import checkVersion from './checkVersion';

pomUpdates.add({
  minVersion: '3.0',
  version: '3.1',
  run: function(originalData) {
    const canUpdate = checkVersion(this.minVersion, this.version, originalData.mainPage.version);

    if (canUpdate) {
      const newPagesData = MigratorUtils.migrateFonts(originalData);

      newPagesData.mainPage.version = this.version;
      newPagesData.subPages.forEach((page) => {
        page.version = this.version;
      });

      return newPagesData;
    } else {
      return originalData;
    }
  }
});
