var pomUpdates = require('ub_legacy/lp/pom/updates/updates');

pomUpdates.add( Object.extend( {

  minVersion: '1.7',
  version: '1.8',

  run: function( jso ) {

    if( this.checkVersion( jso ) ) {
      this.addJQueryToPage( jso );
      jso.version = this.version;
    }

  },

  addJQueryToPage: function( jso ) {
    jso.elements.unshift( this.getJQueryObject() );
  },

  getJQueryObject: function() {
    return {
      name: 'jQuery (1.4.2)',
      containerId: null,
      index: 0,
      type: 'lp-script',
      predefined: true,
      group: 'jquery',
      placement: 'head',
      content: {
        type: null,
        html: '<script src="http://ajax.googleapis.com/ajax/libs/jquery/1.4.2/jquery.min.js" type="text/javascript"></script>'
      }
    };
  }

}, window.lp.pom.updater ) );
