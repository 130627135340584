var _ = require('lodash');

/* globals lp,Class */
module.exports = Class.create(lp.pom.VisibleElementModel, {
  initialize: function($super, element, jso) {
    $super(element, jso);
    this.addBreakpointModifyableAttributes({
      publishedStyles: true,
      style: {
        label: {
          font:{
            size: true,
            family: true,
            weight: true,
            style: true
          },
          color: true,
          centerAlign: true
        },
        cbxlabel: {
          color: true,
          font: {
            size: true,
            family: true,
            weight: true,
            style: true
          }
        },
        field: {
          height: true,
          margin: {
            bottom: true,
          },
          fontSize: true,
          cornerRadius: true,
          border: {
            style: true,
            width: true,
            color: true
          },
          innerShadow: true,
          backgroundColor: true,
          color: true
        },
      },
      geometry: {
        buttonPlacement: true,
        visible: true,
        offset: {
          left: true,
          top: true
        },
        size: {
          width: true,
          height: true
        },
        minSize: {
          width: true,
          height: true
        },
        field: {
          offset: {
            left: true,
            top: true,
          },
          height: true,
          width: true,
          margin: {
            bottom: true,
          },
          fontSize: true,
          cornerRadius: true,
          border: {
            color: true,
            style: true,
            width: true
          }
        },
        label: {
          height: true,
          width: true,
          calculatedWidth: true,
          alignment: true,
          margin: {
            bottom: true,
            right: true
          }
        }
      },
      lightboxSize: true
    });

    this.addBreakpointShallowModifyableAttributes({
      fields: true,
      groups: true,
      labels: true,
      containers: true
    });

    this.addBreakpointAllowDuplicateAttributes({
      geometry:{
        visible:true,
        keepCircular: true,
        label: {
          centerAlign: true
        },
        field: {
          innerShadow: true,
        },
      }
    });
  },

  confirmationType: function() {
    var selector = 'content.confirmAction';
    return this.exists(selector) ? this.get(selector) : 'modal';
  },

  isConfirmationDialogUsed: function() {
    return this.confirmationType()  === 'modal';
  },

  attributeChangeIgnore: function(accessor, accessorList) {
    var accessorToIgnore  = accessorList || [
      'fields',
      'containers',
      'groups',
      'labels',
      'content.confirmationPageSize'
    ];
    return !!accessorToIgnore.find(function(access){
      return accessor.startsWith(access);
    });
  },

  confirmActionIsPost: function() {
    return this.safeGet('content.confirmAction') === 'post';
  },

  getPassParams: function() {
    if (this.confirmActionIsPost()) {
      return this.safeGet('content.passParamsOnPost');
    } else {
      return this.safeGet('content.passParams');
    }
  },

  setPassParams: function(value, undoManager) {
    if (this.confirmActionIsPost()) {
      return this.set('content.passParamsOnPost', value, undoManager);
    } else {
      return this.set('content.passParams', value, undoManager);
    }
  },

  linksToExternalLightbox: function() {
    return this.safeGet('content.confirmAction') === 'externalLightbox' &&
      ! _.isEmpty(this.safeGet('content.confirmURL'));
  }
});
