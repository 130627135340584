/* globals lp,Class */
var inheritLastButtonStyle = require('ub/control/inherit-button-style');

var ButtonModule = Class.create(
  lp.ModuleBase,
  lp.ModuleElementMethods,
  lp.ModuleElementPropertiesPanelMethods,
  {
    beforeCreate: function (page, defaults) {
      if (page.getConfigValue('allowExternalLightboxLinks')) {
        defaults.lightboxSize = lp.module.form.FormElement.lightboxSizeDefault;
      }

      defaults.action.target = page.getConfigValue('linkTargetDefault');
      inheritLastButtonStyle(page, defaults);
    },
  }
);

lp.addModule(
  new ButtonModule({
    type: 'lp-pom-button',
    version: '1.0',
    name: 'Button',
    usageType: 'page-widget',
    namespace: lp.module.button,
  })
);

module.exports = ButtonModule;
