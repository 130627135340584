(function() {
  var dnd = window.jui.dnd;

  var TargetFinder = function(point, source, group) {
    this.point = point;
    this.source = source;
    this.group = group;
  };

  TargetFinder.prototype = (function() {
    var _isTargetRoot = function(t) {
      return !(t && t.elm);
    };

    var _isTargetVisible = function(target) {
      if(!target && !target.elm ||  _isTargetRoot(target)){return true;}

      if(target.elm.getType() === 'lp-pom-block') {
        return target.elm.view.visible();
      } else {
        var visible = target.elm.visible();
        return !visible ? false : _isTargetVisible({elm: target.elm.parentElement});
      }
    };

    var _isFirstVisibleParent = function(target, point, source) {
      return target.isWithin(point, source) && _isTargetVisible(target);
    };

    return {
      all: function() {
        return this.group.getTargets();
      },

      target: function(group) {
        /* jshint unused:vars */
        var found = null,
          self = this;

        self.all().forEach(function(target) {

          // return first visible parent as target
          if (_isFirstVisibleParent(target, self.point, self.source)) {
            if (found === null) {
              found = target;
            } else {
              found = found.topMost(target);
            }
          }
        });

        return found;
      }
    };
  })();


  dnd.TargetFinder = TargetFinder;

})();
