const getDynamicTagName = (editor) => {
  return editor.page.isFormConfirmation() ? 'ub:clientsidedynamic' : 'ub:dynamic';
};

const getDynamicTagQueryName = (editor) => {
  return editor.page.isFormConfirmation() ? 'ub\\:clientsidedynamic' : 'ub\\:dynamic';
};

export default {
  getDynamicTagName,
  getDynamicTagQueryName
};