/* globals Class, $, $H */
var jQuery = require('jquery');
var stripScripts = require('ub/utils').stripScripts;
var dynamicTagHelper = require('ub/data/dtr-tag-helper.js').default;

(function() {
  var jui = window.jui;
  jui.FormDynamicContenteditable = Class.create(jui.FormContenteditable,
    jui.DynamicTagSelectable, jui.DynamicTagAttributeManager, {

    initialize: function($super, options) {
      $super($H({
        attributes  : {
          className : 'form-elm content-editable dynamic-content-editable'
        },
      }).merge(options));

      // Each instance requires a unique uiBinding (passed in through `options`), for the
      // dynamic text dialog opening event
      this._uiBinding = options.uiBinding;
      this._uiBinding.subscribeDomainEvents(this.domainEvents, this);

      this._dynamicEventListeners();
    },

    domainEvents: {
      showDynamicTextDialog: function(ev) {
        window.editor.getDynamicTextDialog().addListener('onDynamicTextDialogWindowClose', this);

        window.editor.showDynamicTextDialog(
          ev.defaultText,
          ev.attributes,
          this._insertDynamicElement.bind(this)
        );
      }
    },

    installUI: function($super) {
      $super();

      this.button = this._button();
      this.help   = this._help();
    },

    getSelection: function() {
      this.selection = document.getSelection();
      return this.selection;
    },

    getRange: function() {
      return this.range || this.getSelection().getRangeAt(0);
    },

    setRange: function(range){
      this.range = range;
    },

    showDynamicTextDialog: function(event) {
      if(this.button && this.button.e.hasClassName('dsbl')){return;}

      var selection = this.completeSelection(true),
          range     = selection.getRangeAt(0).cloneRange();

      this.setRange(range);
      this.currentElement = this._getDynamicElement(event);

      var element     = this.currentElement,
          contentEl   = this.documentFragmentToElement(range.cloneContents()),
          attributes  = this._getDynamicAttributes(element),
          defaultText = this._getSelectedText(contentEl);

      this._uiBinding.onNext({
        type        : 'showDynamicTextDialog',
        defaultText : defaultText,
        attributes  : attributes
      });
    },

    setValue: function(value) {
      this.editable.update(value);
      var dynamicTagName = dynamicTagHelper.getDynamicTagQueryName(window.editor);
      jQuery(this.editable)
        .find(dynamicTagName).attr('contenteditable', 'false');

      this._updateHiddenInput(value);
      this._addTitleToDynamicTags(this.getDynamicTagsFromEditable());
      this.addRemoveDynamicTagButton();
    },

    getValue: function() {
      return this._sanitize(this.input.getValue());
    },

    getSelectedElement: function() {
      var selection = this.completeSelection(true),
          range     = selection.getRangeAt(0).cloneRange();

      this.setRange(range);
      var  contentEl   = this.documentFragmentToElement(range.cloneContents());
      return jQuery(contentEl).children()[0];
    },

    replaceDynamicContents: function(element, range, text) {
      range.deleteContents();
      element.text(text);
      return element;
    },

    addRemoveDynamicTagButton: function() {
      var self = this;
      this.getDynamicTagsFromEditable().each(function(tag) {
        var span = self._findOrCreateCloseTag(tag);
        span.on('click', function() {
          self.removeClickedTag(tag, function(){
            jQuery(tag).find('span').remove();
            jQuery(tag).contents().unwrap();
          });
        });
        jQuery(tag).append(span);
      });
    },

    getDynamicTagsFromEditable: function(){
      return this.editable.getElementsBySelector('ub\\:dynamic');
    },

    /**
     * Private
     **/

  _findOrCreateCloseTag: function(tag) {
    var spanEl  = jQuery(tag).find('span'),
        self    = this,
        newSpan = jQuery('<span></span>').on('click', function(){
          self.removeClickedTag(tag, function(){
            self.editor.fire('saveSnapshot');
            self._removeDynamicTag(tag);
          });
        })
        .addClass('dynamic-tag-close svg-ico-close-dialog')
        .html('<img src="/images/transparent.gif" width="21" height="12" />');

    spanEl.remove();
    return newSpan;
  },

    _updateHiddenInput: function($super, value) {
      $super(this._sanitize(value));
    },

    _sanitize: function(value) {
      var temp  = new Element('div'),
          self  = this,
          dTags = temp.childElements('ub\\:dynamic');

      temp.update(value.replace(/<br>/gi,''));
      dTags.each(function(tag) {
        self._sanitizeUneededAttributesForSave(tag);
      });
      return temp.innerHTML;
    },

    _getDynamicElement: function(event) {
      if (this._isEventInDynamicTag(event)) {
        return event.target.cloneNode();
      } else {
        return this.getRange().startContainer;
      }
    },

    _dynamicEventListeners: function() {
      this.editable.observe('paste', this.onPaste.bind(this));
      this.editable.observe('dblclick', this.onDblClick.bind(this));

      var events = ['mousedown', 'mouseup', 'keydown'],
          self   = this;
      jQuery.each(events, function(i, eventName) {
        self.editable.observe(eventName, function(e){
          self.removeOnKeyPress(e, e.keyCode, function(){
            e.stop(e);
            self.trigger('blur');
          });
          if((e.type === 'mouseup' || e.type === 'keyup' && e.keyCode === self.KEYCODES.shift)) {
            self.completeSelection(e.type === 'mouseup');
          }
        });
      });

      this.editable.observe('mousedown', function(e) {
        // this is crazy firefox stuff. it doesn't get
        // focus when you click on the content.
        if (document.activeElement !== self.editable && self.isWithinADynamicElement(e.target)) {
          self.editable.focus();
        }
      });

      this.editable.observe('key', function(e) {
        var moveLeft   = e.keyCode === self.KEYCODES.left,
            moveRight  = e.keyCode === self.KEYCODES.right,
            cursorMove = (moveLeft || moveRight),
            element    = self.getRange().cloneRange().startContainer.parentNode;

      if (cursorMove && self.isWithinADynamicElement(element)) {
        var range     = self.getRange().cloneRange(),
            selection = self.getSelection(),
            newElement;

        if (moveLeft) {
          newElement = self._getClosestPreviousSibling(element);
          selection.collapse(newElement, 0);
        } else {
          newElement = self._getClosestNextSibling(element);
          selection.collapseToEnd();
        }

        range.selectNode(newElement);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    });
  },

    onPaste: function(e) {
      e.stop();
      var sanitizedText = stripScripts(e.clipboardData.getData('text'));
      document.execCommand('InsertHTML', false, sanitizedText);
    },

    onDblClick: function(e) {
      if(this._isEventInDynamicTag(e)) {
        this.showDynamicTextDialog(e);
      }
    },

    onFocus: function($super,e) {
      $super(e);
      if (this.button) {
        this.button.removeClassName('dsbl');
      }
    },

    onBlur: function($super, e) {
      $super(e);
      if (this.button) {
        this.button.addClassName('dsbl');
      }
    },

    onDynamicTextDialogWindowClose: function() {
      this.editable.focus();
      this.selectDynamicElement(document.createRange(), this.currentElement);
      window.editor.getDynamicTextDialog().removeListener('onDynamicTextDialogWindowClose');
    },

    _button: function(){
      var buttonParams = {
        label      : 'Dynamic Text',
        attributes : {
          className : 'button insert-dynamic dsbl',
          tabIndex  : '-1'
        },
        action    : this.showDynamicTextDialog.bind(this)
      };

      return this.insert(new jui.Button(buttonParams));
    },

    _help: function() {
      var helpParams = {
        attributes  : {
          href      : '#',
          className : this.options.className,
          target    : ''
        }
      };

      return this.insert(new jui.HelpButtonCustomizable(helpParams));
    },

    _insertDynamicElement: function(defaultText, attributes) {
      this.currentElement = this.insertDynamicElementIntoSelection(defaultText, attributes);
      this._updateHiddenInput(this.editable.innerHTML);
      this._cleanEmptyDynamicTags();
      this.editable.focus();
      if (this.options.afterInsert){ this.options.afterInsert();}
    },

  _getAllDynamicTags: function() {
    var dynamicTagName = dynamicTagHelper.getDynamicTagQueryName(window.editor);
    var dTags = jQuery(this.editable).find(dynamicTagName);
    return dTags;
  },

  _cleanEmptyDynamicTags: function() {
    var dTags = this._getAllDynamicTags(),
        self  = this;

    dTags.each(function(i, tag){
      self._removeTagIfEmpty(tag);
    });
  },

  _removeTagIfEmpty: function(tag) {
    var isEmpty = jQuery(tag).text().trim().length === 0;
    if(isEmpty) { jQuery(tag).remove(); }
  },

    _isEventInDynamicTag: function(e) {
      var dynamicTagName = dynamicTagHelper.getDynamicTagName(window.editor).toUpperCase();
      return e.target && $(e.target).tagName === dynamicTagName;
    },

    _removeChildrenBySelector: function(selector, ele) {
      ele.getElementsBySelector(selector).each(function(tag){
        tag.remove();
      });
      return ele;
    }

  });

})();
