import _ from 'lodash';
import * as pageStyleHelper from 'ub/elements/page-style-helper';

const HALF_DIVIDER = 2;

const applyLabelAlignStyles = (addRule, selectors, geometry, useAbsolutePositionedFields) => {
  const {fieldWrapper, labelTag, inputWrapper, multiGroup} = selectors;
  const {labelAlignment, labelMargin, labelWidth} = geometry;

  if (labelAlignment === 'left') {
    addRule(fieldWrapper, 'align-items', 'center');

    addRule(labelTag, 'display', 'inline-block');
    addRule(labelTag, 'width', `${labelWidth}px`);
    addRule(labelTag, 'margin-right', `${labelMargin.right}px`);

    if (useAbsolutePositionedFields) {
      addRule(inputWrapper, 'position', 'absolute');
    } else {
      addRule(inputWrapper, 'flex', '1');

      addRule(multiGroup, 'align-items', 'flex-start');

      addRule(fieldWrapper, 'display', 'flex');
    }
  }

  if (labelAlignment === 'top') {
    addRule(labelTag, 'display', 'block');
    addRule(labelTag, 'margin-bottom', `${labelMargin.bottom}px`);
    addRule(labelTag, 'width', 'auto');
    addRule(labelTag, 'margin-right', '0px');
  }
};

const applyContainerStyles = (formElm, addRule, selectors, geometry, useAbsolutePositionedFields) => {
  const {fieldWrapper, fieldsContainer, optionsList, optionTag} = selectors;
  const {fieldMargin, fieldWidth, groupWidth} = geometry;

  const margin = fieldMargin.bottom / HALF_DIVIDER;

  // BD-9347: it doesn't look like negative margin is needed in publish/preview
  // and it messes up autoscale when transform is applied. We should only apply
  // negative margin in edit mode, and assess how margin is applied in the future.
  // https://unbounce.atlassian.net/browse/BD-9347
  if (formElm.page.isEditMode()) {
    addRule(fieldsContainer, 'margin', formElm.page.getUnit(-margin));
  }
  
  // autoscale: add transform lp-pom-form element
  addRule(fieldsContainer, 'transform', formElm.page.getScale());
  addRule(fieldsContainer, 'transform-origin', `0 0`);

  if (useAbsolutePositionedFields) {
    // BD-9350: width is not needed when children use absolute positioning
    // and it causes white borders outside the page when using autoscale
    addRule(fieldsContainer, 'width', '0');

    addRule(fieldWrapper, 'position', 'absolute');
    addRule(optionTag, 'position', 'absolute');
    addRule(optionsList, 'position', 'absolute');
  } else {
    addRule(fieldsContainer, 'flex-flow', 'row wrap');

    addRule(fieldWrapper, 'position', 'relative');
    addRule(fieldWrapper, 'margin', `${margin}px`);
    addRule(fieldWrapper, 'width', `calc(${fieldWidth}% - ${fieldMargin.bottom}px)`);

    addRule(optionsList, 'position', 'relative');
    addRule(optionsList, 'display', 'flex');
    addRule(optionsList, 'flex-flow', 'row wrap');
    addRule(optionTag, 'width', `${groupWidth}%`);
  }
};

const applyFieldBorderStyles = (addRule, selectors, geometry) => {
  const {border} = geometry;
  const {fieldWrapper} = selectors;

  if (!border) {
    return;
  }

  const selector = [
    'input[type=text]',
    'input[type=email]',
    'input[type=tel]',
    'textarea',
    'select',
  ].map(type => `${fieldWrapper} ${type}`).join(', ');

  addRule(selector, 'border-style', border.style);

  if (border.style !== 'none') {
    addRule(selector, 'border-width', `${border.width}px`);

    if (border.color.trim()) {
      addRule(selector, 'border-color', `#${border.color}`);
    }
  }
};

const applyInputStyles = (addRule, selectors, geometry) => {
  const {textInputFields} = selectors;

  const {
    hasInnerShadow,
    shadow,
    fieldColor,
    fieldRadius,
    backgroundColor
  } = geometry;

  if (backgroundColor) {
    addRule(textInputFields, 'background-color', `#${backgroundColor}`);
  }

  if (fieldColor) {
    addRule(textInputFields, 'color', `#${fieldColor}`);
  }

  if (fieldRadius) {
    addRule(textInputFields, 'border-radius', `${fieldRadius}px`);
  }

  if (hasInnerShadow) {
    addRule(textInputFields, 'box-shadow', shadow);
    addRule(textInputFields, '-webkit-box-shadow', shadow);
    addRule(textInputFields, '-moz-box-shadow', shadow);
  }
};

const applySingleLineFieldStyles = (addRule, selectors, geometry) => {
  const {singeLineFields, dropdownSelect, multilineField} = selectors;

  const {
    fieldHeight,
    fieldFontSize,
    fieldPaddingTop,
    fieldPaddingBottom,
    border
  } = geometry;

  const borderMultiplier = 2;
  const borderWidthFallback = 1;
  const borderOffset = border.width * borderMultiplier || borderWidthFallback;
  const totalHeight = fieldHeight + borderOffset;

  addRule(singeLineFields, 'height', `${totalHeight}px`);
  addRule(dropdownSelect, 'height', `${fieldHeight}px`);

  addRule(singeLineFields, 'font-size', `${fieldFontSize}px`);
  addRule(singeLineFields, 'line-height', `${fieldFontSize}px`);

  addRule(singeLineFields, 'padding-left', `${fieldPaddingTop}px`);
  addRule(singeLineFields, 'padding-right', `${fieldPaddingTop}px`);
  addRule(singeLineFields, 'flex', '1');

  addRule(multilineField, 'padding-top', `${fieldPaddingTop}px`);
  addRule(multilineField, 'padding-bottom', `${fieldPaddingBottom}px`);
};

const applyOptionLabelStyles = (addRule, selectors, geometry) => {
  const {optionSubLabel, optionStyleSpan} = selectors;

  const {checkboxFont, checkboxLabelColor, checkboxRadioTextStyles} = geometry;

  const fontMultiplier = 1.16;
  const labelFontSize = Math.round(checkboxFont.size * fontMultiplier);

  addRule(optionSubLabel, 'font-family', checkboxFont.family);
  addRule(optionSubLabel, 'font-weight', checkboxFont.weight);
  addRule(optionSubLabel, 'font-size', `${checkboxFont.size}px`);
  addRule(optionSubLabel, 'color', `#${checkboxLabelColor}`);
  addRule(optionSubLabel, 'line-height', `${labelFontSize}px`);

  const styleWeight = checkboxRadioTextStyles.strong ? 'bolder' : 'inherit';
  const styleEm = checkboxRadioTextStyles.em ? 'italic' : 'inherit';

  addRule(optionStyleSpan, 'font-weight', styleWeight);
  addRule(optionStyleSpan, 'font-style', styleEm);
};

const applyLabelStyles = (addRule, selectors, geometry) => {
  const {labelTag, labelStyleSpan} = selectors;
  const {label, labelTextStyles} = geometry;

  const lineHeightMultiplier = 1.1;
  const lineHeight = Math.round(label.font.size * lineHeightMultiplier);

  const styleWeight = labelTextStyles.strong ? 'bolder' : 'inherit';
  const styleEm = labelTextStyles.em ? 'italic' : 'inherit';

  addRule(labelTag, 'font-family', label.font.family);
  addRule(labelTag, 'font-weight', label.font.weight);
  addRule(labelTag, 'font-size', `${label.font.size}px`);

  addRule(labelTag, 'line-height', `${lineHeight}px`);
  addRule(labelTag, 'color', `#${label.color}`);

  addRule(labelStyleSpan, 'font-weight', styleWeight);
  addRule(labelStyleSpan, 'font-style', styleEm);
};

const applyMultiLineInputStyles = (addRule, selectors, geometry, fieldData) => {
  const {fieldWrapper} = selectors;
  const fieldSelector = `${fieldWrapper} #${fieldData.id}`;

  const {fieldFontSize, fieldPaddingTop, fieldPaddingBottom, border} = geometry;

  const multiplier = 1.2;

  const lineHeight = Math.round(fieldFontSize * multiplier);
  const lineIsInUnits = fieldData.heightUnits === 'lines';

  const height = lineIsInUnits ? (lineHeight * fieldData.numberOfLines) : fieldData.pixelHeight;

  const borderMultiplier = 2;
  const ZERO = 0;
  const borderOffset = (border.width || ZERO) * borderMultiplier;
  const totalHeight = height + fieldPaddingTop + fieldPaddingBottom + borderOffset;

  addRule(fieldSelector, 'height', `${totalHeight}px`);
  addRule(fieldSelector, 'line-height', `${lineHeight}px`);
  addRule(fieldSelector, 'padding-top', `${fieldPaddingTop}px`);
  addRule(fieldSelector, 'padding-bottom', `${fieldPaddingBottom}px`);
};

const applyFieldSpecificStyles = (addRule, selectors, geometry, fieldViewMap) => {
  _(fieldViewMap)
    .filter((fieldView) => {
      return fieldView.field.lpType === 'multi-line-text';
    })
    .forEach((fieldData) => {
      applyMultiLineInputStyles(addRule, selectors, geometry, fieldData.field);
    });
};

const getGeometry = (formElm) => {
  const model = formElm.model;

  const formWidth = model.safeGet('geometry.size.width');
  const fieldWidth = model.safeGet('geometry.field.width');
  const fieldFontSize = model.safeGet('geometry.field.fontSize') ||
    formElm.getElementDefaults().geometry.field.fontSize;

  const fieldHeight = model.safeGet('geometry.field.height') ||
   formElm.getElementDefaults().geometry.field.height;

  const minPadding = 0;
  const padding = Math.max(minPadding, fieldHeight - fieldFontSize);
  const fieldPaddingTop = Math.floor(padding / HALF_DIVIDER);
  const fieldPaddingBottom = padding - fieldPaddingTop;
  const backgroundColor = model.safeGet('style.field.backgroundColor');

  const shadowColor = formElm.calculateShadowColor(backgroundColor || 'ffffff');
  const shadow = `inset 0px 2px 3px #${shadowColor}`;

  const checkboxFont = formElm.model.safeGet('style.cbxlabel.font') ||
    {size: 14, family: 'arial', weight: 'normal'};

  const checkboxLabelColor = formElm.model.safeGet('style.cbxlabel.color') || '000';

  const labelFallback = {
    font: {
      size: 14,
      family: 'arial',
      weight: 'normal'
    },
    color: '000'
  };

  const borderFallback = {
    color: "bbbbbb",
    style: "solid",
    width: 1
  };

  const label = model.safeGet('style.label') || labelFallback;
  const border = model.safeGet('geometry.field.border') || borderFallback;

  const textStylesFallback = {
    strong: true,
    em: false
  };

  const checkboxRadioStylesFallback = {
    strong: false,
    em: false
  };

  const labelTextStyles = model.safeGet('style.label.font.textStyles') || textStylesFallback;
  const checkboxRadioTextStyles =
    model.safeGet('style.cbxlabel.font.textStyles') || checkboxRadioStylesFallback;

  const publishedFieldStyles = model.safeGet('publishedStyles');

  return {
    publishedFieldStyles,
    checkboxRadioTextStyles,
    labelTextStyles,
    label,
    labelAlignment: model.safeGet('geometry.label.alignment'),
    labelMargin: model.safeGet('geometry.label.margin'),
    labelWidth: formElm.getCalculatedLabelWidth(),
    fieldMargin: model.safeGet('geometry.field.margin'),
    border,
    formWidth,
    fieldWidth,
    fieldFontSize,
    fieldHeight,
    fieldPaddingTop,
    fieldPaddingBottom,
    fieldColor: model.safeGet('style.field.color'),
    fieldRadius: model.safeGet('geometry.field.cornerRadius'),
    groupWidth: model.safeGet('geometry.field.groupWidth'),
    backgroundColor,
    shadow,
    hasInnerShadow: model.safeGet('style.field.innerShadow') || false,
    checkboxFont,
    checkboxLabelColor
  };
};

const applyFormStyles = (formElm, rules) => {
  const formIdSelector = `#${formElm.id}`;
  const pageStyles = formElm.page.style;

  pageStyles.removeCSSRules(formIdSelector);

  formElm.updateElementGeometry();

  _.forEach(rules, (attributes, selector) => {
    pageStyleHelper.addRuleSet(pageStyles, selector, attributes);
  });

  pageStyles.updatePageStyles();
};

const applyCalculatedFieldStyles = (addRule, selectors, geometry) => {
  const {publishedFieldStyles} = geometry;

  publishedFieldStyles
    .forEach((fieldStyle) => {
      const fieldContainerId = fieldStyle.selector;

      addRule(fieldContainerId, 'position', 'absolute');
      addRule(fieldContainerId, 'top', `${fieldStyle.top}px`);
      addRule(fieldContainerId, 'left', `${fieldStyle.left}px`);
      addRule(fieldContainerId, 'width', `${fieldStyle.width}px`);
      addRule(fieldContainerId, 'height', `${fieldStyle.height}px`);
    });
};

const buildCSSRules = (formElm, selectors, geometry, fieldViewMap, isPublishOrPreviewMode) => {
  const rules = {};

  const addRule = (sel, attribute, value) => {
    const newAtrrs = rules[sel] || {selector: sel};

    newAtrrs[attribute] = value;

    rules[sel] = newAtrrs;
  };

  // If publishedFieldStyles, they likely have republished without saving. Fall to
  // flexbox in this case
  const useAbsolutePositionedFields = isPublishOrPreviewMode &&
    !_.isEmpty(geometry.publishedFieldStyles);

  applyContainerStyles(formElm, addRule, selectors, geometry, useAbsolutePositionedFields);
  applySingleLineFieldStyles(addRule, selectors, geometry);
  applyLabelStyles(addRule, selectors, geometry);
  applyLabelAlignStyles(addRule, selectors, geometry, useAbsolutePositionedFields);
  applyFieldBorderStyles(addRule, selectors, geometry);
  applyOptionLabelStyles(addRule, selectors, geometry);
  applyInputStyles(addRule, selectors, geometry);

  applyFieldSpecificStyles(addRule, selectors, geometry, fieldViewMap);

  if (useAbsolutePositionedFields) {
    applyCalculatedFieldStyles(addRule, selectors, geometry);
  }

  return rules;
};

const createSelectorsFromId = (formId) => {
  const formIdSelector = `#${formId}`;
  const fieldWrapper = `${formIdSelector} .lp-pom-form-field`;

  return {
    formId: formIdSelector,
    fieldWrapper,
    labelTag: `${fieldWrapper} .lp-form-label`,
    labelStyleSpan: `${fieldWrapper} .lp-form-label .label-style`,
    inputWrapper: `${formIdSelector} .input-wrap`,
    fieldsContainer: `${formIdSelector} .fields`,
    multiGroup: `${formIdSelector} .multi-group`,
    optionsList: `${formIdSelector} .optionsList`,
    optionTag: `${formIdSelector} .option`,
    optionSubLabel: `${fieldWrapper} .opt-label`,
    optionStyleSpan: `${fieldWrapper} .opt-label .label-style`,
    textInputFields: `${fieldWrapper} .text`,
    singeLineFields: `${fieldWrapper} .single`,
    dropdownSelect: `${fieldWrapper} select`,
    multilineField: `${fieldWrapper} .form_elem_multi`
  };
};

const updateCSSRules = (options = {}, formElm, isPublishOrPreviewMode) => {
  const isMovingElement = options.accessor === 'geometry.offset';

  if (isMovingElement) {
    return;
  }

  const labelIsAffected = _.includes(options.accessor, 'content.fields') ||
    _.includes(options.accessor, 'geometry.label');

  if (labelIsAffected && formElm.page.isEditMode()) {
    formElm.calculateAndStoreCurrentLabelWidth();
  }

  const selectors = createSelectorsFromId(formElm.id);
  const geometry = getGeometry(formElm);
  const rules = buildCSSRules(
    formElm,
    selectors,
    geometry,
    formElm.fieldViewMap,
    isPublishOrPreviewMode
  );

  applyFormStyles(formElm, rules);
};

export default {
  updateCSSRules,
  createSelectorsFromId,
  buildCSSRules,
};
