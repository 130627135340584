import { getImageElementPublishUrls } from 'ub/control/image-publish-urls';

export const updateCanvasImageSource = (imgElement) => {
  // If the content.asset.sizeVerified` flag is not set, that means that we are currently verifying
  // the image asset size (an asynchronous process) - see ImageElement.prototype.verifyAssetSize.
  // While this is taking place, we want to show a loading spinner instead of a potentially
  // inaccurate image-service preview. When it has completed, `verifyAssetSize` will set the
  // `content.asset.sizeVerified` flag and then call this function again, and this guard will be
  // bypassed.
  if (!imgElement || !imgElement.model.safeGet('content.asset.sizeVerified')) {
    return;
  }

  const currentUrl = decodeURIComponent(imgElement.img.src);

  const breakpoint = imgElement.page.getCurrentBreakpoint();
  const newUrls = getImageElementPublishUrls(imgElement, breakpoint, false, false);
  const newUrl = new URL(decodeURIComponent(newUrls[0]), window.location).href;

  if (newUrl !== currentUrl) {
    swapImageSource(imgElement, newUrls);
  }
};

export const updateCanvasImageSources = (page, mimeType) => {
  page.elements
    .filter(el => el.model.safeGet('content.asset.content_content_type') === mimeType &&
      !el.model.safeGet('geometry.transform.quality.globalOverride'))
    .forEach(el => { updateCanvasImageSource(el); });
};

export const buildSrcSet = (urls) => urls.map((url, i) => `${url} ${i+1}x`).join();

export const previewMaxImageQuality = imgElement => {
  if (!imgElement || !imgElement.model.safeGet('content.asset.sizeVerified')) {
    return;
  }

  const breakpoint = imgElement.page.getCurrentBreakpoint();
  const previewUrl = getImageElementPublishUrls(imgElement, breakpoint, false, true);

  swapImageSource(imgElement, previewUrl);
};

export const restoreImageQuality = imgElement => {
  if (!imgElement || !imgElement.model.safeGet('content.asset.sizeVerified')) {
    return;
  }

  updateCanvasImageSource(imgElement);
};

// Private methods, exported for testing only

export const swapImageSource = (imgElement, updatedImageUrls) => {
  addLoadState(imgElement);
  // The first Image URL in this array is for the 1x scale.
  // At minimum, every image element should have at least a 1x resolution.
  imgElement.img.src = updatedImageUrls[0];
  imgElement.img.srcset = buildSrcSet(updatedImageUrls);
  imgElement.img.onload = () => {
    removeLoadState(imgElement);
  };
};

export const addLoadState = (imgElement) => {
  removeLoadState(imgElement);
  const loader = document.createElement('div');
  loader.classList.add('loader');
  imgElement.view.e.firstChild.appendChild(loader);
};

export const removeLoadState = (imgElement) => {
  const loaders = imgElement.view.e.firstChild.querySelectorAll('.loader');
  if (loaders.length > 0) {
    loaders.forEach(e => e.parentNode.removeChild(e));
  }
};
