// A T&Cs requirement of Unsplash's API is that we send a request
// to their 'views' endpoint every time a landing page/convertable
// containing one or more Unsplash photos is viewed by a visitor.
//
// This should happen regardless of whether it's being used as an image or background image.
//
// https://medium.com/unsplash/partners-photo-view-tracking-c76b4aede1cd

const UNSPLASH_DOM_ID = 'unsplash-trk';
const unsplashCaptureRegex = /^unsplash-([\w\d]+)/;

const getPhotoIdFromImgName = (imgName) => {
  const captureGroups = imgName.match(unsplashCaptureRegex);

  if (captureGroups) {
    const [ , photoId] = captureGroups;

    return photoId;
  } else {
    console.error('Unable to parse out Unsplash photo ID');

    return '';
  }
};

const createImgPixel = () => {
  return `<div id="${UNSPLASH_DOM_ID}"></div>`;
};

const getUnsplashIds = (elements, breakpoint) => {
  return elements
    .filter(({ model }) => model.safeGet('geometry.visible', breakpoint))
    .map(({ model }) => (
      model.safeGet('content.asset.name', breakpoint) ||
      model.safeGet('style.background.image.content_file_name', breakpoint)
    ))
    .filter(isUnsplashImage)
    .map(getPhotoIdFromImgName)
    .join(',');
};

const createStylesheetForBreakpoint = (unsplashIds, breakpoint) => {
  if (!unsplashIds.length) { return; }

  const isMediaQuery = !breakpoint.default;

  const unsplashCss =
    `#${UNSPLASH_DOM_ID} {background-image: url("https://views.unsplash.com/v?app_id=27955&photo_id=${unsplashIds}");}`;

  return isMediaQuery ?
    `@media only screen and (max-width:${breakpoint.width}px) {${unsplashCss}}`:
    unsplashCss;
};

const createStylesheet = (page) => {
  const breakpoints = page.getBreakPoints();

  const allStyleSheets = breakpoints
    .filter(({ name }) => page.isBreakpointEnabled(name))
    .map(breakpoint => {
      const unsplashIds = getUnsplashIds(page.elements, breakpoint);

      return createStylesheetForBreakpoint(unsplashIds, breakpoint);
    })
    .filter(Boolean)
    .join('\n');

  if (allStyleSheets.length) {
    return `<style>${allStyleSheets}</style>`;
  }
};

export const isUnsplashImage = (imgName) => unsplashCaptureRegex.test(imgName);

export const getUnsplashTrackingPixel = (page) => {
  const styleSheets = createStylesheet(page);

  if (styleSheets) {
    return {
      content: `${styleSheets}${createImgPixel()}`,
      placement: 'body:after'
    };
  }
};
