/* globals Class, $H, $A */
window.jui.FormLinkInput = Class.create( window.jui.Component, window.jui.ControlModel, {
  options: function($super,options){
    return $super($H({
      attributes:{className:'form-elm link-input'},
      label:'Link',
      selectOptions:[
        {name:'http', value:'http'},
        {name:'https', value:'https'}
      ]
    }).merge(options));

  },

  initialize:function( $super, options ){
    $super( options );

    if ( this.options.onfocus ) {
      this.addListener('focus', this.options.onfocus);
    }

    if ( this.options.onblur ) {
      this.addListener('blur', this.options.onblur);
    }
  },

  installUI: function( $super ) {
    $super();

    this.protocol = this.insert(new window.jui.FormSelect({
      label             : this.options.label,
      attributes        : {className: 'form-elm select social-url-protocol'},
      dropDownClassName : 'editor-info-panel-select jui-drop-down social-url-protocol-dropdown',
      id                : 'jui-protocol-form-selector',
      selectOptions     : this.options.selectOptions,
      action            : this.onBlur.bind(this)
    }));

    this.link = this.protocol.insert(new Element('input', {
      type:'text',
      className:'text social-url-input form-elm-input-text'
    }));

    if (this.options.inputPosition) {
      this.input.addClassName(this.options.inputPosition);
    }

    // this.protocol.selectedIndex = 0;

    this.protocol.observe('change',this.onBlur.bind(this));
    this.link.observe('focus', this.onFocus.bind(this));
    this.link.observe('blur', this.onBlur.bind(this));
  },

  setSelectOptions: function(selectOptions) {
    this.select.update(this.createOptionsForSelect(selectOptions));
  },

  setSelectedValue: function(value) {
    var opts = $A(this.protocol.options);
    var match = opts.find(function(opt){
      return opt.value === value;
    });

    this.protocol.selectedIndex = opts.indexOf(match);
  },

  setValue: function(value) {
    var parts = value.startsWith('mailto') ? value.split(':') : value.split('://');
    this.protocol.setSelectedValue( parts.length > 1 ? parts[0] : 'http');
    this.link.value = parts.length > 1 ? parts[1] : '';
  },

  getValue: function() {
    var protocol = this.protocol.getValue();
    var link = this.link.getValue();

    return (link === '') ? '' : protocol+(protocol === 'mailto' ? ':' : '://')+link;
  },

  onFocus: function( e ) {
    if( this.isEnabled() ) {
      e.stop();
      this.fireEvent('focus', this);
    }
  },

  onkeydown: function(e) {
    switch (e.keyCode){
      case Event.KEY_RETURN:
        this.blur();
        e.stop();
        break;
    }
  },

  onBlur: function() {
    this.fireEvent('blur', this);
  },

  blur: function() {
    this.link.blur();
  }
});
