_ = require 'lodash'

stripScripts = (inputString) ->
  scriptsRegEx = new RegExp('<script[^>]*>([\\S\\s]*?)<\/script>', 'gim')

  return inputString.replace(scriptsRegEx, '')

safeGet = (object, path) ->
  # Get the value of a deep property using a dot-separated path or array.
  # Returns `undefined` if property doesn't exist.
  if typeof path is 'string'
    path = path.split '.'

  if path not instanceof Array or path.length is 0
    return undefined

  for key in path
    if object and object.hasOwnProperty key
      object = object[key]
    else return undefined

  return object

safeRemove = (obj, path) ->
  if typeof path is 'string'
    splitKeys = path.split('.')
  else
    splitKeys = path

  lastKey = splitKeys.pop()
  parentObj = if splitKeys.length > 0 then safeGet obj, splitKeys else obj

  delete parentObj[lastKey]

safeSet = (object, path, newValue, _fullObj) ->
  # Set the value of a deep property of an object using a dot-separated path or array,
  # even if intermediate objects don't exist.
  # Mutates given object and returns that object on success or `false` on failure.

  # Adapted from 'dotty' NPM package:
  # https://github.com/deoxxa/dotty/blob/37b858/lib/index.js#L129

  if typeof object isnt 'object' or object is null
    return false

  if typeof path is 'string'
    path = path.split '.'

  if path not instanceof Array or path.length is 0
    return false

  path = path.slice()
  key  = path.shift()

  if path.length is 0
    object[key] = _.cloneDeep newValue
    return _fullObj or object

  else
    if typeof object[key] isnt 'object'
      object[key] = {}

    return safeSet object[key], path, newValue, (_fullObj or object)


module.exports = {
  stripScripts
  safeGet
  safeSet
  safeRemove
  stripScripts
}
