/* globals lp,Class */
var _ = require('lodash');
module.exports = Class.create(lp.pom.VisibleElementModel, {
  initialize: function($super, element, modelData) {
    $super(element, modelData);

    this.addBreakpointModifyableAttributes({
      geometry: {
        borderLocation: true,
        borderApply: {
          top: true,
          right: true,
          left: true,
          bottom: true
        },
        backgroundImageApply: true,
        size:{
          width:true,
          height:true
        },
        offset:{
          left:true,
          top:true
        },
        scale:true,
        visible: true
      },
      style: {
        background: {
          backgroundColor: true,
          savedBackgroundColor: true,
          gradient: {
            from: true,
            to: true
          },
          fillType: true,
          autoGradient: true,
          reverseGradient: true,
          opacity: true,
          image: {
            uuid: true,
            unique_url: true,
            content_url: true,
            name: true
          },
          backgroundPosition: true,
          backgroundRepeat: true
        },
        border: {
          style: true,
          width: true,
          color: true
        }
      }
    });

    this.addBreakpointAllowDuplicateAttributes({
      geometry: {
        visible: true,
        scale:true,
        borderApply: {
          top: true,
          right: true,
          bottom: true,
          left: true
        },
        backgroundImageApply: true
      },
      style: {
        background: {
          backgroundPosition: true,
          backgroundRepeat: true,
          gradient: {
            from: true,
            to: true
          },
          fillType: true,
          autoGradient: true,
          reverseGradient: true,
          savedBackgroundColor: true,
          opacity: true
        }
      }
    });
  },

  shouldApplyBackgroundImage: function() {
    if (this.exists('geometry.backgroundImageApply')) {
      return this.get('geometry.backgroundImageApply');
    } else {
      return true; // backwards compat
    }
  },

  // TODO-TR: LP-6616 move this up to element_model
  addBreakpointModifyableAttributes: function(object) {
    _.merge(this.breakpointModifyableAttributes, object);
  },

  addBreakpointAllowDuplicateAttributes: function(object) {
    _.merge(this.breakpointAllowDuplicateAttributes, object);
  }

});
