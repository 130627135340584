import _ from 'lodash';

const sysFonts = [
  'Arial',
  'Comic Sans MS',
  'Courier New',
  'Georgia',
  'Lucida Sans Unicode',
  'Tahoma',
  'Times New Roman',
  'Trebuchet MS',
  'Verdana'
];

const webFonts = [
  'Abril Fatface',
  'Allan',
  'Arvo',
  'Cabin',
  'Dancing Script',
  'Droid Sans',
  'Gravitas One',
  'Josefin Sans',
  'Josefin Slab',
  'Lato',
  'Libre Baskerville',
  'Lobster',
  'Merriweather',
  'Montserrat',
  'Old Standard TT',
  'Open Sans',
  'Oswald',
  'PT Sans',
  'PT Serif',
  'Playfair Display',
  'Quantico',
  'Raleway',
  'Roboto',
  'Ubuntu',
  'Vollkorn',
];

const allowedFonts = _.union(sysFonts, webFonts);

const weights = [ '400', '400italic', '700', '700italic' ];
const formattedWeights = `:${weights.join(',')}`;

const cleanQuotes = (fontString) => {
  return fontString.replace(/'|"/g,'');
};

const generateFontList = (fontList) => {
  var generatedFonts = [];

  _.each(fontList, (font) => {
    var foundFont = _.find(webFonts, (f) => {
      // as far as I have seen, there are never gonna be quotes here anyways... must have been some
      // odd bug that we put this in for. zzzz.
      return f.toLowerCase() === cleanQuotes(font.toLowerCase());
    });

    if (foundFont) { generatedFonts.push(foundFont); }
  });

  return generatedFonts;
};

const buildFontList = (fontList) => {
  let generatedFontList = webFonts;
  if (fontList) {
    generatedFontList = generateFontList(fontList);
  }

  var googleURL = '//fonts.googleapis.com/css?family=';

  // this is some custom url encoding stuff. Very bug prone, very bad idea.
  // the typekit/webfonts loader should handle these complexities for us, not sure
  // why we are doing this at all.
  _.each(generatedFontList, (font) => {
    font = font.replace(/\s/g, '+');
    font = font + formattedWeights;
    googleURL = googleURL.concat(font + '|');
  });

  return googleURL;
};

const stripFallbackFonts = (fontString) =>
      fontString
      .split(',', 1)
      .shift()
      .trim()
      .toLowerCase();

const cleanFontString = (fontString) =>
      cleanQuotes(stripFallbackFonts(fontString))
      .replace(/\s+/g, ' ')
      .trim();

const findValidFontName = (fontName) =>
      _.find(allowedFonts, (allowedFont) =>
             allowedFont.toLowerCase() === fontName.toLowerCase());

const getBaseFontFamily = (fontString) =>
      findValidFontName(cleanFontString(fontString));

const adjustButtonLabels = () => {
  const elements = window.editor.page.getElementsByType('lp-pom-button');
  elements.forEach((elm) => {
    elm.adjustLabelSize();
  });
};

const adjustFormLabels = () => {
  const elements = window.editor.page.getElementsByType('lp-pom-form');
  elements.forEach((elm) => {
    elm.adjustFormGeometry();
  });
};

export default {
  sysFonts,
  webFonts,
  allowedFonts,
  weights,
  formattedWeights,
  cleanQuotes,
  buildFontList,
  generateFontList,
  getBaseFontFamily,
  findValidFontName,
  adjustButtonLabels,
  adjustFormLabels,
};
