;(function(){
  var lp = window.lp;

  var getPage = function() {
    return window.editor.page;
  };

  var canRun = function() {
    return window.editor.page.isMobileBreakpoint();
  };

  var run = function(type /*'page' or 'page section'*/) {
    var page = getPage();
    if(canRun()) {
      var designGrid = new lp.pom.SingleColumnGrid(page);
      designGrid.fitElementsToGrid(type);
    }
  };

  // public api
  lp.pom.AutoLayout = {
    run: run
  };

})();
