/* globals $H,Class,jui,lp */
var _ = require('lodash');
var ubBanzai = require('ub/control/banzai-features');
var OldFormElementViews = require('ub_legacy/lp/modules/lp-form/form_element/form_element_views.js');
var formSyles = require('ub/elements/form/styles').default;
var formGeometry = require('ub/elements/form/geometry').default;
var formHelper = require('ub/elements/form/helper').default;

var FormElement = Class.create(lp.pom.VisibleElement, lp.ModuleComponent, {
  type: 'lp-pom-form',

  initialize: function ($super, page, jso, context, options) {
    this.fieldViewMap = [];
    this.adjustingGeometry = false;
    this.minFieldWidth = 50;
    this.minLabelWidth = 40;

    $super(page, jso, context, options);

    this.createFieldViews();

    this.page.addListener('beforePageSave', this);

    this.buttonMoveListener = function (e) {
      if (e.data.accessor.startsWith('geometry.offset')) {
        this.model.set('geometry.buttonPlacement', 'manual');
      }
    }.bind(this);
  },

  getContentElement: function () {
    // This causes children (the submit button) to be inserted into the <form> element instead of
    // the wrapping <div> element.
    return this.form;
  },

  areFormColumnsAllowed: function () {
    var publishedStylesExist = this.model.exists('publishedStyles');
    var contentType = this.page.settings.contentType;

    if (this.page.isPublishOrPreviewMode()) {
      if (contentType === 'stickyBar') {
        return true;
      } else {
        return publishedStylesExist;
      }
    } else {
      return true;
    }
  },

  regenerateGeometry: function () {
    if (this.page.isPublishOrPreviewMode() && !this.hasExpectedModelAttributes()) {
      this.updaterRunning = true;
      this.setFormGeometryToModel(this.adjustFormGeometry);
      this.updaterRunning = false;
    } else {
      this.adjustFormGeometry();
    }
  },

  hasExpectedModelAttributes: function () {
    return this.model.exists('containers') && this._hasFormModelAttributes();
  },

  _hasFormModelAttributes: function () {
    return (
      this.model.exists('labels') || this.model.exists('fields') || this.model.exists('groups')
    );
  },

  isAdjustGeometryNeeded: function () {
    return this.updaterRunning || this.page.isEditMode();
  },

  getModelClass: function () {
    return lp.module.form.FormModel;
  },

  //Check to see if the submit button is currently attached to the form.
  isButtonAttached: function () {
    return this.submitButtonAction() === 'form';
  },

  submitButtonAction: function () {
    if (this.submitButton) {
      return this.submitButton.get('action.type');
    }
  },

  //The reason for this method is because for an unknown reason the form button
  //can become detached from the form causing the form button to not be able to
  //submit the form.  This is to ensure that the form button remains attached.
  reattachDetachedSubmitButton: function () {
    this.submitButton.set('action.type', 'form');
  },

  reAddMissingSubmitButton: function () {
    this.createButton();
    this.model.set('geometry.buttonPlacement', 'auto');
  },

  isFormMissingSubmitButton: function () {
    return _.isUndefined(this.submitButton);
  },

  fixLeftLabelAlignedForm: function (form) {
    if (
      form.geometry.label.alignment === 'left' &&
      form.geometry.label.minWidth === form.geometry.size.width
    ) {
      form.geometry.size.width =
        form.geometry.label.minWidth * 2 + form.geometry.label.margin.right;

      form.geometry.label.width = form.geometry.label.minWidth;
    }
  },

  addCalculatedWidthAttributeToFormLabel: function (form) {
    if (!form.geometry.label.calculatedWidth && form.geometry.label.minWidth) {
      form.geometry.label.calculatedWidth = form.geometry.label.minWidth;
    }
  },

  createDefaultConstraints: function ($super) {
    $super();
    this.defaultConstraints.height_resizeable = false;
    this.defaultConstraints.copyable = false;
  },

  fixLabelColorValues: function (form) {
    if (form.style.label.color && /^\#/.test(form.style.label.color)) {
      form.style.label.color = form.style.label.color.replace(/^\#/, '');
    }
  },

  initView: function () {
    // Forms on lightboxes should submit to the main page instead of the subpage they
    // are actually on.
    var uuid = this.page.getMainPageUUID();
    var variantId = this.page.getMainPageVariantId();

    var path = this.isActiveGoal() ? 'fsg' : 'fsn';

    var action = '/' + path + '?pageId=' + uuid + '&variant=' + variantId.toLowerCase();

    this.form = this.view.insert(
      new jui.Component({
        elementType: 'form',
        attributes: {
          action: this.page.isPreviewMode() ? '' : action,
          method: 'POST',
        },
      })
    );

    if (this.page.resourceType === 'PageVariant') {
      this.form.insert(new Element('input', { type: 'hidden', name: 'pageId', value: uuid }));
      this.form.insert(
        new Element('input', { type: 'hidden', name: 'pageVariant', value: variantId })
      );
    }

    if (this.areFormColumnsAllowed()) {
      this.view.addClassName('has-axis');

      this.fieldsContainer = new jui.Component({
        elementType: 'div',
        attributes: {
          className: 'fields',
        },
      });
    } else {
      this.fieldsContainer = new jui.Component({
        elementType: 'fieldset',
        attributes: {
          className: 'clearfix',
        },
      });
    }

    this.form.insert(this.fieldsContainer.e);

    if (this.page.isPublishOrPreviewMode()) {
      this.addPublishedPageDataInsertion();
    }

    // setFormGeometryToModelForBreakpoints needs to be called at some point before page
    // save, to ensure the model has up-to-date styles for the form's fields. However,
    // it can only be called when the form is visible – i.e. when its page is active in
    // the editor. It is called during page save but we can't rely on this happening,
    // because the user could press Save while another page is active. Because of this,
    // we also call it when user switches away from the page containing the form.
    // TODO: Fix this craziness.
    if (window.editor && this.page.isEditMode()) {
      window.editor.addListener(
        'beforeActivePageChanged',
        function (e) {
          if (e.data.previous === this.page) {
            this.setFormGeometryToModelForBreakpoints();
          }
        }.bind(this)
      );
    }

    if (this.page.getConfigValue('allowFormExternalLightboxAction')) {
      if (!this.model.exists('lightboxSize')) {
        this.model.set('lightboxSize', FormElement.lightboxSizeDefault);
      }
    }

    this.view.addListener('shown', this.shownHandler.bind(this));
  },

  shownHandler: function () {
    if (this.submitButton) {
      this.submitButton.applyStyleAttributes();
    }
  },

  addPublishedPageDataInsertion: function () {
    // We alias `window.ub.form` to `window.module.lp.form.data` for legacy support of
    // custom scripts that depend on it.
    //
    // The reason for the two versions is because we are currently using window.module
    // which will conflict with some thirdparty libraries.  We cannot modify all
    // the pages because many users have created custom scripts refering to
    // window.module;  Instead we will keep users on window.module unless they
    // report problems, when they do we will switch their account to use
    // window.ubModule.
    var aliasProperty = ubBanzai.features.isRenameWindowModuleToUbModuleEnabled()
      ? 'ubModule'
      : 'module';

    return this.page.addInsertion(
      [
        '<script type="text/javascript">',
        'window.ub.form=' + JSON.stringify(this._getPublishedPageDataObject()) + ';',
        'window.' + aliasProperty + '={lp:{form:{data:window.ub.form}}};',
        '</script>',
      ].join(''),
      'head'
    );
  },

  _setUrlAndIncludeFormData: function (data) {
    data.includeFormData = Boolean(this.model.getPassParams());
    data.url = this.model.safeGet('content.confirmURL') || '';
  },

  _getPublishedPageDataObject: function () {
    /* jshint maxcomplexity:13 */
    var data = {
      action: this.model.safeGet('content.confirmAction') || 'message',
      validationRules: {},
      validationMessages: {},
      customValidators: {},
    };

    switch (data.action) {
      case 'url':
        this._setUrlAndIncludeFormData(data);
        data.target = this.model.safeGet('content.confirmTarget') || '_self';
        break;

      case 'externalLightbox':
        this._setUrlAndIncludeFormData(data);
        data.lightboxSize = {
          desktop: this.model.safeGet('lightboxSize', this.page.getBreakpointByName('desktop')),
          tablet: this.model.safeGet('lightboxSize', this.page.getBreakpointByName('tablet')),
          mobile: this.model.safeGet('lightboxSize', this.page.getBreakpointByName('mobile')),
        };
        break;

      case 'post':
        this._setUrlAndIncludeFormData(data);
        data.target = this.model.safeGet('content.confirmTarget') || '_self';
        break;

      case 'message':
        data.message = this.model.safeGet('content.confirmMessage') || 'Thank you!';
        break;

      case 'modal':
        if (this.page.isPublishMode()) {
          data.url = this.page.getMainPageVariantId() + '-form_confirmation.html';
        } else if (this.page.isPreviewMode()) {
          const params = new window.URLSearchParams(window.document.location.search);
          const fcdParams = new window.URLSearchParams({
            sub_page: 'form_confirmation',
            token: params.get('token'),
            token_time: params.get('token_time'),
          }).toString();

          data.url = `${this.page.previewURL}?${fcdParams}`;
        }
        data.lightboxSize = this.model.safeGet('content.confirmationPageSize');
        break;
    }

    // published/form.ts does not use validationMessages or validationRules any more but we need
    // to keep them to preserve compatibility with this script:
    // https://documentation.unbounce.com/hc/en-us/articles/360001198903-Modifying-Form-Validation-Error-Messages
    this.model.get('content.fields').forEach(function (field) {
      let validationObject = {};
      const message = field.validations ? field.validations.message : '';
      if (field.validations && field.validations.email) {
        validationObject = { email: message };
      } else if (field.validations && field.validations.phone) {
        validationObject = { phone: message };
      } else {
        validationObject = { custom: message };
      }
      data.validationMessages[field.id] = message ? validationObject : {};
      data.validationRules[field.id] = field.validations || {};
    });

    data.isConversionGoal = this.isActiveGoal();

    return data;
  },

  getContentHeight: function () {
    return this.model.getHeight();
  },

  createDependantElements: function () {
    if (!this.model.exists('content.buttonId') || this.model.get('content.buttonId') === null) {
      this.createButton();
    } else {
      this.submitButton = this.page.getElementById(this.model.get('content.buttonId'));
    }

    if (!this.model.exists('content.confirmationPage')) {
      this.createConfirmationPage();
    }

    // createDependantElements is called during builder load. If the form is on a page
    // that isn't currently visible (i.e. on a lightbox), don't attempt to recalculate
    // the geometry, as doing so would cause innaccurate values to be calculated.
    if (window.editor && window.editor.isPageActive(this.page)) {
      this.adjustFormGeometry();
    }
  },

  createButton: function () {
    try {
      var button = (this.submitButton = lp
        .getModule('lp-pom-button')
        .addNewElementToPage(this.page, { container: this, dontActivateOnInsert: true }));
      this.model.set('content.buttonId', button.id);
      button.set('name', 'Form Button');
      button.set(
        'content.label',
        this.model.exists('content.submitButtonText')
          ? this.model.get('content.submitButtonText')
          : 'Form Button'
      );
      button.set('constraints.removable', false);
      button.set('constraints.copyable', false);
      button.set('constraints.allowActions', ['form']);
      button.set('action.type', 'form');
    } catch (e) {
      var action = this.submitButton.exists('action.type')
        ? this.submitButton.get('action.type')
        : 'Not defined';

      var details = 'Form Action Type is currently: ' + action;

      window.editor.reportError({
        error: e,
        id: window.editor.mainPage.id,
        message: 'An error occured creating a form. ' + details,
        details: 'Error creating form',
        userAgent: window.navigator.userAgent,
      });
    }
  },

  createConfirmationPage: function () {
    // TODO: instead of manually generating the path here, add the page to the editor
    // and then query for its path using the `_getPathName` method or similar
    this.model.set(
      'content.confirmationPage',
      this.page.getMainPageVariantId() + '-form_confirmation.html'
    );

    var page = new lp.pom.Page(
      {
        page: {
          name: 'Form Confirmation Page',
          used_as: 'form_confirmation',
        },
        name: 'Form Confirmation Page',
        open_graph: '{}',
        favicon: '{}',
        settings: {
          defaultWidth: 512,
          contentType: this.page.settings.contentType,
          multipleBreakpointsVisibility: this.page.settings.multipleBreakpointsVisibility,
          tabletBreakpointDisabled: this.page.settings.tabletBreakpointDisabled,
          desktopBreakpointDisabled: this.page.settings.desktopBreakpointDisabled,
          mainPage: {
            uuid: this.page.getMainPageUUID(),
            variant_id: this.page.getMainPageVariantId(),
          },
        },
        elements: [
          _.merge({}, window.lp.module.root.RootElement.elementDefaults, {
            id: 'lp-pom-root',
            type: 'lp-pom-root',
            containerId: null,
            geometry: {
              contentWidth: 512,
            },
          }),
        ],
        styles: [],
      },
      this.page.context,
      this.page.document
    );

    page.undoManager.disable();

    var root = page.getRootElement();

    var section = lp.getModule('lp-pom-block').addNewElementToPage(page, { container: root });
    var sectionHeight = this.page.getConfigValue('formConfirmationDialogSectionHeight');
    section.model.setHeight(sectionHeight);

    var text = lp.getModule('lp-pom-text').addNewElementToPage(page, { container: section });
    text.setText(this.page.getConfigValue('formConfirmationText'));

    // Temporarily add the new root to the DOM so that we can query the text element's height
    window.editor.pageBody.insert(root.getViewDOMElement());

    var textWidths = {
      desktop: section.model.getWidth() - 40,
      mobile: 200,
    };

    page.getBreakPoints().forEach(function (breakpoint) {
      var width = textWidths[breakpoint.name];

      // We need to get the text element's height so that we can vertically center it. To do this
      // we set its DOM element to the desired width and then query the resulting height
      text.view.setWidth(width);
      var height = text.view.getHeight();

      text.model.setSizeByBreakpoint(
        {
          width: width,
          height: height,
        },
        breakpoint
      );

      // Horizontally and vertically centred
      text.model.setOffsetByBreakpoint(
        {
          left: 20,
          top: (sectionHeight - height) / 2,
        },
        breakpoint
      );
    });

    root.view.remove();

    page.undoManager.enable();

    window.editor.addPage(page);

    return page;
  },

  POMReady: function ($super, e) {
    $super(e);
    if (this.page.isEditMode()) {
      this.createDependantElements();
    }
  },

  updateContentFields: function (fields) {
    this.model.set('content.fields', fields, this.page.undoManager);
    this.adjustFormGeometry();
  },

  // disconnect from the parent class ui binding,
  // replaced with rxUi binding on new method below.
  dblclick: _.noop,

  doubleClick: function () {
    this.getModule().openBuilder(this, {
      callback: this.updateContentFields.bind(this),
    });
  },

  getFonts: function () {
    return [
      this.model.getValuesFromAllBreakpoints('style.cbxlabel.font.family'),
      this.model.getValuesFromAllBreakpoints('style.label.font.family'),
    ]
      .flatten()
      .uniq(true);
  },

  modelChanged: function ($super, e) {
    var details = $super(e);
    var accessor = details.accessor;

    var accessorListToIgnore = [
      'geometry.label.calculatedWidth',
      'geometry.size.height',
      'content.confirmAction',
      'name',
    ];

    if (this.model.attributeChangeIgnore(accessor, accessorListToIgnore)) {
      return;
    } else if (accessor === 'content.fields') {
      this.updateFields({ accessor: accessor });
    } else if (
      accessor === 'style.label.color' ||
      accessor === 'style.cbxlabel.color' ||
      accessor.startsWith('style.field' || accessor === 'geometry.field.cornerRadius')
    ) {
      this.updateCSSRules({ accessor: accessor });
    } else {
      this.updateCSSRules({ accessor: accessor });

      if (!accessor.startsWith('content.confirmationPageSize')) {
        this.adjustFormGeometry(accessor);
      }
    }
  },

  updateCSSRules: function (options) {
    if (this.areFormColumnsAllowed()) {
      formSyles.updateCSSRules(options, this, this.page.isPublishOrPreviewMode());
    } else {
      this.oldUpdateCSSRules(options);
    }
  },

  oldUpdateCSSRules: function (options) {
    // TODO: refactor
    /* jshint maxstatements:73,maxcomplexity:15 */
    if (options && options.accessor && options.accessor === 'geometry.offset') {
      this.updateElementGeometry();
      return;
    }
    var rules = [];
    var self = this;
    var m = this.model;
    var label = m.exists('style.label')
      ? m.get('style.label')
      : { font: { size: 14, family: 'arial', weight: 'normal', style: 'normal' }, color: '000' };
    var selField = '#' + this.id + ' .lp-pom-form-field';
    var selLabel = selField + ' label';
    var selLabelSpan = selLabel + ' .labelStyle';
    var selText = selField + ' input.text';
    var selTextarea = selField + ' textarea';
    var selOptLabel = selField + ' .option label';
    var selOptLabelSpan = selOptLabel + ' .optLabelStyle';
    var selOptInput = selField + ' .option input';
    var rule = function (s, a, v) {
      rules.push({ selector: s, attribute: a, value: v });
    };

    this.page.style.removeCSSRules('#' + this.id);

    var lineHeight = Math.round(label.font.size * 1.1);
    var fieldHeight = m.get('geometry.field.height');
    var borderWidth = m.exists('geometry.field.border.width')
      ? m.get('geometry.field.border.width')
      : 1;

    var isMarginTopSettable = function (m) {
      return (
        m.get('geometry.label.alignment') === 'left' &&
        m.exists('style.label.centerAlign') &&
        m.get('style.label.centerAlign') === true
      );
    };

    var getMarginTop = function (fieldHeight, label, borderWidth) {
      return Math.max(0, Math.floor((fieldHeight - label.font.size) / 2) + borderWidth);
    };

    if (isMarginTopSettable(m)) {
      rule(selLabel, 'margin-top', getMarginTop(fieldHeight, label, borderWidth) + 'px');
    } else {
      rule(selLabel, 'margin-top', '0px');
    }

    rule(selLabel, 'font-family', label.font.family);
    rule(selLabel, 'font-weight', label.font.weight);
    this.setStyleRule(label.font.style, selLabel, rule);
    rule(selLabel, 'font-size', label.font.size + 'px');
    rule(selLabel, 'line-height', lineHeight + 'px');
    rule(selLabel, 'color', '#' + label.color);

    this.updateLabelStyles(m, rule, selLabelSpan, 'label');

    var cbxlabelFont = m.exists('style.cbxlabel.font')
      ? m.get('style.cbxlabel.font')
      : { size: 14, family: 'arial', weight: 'normal', style: 'normal' };

    var cbxlabelColor = m.exists('style.cbxlabel.color') ? m.get('style.cbxlabel.color') : '000';

    rule(selOptLabel, 'font-family', cbxlabelFont.family);
    rule(selOptLabel, 'font-weight', cbxlabelFont.weight);
    this.setStyleRule(cbxlabelFont.style, selOptLabel, rule);
    rule(selOptLabel, 'font-size', cbxlabelFont.size + 'px');
    rule(selOptLabel, 'line-height', Math.round(cbxlabelFont.size * 1.16) + 'px');
    rule(selOptLabel, 'left', Math.max(18, cbxlabelFont.size) + 'px');
    rule(selOptLabel, 'color', '#' + cbxlabelColor);

    rule(selOptInput, 'top', Math.max(0, Math.round((cbxlabelFont.size * 1.16 - 12) / 2)) + 'px');

    if (m.exists('style.field.backgroundColor')) {
      rule(selText, 'background-color', '#' + m.get('style.field.backgroundColor'));
      rule(selTextarea, 'background-color', '#' + m.get('style.field.backgroundColor'));
    }

    if (m.exists('style.field.color')) {
      var color = '#' + m.get('style.field.color');
      rule(selText, 'color', color);
      rule(selTextarea, 'color', color);
    }

    if (m.exists('style.field.innerShadow') && m.get('style.field.innerShadow')) {
      var shadowColor = this.calculateShadowColor(
        m.exists('style.field.backgroundColor') ? m.get('style.field.backgroundColor') : 'ffffff'
      );

      var shadow = 'inset 0px 2px 3px #' + shadowColor;

      rule(selText, 'box-shadow', shadow);
      rule(selTextarea, 'box-shadow', shadow);
      rule(selText, '-webkit-box-shadow', shadow);
      rule(selTextarea, '-webkit-box-shadow', shadow);
      rule(selText, '-moz-box-shadow', shadow);
      rule(selTextarea, '-moz-box-shadow', shadow);
    }

    if (m.exists('geometry.field.border')) {
      var border = m.get('geometry.field.border');
      var selector = [
        'input[type=text]',
        'input[type=email]',
        'input[type=tel]',
        'textarea',
        'select',
      ]
        .map(function (type) {
          return '#' + self.id + ' .lp-pom-form-field ' + type;
        })
        .join(', ');

      rules.push({ selector: selector, attribute: 'border-style', value: border.style });
      if (border.style !== 'none') {
        rules.push({ selector: selector, attribute: 'border-width', value: border.width + 'px' });
        if (border.color && border.color.strip() !== '') {
          rules.push({ selector: selector, attribute: 'border-color', value: '#' + border.color });
        }
      }
    }

    if (m.exists('geometry.field.cornerRadius')) {
      var radius = m.get('geometry.field.cornerRadius');
      rule(selText, 'border-radius', radius + 'px');
      rule(selTextarea, 'border-radius', radius + 'px');
    }

    this.updateLabelStyles(m, rule, selOptLabelSpan, 'cbxlabel');

    this.updateElementGeometry();
    this.applyPageStyles(rules, options);
  },

  setStyleRule: function (style, label, rule) {
    if (style) {
      rule(label, 'font-style', style);
    } else {
      rule(label, 'font-style', 'normal');
    }
  },

  updateLabelStyles: function (model, rule, selector, fieldType) {
    if (model.safeGet('style.' + fieldType + '.font.textStyles.strong')) {
      rule(selector, 'font-weight', 'bolder');
    }
    if (model.safeGet('style.' + fieldType + '.font.textStyles.em')) {
      rule(selector, 'font-style', 'italic');
    }
  },

  calculateShadowColor: function (color) {
    return jui.ColorMath.rgbToHex(
      jui.ColorMath.blend(
        'multiply',
        jui.ColorMath.hexToRgb(color),
        jui.ColorMath.hexToRgb('dddddd')
      )
    );
  },

  setDimensions: function ($super, dims) {
    if (this.areFormColumnsAllowed() && this.page.isEditMode()) {
      this.view.setWidth(dims.width);
      this.updateHeight();
    } else {
      $super(dims);
    }

    const rootElement = this.getRootElement();

    if (this.parentElement && !this.adjustingGeometry && rootElement && rootElement.isInserted()) {
      this.adjustFormGeometry();
    }
  },

  updateHeight: function () {
    if (this.updatingHeight) {
      return;
    }

    this.updatingHeight = true;
    var fieldsHeight = this._getFieldsHeight();

    if (fieldsHeight !== this.model.getHeight()) {
      this.model.setHeight(fieldsHeight);
    }

    this.updatingHeight = false;
  },

  _getFieldsHeight: function () {
    var fieldsHeight = this.view.e.down('.fields').getHeight();
    var fieldsMargin = this.model.get('geometry.field.margin').bottom;

    return fieldsHeight ? fieldsHeight - fieldsMargin : 0;
  },

  adjustFormGeometry: function (accessor) {
    if (this.areFormColumnsAllowed()) {
      formGeometry.adjustGeometry(this, accessor);
    } else {
      this.oldAdjustFormGeometry();
    }
  },

  oldAdjustFormGeometry: function () {
    //TODO: refactor
    /* jshint maxstatements:54 */
    /* jshint maxcomplexity:17 */
    if (arguments[0] === 'geometry.offset') {
      return;
    }

    if (this.adjustingGeometry) {
      return;
    }

    this.adjustingGeometry = true;
    var m = this.model;
    var visible = this.view.visible();
    var positionSubmit = this.canAutoPositionSubmitButton();
    var fieldViewCount = this.fieldViewMap.length;
    var i, f;

    if (!visible) {
      this.view.show();
    }

    if (positionSubmit) {
      this.submitButton.model.removeListener('attributeChanged', this.buttonMoveListener);
    }

    var g = {}; // this will hold all the calulated geometry information that each field needs to adjustGeometry

    g.leftLabelWidth = 0;
    g.x = 0;
    g.y = 0;

    g.labelMargin = m.get('geometry.label.margin');
    g.fieldHeight = m.get('geometry.field.height');
    g.fieldMargin = m.get('geometry.field.margin');
    g.fieldFontSize = Math.min(
      m.exists('geometry.field.fontSize') ? m.get('geometry.field.fontSize') : 12,
      g.fieldHeight
    );
    g.fieldBorder = m.exists('geometry.field.border.width')
      ? m.get('geometry.field.border.width') * 2
      : 2;

    var padding = Math.max(0, g.fieldHeight - g.fieldFontSize);

    g.fieldPaddingTop = Math.floor(padding / 2);
    g.fieldPaddingBottom = padding - g.fieldPaddingTop;
    g.labelAlign = m.get('geometry.label.alignment');

    g.formWidth = m.get('geometry.size.width');

    var minFormWidth = this.minFieldWidth;

    if (this.page.isEditMode()) {
      this.calculateAndStoreCurrentLabelWidth();
    }

    g.leftLabelWidth = this.getCalculatedLabelWidth();

    if (g.leftLabelWidth > 0) {
      minFormWidth += g.leftLabelWidth + g.labelMargin.right;
    }

    m.setMinSize({
      width: minFormWidth,
      height: 0,
    });

    if (g.formWidth < minFormWidth) {
      g.formWidth = minFormWidth;
      m.setWidth(minFormWidth);
    }

    for (i = 0; i < fieldViewCount; i++) {
      f = this.fieldViewMap[i];
      f.view.adjustGeometry(g, f.field, f);

      if (this.page.isEditMode()) {
        f.field.labelHeight = f.view.getLabelHeight(f.field);
        m.set('content.fields.' + i + '.labelHeight', f.field.labelHeight);
        if (f.field.lpType === 'checkbox-group' || f.field.lpType === 'radio-group') {
          m.set('content.fields.' + i + '.optionsListHeight', f.view.getOptionsListHeight());
        }
      }
    }

    this.model.setHeight(g.y - g.fieldMargin.bottom);

    if (positionSubmit === 'auto') {
      this.positionSubmitButton(g);
    }

    if (!visible) {
      this.view.hide();
    }

    this.adjustingGeometry = false;
  },

  canAutoPositionSubmitButton: function () {
    return (
      !Object.isUndefined(this.submitButton) &&
      (!this.model.exists('geometry.buttonPlacement') || this.model.get('geometry.buttonPlacement'))
    );
  },

  positionSubmitButton: function () {
    formGeometry.positionSubmitButton(this);
  },

  hasAutoButtonPlacement: function () {
    return this.model.safeGet('geometry.buttonPlacement') !== 'manual';
  },

  setAutoButtonPlacement: function (value) {
    this.model.set('geometry.buttonPlacement', value);

    this.submitButton.model.removeListener('attributeChanged', this.buttonMoveListener);

    if (value === 'auto') {
      this.submitButton.model.addListener('attributeChanged', this.buttonMoveListener);
    }
  },

  clearLabelSizeAttributes: function () {
    for (var i = 0, len = this.fieldViewMap.length; i < len; i++) {
      var f = this.fieldViewMap[i];
      f.view.clearLabelSize(f.field);
    }
  },

  setLabelWidthExplicitly: function (width, undoManager) {
    width = Math.max(this.minLabelWidth, Math.min(width, this.getMaxLabelWidth()));

    if (undoManager) {
      undoManager.startGroup();
    }

    this.model.set('geometry.label.width', width, undoManager);

    if (undoManager) {
      undoManager.endGroup();
    }
  },

  setAutoCalculateLabelWidth: function (auto, undoManager) {
    if (auto) {
      this.model.deleteAttr('geometry.label.width', undoManager);
    } else {
      this.setLabelWidthExplicitly(this.getCalculatedLabelWidth(), undoManager);
    }
  },

  isAutoCalculateLabelWidth: function () {
    return !this.model.exists('geometry.label.width');
  },

  setLabelAlignment: function (value, undoManager) {
    this.model.set('geometry.label.alignment', value, undoManager);
  },

  getLabelAlignment: function () {
    return this.model.get('geometry.label.alignment');
  },

  isLabelAlignTop: function () {
    return this.getLabelAlignment() === 'top';
  },

  isLabelAlignLeft: function () {
    return this.getLabelAlignment() === 'left';
  },

  setCalculatedLabelWidth: function (width, undoManager) {
    this.model.set('geometry.label.calculatedWidth', width, undoManager);
  },

  getCalculatedLabelWidth: function () {
    return this.isLabelAlignLeft() ? this.model.get('geometry.label.calculatedWidth') : 0;
  },

  calculateAndStoreCurrentLabelWidth: function () {
    var width = 0;

    if (this.isLabelAlignLeft()) {
      width = this.isAutoCalculateLabelWidth()
        ? this.autoCalculateLabelWidth()
        : this.model.get('geometry.label.width');
      this.setCalculatedLabelWidth(width);
    }

    return width;
  },

  getMaxLabelWidth: function () {
    return (
      this.model.getWidth() - this.minFieldWidth - this.model.get('geometry.label.margin.right')
    );
  },

  getMinLabelWidth: function () {
    return this.minLabelWidth;
  },

  oldGetLongestLabelWidth: function () {
    var width = 0;

    this.clearLabelSizeAttributes();

    for (var i = 0, len = this.fieldViewMap.length, field; i < len; i++) {
      field = this.fieldViewMap[i];
      width = Math.max(width, field.view.getUnwrappedLabelWidth(field.field, field.view.label));
    }
    return width;
  },

  getLongestLabelWidth: function () {
    if (this.areFormColumnsAllowed()) {
      return formHelper.getLongestLabelWidth(this);
    } else {
      return this.oldGetLongestLabelWidth();
    }
  },

  autoCalculateLabelWidth: function () {
    var width = Math.min(this.getLongestLabelWidth(), this.getMaxLabelWidth());
    if (width === 0) {
      // JS: then there were no visible labels
      return 0;
    }

    return Math.max(width, this.minLabelWidth);
  },

  createFieldViews: function () {
    if (!this.areFormColumnsAllowed()) {
      this.fieldViews = new OldFormElementViews(this).all();
    }
  },

  getFieldsFromModel: function () {
    return this.model.exists('content.fields') ? this.model.get('content.fields') : [];
  },

  fieldAttributeTypes: function () {
    return $H(lp.module.form.FormElement.ModelAttributeExtractor.TransformationMap).keys();
  },

  cleanInvalidFields: function () {
    var fieldCleaner = new lp.module.form.FormElement.FieldCleaner(
      this.model,
      this.fieldAttributeTypes()
    );
    fieldCleaner.cleanInvalidFields();
  },

  oldUpdateFields: function (options) {
    var fields = this.getFieldsFromModel();

    this.fieldViewMap = [];
    this.fieldsContainer.clear();

    fields.each(function (f) {
      var v = this.fieldViews
        .find(function (view) {
          return view.types.indexOf(f.lpType) > -1;
        })
        .view(f);

      this.fieldsContainer.insert(v.root);
      this.fieldViewMap.push({ view: v, field: f });
    }, this);

    this.updateCSSRules(options);

    if (this.parentElement !== null) {
      this.regenerateGeometry();
    }
  },

  updateFields: function (options) {
    if (this.areFormColumnsAllowed()) {
      formHelper.updateFields(this, options);
    } else {
      this.oldUpdateFields(options);
    }
  },

  canHaveGoals: function () {
    return true;
  },

  getGoals: function () {
    return [
      {
        type: 'form',
        url: '/fs',
      },
    ];
  },

  setFormGeometryToModel: function (callback) {
    this.modelUpdateable = true;
    callback.apply(this);
    this.modelUpdateable = false;
  },

  setFormGeometryToModelForBreakpoints: function () {
    if (_.isUndefined(this.page)) {
      return;
    }

    var form = this,
      page = form.page,
      breakpoints = page.getBreakPoints(),
      currentBreakpoint = page.getCurrentBreakpoint();

    var retainActiveElement = function (callback) {
      var elm = window.editor.activeElement;
      if (elm) {
        window.editor.setActiveElement(window.editor.page.getRootElement());
      }
      callback();
      if (elm) {
        window.editor.setActiveElement(elm);
      }
    };

    var rearrange = function (breakpoints, currentBreakpoint) {
      var rearrangedBreakpoints = _.reject(breakpoints, function (breakpoint) {
        return breakpoint.name === currentBreakpoint.name;
      });
      rearrangedBreakpoints.push(currentBreakpoint);
      return rearrangedBreakpoints;
    };

    var draw = function (page, breakpoint) {
      page.currentBreakpoint = breakpoint;
      page.updateAndRefresh();
    };

    _.each(rearrange(breakpoints, currentBreakpoint), function (breakpoint) {
      retainActiveElement(function () {
        draw(page, breakpoint);
      });
      if (form.isVisibleOnPage()) {
        form.setFormGeometryToModel(form.adjustFormGeometry);
      }
    });
  },

  beforePageSave: function () {
    this.cleanInvalidFields();

    if (window.editor.page.hasForm()) {
      this.setFormGeometryToModelForBreakpoints();
    }

    if (
      this.model.exists('content.confirmAction') &&
      this.model.get('content.confirmAction') === 'modal'
    ) {
      this.page.getBreakPoints().each(function (breakpoint) {
        var confirmPage = window.editor.findPagesUsedAs('form_confirmation')[0];
        this.model.set(
          'content.confirmationPageSize.' + breakpoint.name,
          confirmPage.getDimensions(breakpoint.name)
        );
      }, this);
    }
  },

  applyStyleAttributes: function ($super) {
    this.updateFields();
    $super();
  },

  _detach: function ($super) {
    $super();
    this.page.style.removeCSSRules('#' + this.id + ' .lp-pom-form-field');
    this.page.style.updatePageStyles();
  },

  destroy: function ($super) {
    $super();
    if (this.model.exists('content.confirmationPage')) {
      window.editor.removePage(window.editor.findPagesUsedAs('form_confirmation')[0]);
    }
    this.page.removeListener('beforePageSave', this);
    window.editor.removeListener('beforeActivePageChanged', this);
  },

  setVisible: function ($super, visible) {
    $super(visible);

    if (this.submitButton) {
      this.submitButton.setVisible(visible);
    }
  },
});

FormElement.elementDefaults = {
  name: 'Form',
  style: {
    label: {
      font: {
        size: 14,
        family: 'arial',
        weight: 'bold',
        style: 'normal',
      },
      color: '000',
      centerAlign: true,
    },

    cbxlabel: {
      font: {
        size: 13,
        family: 'arial',
        weight: 'normal',
        style: 'normal',
      },
      color: '000',
    },
    field: {
      innerShadow: true,
      backgroundColor: 'fff',
      color: '000',
    },
  },
  geometry: {
    position: 'absolute',
    offset: { top: 0, left: 0 },
    size: { width: 240 },
    label: {
      calculatedWidth: 0,
      margin: { bottom: 4, right: 12 },
      alignment: 'top',
    },
    field: {
      height: 32,
      width: 100,
      groupWidth: 100,
      margin: { bottom: 18 },
      fontSize: 15,
      cornerRadius: 5,
      border: {
        color: 'bbbbbb',
        style: 'solid',
        width: 1,
      },
    },
    buttonPlacement: 'auto',
  },
  content: {
    submitButtonText: 'Form Button',
    confirmAction: 'modal',
    confirmMessage: 'Thank you!',
    passParams: false,
    buttonId: null,
  },
};

FormElement.lightboxSizeDefault = {
  width: 840,
  height: 480,
};

module.exports = FormElement;
