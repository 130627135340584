/* globals Class, $H */
window.jui.FormNumericInput = Class.create( window.jui.FormTextInput, {
  options: function($super, options) {
    return $super($H({
      attributes: {
        className: 'form-elm numeric-input'
      },
      label: ''
    }).merge(options));

  },

  onFocus: function(e) {
    if (this.isEnabled()) {
      this.memorizedValue = this.input.value;
      e.stop();
      this.fireEvent('focus', this);
    }
  },

  onkeypress: function(e) {
    window.jui.NumericInputFilter.filter(e);
    this.fireEvent('keypress', e);
  },

  onBlur: function(e) {
    if (this.isEnabled() && this._isValidValue(e.target.value)) {
      e.stop();
      this.fireEvent('blur', this);
    } else {
      this._resetValue();
    }
  },

  _isValidValue: function(value) {
    return value !== null && value !== '' && !isNaN(value) && value >= 0;
  },

  _resetValue: function() {
    this.input.value = this.memorizedValue || 0;
  }

});
