/* globals $, jui, rxSwitchboard */
var Rx = require('ub_wrapped/rx');
var _ = require('lodash');
var uuid = require('ub/data/uuid');
var ubBanzai = require('ub/control/banzai-features.js');
var builderFontService = require('ub/fonts/font-service/builder').default;
var fontServerUtils = require('ub/fonts/font-service/utils').default;
var pomUpdates = require('ub_legacy/lp/pom/updates/updates');

window.console = window.console || {
  log: function () {},
  error: function () {},
  info: function () {},
};

var modules = [];

var lp =
  (window.LP =
  window.lp =
    _.merge(window.lp || {}, {
      pageData: null,
      sessionIncrement: 0,
      imageCalcDone: false,
      fontsLoaded: false,
      // put any utility functions etc in here
      //editObj = {id: id, page_uuid: page_uuid, admin: admin, lastUpdatedAt: pageVariantUpdateAt}
      editPage: function (pageData) {
        lp.context = lp.pom.context.EDIT;
        this.pageData = pageData;
        this.loadEditor();
        this.loadPage();
      },

      loadEditor: function () {
        lp.errorNotifier.addTagsToBaseContext({ editor_state: 'loading' });
        window.editor = new lp.Editor({
          page_uuid: this.pageData.uuid,
          admin: this.pageData.admin,
          updatedAt: this.pageData.updatedAt,
          onComplete: this.editorLoaded.bind(this),
        });

        var editor = window.editor;
        rxSwitchboard.subjects.page_loaded.ub_subscribe(function () {
          var resizeObs = Rx.Observable.fromEvent(window, 'resize').map(function () {
            return { dimensions: editor.getCurrentDimensions() };
          });
          editor._uiBinding
            .registerOnEventType('updateForWindowResize', resizeObs)
            .ub_subscribe(editor.updateCanvasLayout.bind(editor));
        });
      },

      editorLoaded: function () {
        var self = this;

        builderFontService.fontLoaderSubject.subscribe(function () {
          self.fontsLoaded = true;
          self.setLoadingState();

          // because of the order of custom font-load,
          // we need to recalculate the label height after font's been applied
          fontServerUtils.adjustButtonLabels();
          fontServerUtils.adjustFormLabels();
        });
        builderFontService.loadAllFonts();

        lp.errorNotifier.addTagsToBaseContext({ editor_state: 'running_upgraders' });
        // See the notes in lp/image_size_upgrader.
        window.lp.imageUpgrader.sizeCalculationStream.subscribe(function (e) {
          self.imageCalcDone = true;
          self.setLoadingState();

          // This is because we have updaters and upgraders that change the state of the POM
          // we don't want these to be undoable and should not be undoable.  So we make sure
          // the undo stack is reset to new after the builder is done loading or a page is loaded
          // for the first time.
          // The stack should also only be cleared if images have run through the upgrader. Which is
          // why we only clear the queue if e === true
          if (e) {
            window.editor.page.undoManager.clearUndoQueue();
          }
        });
        window.lp.imageUpgrader.upgrade();
        window.editor.page.removeAbandonedDetachedElements();

        lp.errorNotifier.addTagsToBaseContext({ editor_state: 'loaded' });
      },

      setLoadingState: function () {
        if (this.imageCalcDone && this.fontsLoaded) {
          $('loading').hide();
        }
      },

      loadPage: function (editObj) {
        /* jshint unused:vars */
        // don't know why this is passed and/or why it's marked to skip the hint check
        window.editor.loadPage(this.pageData.id, lp.context);
      },

      publishPage: function (
        pageData,
        hidePoweredBy,
        context,
        previewURL,
        mainPageVariantId,
        mainPageUUID
      ) {
        try {
          this._publishPage(
            window.document,
            pageData,
            hidePoweredBy,
            context,
            previewURL,
            mainPageVariantId,
            mainPageUUID
          );
        } catch (e) {
          lp.errorNotifier.captureException(e);
          throw e;
        }
      },

      previewPage: function (pageData, options) {
        // jshint maxstatements: 44
        const { mainPage } = pageData;
        const hidePoweredBy = options.hide_branding;

        // Derive preview URL from current URL, with the ?sub_page= query string param omitted
        const previewURL = `${location.protocol}//${location.host}${location.pathname}`;

        // Create a clean DOM (using an empty iframe)
        const rendererIframe = document.createElement('iframe');
        rendererIframe.style.display = 'none';
        document.body.appendChild(rendererIframe);
        const { contentDocument, contentWindow } = rendererIframe;
        const { head } = contentDocument;

        // The builder relies on methods monkeypatched onto `HTMLElement.prototype` by the PrototypeJS
        // library. In some browsers (e.g. Firefox) when we add page elements to the iframe's DOM, the
        // prototype of the elements is changed to the iframe window's `HTMLElement.prototype`, which
        // lacks the PrototypeJS methods. Therefore we need to re-run PrototypeJS inside the iframe
        // window as well, so its methods are added to the iframe window's `HTMLElement.prototype`.
        // We're using `eval` because PrototypeJS is not a module and needs to run in the global scope.
        // It's the same as what `script-loader` does when we import PrototypeJS at the top of
        // src/base.js.
        contentWindow.eval.call(null, require('raw-loader!ub/thirdparty/prototype-1.7.js').default);

        // Render comment and <meta> tags to the <head> of the iframe. These are currently rendered by
        // the webapp during publish (app/views/page_variants/publish.html.erb). It's arguable whether
        // they're necessary here in preview, but they're included to make the previewed output as
        // similar to publish as possible.
        const metaContentType = document.createElement('meta');
        metaContentType.httpEquiv = 'Content-Type';
        metaContentType.content = 'text/html; charset=UTF-8';
        head.appendChild(metaContentType);

        if (mainPage.page.used_as === 'main') {
          const comment = document.createComment(`${mainPage.page.uuid} ${mainPage.variant_id}`);
          head.appendChild(comment);
        }

        const title = document.createElement('title');
        title.textContent = mainPage.title;
        head.appendChild(title);

        const metaKeywords = document.createElement('meta');
        metaKeywords.name = 'keywords';
        metaKeywords.content = (mainPage.keywords || '').replace(/\n/g, '');
        head.appendChild(metaKeywords);

        const metaDescription = document.createElement('meta');
        metaDescription.name = 'description';
        metaDescription.content = (mainPage.description || '').replace(/\n/g, '');
        head.appendChild(metaDescription);

        // Render the rest of the page markup to the iframe
        const page = this._publishPage(
          contentDocument,
          pageData,
          hidePoweredBy,
          'PREVIEW',
          previewURL,
          // We don't need to include the main page IDs in preview. It's only necessary in publish mode
          // for form submissions.
          undefined,
          undefined
        );

        // Add insertions to the rendered page
        page.getPageInsertions().forEach(({ placement, content }) => {
          // Possible placements and intended position:
          //    body:before    = afterbegin
          //    body:after     = beforeend
          //    head           = beforeend
          //    div#lp-code-1  = beforeend
          const [selector, beforeOrAfter] = placement.split(':');
          const position = beforeOrAfter === 'before' ? 'afterbegin' : 'beforeend';

          contentDocument.querySelector(selector).insertAdjacentHTML(position, content);
        });

        // Allow anchor links to work within the srcdoc
        [...contentDocument.body.querySelectorAll('a[href^="#"]')].forEach(element =>
          element.setAttribute('href', `about:srcdoc${element.getAttribute('href')}`)
        );

        // Extract rendered HTML from the iframe
        const html = contentDocument.documentElement.outerHTML;

        // Remove the iframe from the DOM since we don't need it any more
        document.body.removeChild(rendererIframe);

        // Display rendered HTML to user in a full-screen iframe. The reason for doing this in a
        // separate iframe is that when we add insertions containing <script> tags above, because we're
        // adding them to the DOM as a string with insertAdjacentHTML, they aren't executed. This would
        // mean that none of the interactivity on the published page page would work. There are hacks to
        // get around this (e.g. using jQuery, which contains magic to execute scripts added as
        // strings), but there would still be the issue of scripts running later in preview than they
        // would on the published page. More broadly, the two-step process replicates the published page
        // workflow more closely: the first iframe (rendererIframe) replicates what happens when the POM
        // is published to HTML, and the second iframe (displayIframe) replicates what happens when a
        // visitor visits the published HTML page.
        //
        // Using `src` with a data: or blob: URI would have better browser compatibility than `srcdoc`,
        // but it would break external resources on the published page that have protocol-relative URLs
        // (e.g. the old predefined jQuery script)
        const displayIframe = document.createElement('iframe');
        displayIframe.srcdoc = html;
        displayIframe.id = 'page-preview-output';
        displayIframe.style.position = 'absolute';
        displayIframe.style.width = '100vw';
        displayIframe.style.height = '100vh';
        displayIframe.style.top = '0';
        displayIframe.style.left = '0';
        displayIframe.style.border = 'none';

        // embeddable.ts, which runs on the published embeddable page, posts messages to the parent
        // window, assuming that the parent window is the host page containing the universal script.
        // But in the case of preview, because we're displaying the page in an additional iframe, the
        // host page is actually the published embeddable page is actually two levels down from the
        // host page. This proxies messages through to the actual host page.
        if (page.getConfigValue('isEmbeddable')) {
          window.addEventListener('message', event => {
            if (event.source === displayIframe.contentWindow) {
              window.parent.postMessage(event.data, '*');
            }
          });
        }

        document.body.appendChild(displayIframe);
      },

      // jshint maxparams: 7
      _publishPage: function (
        document,
        pageData0,
        hidePoweredBy,
        context,
        previewURL,
        mainPageVariantId,
        mainPageUUID
      ) {
        lp.context = lp.pom.context[context]; // PUBLISH || PREVIEW
        lp.publishLog.start();

        var pageData = pomUpdates.run(pageData0);

        var pomData = pageData.mainPage; // only a json object; does not have access to methods.
        if (pomData.page.used_as !== 'main') {
          // Add information about the main page to the subpage's settings.
          pomData = _.merge({}, pomData, {
            settings: {
              mainPage: {
                variant_id: mainPageVariantId,
                uuid: mainPageUUID,
              },
            },
          });
        }

        var page = new lp.pom.Page(pomData, lp.pom.context[context], document, previewURL);

        window.page = page;
        page.insertPublishedPage();

        this.getPageInsertions = function () {
          // This method is called by lp-builder-render, which is called by Publisher
          // https://github.com/unbounce/lp-builder-renderer/
          try {
            return page.getPageInsertions();
          } catch (e) {
            lp.errorNotifier.captureException(e);
            throw e;
          }
        };

        if (!hidePoweredBy) {
          var freePageUrl =
            '//why.unbounce.com/for-building-landing-pages/?utm_source=published_page&utm_medium=powered_by_bar&utm_campaign=branded_footer';
          var freeFooterImage =
            '//builder-assets.unbounce.com/builder_assets/images/unbounce-free-account-footer-2019.png';

          var freePageFooterContainerStyle = [
            'display: block !important;',
            'position: absolute !important;',
            'margin: 0 !important;',
            'padding: 0 !important;',
            'visibility: visible !important;',
            'text-indent: 0 !important;',
            'bottom: -49px !important;',
            'width: 100% !important;',
            'height: 49px !important;',
            'overflow: hidden !important;',
            'background: #0033ff !important;',
            'z-index: 8675309 !important;',
            'text-align: center !important;',
            'font-size: 11px !important;',
            'color: #666 !important;',
            'font-weight: bold !important;',
            'transform: rotate(000deg) !important;',
          ].join(' ');

          var freePageFooterLinkStyle = [
            'display: block !important;',
            'position: static !important;',
            'visibility: visible !important;',
            'z-index: 18885159161 !important;',
            'text-indent: 0 !important;',
            'height: 44px !important;',
          ].join(' ');

          var freeFooterImageStyle = [
            'display: inline !important;',
            'position: static !important;',
            'visibility: visible !important;',
            'max-width: 440px; !important;',
            'margin-top: 7px; !important;',
            'width: 85% !important;',
            'vertical-align: bottom !important;',
          ].join(' ');

          page
            .getRootElement()
            .getViewDOMElement()
            .insert(
              new Element('div', {
                id: 'powered-by-unbounce',
                style: freePageFooterContainerStyle,
              }).update(
                '' +
                  '<a href="' +
                  freePageUrl +
                  '" rel="nofollow" target="_blank" style="' +
                  freePageFooterLinkStyle +
                  '" title="Build, Publish and Test Landing Pages with Unbounce">' +
                  '<img src="' +
                  freeFooterImage +
                  '" alt="Build using the Unbounce Landing Page Platform" style="' +
                  freeFooterImageStyle +
                  '">' +
                  '</a>'
              )
            );
        }

        return page;
      },

      publishLog: {
        startTime: 0,
        start: function () {
          this.startTime = Date.now();
        },
        elapsedTime: function () {
          return Date.now() - this.startTime;
        },
        info: function (message) {
          if (lp.context === lp.pom.context.PUBLISH) {
            alert('[INFO] ' + this.elapsedTime() + ' ' + message);
          }
        },
      },

      createModule: function (module, options) {
        /* jshint maxcomplexity:15 */
        options = options || {};
        var elementClass = null,
          propertiesPanelClass = null,
          propertiesPanel = null,
          builderClass = null,
          builder = null;

        module.getElementDefaults = function () {
          return this.elementDefaults;
        };

        if (typeof options.createElementClass === 'function') {
          module.activeElement = null;

          var elementActivated = function (e) {
            module.activeElement = e.source;
          };

          var elementDeactivated = function () {
            module.activeElement = null;
          };

          var elementDestroyed = function (e) {
            var elm = e.source;
            module.activeElement = null;
            elm.removeListener('activated', elementActivated);
            elm.removeListener('deactivated', elementDeactivated);
            elm.removeListener('destroyed', elementDestroyed);
          };

          module.addElementToPage =
            options.addElementToPage ||
            function (page, options) {
              this.createElement(page, options);
            };

          module.addNewElementToPage =
            options.addElementToPage ||
            function (page, options) {
              return this.createElement(page, options);
            };

          module.createElement =
            module.createElement ||
            function (page, options) {
              options = options || {};
              options.offset = options.offset || { left: 0, top: 0 };
              if (options.center) {
                options.offset.left =
                  options.offset.left -
                  (module.elementDefaults.geometry.size.width
                    ? Math.round(module.elementDefaults.geometry.size.width / 2)
                    : 0);
                options.offset.top =
                  options.offset.top -
                  (module.elementDefaults.geometry.size.height
                    ? Math.round(module.elementDefaults.geometry.size.height / 2)
                    : 0);
              }

              var defaults = jui.clone(module.elementDefaults);

              defaults.name = page.generateDefaultElementName(this.type, defaults.name);

              if (options.container) {
                defaults.containerId = options.container.id;
              }

              var self = this;
              var createFunction = function (data) {
                if (module.beforeCreate) {
                  module.beforeCreate(page, options, defaults, data);
                }
                var element = self.buildPageElement(page, defaults);

                if (options.offset) {
                  element.model.setOffset(options.offset);
                }

                if (options.dontActivateOnInsert) {
                  element.activateOnInsert = false;
                }

                if (options.dontUpdateElementTreeOnInsert) {
                  element.updateElementTreeOnInsert = false;
                }

                self.insertElement(element, {
                  container: options.container,
                  containerIndex: options.index,
                  center: options.center,
                });
                if (module.afterInsert) {
                  module.afterInsert(element, options);
                }

                if (typeof element.displayWarningIfInvalid === 'function') {
                  element.displayWarningIfInvalid(!!data.valid);
                }
                return element;
              };

              if (module.openBuilder) {
                this.openBuilder(null, { callback: createFunction });
              } else {
                return createFunction();
              }

              return null;
            };

          module.insertElement =
            module.insertElement ||
            function (element, options) {
              element.page.insertElement(element, options);
            };

          module.buildPageElement =
            module.buildPageElement ||
            function (page, jso) {
              var elm = new (this.getElementClass())(page, jso);
              elm.addListener('activated', elementActivated);
              elm.addListener('deactivated', elementDeactivated);
              elm.addListener('destroyed', elementDestroyed);
              return elm;
            };

          module.getElementClass =
            module.getElementClass ||
            function () {
              elementClass = elementClass || options.createElementClass(this);
              return elementClass;
            };
        }

        if (typeof options.createPropertiesPanelClass === 'function') {
          module.getPropertiesPanel =
            module.getPropertiesPanel ||
            function (elm) {
              /* jshint unused:vars */
              propertiesPanel = propertiesPanel || new (this.getPropertiesPanelClass())();
              return propertiesPanel;
            };

          module.getPropertiesPanelClass =
            module.getPropertiesPanelClass ||
            function () {
              propertiesPanelClass =
                propertiesPanelClass || options.createPropertiesPanelClass(this);
              return propertiesPanelClass;
            };
        }

        if (typeof options.createBuilderClass === 'function') {
          module.openBuilder =
            module.openBuilder ||
            function (elm, options) {
              this.getBuilder().open(elm, options);
            };

          module.getBuilder = function (elm) {
            /* jshint unused:vars */
            builder = builder || new (this.getBuilderClass())();
            return builder;
          };

          module.getBuilderClass = function () {
            builderClass = builderClass || options.createBuilderClass(this);
            return builderClass;
          };
        }

        this.addModule(module);
      },

      createModuleNamespace: function (namespace) {
        var namespaceObj = (this.module[namespace] = {});
        return namespaceObj;
      },

      addModule: function (module) {
        if (lp.getModule(module.type)) {
          // JS: module with same key already exist. we should throw some kind of exception
          return;
        }
        modules.push(module);
      },

      getModule: function (type) {
        return modules.find(function (m) {
          return m.type === type;
        });
        //return modules.get(type);
      },

      getModulesWithUsageType: function (type) {
        return modules.findAll(function (m) {
          return m.usageType === type;
        });
      },

      getPublishedAssetsUrl: function (key) {
        return this._lookupHashedAssetUrl(key);
      },

      _lookupHashedAssetUrl: function (key) {
        if (lp.publicModulePath && lp.publicModulePath[key]) {
          return lp.publicModulePath[key];
        } else if (process.env.NODE_ENV === 'development') {
          return `http://localhost:8989/${key}`;
        }
      },

      isDynamicTextEnabled: function () {
        return window.gon ? Boolean(window.gon.dynamic_text) : false;
      },

      incrementSessionId: function () {
        this.sessionIncrement = this.sessionIncrement + 1;
      },

      getSessionId: function () {
        //This is an editing session id generated per page load.
        //THIS IS NOT A WEBAPP SESSION!!
        this.sessionId = this.sessionId || uuid();
        return this.sessionId;
      },

      getSessionIdWithSaveIncrement: function () {
        return this.getSessionId() + '-' + this._getSessionIdAutoIncrement();
      },

      _getSessionIdAutoIncrement: function () {
        return this.sessionIncrement;
      },

      isLightboxLimitReached: function () {
        return (
          window.editor.findPagesUsedAs('lightbox').length >=
          ubBanzai.getFeatureValue('lightboxLimit')
        );
      },
    }));

window.lp.module = {};
