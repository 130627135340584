/* globals Class, $H */
var svgImages = require('ub/lp/editor/svg/social-svg.coffee');

window.jui.SocialWidgetLayoutMenu = Class.create(window.jui.Component, window.jui.ControlModel, {
  options: function($super, options) {
    return $super($H({
      attributes: { className: 'social_widget_layout_button' },
      name: '',
      label: 'layout'
    })).merge(options);
  },

  initialize: function($super, options) {
    this.layoutButtons = {};
    $super(options);

    if(this.options.change) {
      this.addListener('change', this.options.change);
    }
  },

  installUI: function($super) {
    $super();
    this.options.layoutButtons.each(function(b) {
      var button = new Element('a', { className: 'layout_button' });

      button.insert(new Element('div', { className: 'social_widget_layout_icon ' + b.id })).update(svgImages.socialSvg(b.id));

      this.layoutButtons[ b.id ] = this.insert(button);
      this.layoutButtons[ b.id ].observe('click', this.change.bind(this, b.id));
    }, this);
  },

  select: function(key) {
    this.clearAllSelected();
    if(!this.layoutButtons[key]){return;}
    this.layoutButtons[key].addClassName('selected');
  },

  clearAllSelected: function() {
    $H(this.layoutButtons).each(function(pair) {
      pair.value.removeClassName('selected');
    });
  },

  getValue: function() {
    return $H(this.layoutButtons).keys().find(function(k) {
      return this.layoutButtons[k].hasClassName('selected');
    }, this);
  },

  change: function(key) {
    this.select(key);
    this.fireEvent('change', key);
  }

});
