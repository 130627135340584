import _ from 'lodash';

export const COLUMN_DIVISOR = 100;
const MAX_COLUMNS = 4;

const positionSubmitButton = (formElm) => {
  const submitButton = formElm.submitButton;

  if (!submitButton) {
    return;
  }

  const formModel = formElm.model;
  const formWidth = formModel.safeGet('geometry.size.width');
  const formHeight = formModel.safeGet('geometry.size.height');
  const formHasColumns = formModel.safeGet('geometry.field.width') < COLUMN_DIVISOR;

  const labelMargin = formModel.safeGet('geometry.label.margin');
  const labelAlign = formModel.safeGet('geometry.label.alignment');
  const labelWidth = formElm.getCalculatedLabelWidth();

  const fieldMargin = formModel.safeGet('geometry.field.margin').bottom;

  const marginFallback = 0;

  let newLeftOffset;
  let newTopOffset;

  submitButton.model.removeListener('attributeChanged', formElm.buttonMoveListener);

  if (formElm.page.getConfigValue('rightAlignFormButtonPosition') || formHasColumns) {
    const buttonHeight = submitButton.model.safeGet('geometry.size.height');
    newLeftOffset = formWidth + fieldMargin;
    newTopOffset = formHeight - buttonHeight;
  } else {
    newLeftOffset = labelAlign === 'left' ? labelWidth + labelMargin.right : marginFallback;
    newTopOffset = formHeight + fieldMargin;
  }

  submitButton.model.setOffset({
    left: newLeftOffset,
    top: newTopOffset
  });

  submitButton.model.addListener('attributeChanged', formElm.buttonMoveListener);
};

const adjustWidth = (formElm) => {
  const model = formElm.model;
  const formWidth = model.safeGet('geometry.size.width');
  const fieldWidth = model.safeGet('geometry.field.width');
  const columnCount = COLUMN_DIVISOR / fieldWidth;
  const minFormWidth = Math.max((columnCount * formElm.minFieldWidth), formElm.minFieldWidth);

  if (formWidth < minFormWidth) {
    model.setWidth(minFormWidth);
  }
};

const setFieldWidthByFieldCount = (formElm) => {
  const fieldCount = formElm.getFieldsFromModel()
    .filter((field) => {
      return field.type !== 'hidden';
    })
    .length;

  const mapFieldCountsToColumns = {1: 1, 2: 2, 3: 3, 4: 4, 5: 3, 6: 3};
  const fieldWidth = COLUMN_DIVISOR / (mapFieldCountsToColumns[fieldCount] || MAX_COLUMNS);

  formElm.model.set('geometry.field.width', fieldWidth);
};

const adjustGeometry = (formElm, accessor) => {
  if (!accessor || accessor === 'geometry.offset' || formElm.adjustingGeometry) {
    return;
  }

  const visible = formElm.view.visible();

  formElm.adjustingGeometry = true;

  if (!visible) {
    formElm.view.show();
  }

  if (_.includes(['geometry.size', 'geometry.field.width'], accessor)) {
    adjustWidth(formElm);
  }

  formElm.updateHeight();

  if (formElm.hasAutoButtonPlacement()) {
    positionSubmitButton(formElm);
  }

  formElm.adjustingGeometry = false;

  if (!visible) {
    formElm.view.hide();
  }
};

export default {
  adjustWidth,
  adjustGeometry,
  positionSubmitButton,
  setFieldWidthByFieldCount,
};
