  window.lp.pom.VisibleElementViewWrapperMethods = {
    cumulativeOffset: function() {
      return this.view.cumulativeOffset();
    },

    setWidth: function(v, unit) {
      this.view.setWidth(v, unit);
    },

    setHeight: function(v, unit) {
      this.view.setHeight(v, unit);
    },

    setSize: function(v, unit) {
      this.view.setSize(v, unit);
    },

    setLeft: function(v) {
      this.view.setLeft(v);
    },

    setTop: function(v) {
      this.view.setTop(v);
    },

    setOffset: function(v) {
      this.view.setOffset(v);
    },

    getWidth: function() {
      return this.view.getWidth();
    },

    getHeight: function() {
      return this.view.getHeight();
    },

    getLeft: function() {
      return this.view.getLeft();
    },

    getTop: function() {
      return this.view.getTop();
    },

    getOffset: function() {
      return this.view.getOffset();
    },

    visible: function() {
      return this.view.visible();
    },

    insert: function(elmOrJuiComponent, position) {
      return this.view.insert(elmOrJuiComponent, position);
    },

    update: function(content) {
      return this.view.update(content);
    },

    remove: function() {
      return this.view.remove();
    }
  };
