import _ from 'lodash';

import {items as fullFontList} from '@unbounce/fonts.json';

// Filter out any non-english fonts (there's ~30 of them)
// until we know how we plan to preview these subsets
const fontList = _.filter(fullFontList, (font) => {
  return _.includes(font.subsets, 'latin');
});

let fontMap = _.map(fontList, (fontData) => {
  return {[fontData.family]: fontData};
});

fontMap = _.assign(...fontMap);

const fontFamilies = fontList.map((font) => {
  return font.family;
});

export default {
  get allFonts() {
    return fontList;
  },
  get fontMap() {
    return fontMap;
  },
  get fontFamilies() {
    return fontFamilies;
  }
};
