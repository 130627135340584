var _ = require('lodash');

window.jui.utils = {
  stringToKey: function(str) {
    return str.
      toLowerCase().
      stripTags().
      gsub( ' ', '_' ).
      gsub( /\W+/, '' );
  },

  isStringJQuerySrc: function(string) {
    var regex = /(jquery)(\.min)?(\-[0-9]\.[0-9]\.[0-9])?(\.js)/gi;
    return regex.test(string);
  },

  sort: function(arrayOfObjects, byField, direction) {
    arrayOfObjects = arrayOfObjects ? arrayOfObjects : [];
    if(arrayOfObjects) {
      arrayOfObjects.sort(this._compare(byField));
      if(direction && direction.toLowerCase() === 'dsc') {
        arrayOfObjects.reverse();
      }
    }
    return arrayOfObjects;
  },

  isValidDate: function(date) {
    return !isNaN(Date.parse(date));
  },

  loadImage: function(imageURL, onLoadCallback){
    var image = new Image();
    image.onload = function() {
      onLoadCallback(image);
    };
    image.src = imageURL;
  },

  _compare: function(field) {
    var self = this;
    return function(a, b) {
      var aField = a[field],
          bField = b[field];

      //If the field responds to toLowerCase than we can
      //assume that it is a string.  Lowercase strings.
      //The reason we are checking that aField is not empty because we have found that the asset library would
      //not load under some situations.  PB-1428.  The reason for this is unknown but the first guess is something
      //to do with memcache not generating the data correctly.  This is not a fix but it does allow the asset library to load.

      if( ( ! _.isEmpty(aField) && aField.toLowerCase) && ( ! _.isEmpty(bField) && bField.toLowerCase)) {
        aField = aField.toLowerCase();
        bField = bField.toLowerCase();
      }

      if(self.isValidDate(aField) && self.isValidDate(bField)) {
        aField = Date.parse(aField);
        bField = Date.parse(bField);
      }

      return (aField < bField ) ? -1 : (aField > bField ? 1 : 0);
    };
  }

};
