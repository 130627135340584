/* globals $H, $, $$, Template */
module.exports = {

  id: 'facebook-jssdk',
  name: 'facebook',

  getWidgetHtml: function(facebookObj) {
    this.validate(facebookObj);
    return this.facebookTemplateIframe()
      .evaluate(this.getFacebookLayoutObj(facebookObj));
  },

  getScript: function() {
    return "";
  },

  getStyle: function() {
    return '';
  },

  getSizeByLayout: function(layout) {
    var size = {
      top: {
        width: 61,
        height: 62
      },
      right: {
        width: 83,
        height: 21
      },
      none: {
        width: 49,
        height: 21
      }
    };

    //Account for the verb if it has been set to recommend than increase the
    //width.
    if('recommend' ===  layout.verb) {
      size[layout.countOrientation].width += 48;
    }
    return size[ layout.countOrientation ];
  },

  validate: function(facebookObj) {
    this.validateKeys(facebookObj);
  },

  validateKeys: function(facebookObj) {
    var keys         = $H(facebookObj).keys(),
        requiredKeys = [ 'orientation', 'countOrientation', 'name', 'verb', 'color' ];

    requiredKeys.each(function(key) {
      if(!keys.include(key)) {
        throw key + ': Key is missing from social_widget_facebook.js';
      }
    });
  },

  facebookTemplateIframe: function() {
    return new Template('<div class="#{data-class}"><iframe src="//www.facebook.com/plugins/like.php?href=#{url}&amp;send=false&amp;layout=#{data-layout}&amp;width=#{data-width}&amp;height=#{ data-height}&amp;show_faces=false&amp;action=#{verb}&amp;colorscheme=#{color}&amp;font&amp;;appId=131761285788" scrolling="no" frameborder="0" style="border:none; overflow:hidden; width:#{data-width}px; height:#{data-height}px;" allowTransparency="true"></iframe></div>');
  },

  cleanup: function() {
    var jsNode     = $(this.id),
        jsNodes    = $$("script[src*='" + this.id + "']"),
        fbRoot     = $('fb-root'),
        fbOsCheck     = $('fb-os-check');
    if(fbRoot) {
      fbRoot.remove();
    }

    if(fbOsCheck) {
      fbOsCheck.remove();
    }

    if(jsNode) {
      jsNode.remove();
    }

    if(jsNodes) {
      jsNodes.each(function(el) {
        el.remove();
      });
    }
  },

  getFacebookLayoutObj: function(facebookObj) {
    facebookObj = $H(facebookObj);
    switch(facebookObj.get('countOrientation')) {
      case 'top':
        facebookObj.set('data-layout', 'box_count');
        break;
      case 'right':
        facebookObj.set('data-layout', 'button_count');
        break;
      default:
        facebookObj.set('data-layout', 'button');
    }
    return this.setClassName(this.setWidth(facebookObj));
  },

  setWidth: function(facebookObj) {
    var size = this.getSizeByLayout(facebookObj.toObject());
    facebookObj.set('data-width', size.width);
    facebookObj.set('data-height', size.height);
    return facebookObj;
  },

  setClassName: function(facebookObj) {
    facebookObj.set('data-class', 'fb-nocount fb_' + facebookObj.get('verb'));
    return facebookObj;
  }

};
