import _ from 'lodash';
import getClosest from 'get-closest';
import googleFonts from './fonts';
import googleFontsAPI from 'ub/fonts/font-service/google/api';

const isValidFontVariant = (fontFamily, variant) => {
  const fontData = googleFontsAPI.findFontInList(fontFamily);

  if (!_.isEmpty(fontData)) {
    return _.includes(fontData.variants, variant);
  }
};

const getDefaultFontWeight = (family) => {
  if (isValidFontVariant(family, 'regular')) {
    return 'regular';
  } else {
    const matchedFont = googleFontsAPI.findFontInList(family);

    // This is for the fonts that don't have a regular weight but the 'regular' weight is requested.
    // In those cases we just grab the first one in the list.
    return _.head(matchedFont.variants);
  }
};

const convertWeightToInt = (variant) => {
  const normalWeight = 400;
  return (variant === 'regular') ? normalWeight : Number(variant);
};

const getFallbackWeight = (currentVariant, availibleVariants) => {
  // The method used to determine the fallback weight was taken from
  // https://developer.mozilla.org/en/docs/Web/CSS/font-weight?v=control#Fallback

  const normalWeight = 400;
  const boldWeight = 500;
  const variant = convertWeightToInt(currentVariant);

  // This returns 'undefined' if there is no number close to what is requested
  const closestGreaterIndex = getClosest.greaterNumber(variant, availibleVariants);
  const closestLessIndex = getClosest.lowerNumber(variant, availibleVariants);

  const getClosestGreaterWeight = () => {
    if (_.isUndefined(closestGreaterIndex)) {
      return `${availibleVariants[closestLessIndex]}`;
    } else {
      return `${availibleVariants[closestGreaterIndex]}`;
    }
  };

  const getClosestLowerWeight = () => {
    if (_.isUndefined(closestLessIndex)) {
      return `${availibleVariants[closestGreaterIndex]}`;
    } else {
      return `${availibleVariants[closestLessIndex]}`;
    }
  };

  if (variant > boldWeight) {
    return getClosestGreaterWeight();
  } else if (variant < normalWeight) {
    return getClosestLowerWeight();
  } else if (variant === normalWeight) {
    return _.includes(availibleVariants, boldWeight) ? '500' : getClosestLowerWeight();
  } else if (variant === boldWeight) {
    return _.includes(availibleVariants, normalWeight) ? 'regular' : getClosestLowerWeight();
  }
};

const findClosestFontVariant = (fontFamily, variant) => {
  const fontVariations = googleFonts.fontMap[fontFamily].variants;

  const variantsAsInt = _(fontVariations)
    .map(convertWeightToInt)
    .reject(_.isNaN)
    .value();

  return getFallbackWeight(variant, variantsAsInt);
};

const getValidFontVariant = (fontFamily, variant) => {
  if (isValidFontVariant(fontFamily, variant)) {
    return variant;
  } else {
    return findClosestFontVariant(fontFamily, variant);
  }
};

const getBolderWeight = (currentWeight) => {
  // This relative font weight mapping was taken from:
  // https://developer.mozilla.org/en/docs/Web/CSS/font-weight?v=control#Meaning_of_relative_weights
  const weightMap = {
    100: '400',
    200: '400',
    300: '400',
    400: '700',
    500: '700',
    600: '900',
    700: '900',
    800: '900',
    900: '900',
    'regular': '700'
  };

  return weightMap[currentWeight] || '700';
};

const isRegularFontWeight = (fontWeight) => {
  const upperCaseWeight = `${fontWeight}`.toUpperCase();

  return _.includes(['NORMAL', 'REGULAR', '400'], upperCaseWeight);
};

const normalizeFontWeight = (fontWeight) => {
  const weightString = `${fontWeight}`;

  if (isRegularFontWeight(weightString)) {
    return 'regular';
  } else if (weightString.toUpperCase() === 'BOLD') {
    return '700';
  } else {
    return weightString;
  }
};

const isItalic = (fontData) => {
  return (fontData.style && fontData.style.toUpperCase() === 'ITALIC') ||
    (fontData.textStyles && fontData.textStyles.em);
};

const isStrong = (fontData) => {
  return fontData.textStyles && fontData.textStyles.strong;
};


const getWeightFromItalicVariant = (variant) => {
  if (variant === 'regular' || variant === 'italic') {
    return '400';
  }
  return variant.replace('italic', '');
};

const getBetterFallbackItalic = (fontFamily, currentFontWeight) => {
  const font = googleFontsAPI.findFontInList(fontFamily);

  const italicVariants = font.variants
    .filter((variant) => { return _.includes(variant, 'italic'); })
    .map((variant) => {
      if (variant === 'italic') {
        return 'regular';
      }
      return variant.replace('italic', '');
    })
    .map(convertWeightToInt);

  if (_.isEmpty(italicVariants)) {
    return currentFontWeight;
  }

  const italicWeight = getWeightFromItalicVariant(currentFontWeight);
  const weight = getFallbackWeight(italicWeight, italicVariants);
  return weight === '400' ? 'italic' : `${weight}italic`;
};

const getItalicVariant = (fontData, currentFontWeight) => {
  if (isItalic(fontData)) {
    let italicVariant = `${currentFontWeight}italic`;

    if (italicVariant === 'regularitalic') {
      italicVariant = 'italic';
    }

    const italicVariantExists = isValidFontVariant(fontData.family, italicVariant);

    if (italicVariantExists) {
      return italicVariant;
    } else {
      const betterFallbackFont = getBetterFallbackItalic(fontData.family, currentFontWeight);

      const isValid = isValidFontVariant(fontData.family, betterFallbackFont);
      return isValid ? betterFallbackFont : currentFontWeight;
    }

  } else {
    return currentFontWeight;
  }
};

const findFontVariant = (fontData) => {
  const fontFamily = fontData.family;
  let finalFontWeight = normalizeFontWeight(fontData.weight);

  if (isStrong(fontData)) {
    // We need to get the 'bolder' variation of the current weight because
    // the builder has for some time applied font-style: bolder; to text wrapped in
    // a <strong> tag. Defined in page_defaults.scss.
    const boldFontWeight = getBolderWeight(finalFontWeight);
    finalFontWeight = getValidFontVariant(fontFamily, boldFontWeight);
  } else if (isRegularFontWeight(finalFontWeight)) {
    finalFontWeight = getDefaultFontWeight(fontFamily);
  } else {
    finalFontWeight = getValidFontVariant(fontFamily, finalFontWeight);
  }

  finalFontWeight = getItalicVariant(fontData, finalFontWeight);

  return {[fontFamily]: [finalFontWeight]};
};

export default {findFontVariant};
