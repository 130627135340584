var Setter = function(id, whitelist) {
  this.id = id;
  this.whitelist = whitelist;
};

Setter.prototype = (function() {
  var _isInWhitelist = function(styleKey) {
    return this.whitelist ? this.whitelist.include(styleKey) : true;
  };

  var _getStyle = function(style, elementPrefix, callback) {
    var styleParts = style.split(':');
    var styleValue = styleParts[1];
    var styleKey = styleParts[0].trim();
    var inWhiteList = _isInWhitelist.apply(this, [styleKey]);
    var hasKeyValue = !! (styleKey && styleValue && inWhiteList);
    var arg = {
      hasKeyValue: hasKeyValue,
      accessor: hasKeyValue ? elementPrefix + '.' + this.id + '.style.' + styleKey : '',
      value: hasKeyValue ? styleValue.trim() : '',
      attribute: styleKey,
      breakpoint: null
    };
    callback.apply(this, [arg]);
  };

  var _setStyles = function(element, elementPrefix) {
    var inputStyles = element.style.cssText.split(';').filter(function(style) {
      return style.trim().length > 0;
    });

    inputStyles.each(function(style) {
      _getStyle.apply(this, [style, elementPrefix,
          function(arg) {
            this.onSetCallback.apply(this, [arg]);
          }
      ]);
    }, this);
  };

  return {
    execute: function(element, elementPrefix) {
      if(element) {
        _setStyles.apply(this, [element, elementPrefix]);
      }
      return this;
    },

    onSet: function(fn) {
      if (typeof fn === 'function') {
        this.onSetCallback = fn;
      } else {
        throw new Error("Invalid argument - Must be a function");
      }
      return this;
    }
  };
})();

module.exports = Setter;
