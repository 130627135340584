/**
 * Get the CSRF token value. This must be included in all POST/PUT/DELETE/PATCH requests to the
 * webapp (as a X-CSRF-Token header or authenticity_token body param). This function should be
 * called when preparing each request and the return value should not be cached, because the value
 * will change when a user is re-authenticated after a session expiry - see
 * https://github.com/unbounce/lp-builder/blob/8dbc8a/src/components/login/components/Login.jsx#L88
 */

export default function getCsrfToken() {
  const meta = document.head.querySelector('meta[name="csrf-token"]');
  return meta ? meta.content : null;
}
