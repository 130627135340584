/* globals lp,Class */
var BlockModule = Class.create(
    lp.ModuleBase,
    lp.ModuleElementMethods,
    lp.ModuleElementPropertiesPanelMethods,
    {
      getLimit: function(page) {
        return page.getConfigValue('sectionCountLimit') || Infinity;
      },
      beforeCreate: function(page, defaults, options) {
        defaults.geometry.size = options.container.getDefaultSectionSize();

        if (page.usedAs() === 'main') {
          defaults.geometry.fitWidthToPage = page.getConfigValue('fitWidthToPageValue');
        } else {
          defaults.geometry.fitWidthToPage = false;
        }
      }
    });

lp.addModule(new BlockModule({
  type      : 'lp-pom-block',
  version   : '1.0',
  name      : 'Section',
  usageType : 'page-widget',
  namespace : lp.module.block
}));

module.exports = BlockModule;
