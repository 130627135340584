import PropTypes from 'prop-types';
import React from 'react';
import ColorPopdown from './color-popdown';
import ColorValidator from 'ub/data/color-validator';
import ClickAwayListener from 'material-ui/internal/ClickAwayListener';
import tinycolor2 from 'tinycolor2';

const focusHandler = ev => {
  if (window.editor) {
    window.editor.keyController.requestFocus(ev.target);
  }
};

export default class ColorSelection extends React.Component {
  constructor(props) {
    super(props);

    this._getColorHex = color => {
      if (ColorValidator.isValidHex(color)) {
        return color;
      } else {
        return tinycolor2(color).toHex();
      }
    };

    this._togglePopdown = e => {
      if (e.clientY > window.innerHeight / 2) {
        // is above the half way point and won't get cut off
        this.setState({ positionAbove: true });
      } else {
        this.setState({ positionAbove: false });
      }
      if (!this.props.disabled) {
        this.setState({ open: !this.state.open });
      }
    };

    this.isMousedDown = false;

    this._onMouseDown = () => {
      this.isMousedDown = true;
    };

    this._onMouseUp = () => {
      this.isMousedDown = false;
    };

    this._closePopdown = () => {
      if (!this.isMousedDown) {
        this.setState({ open: false });
      }
    };

    this._onUserInput = ev => {
      this.setState({
        inputValue: ev.target.value,
        open: false,
      });
    };

    this._onBlurEvent = ev => {
      const colorInput = ev.target.value;

      if (ColorValidator.isValidHex(colorInput)) {
        // hexToRgb does not provide an alpha value.
        const newColor = tinycolor2(colorInput);
        const previousColor = this.props.color;
        const previousAlpha = previousColor.a;

        // Fallback is fully opaque.
        // We will only use the previous alpha if it's greater than 0. We are assuming
        // the user wants to see their input color after setting a new color value
        let alphaValue = 1;

        if (previousAlpha && previousAlpha > 0) {
          alphaValue = previousAlpha;
        }

        newColor.setAlpha(alphaValue);

        this._changeColor(newColor.toRgb());
      } else {
        this._resetColor();
      }
    };

    this.onKeyUp = this.onKeyUp.bind(this);

    this._changeColor = color => {
      this.props.changeHandler({
        type: this.props.changeProperty,
        value: tinycolor2(color).toRgbString(),
        id: this.props.id,
      });
    };

    this._handlePopdownChangeComplete = color => {
      const { r, g, b, a } = color;

      this.setState({
        color,
        inputValue: this._getColorHex({ r, g, b, a }),
        open: false,
      });

      this.props.changeHandler({
        type: this.props.changeProperty,
        value: tinycolor2(color).toRgbString(),
        id: this.props.id,
      });
    };

    const { r, g, b, a } = this.props.color;

    this.state = {
      open: false,
      inputValue: this._getColorHex(this.props.color), // for display in input box
      color: { r, g, b, a }, // source of truth
    };
  }

  componentWillReceiveProps(nextProps) {
    const color = nextProps.color;

    this.setState({
      open: false,
      inputValue: this._getColorHex(nextProps.color),
      color,
    });
  }

  onKeyUp(ev) {
    const RETURN_KEY = 13;
    const ESC_KEY = 27;

    if (ev.which === RETURN_KEY) {
      ev.target.blur();
    } else if (ev.which === ESC_KEY) {
      this._resetColor();
    }

    return true;
  }

  _resetColor() {
    this.setState({
      inputValue: this._getColorHex(this.props.color),
      open: false,
    });
  }

  showHidePopdown() {
    if (this.state.open) {
      return (
        <div onMouseDown={this._onMouseDown} onMouseUp={this._onMouseUp}>
          <ClickAwayListener onClickAway={this.state.open && this._closePopdown}>
            <ColorPopdown
              color={this.state.color}
              handleChangeComplete={this._handlePopdownChangeComplete}
              handleCancel={this._closePopdown}
              showOpacity={this.props.showOpacity}
              saveColor={this._saveColor}
              borderSelector={this.props.borderSelector}
              positionAbove={this.state.positionAbove}
            />
          </ClickAwayListener>
        </div>
      );
    }
  }

  render() {
    const pickerStyle = {
      background: tinycolor2(this.state.color).toRgbString(),
    };

    return (
      <div className="input-field">
        <div className="color-picker-button" onClick={this._togglePopdown.bind(this)}>
          <div className="swatch color-picker-swatch" style={pickerStyle} />
        </div>

        <div className="color-input-container">
          <span className="hash color-picker-hash form-elm-mini-label">#</span>
          <input
            value={this.state.inputValue}
            onChange={this._onUserInput}
            onBlur={this._onBlurEvent}
            className="text form-elm-input-text"
            onFocus={focusHandler}
            onKeyUp={this.onKeyUp}
          />
        </div>

        {this.showHidePopdown()}
      </div>
    );
  }
}

ColorSelection.propTypes = {
  color: PropTypes.object, // was string, then array now b/c of tinycolor is obj
  changeHandler: PropTypes.func,
  changeProperty: PropTypes.string,
  showOpacity: PropTypes.bool,
  id: PropTypes.number,
  borderSelector: PropTypes.bool,
  disabled: PropTypes.bool,
};
