export default {
  defaults: {
    // General Settings
    displayName: 'Page',
    displayNameLowerCase: 'page',

    webappPayloadKey: 'page',
    webappPagesResource: 'pages',
    webappVariantsResource: 'variants',
    webappTemplatesResource: 'page_templates',

    fitWidthToPageValue: true,
    linkTargetDefault: '_self',

    // Variants
    supportsMultipleVariants: true,

    // Widgets
    allowVideoWidget: true,

    // Page Properties
    allowPageMetadata: true,
    allowCanonicalUrl: false,

    // Page Sections
    showPageHeightWarning: false,
    sectionHeight: 200,
    sectionCountLimit: null,
    allowSectionBorderRadiusControls: true,
    allowSectionBorderLocationControls: true,
    allowSectionMargins: true,

    // General Element Properties
    allowVisibilityToggle: true,

    // Page Root
    allowRootTopPadding: true,
    allowRootBackground: true,
    showRootDisplayPositionControls: false,

    // Backgrounds
    allowFitWidthToPage: true,
    allowParallaxBackgrounds: true,
    allowVideoBackgrounds: true,
    showRootBackground: true,

    // Responsive & Mobile
    startingBreakpoint: 'desktop',
    allowTabletBreakpoint: true,
    allowAutoscale: true,
    allowAutoLayout: true,
    allowViewportMetaTag: true,
    allowDisablingDesktopBreakpoint: false,
    useDesktopWidthAsCSSBreakpoint: false,
    showResponsiveControls: true,

    // Text
    allowDTR: true,

    // Forms & Form Confirmation
    allowFormRadioFields: true,
    setFormColumnsAutomatically: false,
    rightAlignFormButtonPosition: false,
    formTargetDefault: '',
    formConfirmationDialogSectionHeight: 180,
    formConfirmationText:
      '<h1 style="text-align: center;">Thank You!</h1><p style="text-align: center;">Your form has been submitted.</p>',
    maxFormColumns: 4,
    allowFormPostAction: true,
    allowFormAlertMessageAction: true,
    allowFormExternalLightboxAction: true,
    allowFormExternalLightboxWithPassParams: true,
    requireHTTPSFormRedirect: false,

    // Lightboxes
    allowExternalLightboxLinks: true,
    allowInternalLightboxes: true,
    allowUrlParamPassthrough: true,

    // Scripts
    showScriptManager: true,
    allowScriptsAndStylesheetsOnSubPages: true,
    showAmpValidationWarnings: false,
    customStylesheetCodeMirrorMode: 'htmlmixed',

    // Saving & Publishing
    showPublishedURL: true,
    allowRepublishing: true,
    allowPreviewing: true,

    // Goals
    addGoalAttributeToLinks: false,
    showExternalConversionGoals: true,
    autoEnableGoals: false,

    // Tours
    showTour: true,
    useConvertablesMobileTour: false,

    // Popups and Overlays
    allowButtonCloseClickAction: false,
    allowAutoDismissEmbeddable: false,
    showMainPageCanvasAsModal: false,
    showSecondaryPageCanvasAsModal: true,
    isEmbeddable: false,
    showOverlaySEOWarning: false,
    selfTargetLabel: 'Same tab or window',
  },

  overrides: {
    // Overlay/popup. Named 'embeddable' for legacy reasons.
    embeddable: {
      // General Settings
      displayName: 'Popup',
      displayNameLowerCase: 'Popup', // Unlike 'page', 'Popup' should always be capitalized

      webappPayloadKey: 'embeddable',
      webappPagesResource: 'convertables',
      webappVariantsResource: 'embeddable_variants',
      webappTemplatesResource: 'embeddable_templates',

      fitWidthToPageValue: false,
      linkTargetDefault: '_blank',
      formTargetDefault: '_parent',

      // Variants
      supportsMultipleVariants: false,

      // Widgets

      // Page Properties
      allowPageMetadata: false,

      // Page Sections
      sectionHeight: 500,
      allowSectionMargins: false,

      // Page Root
      allowRootTopPadding: false,

      // Backgrounds
      allowFitWidthToPage: false,
      allowParallaxBackgrounds: false,

      // Responsive & Mobile
      allowTabletBreakpoint: false,
      allowAutoscale: false,
      allowViewportMetaTag: false,
      allowDisablingDesktopBreakpoint: true,
      useDesktopWidthAsCSSBreakpoint: true,

      // Forms & Form Confirmation
      allowFormExternalLightboxAction: false,

      // Lightboxes
      allowExternalLightboxLinks: false,
      allowInternalLightboxes: false,

      // Scripts
      showScriptManager: false,

      // Saving & Publishing
      showPublishedURL: false,
      allowRepublishing: false,

      // Goals
      showExternalConversionGoals: false,
      addGoalAttributeToLinks: true,
      autoEnableGoals: true,

      // Tours
      useConvertablesMobileTour: true,

      // Popups and Overlays
      allowButtonCloseClickAction: true,
      showMainPageCanvasAsModal: true,
      isEmbeddable: true,
      showOverlaySEOWarning: true,
      allowAutoDismissEmbeddable: true,
      selfTargetLabel: 'Within Popup',
    },

    stickyBar: {
      // General Settings
      displayName: 'Sticky Bar',
      displayNameLowerCase: 'Sticky Bar', // Unlike 'page', 'Sticky Bar' should always be capitalized

      webappPayloadKey: 'embeddable',
      webappPagesResource: 'convertables',
      webappVariantsResource: 'embeddable_variants',
      webappTemplatesResource: 'embeddable_templates',

      formConfirmationDialogSectionHeight: 60,
      formConfirmationText:
        '<p style="text-align: center;"><strong>Thank You!</strong> Your form has been submitted.</p>',
      linkTargetDefault: '_blank',
      formTargetDefault: '_parent',

      // Variants
      supportsMultipleVariants: false,

      // Widgets
      allowVideoWidget: false,

      // Page Properties
      allowPageMetadata: false,

      // Page Sections
      showPageHeightWarning: true,
      sectionCountLimit: 1,
      sectionHeight: 60,
      allowSectionMargins: false,
      allowSectionBorderRadiusControls: false,
      allowSectionBorderLocationControls: false,

      // Page Root
      allowRootTopPadding: false,
      allowRootBackground: false,
      showRootDisplayPositionControls: true,

      // Backgrounds
      allowFitWidthToPage: false,
      allowParallaxBackgrounds: false,
      allowVideoBackgrounds: false,
      showRootBackground: false,

      // Responsive & Mobile
      allowTabletBreakpoint: false,
      allowAutoscale: false,
      allowAutoLayout: false,
      allowViewportMetaTag: false,
      allowDisablingDesktopBreakpoint: true,
      useDesktopWidthAsCSSBreakpoint: true,

      // Forms & Form Confirmation
      allowFormRadioFields: false,
      setFormColumnsAutomatically: true,
      rightAlignFormButtonPosition: true,
      allowFormExternalLightboxAction: false,

      // Lightboxes
      allowExternalLightboxLinks: false,
      allowInternalLightboxes: false,

      // Scripts
      showScriptManager: false,

      // Saving & Publishing
      showPublishedURL: false,
      allowRepublishing: false,

      // Goals
      showExternalConversionGoals: false,
      addGoalAttributeToLinks: true,
      autoEnableGoals: true,

      // Tours
      useConvertablesMobileTour: true,

      // Popups and Overlays
      allowButtonCloseClickAction: true,
      showSecondaryPageCanvasAsModal: false,
      isEmbeddable: true,
      allowAutoDismissEmbeddable: true,
      selfTargetLabel: 'Within Sticky Bar',
    },

    amp: {
      // General Settings
      displayName: 'AMP',
      displayNameLowerCase: 'amp',

      webappPayloadKey: 'amp',
      webappPagesResource: 'amp',
      webappVariantsResource: 'variants',
      webappTemplatesResource: 'page_templates',

      // Page Properties
      allowCanonicalUrl: true,

      // Variants
      supportsMultipleVariants: false,

      // General Element Properties
      allowVisibilityToggle: false,

      // Text
      allowDTR: false,

      // Forms & Form Confirmation
      maxFormColumns: 1,
      allowFormPostAction: false,
      allowFormAlertMessageAction: false,
      allowFormExternalLightboxWithPassParams: false,
      requireHTTPSFormRedirect: true,

      // Lightboxes
      allowUrlParamPassthrough: false,

      // Saving & Publishing
      allowPreviewing: false,

      // Scripts
      showScriptManager: false,
      allowScriptsAndStylesheetsOnSubPages: false,
      showAmpValidationWarnings: true,
      customStylesheetCodeMirrorMode: 'css',

      // Goals
      showExternalConversionGoals: false,

      // Backgrounds
      allowParallaxBackgrounds: false,
      allowVideoBackgrounds: false,

      // Responsive & Mobile
      startingBreakpoint: 'mobile',
      showResponsiveControls: false,
      allowAutoLayout: false,

      // Tours
      showTour: false,
    },
  },
};
