import _ from 'lodash';
import Rx from 'rx';
import jQuery from 'jquery';
import googleFontsApi from 'ub/fonts/font-service/google/api';
import { normalizeFontData, cleanQuotes } from './font-data-normalizer.js';

const fontStorageUpdates = new Rx.Subject();

function parseTextContent(text) {
  const content = document.createElement('p');
  content.innerHTML = text.model.safeGet('content.text');
  return content.querySelectorAll('*');
}

function matchFont(familyToCompare, actualFamily) {
  if (!familyToCompare || !actualFamily) {
    return;
  }

  const elmFontFamily = cleanQuotes(actualFamily).toLowerCase();
  const familyLower = familyToCompare.toLowerCase();
  return _.isEqual(familyLower, elmFontFamily);
}

function syncFontLists() {
  const allPages = window.editor && window.editor.pages;

  if (_.isUndefined(allPages)) {
    return [];
  }

  const fontList = _(window.editor.pages)
    .map(page => {
      return page.settings.fonts;
    })
    .flattenDeep()
    .compact()
    .uniqBy('family')
    .value();

  _.forEach(window.editor.pages, page => {
    page.settings.fonts = fontList;
  });

  return fontList;
}

function addedWebFonts() {
  let pageFonts = window.editor && window.editor.page.settings.fonts;

  if (_.isUndefined(pageFonts)) {
    pageFonts = syncFontLists();
  }

  return pageFonts;
}

function isFontAddedToDropdown(fontFamily) {
  const fontExists = _.filter(addedWebFonts(), { family: fontFamily });

  return !_.isEmpty(fontExists);
}

function updateFontList(webFonts) {
  _.forEach(window.editor.pages, page => {
    page.settings.fonts = webFonts;
  });
  fontStorageUpdates.onNext({ webFonts });
}

function formatForWebfontLoader(fonts) {
  return _.map(fonts, ({ family, variants }) => {
    return `${family}:${_.map(variants, 'name').join(',')}`;
  });
}

function addFontFromData(fontData, autoRender) {
  if (!isFontAddedToDropdown(fontData.family)) {
    const currentFonts = addedWebFonts();
    const newFonts = _.sortBy([...currentFonts, fontData], 'family');

    updateFontList(newFonts);

    const formattedWebfont = formatForWebfontLoader([fontData]);

    googleFontsApi.loadGoogleFonts(formattedWebfont, autoRender);
  }
}

function addFont(fontData, autoRender) {
  const normalizedFont = normalizeFontData(fontData);

  addFontFromData(normalizedFont, autoRender);
}

function getAllTextWithMatchingFont(pages, family) {
  return _(pages)
    .map(page => {
      return page.getElementsByType('lp-pom-text');
    })
    .flattenDeep()
    .filter(elm =>
      _.some(parseTextContent(elm), child => matchFont(family, child.style.fontFamily))
    )
    .value();
}

function getAllButtonsWithMatchingFont(pages, family) {
  return _(pages)
    .map(page => {
      return page.getElementsByType('lp-pom-button');
    })
    .flattenDeep()
    .filter(button => {
      return matchFont(family, button.model.safeGet('style.fontFamily'));
    })
    .value();
}

function getAllFormCheckboxesWithMatchingFont(pages, family) {
  return _(pages)
    .map(page => {
      return page.getElementsByType('lp-pom-form');
    })
    .flattenDeep()
    .filter(form => {
      return matchFont(family, form.model.safeGet('style.cbxlabel.font.family'));
    })
    .value();
}

function getAllFormLabelsWithMatchingFont(pages, family) {
  return _(pages)
    .map(page => {
      return page.getElementsByType('lp-pom-form');
    })
    .flattenDeep()
    .filter(form => {
      return matchFont(family, form.model.safeGet('style.label.font.family'));
    })
    .value();
}

function removeFontFromButtons(pages, family) {
  const buttonsUsingFont = getAllButtonsWithMatchingFont(pages, family);

  buttonsUsingFont.each(modelData => {
    modelData.set('style.fontFamily', 'arial');
    modelData.set('style.fontWeight', 'normal');
    modelData.set('style.fontStyle', 'normal');
  });
}

function removeFontFromForms(pages, family) {
  const checkBoxElements = getAllFormCheckboxesWithMatchingFont(pages, family);
  const labelElements = getAllFormLabelsWithMatchingFont(pages, family);

  checkBoxElements.each(modelData => {
    modelData.set('style.cbxlabel.font.family', 'arial');
    modelData.set('style.cbxlabel.font.weight', 'normal');
    modelData.set('style.cbxlabel.font.style', 'normal');
  });

  labelElements.each(modelData => {
    modelData.set('style.label.font.family', 'arial');
    modelData.set('style.label.font.weight', 'normal');
    modelData.set('style.label.font.style', 'normal');
  });
}

function removeFontFromText(pages, family) {
  const textElements = getAllTextWithMatchingFont(pages, family);

  return textElements.forEach(textElement => {
    // use jQuery here to keep a copy of the parent element
    const $content = jQuery(textElement.model.safeGet('content.text')).wrapAll('<div>').parent();

    $content
      .find('*')
      .toArray()
      .filter(element => matchFont(family, element.style.fontFamily))
      .forEach(elm => {
        elm.style.fontFamily = 'Arial';
        elm.style.fontStyle = 'normal';
        elm.style.fontWeight = 'normal';
      });

    textElement.model.set('content.text', $content.html());
  });
}

function isFontInUse(family) {
  const pages = window.editor.pages;

  const allTextElements = getAllTextWithMatchingFont(pages, family);
  const allButtonElements = getAllButtonsWithMatchingFont(pages, family);
  const allFormLabelElements = getAllFormLabelsWithMatchingFont(pages, family);
  const allFormCheckboxElements = getAllFormCheckboxesWithMatchingFont(pages, family);

  const allElements = _.union(
    allTextElements,
    allButtonElements,
    allFormLabelElements,
    allFormCheckboxElements
  );

  return !_.isEmpty(allElements);
}

function removeFont({ family }) {
  const currentFonts = addedWebFonts();
  const newFonts = _.reject(currentFonts, font => {
    return font.family === family;
  });

  updateFontList(newFonts);

  removeFontFromForms(window.editor.pages, family);
  removeFontFromButtons(window.editor.pages, family);
  removeFontFromText(window.editor.pages, family);
}

const getHtmlAsNodeList = contentHtml => {
  const template = document.createElement('template');
  template.innerHTML = contentHtml;
  return template.content;
};

const addFontFamilyFromExternal = fontFamily => {
  const currentFonts = addedWebFonts();
  const newFonts = _.sortBy([...currentFonts, fontFamily], 'family');

  updateFontList(newFonts);
};

export default {
  get addedWebFonts() {
    return addedWebFonts();
  },
  addFont,
  addFontFromData,
  isFontAddedToDropdown,
  fontStorageUpdates,
  removeFont,
  syncFontLists,
  isFontInUse,
  removeFontFromForms,
  removeFontFromButtons,
  removeFontFromText,
  updateFontList,
  getAllTextWithMatchingFont,
  getAllButtonsWithMatchingFont,
  getAllFormLabelsWithMatchingFont,
  getAllFormCheckboxesWithMatchingFont,
  getHtmlAsNodeList,
  addFontFamilyFromExternal,
};
