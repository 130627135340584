/* globals lp, jui,Class */

var ubBanzai = require('ub/control/banzai-features');
var isSandboxMode = ubBanzai.features.isSandboxEnabled();

var VideoModule = Class.create(
  lp.ModuleBase,
  lp.ModuleElementMethods,
  lp.ModuleElementBuilderMethods,
  lp.ModuleElementPropertiesPanelMethods,
  {
    beforeCreate: function (page, defaults, options, data) {
      defaults.content.html = data.html;
    },

    getTransformBoxUIExtensions: function () {
      var self = this;
      return [
        {
          placement: 'outside blh',
          component: new jui.ToggleButton({
            label: 'View&nbsp;Video',
            classNames: ['dark', 'canvas-action-button-container', 'view-video'],
            action: function (e){
              var button = e.source;
              var state = e.data;
              button.setLabel( state ? 'Move&nbsp;Video' : 'View&nbsp;Video' );
              self.activeElement.overlay.style.display = state ? 'none' : 'block';
              if( !state ) {
                self.activeElement.resetVideo();
              }
            },
            toolTip:'Toggle between view video mode and move video mode'
          })
        }
      ];
    }
  }
);

lp.addModule(new VideoModule(
  {
    type:'lp-pom-video',
    getLimit: function() {
      return isSandboxMode ? 0 : Infinity;
    },
    isAllowedOnPage: function(page) {
      return page.getConfigValue('allowVideoWidget');
    },
    version:'1.0',
    name: 'Video',
    usageType:'page-widget',
    namespace: lp.module.video,
    overlay: null,
  }
));
