// TODO: Refactor this into a commonjs module
/* globals gon */
var Raven = require('raven-js');
var _     = require('lodash');

window.ravenDataCallbackHooks = [];
var ravenDataCallback = function(data) {
  // http://raven-js.readthedocs.org/en/latest/config/index.html#datacallback
  _.forEach(
    window.ravenDataCallbackHooks,
    function(hook) {
      hook(data); // hook mutates `data`
    });
  return data;
};

function getRavenDsn(env) {
  switch (env) {
    case 'integration':
      return 'https://b7ae34e5a5cf4f32bd2ad08e3b5af8f1@app.getsentry.com/31500';
    case 'test':
      return 'https://b7ae34e5a5cf4f32bd2ad08e3b5af8f1@app.getsentry.com/31500';
    case 'development':
      return 'https://c29ce2ecb7684f84bf3d67a96e04a4fc@app.getsentry.com/52045';
    case 'sandbox':
      return 'https://415ae2ea3918485bab0a775dce24bf0c@sentry.io/236829';
    default:
      // Production
      return 'https://a1b95f7fc58143ebb4b5f939bf2e4b7e@app.getsentry.com/31180';
  }
}

;(function() {
  window.RavenBaseContext = {};

  var RavenDsn;
  var autoBreadcrumbs = true;

  if (window.gon) {
    var env = window.gon.env;

    if (env === 'test') {
      return;
    }

    if (env === 'development') {
      autoBreadcrumbs = false;
    }

    RavenDsn = getRavenDsn(env);
  }

  var ravenOptions = {
    // http://raven-js.readthedocs.org/en/latest/tips/index.html
    // http://raven-js.readthedocs.org/en/latest/config/index.html
    // see https://gist.github.com/impressiver/5092952 for ideas
    // Will cause a deprecation warning, but the demise of `ignoreErrors` is still under discussion.
    // See: https://github.com/getsentry/raven-js/issues/73
    whitelistUrls: [
      /undefined/ // for console log messages, etc
      , /.*-dev/
      , /localhost/
      , /.*\.unbounce\.com/
      , /s3\.amazonaws\.com/
      , /.*.ub/
    ],
    ignoreErrors: [
      'window.onbeforeunload',
      'atomicFindClose',
      'Wistia',
      'Problem attempting to save: Forbidden'
    ],
    ignoreUrls: [
      // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i
    ],
    //shouldSendCallback: ..., http://raven-js.readthedocs.org/en/latest/config/index.html#shouldsendcallback
    autoBreadcrumbs: autoBreadcrumbs,
    dataCallback: ravenDataCallback,
    fetchContext: true,
    collectWindowErrors: true,
    culprit: 'builder'
  };

    try {
      ravenOptions.release = window.builderVersion;
      Raven.config(RavenDsn, ravenOptions).install();
      if (window.gon) {
        var context = {tags: _.merge({}, gon, gon.server_metadata || {})};
        context.tags.editor_version = window.builderVersion || 'dev';
        context.culprit = ravenOptions.culprit;
        delete context.tags.server_metadata;
        delete context.tags.user;
        window.RavenBaseContext = context;
        Raven.setTagsContext(context.tags);
        if (gon.user) {
          Raven.setUserContext(gon.user);
        }
      }
    } catch (e) {
      if (console) {
        console.error(e);
      }
    }

})();
