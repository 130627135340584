window.lp = window.lp || {};
window.builderVersion = require('val-loader!./builderVersion.js');

if (process.env.NODE_ENV === 'production') {
  require('../dist/build_config_manifest');
} else {
  window.builderVersion += '-dev';
}

// Prevent lodash from assigning itself to window
require('lodash').noConflict();

require('script-loader!ub/thirdparty/prototype-1.7.js');

// We include jQuery plugins here in such a way that the `jQuery` variable doesn't need to
// be global. However, the Rails template assumes jQuery is available on the window object
// so until that is addressed we still need to expose it below.
require('imports-loader?jQuery=jquery!ub/thirdparty/jquery.unveil.js');
require('imports-loader?jQuery=jquery!ub/thirdparty/bootstrap-dropdown.js');
require('imports-loader?jQuery=jquery!ub/thirdparty/select2.js');

require('imports-loader?jQuery=jquery!froala-editor/js/froala_editor.min.js');
require('imports-loader?jQuery=jquery!froala-editor/js/plugins/align.min.js');
require('imports-loader?jQuery=jquery!froala-editor/js/plugins/code_view.min.js');
require('imports-loader?jQuery=jquery!froala-editor/js/plugins/code_beautifier.min.js');
require('imports-loader?jQuery=jquery!froala-editor/js/plugins/colors.min.js');
require('imports-loader?jQuery=jquery!froala-editor/js/plugins/entities.min.js');
require('imports-loader?jQuery=jquery!froala-editor/js/plugins/font_family.min.js');
require('imports-loader?jQuery=jquery!froala-editor/js/plugins/font_size.min.js');
require('imports-loader?jQuery=jquery!froala-editor/js/plugins/link.min.js');
require('imports-loader?jQuery=jquery!froala-editor/js/plugins/lists.min.js');
require('imports-loader?jQuery=jquery!froala-editor/js/plugins/paragraph_format.min.js');
require('imports-loader?jQuery=jquery!froala-editor/js/plugins/paragraph_style.min.js');
require('imports-loader?jQuery=jquery!froala-editor/js/plugins/url.min.js');

window.jQuery = require('jquery');

require('imports-loader?jQuery=jquery!./froala-key.js'); // this must be after froala and jquery require.

window.loadAsync = require('ub_legacy/header.js');

window.CodeMirror = require('codemirror');
require('codemirror/mode/xml/xml.js');
require('codemirror/mode/javascript/javascript');
require('codemirror/mode/css/css.js');
require('codemirror/mode/htmlmixed/htmlmixed.js');

window.Flog = require('exports-loader?Flog!ub/thirdparty/flog/uri-parser.js');

require('ub_wrapped/rx');

var ubBanzai = require('ub/control/banzai-features');
window.ubBanzai = ubBanzai;

var ErrorNotifier = require('ub/control/error_notifier');
window.lp.errorNotifier = new ErrorNotifier().install();

// TODO: use require instead of global scope
window.rxSwitchboard = require('ub/control/rx-switchboard');

require('ub/control/rx-ui.js');
