/* globals Class, $H */
window.jui.BubbleTip = Class.create(window.jui.Component, {
  options: function($super,options){
    return $super($H({
      attributes:{className:'bubble-tip'},
      arrowPos:"top-left"
      }).merge(options));
  },

  initialize: function($super, options) {
    $super(options);
  },

  installUI: function($super) {
    $super();
    this.insert(new Element('p').update(this.options.text || ''));
    this.insert(new Element('div', {className:'arrow-'+this.options.arrowPos}));
  },

  show: function($super, options) {
    document.body.insert(this.e);
    var loc = {left:options.origin.left + options.offset.left, top:options.origin.top + options.offset.top};
    this.setOffset(loc);
  },

  hide: function($super, options) {
    /* jshint unused:vars */
    this.remove();
  }
});
