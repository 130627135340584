/* globals lp,Class */
var ScriptElement = Class.create(
  lp.pom.Element,
  lp.ModuleComponent,
  {
    type: 'lp-script',

    toPageInsertion: function() {
      return {
        content: this.model.get('content.html'),
        placement: this.model.get('placement'),
      };
    },

    createDefaultConstraints: function($super) {
      $super();
      this.defaultConstraints.displayable = false;
      this.defaultConstraints.copyable = false;
    }
  }
);

ScriptElement.elementDefaults = {
  name: 'Javascript',
  containerId: null,
  content: {
    type: null,
    valid: true,
  }
};

module.exports = ScriptElement;
