var pomUpdates = require('ub_legacy/lp/pom/updates/updates');

pomUpdates.add(Object.extend({

  minVersion: '2.1',
  version: '2.2',

  run: function(jso) {
    if(this.checkVersion(jso)) {
      this.putFancyboxBehindCloudFront(jso);
      jso.version = this.version;
    }
  },

  putFancyboxBehindCloudFront: function(jso) {
    var hostedFancybox = this.getHostedFancyboxScripts(jso);
    hostedFancybox.each(function(el){
      el.content.html = '<link href="//assets.unbounce.com/m/lp-webapp/jquery.fancybox-1.3.4/fancybox/jquery.fancybox-1.3.4.css" media="screen" rel="stylesheet" type="text/css">\n' +
        '<script src="//assets.unbounce.com/m/lp-webapp/jquery.fancybox-1.3.4/fancybox/jquery.fancybox-1.3.4.js"></script>';
    });
  },

  getHostedFancyboxScripts: function(jso) {
    return jso.elements.findAll(function(el){
      return el.type === 'lp-script' && el.predefined === true && el.name === 'jQuery.Fancybox (1.3.4)';
    });
  }
}, window.lp.pom.updater));
