import _ from 'lodash';

import {safeGet, safeSet, safeRemove} from 'ub/utils';
import pomUpdates from 'ub_legacy/lp/pom/updates/updates';
import checkVersion from './checkVersion';

// This upgrader adds `geometry.layoutAxis` to form elements.
// The default value is "vertical", representing the old, full-width, vertical form layout.

pomUpdates.add({
  minVersion: '2.7',
  version: '2.8',

  run: function(pomData0) {
    if (!checkVersion(this.minVersion, this.version, pomData0.mainPage.version)) {
      return pomData0;
    }

    const pomData = _.cloneDeep(pomData0);
    const DEFAULT_FIELD_WIDTH = 200;

    const updateElement = (el) => {
      if (el.type === 'lp-pom-form') {
        if (!safeGet(el, 'geometry.layoutAxis')) {
          safeSet(el, 'geometry.layoutAxis', 'vertical');
        }

        if (!safeGet(el, 'geometry.field.width')) {
          safeSet(el, 'geometry.field.width', DEFAULT_FIELD_WIDTH);
        }

        if (safeGet(el, 'geometry.label.height')) {
          safeRemove(el, 'geometry.label.height');
        }
      }
    };

    [pomData.mainPage, ...pomData.subPages].forEach((page) => {
      page.elements.forEach(updateElement);
      page.version = this.version;
    });

    return pomData;
  }
});
