/* eslint-disable no-useless-escape */

// Optional leading '+' followed by 6-13 numbers, allowing a space, paren, or dash between each
// digit
export const generic = new RegExp(/^\+?( ?[\(]?[\-]?[0-9][\)]?){6,13}$/);

// Optional country code with optional leading plus and optional trailing space or dash
// Area code: 3 digits, with optional parens
// Optional space or dash
// Exchange code: 3 digits
// Optional space or dash
// Line number: 4 digits
export const northAmerica = new RegExp(
  /^\+?1?[ \-]?[\(]?[2-9][0-9][0-9][\)]?[ \-]?[2-9][0-9][0-9][ \-]?[0-9]{4}$/
);

// prettier-ignore
// "0", "(0", or "+44"
// 10-digit numbers. Cannot start with 0 or 4. Allow a space and/or parens between each digit.
// 9-digit numbers. Must start with 01, 0500, or 0800. Allow a space and/or parens between each
// digit.
export const uk = new RegExp(
  /^([\(]?0|\+44)(( ?[\(]?([12356789]))( ?[\(]?[0-9][\)]?){9}|( ?[\(]?(1[0-9][0-9]|500|800)( ?[\(]?[0-9][\)]?){6}))$/);

// "0", "(0", or "+61"
// Area code
// Optional space or dash
// 8 digits, allowing a space and/or parens between each digit
export const australia = new RegExp(
  /^([\(]?0|\+?61)[ \-]?[\(]?(2|3|4|7|8)[ .\-]?( ?[\(]?[0-9][\)]?){8}$/
);
