/* globals lp, editor, jui */
var Rx           = require('ub_wrapped/rx');
var _            = require('lodash');

var ubBanzai          = window.ubBanzai;
var videoHosts        = require('ub/video-hosts');
var AlertDialog       = require('ub/ui/alert-dialog');

var backgroundStyleTypes    = ['solidColor', 'gradient', 'image', 'pattern', 'video'];
var imagePatternSharedProps = ['image', 'opacity', 'bgColor', 'isColorOverImage'];

//////////////////////////////////////////////////////////////////////
var _defaults = {
  // Lookup precedence order. Matches the most specific selector found - like css:
  // "<element-type>,<style>,<prop>": "value",
  // "<style>,<prop>": "value",
  // "<element-type>,<prop>": "value",
  // "<prop>": "value",

  "image,image": null,
  "pattern,image": null,

  "opacity": 100,
  "image,opacity": 0,
  "pattern,opacity": 0,
  "video,opacity": 0,
  "lp-pom-text,opacity": 0,

  "isColorOverImage": true,

  "gradient,baseColor": "fff",
  "gradient,fromColor": "fff",
  "gradient,toColor": "e6e6e6",
  "gradient,autoGradient": true,
  "gradient,reverseGradient": false,
  "lp-pom-root,gradient,baseColor": "eee",
  "lp-pom-root,gradient,fromColor": "eee",
  "lp-pom-root,gradient,toColor": "c6c6c6",

  "bgColor": "fff",
  "lp-pom-root,bgColor": "eee",

  "parallax": false,

  "image,position": "center center",
  "pattern,position": "left top",
  "pattern,tiling": "repeat",

  "image,repeat": "no-repeat",
};

var _styleProperties = {
  solidColor: ['bgColor', 'opacity', 'fitWidthToPage'],
  gradient: ['baseColor', 'fromColor', 'toColor', 'autoGradient', 'reverseGradient', 'fitWidthToPage'],
  image: [
    'image', 'parallax',
    'fitToContainer', 'position', 'bgColor', 'isColorOverImage', 'opacity', 'fitWidthToPage'],
  pattern: [
    'image', 'parallax',
    'tiling',
    'position', 'bgColor', 'isColorOverImage', 'opacity', 'fitWidthToPage'],
  video: [
    'videoId', 'videoHost', 'videoRatio', 'videoImage', 'videoUrl',
    'bgColor', 'opacity', 'fitWidthToPage']
};

var _firstMatchInMap = function(map, keys) {
  var k = _.find(keys, function(k) {
    return !_.isUndefined(map[k]);
  });
  return map[k];
};

var _commaify = function() {
  return _.toArray(arguments).join(',');
};

var _getNewPropNoDefaults = function(model, style, propName) {
  return model.safeGet(['style.newBackground', style, propName].join('.'));
};

var _hasLargeBackgroundImage = function(imageSize0) {
  var imageSize = _.isObject(imageSize0) ? _.clone(imageSize0) : {width: 0, height: 0},
  minSize = ubBanzai.getFeatureValue('backgroundImageMinSize');
  return (imageSize.width > minSize || imageSize.height > minSize);
};

var NO_CALC_DEFAULT = {};
var _defaultCalculators = {};

_defaultCalculators.solidColor = {};

_defaultCalculators.solidColor.fitWidthToPage = function(model /*, style, propName */) {
  try {
    return model.safeGet('geometry.fitWidthToPage');
  } catch(e) {
    return model.element.page.getConfigValue('fitWidthToPageValue');
  }
};

_defaultCalculators.gradient = {};

_defaultCalculators.gradient.fitWidthToPage = function(model /*, style, propName */) {
  try {
    return model.safeGet('geometry.fitWidthToPage');
  } catch(e) {
    return model.element.page.getConfigValue('fitWidthToPageValue');
  }
};

// we share the image and pattern lookup functions
_defaultCalculators.image = _defaultCalculators.pattern = {};

// use this one for clarity:
_defaultCalculators.imageAndPattern = _defaultCalculators.image;

_defaultCalculators.imageAndPattern.opacity = function(model, style /* , propName */ ) {
  var hasImage = _getNewPropNoDefaults(model, style, 'image');
  if (model.element.type === 'lp-pom-text') {
    return NO_CALC_DEFAULT;
  } else {
    return ( ! hasImage) ? 100 : 0;
  }
};

_defaultCalculators.imageAndPattern.fitWidthToPage = function(model /*, style, propName */) {
  try {
    return model.safeGet('geometry.fitWidthToPage');
  } catch(e) {
    return model.element.page.getConfigValue('fitWidthToPageValue');
  }
};

var _hasImageSize = function(image) {
  return _.isEmpty(image) || _.isEmpty(image.size);
};

_defaultCalculators.imageAndPattern.position = function(model, style /* , propName */ ) {
  var image = _getNewPropNoDefaults(model, style, 'image');
  if (_hasImageSize(image)) {
    // pre-exiting images from old pages will have no
    // size and we shouldn't change how they look
    return NO_CALC_DEFAULT;
  } else if (image && ! _hasLargeBackgroundImage(image.size)) {
    return 'left top';
  } else {
    return NO_CALC_DEFAULT;
  }
};

//TODO: fix so you can have image-specific actions
_defaultCalculators.image.fitToContainer = function(model, style /* , propName */ ) {
  var image = _getNewPropNoDefaults(model, style, 'image');
  if (_hasImageSize(image)) {
    // pre-exiting images from old pages will have no
    // size and we shouldn't change how they look
    return false;
  } else if (image && ! _hasLargeBackgroundImage(image.size)) {
    return false;
  } else {
    return !!image;
  }
};

_defaultCalculators.imageAndPattern.parallax = function(/* model, style, propName */ ) {
  return window.editor.page.isMobileBreakpoint() ? false : NO_CALC_DEFAULT;
};

_defaultCalculators.video = {};
_defaultCalculators.video.fitWidthToPage = function(model /*, style, propName */) {
  return model.element.page.getConfigValue('allowFitWidthToPage');
};

var _getCalculatedDefault = function(model, style, propName) {
  var lookupFn = _defaultCalculators[style][propName];
  return _.isFunction(lookupFn) ? lookupFn(model, style, propName) : NO_CALC_DEFAULT;
};

var _getDefaultFromLookupTable = function(model, style, propName) {
  var elem_type = model.element.type;
  var lookupKeys = [
    // see the example of 'Lookup precedence order' above in _defaults
    _commaify(elem_type, style, propName),
    _commaify(elem_type, propName),
    _commaify(style, propName),
    propName
  ];
  return _firstMatchInMap(_defaults, lookupKeys);
};

var _getDefaultForProp = function(model, style, propName) {
  var calculatedDefault = _getCalculatedDefault(model, style, propName);
  if (calculatedDefault === NO_CALC_DEFAULT) {
    return _getDefaultFromLookupTable(model, style, propName);
  } else {
    return calculatedDefault;
  }
};

var _getDefaults = function(model, style) {
  var propNames = _styleProperties[style];
  var defs = _.zipObject(propNames,
      // the prop default values:
      _.map(propNames,
        function(prop) {
          return _getDefaultForProp(model, style, prop);
        }));
  return defs;
};

var getProperties = function(model) {
  var newProps = model.get('style.newBackground');
  var currentProps = newProps[newProps.type] || {};
  currentProps.style = newProps.type;
  return _.merge({}, _getDefaults(model, newProps.type), currentProps);
};

var _setStyleType = function(model, style) {
  var undoManager = null;
  var props = getProperties(model);
  if (!props || style !== props.style) {
    model.set('style.newBackground.type', style, undoManager);
    if (! model.existsByBreakpoint('style.newBackground.'+style, window.editor.getCurrentBreakpoint()) ) {
      model.set('style.newBackground.'+style, {}, undoManager);
    }
  }
};

var modelSyncHandlers = {};
//
// The model properties have to be set separately
// to trigger the model change handlers in the model.
//

var _resetBackgroundStyle = function(model) {
  var currentFillType = model.safeGet('style.background.fillType');
  model.set('style.background', {fillType: currentFillType}, null);
};

var _syncPageSectionFitToPage = function(model, fitWidthToPage) {
  if (model.element.type === 'lp-pom-block') {
    model.set('geometry.fitWidthToPage', fitWidthToPage || false);
    _.forEach(
      ['right', 'left'],
      function(side) {
          var accessor = 'geometry.borderApply.'+side;
          var savedAccessor = 'geometry.savedBorderState.'+side;
        if (fitWidthToPage) { // force off
          model.set(savedAccessor, model.get(accessor));
          model.set(accessor, false);
        } else { // reset
          if (model.exists(savedAccessor)) {
            model.set(accessor, model.get(savedAccessor));
          } else {
            model.set(accessor, true);
            model.set(savedAccessor, true);
          }
        }
      });
  }
};

modelSyncHandlers.solidColor = function(model, props) {
  var um = null;
  model.set('geometry.backgroundImageApply', false, um);
  _resetBackgroundStyle(model);
  model.set('style.background.backgroundColor', props.bgColor, um);
  model.set('style.background.fillType', 'solid', um);
  model.set('style.background.opacity', props.opacity, um);
  _syncPageSectionFitToPage(model, props.fitWidthToPage);
};

modelSyncHandlers.gradient = function(model, props) {
  var um = null;
  model.set('geometry.backgroundImageApply', false, um);
  _resetBackgroundStyle(model);
  model.set('style.background.fillType', 'gradient', um);
  model.set('style.background.autoGradient', props.autoGradient, um);
  model.set('style.background.backgroundColor', props.baseColor, um);
  model.set('style.background.savedBackgroundColor', props.baseColor, um);
  if (props.autoGradient && props.reverseGradient) {
    model.set('style.background.gradient', {from: props.toColor, to: props.fromColor}, um);
    model.set('style.background.reverseGradient', true, um);
  } else {
    model.set('style.background.gradient', {from: props.fromColor, to: props.toColor}, um);
    model.set('style.background.reverseGradient', false, um);
  }
  _syncPageSectionFitToPage(model, props.fitWidthToPage);
};

modelSyncHandlers.image = function(model, props) {
  var um = null;
  model.set('geometry.backgroundImageApply', !!props.image, um);
  _resetBackgroundStyle(model);
  model.set('style.background.backgroundColor', props.bgColor, um);
  model.set('style.background.image', props.image, um);
  model.set('style.background.backgroundRepeat', 'no-repeat', um);
  model.set('style.background.backgroundPosition', props.position, um);
  var parallax = model.element.page.isMobileBreakpoint() ? false : props.parallax;
  model.set('style.background.imageFixed', parallax, um);
  model.set('style.background.fillType', 'solid', um);
  model.set('style.background.imageStretched', props.fitToContainer, um);
  model.set('style.background.imageAboveColor', !props.isColorOverImage, um);
  model.set('style.background.opacity', props.opacity, um);

  _syncPageSectionFitToPage(model, props.fitWidthToPage);
};

modelSyncHandlers.pattern = function(model, props) {
  var um = null;
  props.fitToContainer = false;
  modelSyncHandlers.image(model, props);
  model.set('style.background.backgroundRepeat', props.tiling, um);
};

modelSyncHandlers.video = function(model, props) {
  var um = null;
  _resetBackgroundStyle(model);
  model.set('style.background.backgroundColor', props.bgColor, um);
  model.set('style.background.fillType', 'solid', um);
  model.set('style.background.imageAboveColor', false, um);
  model.set('style.background.opacity', props.opacity, um);

  _syncPageSectionFitToPage(model, props.fitWidthToPage);
};

//
/////////////////////////////////////////////////////////////////

var _setNewPropertyForStyle = function(model, style, prop, val) {
  var um = null;
  var accessor = 'style.newBackground.'+style+'.'+prop;
  model.set(accessor, val, um);
};

var propertyHandler = {};
propertyHandler.solidColor = function(model, prop, val) {
  _setNewPropertyForStyle(model, 'solidColor', prop, val);
};

propertyHandler.gradient = function(model, prop, val) {
  var set = function(prop, val) {
    _setNewPropertyForStyle(model, 'gradient', prop, val);
  };
  var updateAutoGradient = function() {
    var props = getProperties(model);
    var autoGradientProps = jui.ColorMath.calculateGradient(props.baseColor);
    if (props.reverseGradient) {
      set('toColor', autoGradientProps.from);
      set('fromColor', autoGradientProps.to);
    } else {
      set('toColor', autoGradientProps.to);
      set('fromColor', autoGradientProps.from);
    }
  };
  if(prop === 'baseColor') {
    // propsBefore.autoGradient is implied by this
    set('baseColor', val);
    updateAutoGradient();
  } else if(prop === 'autoGradient') {
    set('autoGradient', val);
    if (!val) {
      set('reverseGradient', false);
    } else {
      updateAutoGradient();
    }
  } else if(prop === 'reverseGradient') {
    var propsBefore = getProperties(model);
    if (propsBefore.autoGradient) {
      set('reverseGradient', !propsBefore.reverseGradient);
      updateAutoGradient();
    } else {
      set('reverseGradient', false);
      set('fromColor', propsBefore.toColor);
      set('toColor', propsBefore.fromColor);
    }
  } else {
    set(prop, val);
  }
};

propertyHandler.image = function(model, prop, val) {
  _setNewPropertyForStyle(model, 'image', prop, val);
  if (_.includes(imagePatternSharedProps, prop)) {
    _setNewPropertyForStyle(model, 'pattern', prop, val);
  }
};

propertyHandler.pattern = function(model, prop, val) {
  _setNewPropertyForStyle(model, 'pattern', prop, val);
  if (_.includes(imagePatternSharedProps, prop)) {
    _setNewPropertyForStyle(model, 'image', prop, val);
  }
};

var _handleVideoError = function(error, model) {
  // The URL entered was not recogonized as valid, so reset all derived model values.
  _setNewPropertyForStyle(model, 'video', 'videoHost', '');
  _setNewPropertyForStyle(model, 'video', 'videoId', '');
  _setNewPropertyForStyle(model, 'video', 'videoRatio', null);
  _setNewPropertyForStyle(model, 'video', 'videoImage', '');

  window.editor.keyController.clearFocus();

  new AlertDialog({
    title: 'Video Backgrounds',
    message: error.message || 'We were unable to access that video. Please try again later.',
    icon: 'error'
  }).safeOpen();
};

propertyHandler.video = function(model, prop, val) {
  _setNewPropertyForStyle(model, 'video', prop, val);

  if (prop === 'videoUrl') {
    // videoUrl is the only property that is directly modifyable by the user. All other
    // properties are derived from it, so this method will either set them all to their
    // derived values or reset them, if the videoUrl is invalid.

    var videoHost = videoHosts.getVideoHostFromUrl(val);
    if (videoHost instanceof Error) {
      return _handleVideoError(videoHost, model);
    }

    var videoHostMethods = videoHosts[videoHost];
    var videoId          = videoHostMethods.getIdFromUrl(val);
    if (videoId instanceof Error) {
      return _handleVideoError(videoId, model);
    }

    videoHostMethods.getImageAndRatio(videoId, function(values) {
      if (values instanceof Error) {
        _handleVideoError(values, model);
      } else {
        _setNewPropertyForStyle(model, 'video', 'videoHost',  videoHost);
        _setNewPropertyForStyle(model, 'video', 'videoId',    videoId);
        _setNewPropertyForStyle(model, 'video', 'videoRatio', values.ratio);
        _setNewPropertyForStyle(model, 'video', 'videoImage', values.image);
      }
    });
  }
};

var withBreakpoint = function(model, breakpointName, blockFn) {
  var stashedBreakpoint = editor.page.currentBreakpoint.name;
  var targetBreakpoint = editor.page.getBreakpointByName(breakpointName);
  var stashedChangeIgnore = model.attributeChangeIgnore;

  model.attributeChangeIgnore = _.constant(true);
  editor.page.currentBreakpoint = targetBreakpoint;
  try {
    return blockFn();
  } finally {
    model.attributeChangeIgnore = stashedChangeIgnore;
    editor.page.currentBreakpoint = editor.page.getBreakpointByName(stashedBreakpoint);
  }
};

var syncModel = function(model) {
  var props = getProperties(model);
  modelSyncHandlers[props.style](model, props);
  if (editor.page.isDesktopBreakpoint() &&
      model.existsByBreakpoint('style.newBackground', 'mobile')) {

    withBreakpoint(model, 'mobile', function(){
      var mobileProps = getProperties(model);
      modelSyncHandlers[mobileProps.style](model, mobileProps);
    });
  }
};

var _isValidStyleType = function(style) {
  return _.includes(backgroundStyleTypes, style);
};

var _ensureCurrentBreakpointHasExplicitStyleType = function(model, style) {
  // NOTE: we always want this to be explicitly set on the
  // breakpoint a bg prop is being written to
  if ( ! _isValidStyleType(style)) {
    //TODO: log something
    return;
  }

  var accessor = 'style.newBackground.type';
  var breakpointName = window.editor.getCurrentBreakpoint();
  if ( ! model.existsByBreakpoint(accessor, breakpointName)) {
    model.setOnWritableModelByBreakpoint(accessor, style, breakpointName);
  }
};

//////////////////////////////////////////////////////////////////////
var changeEventsSubject = new Rx.Subject();
window.lp.pom.NewBGPropsHelper = {

  changeEvents: changeEventsSubject,
  getProperties: getProperties,
  withBreakpoint: withBreakpoint,

  syncModel: syncModel,
  getStyleType: function(model) {
    return model.get('style.newBackground.type');
  },

  setStyleType: function(model, style) {
    // This should only be called from the UI code or undo!
    if ( ! _isValidStyleType(style)) {
      //TODO: log something?
      return;
    }
    model.element.page.undoManager.startGroup();

    var oldStyleForUndo;
    if (model.exists('style.newBackground.type')) {
      // ^ this would only be undefined if the upgrader had failed.
      // But, we still need to explicitly handle that.
      oldStyleForUndo = model.get('style.newBackground.type');
    }

    _setStyleType(model, style);
    syncModel(model);

    window.editor.page.undoManager.registerUndo({
      action: lp.pom.NewBGPropsHelper.setStyleType,
      receiver:{},
      params:[model, oldStyleForUndo]
    });
    model.element.page.undoManager.endGroup();

    changeEventsSubject.onNext({model: model, type: 'setStyleType', style: style});
  },

  setProperty: function(model, style, property, val){
    // This should only be called from the UI code or undo!
    model.element.page.undoManager.startGroup();
    var old_value = _getNewPropNoDefaults(model, style, property);

    // patch up missing style types in case of failed props upgrade:
    _ensureCurrentBreakpointHasExplicitStyleType(model, style);

    propertyHandler[style](model, property, val);
    syncModel(model);
    window.editor.page.undoManager.registerUndo({
      action: lp.pom.NewBGPropsHelper.setProperty,
      receiver:{},
      params:[model, style, property, old_value]
    });
    model.element.page.undoManager.endGroup();
    changeEventsSubject.onNext({
      model: model, type: 'setProperty',
      style: style,
      property: property,
      old_value: old_value,
      value: val
    });
  }
};

// exports : lp.pom.NewBGPropsHelper
