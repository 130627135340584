"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function fetchImageDimensions(url) {
    return new Promise(function (resolve, reject) {
        var imageElement = document.createElement('img');
        imageElement.style.visibility = 'hidden';
        imageElement.addEventListener('load', function () {
            resolve({
                // Firefox cannot handle svgs that does not have specified dimensions.
                // In this case we fallback on imageElement.width and imageElement.height.
                // https://bugzilla.mozilla.org/show_bug.cgi?id=700533
                width: imageElement.naturalWidth || imageElement.width,
                height: imageElement.naturalHeight || imageElement.height,
            });
            document.body.removeChild(imageElement);
        });
        imageElement.addEventListener('error', function () { return reject(new Error('Image failed to load')); });
        imageElement.src = url;
        document.body.appendChild(imageElement);
    });
}
exports.default = fetchImageDimensions;
