import apiClient from 'ub/control/api-client';
import brandState from 'ui/brand/state-controller';
import AlertDialog from 'ub/ui/alert-dialog';
import { Messages } from './messages';

const BRAND_PACK_URL = '/assets/colors';

const createColorPack = async () => {
  try {
    const response = await apiClient(BRAND_PACK_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        colors: [{ css_value: 'rgba(255,255,255,1)', usage_data: { source: 'user', usages: [] } }],
      }),
    });
    const data = await response.json();
    brandState.brandColorsSubject.onNext({
      type: 'setBrandColors',
      value: data,
    });
  } catch (err) {
    console.trace(Messages.SAVING_COLOR_ERROR, err);
  }
};

const getColors = async () => {
  try {
    const response = await apiClient(BRAND_PACK_URL);
    if (response.ok) {
      const data = await response.json();
      return data;
    }
  } catch (err) {
    // If there are no brand colors yet, we create a new asset pack
    if (err.res.status === 404) {
      await createColorPack();
    } else {
      console.trace(Messages.RETRIEVING_COLORS_ERROR, err);
    }
  }
};

// Load Brand Colors
const loadBrandColors = async () => {
  getColors().then(res => {
    brandState.brandColorsSubject.onNext({
      type: 'setBrandColors',
      value: res,
    });
  });
};

const updateColors = async (newColorPack, isAddingColor, conflictHandler) => {
  const errorMessage = isAddingColor ? Messages.SAVING_COLOR_ERROR : Messages.REMOVING_COLOR_ERROR;
  try {
    const response = await apiClient(BRAND_PACK_URL, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(newColorPack),
    });

    if (response.ok) {
      const data = await response.json();
      brandState.brandColorsSubject.onNext({
        type: 'setBrandColors',
        value: data,
      });
    } else {
      if (response.status === 409) {
        if (conflictHandler) {
          await conflictHandler();
        } else {
          new AlertDialog({
            title: 'Brand Colors',
            message: Messages.SAVING_COLOR_CONFLICT_ERROR,
            icon: 'error',
          }).safeOpen();
        }
        return;
      }
      new AlertDialog({
        title: 'Brand Colors',
        message: errorMessage,
        icon: 'error',
      }).safeOpen();
    }
  } catch (err) {
    new AlertDialog({
      title: 'Brand Colors',
      message: Messages.SAVING_COLOR_CONFLICT_ERROR,
      icon: 'error',
    }).safeOpen();
  }
};

const addColor = async (newColorPack, addedColor) => {
  const conflictHandler = async () => {
    try {
      const response = await apiClient(BRAND_PACK_URL);

      const data = await response.json();
      const { colors: freshColors, updated_at: freshUpdatedAt } = data;

      const colorInBrandColors = freshColors.some(
        bColor => bColor.css_value === addedColor.css_value
      );

      if (!colorInBrandColors) {
        // conflictHandler excluded as we only want to try handling a conflict once to avoid looping
        const freshAssetColor = {
          colors: [...freshColors, addedColor],
          updated_at: freshUpdatedAt,
        };
        await updateColors(freshAssetColor, true);
      }
    } catch (err) {
      new AlertDialog({
        title: 'Brand Colors',
        message: Messages.SAVING_COLOR_ERROR,
        icon: 'error',
      }).safeOpen();
    }
  };

  await updateColors(newColorPack, true, conflictHandler);
};

const removeColor = async (newColorPack, removedColor) => {
  const conflictHandler = async () => {
    try {
      const response = await apiClient(BRAND_PACK_URL);

      const data = await response.json();
      const { colors: freshedColors, updated_at: freshUpdatedAt } = data;
      const updatedBrandColors = freshedColors.filter(rcolor => rcolor.css_value !== removedColor);

      // conflictHandler excluded as we only want to try handling a conflict once to avoid looping
      const updatedAssetColor = { colors: [...updatedBrandColors], updated_at: freshUpdatedAt };
      await updateColors(updatedAssetColor, false);
    } catch (err) {
      new AlertDialog({
        title: 'Brand Colors',
        message: Messages.REMOVING_COLOR_ERROR,
        icon: 'error',
      }).safeOpen();
    }
  };

  await updateColors(newColorPack, false, conflictHandler);
};

export { addColor, removeColor, loadBrandColors };
