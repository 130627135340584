export const createSelectorKey = selector => {
  // NOTE: the code used to do
  // return selector.replace(/\W/g, '');
  // not sure why but see LP-6413 for the damage it caused
  // leaving this note here till LP-6413 is fully resolved
  return selector.trim().replace(/[ \-,#]/g, '');
};

export const createMediaKey = media => (media ? media.join(',') : 'default');

export const addRuleSet = (pageStyle, selector, attributes) => {
  Object.keys(attributes)
    .filter(key => key !== 'selector')
    .forEach(attribute =>
      pageStyle.setCSSRule({ selector, attribute, value: attributes[attribute] })
    );
};
