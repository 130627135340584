import {
  parse as parseHtmlToJs,
  stringify as parseJsToHtml
} from 'himalaya';
import validateHtml from 'ub/data/validate-html';

const wistiaJsRegex = /^https:\/\/fast\.wistia\.com\/embed\/medias\/.*\.jsonp$/;
const wistiaSettingsRegex = /^https:\/\/fast\.wistia\.com\/assets\/external\/E-v1\.js$/;

const validElms = ['iframe', 'object', 'video'];

const isValidSrcAttr = (attr) => (
  attr.key === 'src' && !attr.value.includes(" ") &&
  Boolean(attr.value.trim())
);

const isWistiaDiv = (elm) => (
  ['div', 'span'].includes(elm.tagName) &&
  elm.attributes.some(({key, value}) => {
    return key === 'class' && value.includes('wistia_');
  })
);

const getScriptSrcValue = (elm) => {
  if (elm.tagName !== 'script') { return ''; }

  return elm.attributes
    .filter(isValidSrcAttr)
    .map(attr => attr.value);
};

const isWistiaScript = (elm) => wistiaJsRegex.test(getScriptSrcValue(elm));
const isWistiaSettings = (elm) => wistiaSettingsRegex.test(getScriptSrcValue(elm));

const isWistiaElm = (elm) => (
  isWistiaScript(elm) ||
  isWistiaSettings(elm) ||
  isWistiaDiv(elm)
);

const containsSrcAttr = (parentElm) => {
  const parentHasSrc = parentElm.attributes.some(isValidSrcAttr);
  const parentTag = parentElm.tagName;

  if (parentHasSrc) {
    return true;
  } else if (parentTag === 'video') {
    return parentElm.children
      .filter(elm => elm.tagName === 'source')
      .map(elm => elm.attributes.some(isValidSrcAttr))
      .every(Boolean);
  } else if (parentTag === 'object') {
    return parentElm.children
      .filter(elm => elm.tagName === 'embed')
      .map(elm => elm.attributes.some(isValidSrcAttr))
      .every(Boolean);
  } else {
    return false;
  }
};

const filterChildren = (parentElm) => {
  const parentTag = parentElm.tagName;

  return parentElm.children
    .filter(elm => {
      if (elm.tagName === 'text') {
        return true;
      } else if (parentTag === 'object') {
        return ['param', 'embed'].includes(elm.tagName);
      } else if (parentTag === 'video') {
        return elm.tagName === 'source';
      } else {
        return false;
      }
    })
    .map(elm => {
      elm.children = [];

      return elm;
    });
};

const parseStyleAttrForSize = (styleAttr = '') => {
  return styleAttr
    .split(';')
    .map(attr => attr.split(':'))
    .map(attrArr => {
      return [
        attrArr[0] && attrArr[0].trim(),
        attrArr[1] && attrArr[1].trim()
      ];
    })
    .filter(attrArr => {
      const key = attrArr[0];
      const value = attrArr[1];

      return Boolean(key) &&
        Boolean(value) &&
        ['width', 'height'].includes(key);
    })
    .map(attrArr => {
      const key = attrArr[0];
      const value = attrArr[1];

      return { [key]: value };
    })
    .reduce((acc, curr) => ({...acc, ...curr}), {});
};

const cleanAndNormalizeElm = (elm) => {
  const normalElm = JSON.parse(JSON.stringify(elm));

  normalElm.children = filterChildren(normalElm);

  const elmAttrs = elm.attributes
    .map(({key, value}) => ({ [key]: value }))
    .reduce((acc, curr) => ({...acc, ...curr}), {});

  const parsedStyleAttrs = parseStyleAttrForSize(elmAttrs.style);
  const newWidth = { key: 'width', value: '100%' };
  const newHeight = { key: 'height', value: '100%' };

  normalElm.attributes = normalElm.attributes
      .filter(({key}) => !['width', 'height', 'style'].includes(key));

  if (elmAttrs.width && elmAttrs.height) {
    newWidth.value = elmAttrs.width;
    newHeight.value = elmAttrs.height;
  } else if (parsedStyleAttrs.width && parsedStyleAttrs.height) {
    newWidth.value = parsedStyleAttrs.width;
    newHeight.value = parsedStyleAttrs.height;
  }

  normalElm.attributes.push(newWidth, newHeight);

  return normalElm;
};

const validateElm = (elm) => {
  const valid = containsSrcAttr(elm);

  const elmData = { valid };

  if (valid) {
    const cleanedElm = cleanAndNormalizeElm(elm);

    elmData.content = parseJsToHtml([cleanedElm]);
  } else {
    elmData.warning = 'Your src attribute appears to be missing or invalid';
  }

  return elmData;
};

const containsAllWistiaElms = (elms) => {
  const initialState = {
    wistiaScript: false,
    wistiaSettings: false,
    wistiaElm: false
  };

  const wistiaState = elms
    .reduce((state, elm) => {
      if (isWistiaDiv(elm)) {
        state.wistiaElm = true;
      } else if (isWistiaScript(elm)) {
        state.wistiaScript = true;
      } else if (isWistiaSettings(elm)) {
        state.wistiaSettings = true;
      }

      return state;
    }, initialState);

  return Object.values(wistiaState).every(Boolean);
};

const validateForWistia = (elms) => {
  const elmData = { sandbox: true };

  const wistiaElms = elms.filter(elm => isWistiaElm(elm));

  elmData.valid = containsAllWistiaElms(wistiaElms);

  if (elmData.valid) {
    elmData.content = parseJsToHtml(wistiaElms);
  } else {
    elmData.warning = 'Your wistia script appears to be invalid';
  }

  return elmData;
};

const bracketsMatch = (string) => {
  const openingBrackets = (string.match(/</g) || []).length;
  const closingBrackets = (string.match(/>/g) || []).length;
  return openingBrackets === closingBrackets;
};

const validate = embedCode => {

  if (!bracketsMatch(embedCode)) {
    return {
      valid: false,
      warning: `Please ensure you're using the correct angle brackets: < >`
    };
  }

  const isValidHtml = validateHtml(embedCode);

  if (!isValidHtml) {
    return {
      valid: false,
      warning: 'Sorry, the embed code you have entered appears to be invalid'
    };
  }

  const parsedElms = parseHtmlToJs(embedCode);

  if (parsedElms.length === 0) {
    return {
      valid: false,
      warning: 'Please enter your video embed code'
    };
  }

  const mainElm = parsedElms[0];

  if (validElms.includes(mainElm.tagName)) {
    return validateElm(mainElm);

  } else if (isWistiaElm(mainElm)) {
    const compatibleElms = parsedElms.filter(elm => isWistiaElm(elm));
    return validateForWistia(compatibleElms);

  } else {
    return {
      valid: false,
      warning: 'Sorry, the embed code you have entered appears to be invalid'
    };
  }
};

export default { validate };
