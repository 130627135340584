/* globals Class, $H */

window.jui.FormSizeInput = Class.create( window.jui.Component, window.jui.ControlModel, {
  options: function($super,options){
    return $super($H({
      attributes:{className:'form-elm size-input'},
      label:'Size',
      labelW: 'W',
      labelH: 'H',
      hideWidth:false,
      hideHeight:false,
      disableWidth:false,
      disableHeight:false,
      minWidth:0,
      minHeight:0,
    }).merge(options));
  },

  initialize:function( $super, options ){
    options = options || {};
    $super( options );

    if ( this.options.onfocus ) {
      this.addListener( 'focus', this.options.onfocus );
    }

    if ( this.options.onblur ) {
      this.addListener( 'blur', this.options.onblur );
    }

    if ( this.options.onmaintainARChange ) {
      this.addListener( 'maintainARChanged', this.options.onmaintainARChange );
    }

    this.aspectRatio = 1;
    this.options.minWidth = options.minWidth || 0;
    this.options.maxWidth = options.maxWidth || 100000;
    this.options.minHeight = options.minHeight || 0;
    this.options.maxHeight = options.maxHeight || 100000;
  },

  installUI: function($super) {
    $super();
    this.insert(new Element('label',{className:'form-elm-label'}).update(this.options.label));

    if ( ! this.options.hideWidth) {
      if ( ! this.options.hideMiniLabel) {
        this.labelW = this.insert(new Element('label', {className:'mini form-elm-mini-label'}).update(this.options.labelW));
      }
      this.width = this.insert(new Element('input', {type:'text', className:'text width form-elm-input form-elm-input-text form-elm-with-label form-elm-mini-input'}));

      if ( ! this.options.disableWidth) {
        this.width.observe('focus', this.onFocus.bind(this));
        this.width.observe('blur', this.onBlur.bind(this));
        this.width.observe('keypress', window.jui.NumericInputFilter.filter);
        this.width.observe('keyup', this.widthChanged.bind(this));
      }
      this.width.disabled = this.options.disableWidth;
    }

    var self = this;
    if (this.hasMaintainAspectRatio()) {
      // this.maintainAR = this.insert( new Element('input', {type:'checkbox'}));
      this.maintainAR = this.insert(new window.jui.IconToggle('field_link svg-ico-', {
        action: function(){
          self.fireEvent('maintainARChanged', self.isMaintainAspectRatio());
          if (self.maintainAR.isSelected()) {
            // 'Lock' the current aspect ratio
            self.aspectRatio = self.width.value / self.height.value;
          }
        },
        toolTip:'Maintain aspect ratio'
      }));
    }

    if ( ! this.options.hideHeight) {
      if ( ! this.options.hideMiniLabel) {
        this.labelH = this.insert(new Element('label', {className:'mini form-elm-mini-label'}).update(this.options.labelH));
      }
      this.height = this.insert(new Element('input', {type:'text', className:'text height form-elm-input form-elm-input-text form-elm-with-label form-elm-mini-input'}));

      if ( ! this.options.disableHeight) {
        this.height.observe('focus', this.onFocus.bind(this));
        this.height.observe('blur', this.onBlur.bind(this));
        this.height.observe('keypress', window.jui.NumericInputFilter.filter);
        this.height.observe('keyup', this.heightChanged.bind(this));
      }
      this.height.disabled = this.options.disableHeight;
    }
  },

  setValue: function(value) {
    if (!this.options.hideWidth) {
      this.width.value = Math.min(Math.max(this.options.minWidth ,value.width),this.options.maxWidth);
    }

    if (!this.options.hideHeight) {
      this.height.value = Math.min(Math.max(this.options.minHeight,value.height),this.options.maxHeight);
    }

    this.aspectRatio = value.width && value.height ? value.width / value.height : 1;
  },

  getValue: function() {
    var value = {};
    if (!this.options.hideWidth) {
      value.width = this.width.value === '' ? '' : this.width.value * 1;
    }
    if (!this.options.hideHeight) {
      value.height = this.height.value === '' ? '' : this.height.value * 1;
    }
    return value;
  },

  hasMaintainAspectRatio: function() {
    return this.options.showMaintainAR;
  },

  isMaintainAspectRatio: function() {
    return this.hasMaintainAspectRatio() ? this.maintainAR.isSelected() : false;
  },

  setMaintainAspectRatio: function(bool) {
    if (this.hasMaintainAspectRatio()) {
      this.maintainAR.setSelected(bool);
    }
  },

  onFocus: function( e ) {
    this.memorizedSize = {
      width: this.width.value,
      height: !this.options.hideHeight ? this.height.value : null
    };
    if( this.isEnabled() ) {
      e.stop();
      this.fireEvent('focus', this);
      this.focusField = e.findElement('input');
    }
  },

  widthChanged: function(e) {
    if (this.isMaintainAspectRatio() && e.keyCode !== 9) {
      this.height.value = Math.round(this.width.value / this.aspectRatio);
    }
  },

  heightChanged: function(e) {
    if (this.isMaintainAspectRatio() && e.keyCode !== 9) {
      this.width.value = Math.round(this.height.value * this.aspectRatio);
    }
  },

  onkeydown: function(e) {
    if(e.keyCode === Event.KEY_RETURN) {
      e.target.blur();
    }
  },

  onkeyup: function() {
  },

  onBlur: function(e) {
    if (this.isEnabled() &&
        this.isValidValue(e.target.value) &&
        (e.target === this.width ? this.isValidWidth(e.target.value) : this.isValidHeight(e.target.value))) {
      e.stop();
    } else {
      this.resetValues();
    }
    this.blur();
  },

  resetValues: function() {
    if (!this.options.hideWidth) {
      this.width.value = this.memorizedSize.width;
    }
    if (!this.options.hideHeight) {
      this.height.value = this.memorizedSize.height;
    }
  },

  isValidWidth: function(width) {
    return width >= this.options.minWidth && width <= this.options.maxWidth;
  },

  isValidHeight: function(height) {
    return height >= this.options.minHeight && height <= this.options.maxHeight;
  },

  isValidValue: function(value) {
    return value !== null && value !== '' && !isNaN(value);
  },

  blur: function() {
    if (this.focusField !== null) {
      this.fireEvent('blur', this);
    }
  }
});
