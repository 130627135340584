/* globals lp, jui, Class */
var jQuery = require('jquery');

var VideoBackground = require('ub/video-background');

var RootElement = Class.create(
      lp.pom.VisibleElement,
      lp.ModuleComponent, {
        type: 'lp-pom-root',

        initialize: function($super, page, jso, context, options){
          // JS: TODO: this should be handled by a future pom updater
          if (Object.isUndefined(jso.geometry.contentWidth)) {
            jso.geometry.contentWidth = page.getDefaultWidth();
          }

          var self = this;
          this.blockListener = function(e){
            var accessor = e.data.accessor;

            if(accessor === 'geometry.size.width') {return;}

            if (accessor.startsWith('geometry.size') ||
                accessor.startsWith('geometry.margin') ||
                accessor.startsWith('style.border') ||
                accessor.startsWith('geometry.borderLocation') ||
                accessor.startsWith('geometry.borderApply')) {
              self.fireEvent('blockResized');
              self.fireEvent('layoutChanged');
            }
          };

          this.fireBlockHidden = function() {
            self.fireEvent('blockHidden');
            self.fireEvent('layoutChanged');
          };

          this.fireBlockShown = function() {
            self.fireEvent('blockShown');
            self.fireEvent('layoutChanged');
          };

          $super(page, jso, context, options);
          this.applyDefaultStyles();

          // Set the initial geometry.contentWidth model value for each breakpoint
          this.page.breakpoints.forEach(this.setContentWidthForBreakpoint.bind(this));
        },

        getModelClass: function() {
          return lp.module.root.RootModel;
        },

        createDefaultConstraints: function($super) {
          $super();
          this.defaultConstraints.displayable       = false;
          this.defaultConstraints.renameable        = false;
          this.defaultConstraints.offsetable        = false;
          this.defaultConstraints.orderable         = false;
          this.defaultConstraints.indexable         = false;
          this.defaultConstraints.height_resizeable = false;
          this.defaultConstraints.removable         = false;
          this.defaultConstraints.opacityable       = true;
          this.defaultConstraints.parallaxable      = true;
        },

        initView: function() {
          if (this.page.isEditMode() && this.page.isMain() && !this.isDisplayPositionBottom()) {
            // This rule ensures that the root expands to cover the last page section's bottom margin
            this.view.e.style.paddingBottom = "1px";
          }

          this.overlay = new lp[this.page.isEditMode() ? 'editor' : 'publisher']
                               .ColorOverlayElement(this);

          this.videoBackground = new VideoBackground[this.page.isEditMode() ? 'edit' : 'publish'](this);

          this.positionedContent = this.view.insert(new jui.Component({attributes:{className:'lp-positioned-content'}}));
        },

        modelChanged: function($super, e) {
          var details = $super(e);
          var updateStyles = false;
          if (details.accessor === 'geometry.contentWidth') {
            this.fireEvent('contentWidthChanged', details.value);
            this.fireEvent('layoutChanged');
          } else if (details.accessor.startsWith('geometry.padding')) {
            this.fireEvent('layoutChanged', details.value);
          } else if (details.accessor === 'style.defaults.color') {
            this.applyDefaultTextColor();
            updateStyles = true;
          } else if (details.accessor === 'style.defaults.linkColor') {
            this.applyDefaultLinkColor();
            updateStyles = true;
          }
          if (updateStyles) {
            this.page.style.updatePageStyles();
          }
        },

        applyDefaultTextColor: function(){
          var bodyColor = this.model.get('style.defaults.color');
          if(bodyColor.strip() !== '') {
            this.page.style.setCSSRule({selector:'body', attribute:'color', value:'#'+bodyColor});
          }
        },

        applyDefaultLinkColor: function(){
          var linkColor = this.model.get('style.defaults.linkColor');
          if(linkColor.strip() !== '') {
            this.page.style.setCSSRule({selector:'a', attribute:'color', value:'#'+linkColor});
          }
        },

        applyDefaultTopPadding: function() {
          if(!this.model.exists('geometry.padding.top')) {
            this.model.set('geometry.padding.top', 0);
          }
        },

        applyDefaultStyles: function() {
          this.applyDefaultTextColor();
          this.applyDefaultLinkColor();
          this.applyDefaultTopPadding();
          this.page.style.setCSSRule({selector:'a', attribute:'text-decoration', value:this.model.get('style.defaults.linkDecoration')});
          if (this.page.isInserted()) {
            this.page.style.updatePageStyles();
          }
        },

        applyGeometry: function($super) {
          /* jshint unused:vars */
          var s = this.view.e.style;
          var pcs = this.positionedContent.e.style;
          var m = this.model;
          var cw = m.get('geometry.contentWidth');
          var breakpoint = this.page.getCurrentBreakpoint();
          var rules = [];
          var padding = this.page.getUnit(this.getTopPadding());

          if (this.page.isEditMode()){
            pcs.top = padding;

            pcs.width = cw + 'px';
            pcs.marginLeft = -Math.round(cw / 2) + 'px';
            s.minWidth = pcs.width;
            s.maxWidth = typeof breakpoint.width === 'number' ? breakpoint.width + 'px' : '';
          } else {
            // isPublishOrPreviewMode
            rules.push({attribute:'top', value: padding, selector:'#lp-pom-root .lp-positioned-content'});
            rules.push({attribute:'width', value: this.page.getUnit(cw), selector:'#lp-pom-root .lp-positioned-content'});
            rules.push({attribute:'margin-left', value: this.page.getUnit(-Math.round(cw / 2)), selector:'#lp-pom-root .lp-positioned-content'});
            rules.push({attribute:'min-width', value: this.page.getUnit(cw)});

            // We calculate a pixel height to ensure the root and overlay covers a margin
            // below the last page section
            rules.push({attribute:'height', value: this.page.getUnit(this.calculateHeight())});

            this.applyPageStyles(rules);
          }
        },

        calculateHeight: function() {
          // Calculate height of the root by adding up the heights of each visible page
          // section contained inside it (including their borders and margins)
          return this.getSections()
            .filter(function(section) {
              return section.model.isVisible();
            })
            .reduce(function(height, section) {
              return height + section.getHeightWithBorderAndMargin();
            }, 0);
        },

        calculateHeightWithTopPadding: function() {
          var topPadding = this.model.safeGet('geometry.padding.top') || 0;
          return this.calculateHeight() + topPadding;
        },

        insertChildElement: function($super, elm, options) {
          options = options || {};
          if (!Object.isUndefined(options.containerIndex) && options.containerIndex < this.childElements.length) {
            elm.fireEvent('moved');
          }

          $super(elm, options);
          elm.addListener('hidden', this.fireBlockHidden);
          elm.addListener('shown', this.fireBlockShown);
          this.fireEvent('blockInserted', elm);
          this.fireEvent('layoutChanged');
        },

        isInserted: function() {
          return this.view.e.parentNode !== null;
        },

        removeChildElement: function($super, elm) {
          $super(elm);
          elm.removeListener('hidden', this.fireBlockHidden);
          elm.removeListener('shown', this.fireBlockShown);
          this.fireEvent('blockRemoved', elm);
          this.fireEvent('layoutChanged');
        },

        swapElementOrder: function($super, a, b) {
          $super(a,b);
          this.fireEvent('blockMoved');
          jQuery(document).trigger('blockMoved');
          this.fireEvent('layoutChanged');
        },

        getContentWidth: function() {
          return this.model.get('geometry.contentWidth');
        },

        setContentWidthForBreakpoint: function(breakpoint) {
          // Enforce that the geometry.contentWidth model value is within the page's allowed min and
          // max for the given breakpoint
          var currentWidth = this.model.get('geometry.contentWidth', breakpoint);
          var minWidth = this.page.getMinWidthForBreakpoint(breakpoint);
          var maxWidth = this.page.getMaxWidthForBreakpoint(breakpoint);

          if (currentWidth < minWidth) {
            this.model.setOnWritableModelByBreakpoint('geometry.contentWidth', minWidth, breakpoint);
          } else if (currentWidth > maxWidth) {
            this.model.setOnWritableModelByBreakpoint('geometry.contentWidth', maxWidth, breakpoint);
          }
        },

        getPageWidth: function() {
          return this.view.getWidth();
        },

        getDefaultSectionSize: function() {
          return {
            width  : this.model.get('geometry.contentWidth'),
            height : this.page.getConfigValue('sectionHeight')
          };
        },

        getParentElement: function() {
          return this.parentElement || this.page;
        },

        getPageOffset: function() {
          return {left:0, top:0};
        },

        getMinZIndex: function() {
          return 1;
        },

        getMaxZIndex: function() {
          return this.getMinZIndex() + this.getDescendants(true).length - 1;
        },

        getIndexables: function() {
          return this.getDescendants(true, 2);
        },

        getDescendantsSortedByZIndex: function() {
          return this.getDescendants(true).sortBy(function(e) {
            return e.model.getZIndex();
          });
        },

        compactZIndexes: function() {
          var min = this.getMinZIndex();
          this.getDescendantsSortedByZIndex().each(function(e, i) {
            if (e.model.getZIndex() !== i+min) {
              e.model.setZIndex(i+min);
            }
          });
        },

        insertZIndex: function(minZ, gap) {
          gap = gap || 1;
          this.childElements.each(function(e) {
            e.childElements.each(function(e) {
              if (e.is('z_indexable')) {
                var current = e.model.getZIndex();
                if (current >= minZ) {
                  e.model.setZIndex(current + gap);
                }
              }
            });
          });
        },

        hasSections: function() {
          return this.getSections().length > 0;
        },

        getTopPadding: function() {
          return this.model.exists('geometry.padding.top') ? this.model.get('geometry.padding.top') : 0;
        },

        getSections: function() {
          return this.childElements.filter(function(element) {
            return element.type === 'lp-pom-block' && element.id !== 'dummy-section';
          });
        },

        isDisplayPositionBottom: function() {
          // The `displaySettings.position` model property specifies whether a Convertable
          // sticky bar should be displayed as a header or footer.
          return this.model.safeGet('displaySettings.position') === 'bottom' &&
            this.page.getConfigValue('showRootDisplayPositionControls');
        },

        destroy: function($super) {
          $super();
          this.page.style.removeCSSRules('#'+this.id);
        }
      }
  );

RootElement.elementDefaults = {
  name: 'New Page Root',
  style:{
    background: {
      backgroundColor: 'eee',
      opacity: 100
    },
    newBackground: {
      type: 'solidColor',
      solidColor: {},
    },
    defaults:{
      color: '000',
      linkColor: '0000ff',
      linkDecoration: 'none'
    }
  },
  geometry:{
    position: 'relative',
    backgroundImageApply: false,
    margin: 'auto'
  }
};

module.exports = RootElement;
