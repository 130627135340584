// TODO: Bake this into src/legacy/lp/pom/updates/updates.js. Each individual updater shouldn't need
// to perform this check.

export default function checkVersion(minVersion, newVersion, pomVersion = '0') {
  if (pomVersion >= newVersion) {
    return false;
  }

  if (pomVersion < minVersion) {
    throw new Error(`Page version ${pomVersion} can't be updated to version ${newVersion}`);
  }

  return true;
}
