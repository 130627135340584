/* globals lp,Class */
var _ = require('lodash');

module.exports = Class.create(lp.pom.VisibleElementModel, {
  initialize: function($super, element, jso) {
    $super(element, jso);
    this.addBreakpointModifyableAttributes({
      content: {
        html: false
      }
    });
    this.addBreakpointAllowDuplicateAttributes({
      geometry:{
        visible:true
      }
    });
  },
  // TODO-TR: LP-6616 move this up to element_model
  addBreakpointModifyableAttributes: function(object) {
    _.merge(this.breakpointModifyableAttributes, object);
  },

  addBreakpointAllowDuplicateAttributes: function(object) {
    _.merge(this.breakpointAllowDuplicateAttributes, object);
  }
});
